import React from 'react';
import './styles.scss';

import VendorFilesTable from "../../components/specific/VendorFilesTable/VendorFilesTable";
import FileApi from "../../api/File/FileAPI";
import {IVendorFile} from "../../api/VendorFile/VendorFileTypes";
import AppStore from "../../stores/App/AppStore";
import { FileUtils } from '../../global/utils/FileUtils';

interface Props {
    vendorId: number
}

function VendorFilesContainer(props: Props) {
    const {vendorId} = props;
    async function onVendorFilesTableRowClick(vendorFile: IVendorFile, vendorId?: number) {
        if (vendorId) {
            let url = await FileApi.GetFileUrl(vendorFile.file_id, vendorId);
            if(url) FileUtils.downloadFileFromUrl(vendorFile.file_name, url);
        }
    }

    return (
        <section className='vendor-container'>
            <VendorFilesTable vendorId={AppStore.user?.vendor?.id || vendorId}
                              onClickRow={onVendorFilesTableRowClick}/>
        </section>
    )
}

export default VendorFilesContainer;