import React from 'react';
import moment from 'moment';

import {DeleteOutlined, RedoOutlined} from '@ant-design/icons';
import {IUser} from "../../../api/User/UserTypes";
import Button from '../../common/Button/Button';

export const UserTableConfig = (reinviteUser:(user: IUser) => void, onDeleteUser?: (user: IUser) => void) => [
    {
        title: 'User', dataIndex: 'email', key: 'email',
    },
    {
        title: 'Created', dataIndex: 'created_at', key: 'created_at',
        render: (created_at:string) => {
            return `${moment(created_at).format('MMM DD, YYYY')}`
        }
    },
    {
        title: 'Actions', key: 'actions',
        render: (fullRecord: IUser) => {
            if (onDeleteUser) {
                return <div className='actions'>
                    <Button color='orange' icon={<RedoOutlined/>} onClick={() => reinviteUser(fullRecord)}>Reinvite</Button>
                    <Button className='delete-user' color='red' icon={<DeleteOutlined/>} onClick={() => onDeleteUser(fullRecord)}>Delete</Button>
                </div>
            } else {
                return <div className='actions'>
                    <Button color='orange' icon={<RedoOutlined/>} onClick={() => reinviteUser(fullRecord)}>Reinvite</Button>
                </div>;
            }
        }
    },
];