import React from 'react';
import {Select} from 'antd';

import './style.scss';

import Status from "../Status/Status";
import { EQuestionnaireStatus } from '../../../api/Questionnaire/QuestionnaireTypes';

const {Option} = Select;

export interface Props {
    onChange?: (selectedStatus?: number) => void,
    defaultValue?:EQuestionnaireStatus,
    loading?:boolean,
    value?:any
    disabled?:boolean,
}

function QuestionnaireStatusSelect(props:Props) {
    const {onChange, defaultValue, loading, value, disabled} = props;

    function onClear() {
        if (onChange) onChange(undefined);
    }

    return (
        <div className='assessment-status-select' onClick={e=>e.stopPropagation()}>
            <Select className='assessment-status-select-search' size='large' placeholder='Choose status' loading={loading} value={value}
                    optionLabelProp='children' disabled={disabled} onChange={onChange} defaultValue={defaultValue} allowClear onClear={onClear}>
                {Object.keys(EQuestionnaireStatus).map(key => (
                    <Option value={EQuestionnaireStatus[key]} key={EQuestionnaireStatus[key]}>
                        <Status status={EQuestionnaireStatus[key]}/>
                    </Option>
                ))}
            </Select>
        </div>
    )
}
export default QuestionnaireStatusSelect;