import { Alert, Col, Row, Tag } from "antd"
import React from "react"
import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import RiskFindingDrawer from "./RiskFindingDrawer"
import RiskFindingStatus from "./RiskFindingStatus"
import {
  KeyOutlined,
  WarningOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons"
import moment from "moment"
import RiskReductionBadge from "./RiskReductionBadge"
import { IRemediationPathway } from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import Button from "../../../common/Button/Button"
import RiskFindingUtils from "../../../../global/utils/riskFindingUtils"

interface Props {
  riskFinding: IRiskFinding
  remediationPathways: IRemediationPathway[]
  loading?: boolean
  disabled?: boolean
  onSubmit?: (values: any) => void
}

function RiskFinding({
  riskFinding,
  remediationPathways,
  loading,
  disabled,
  onSubmit,
}: Props) {
  const onFormSubmit = async (values: any) => {
    if (onSubmit) await onSubmit(values)
  }

  function getAlertType() {
    switch (riskFinding.status) {
      case ERiskFindingStatus.Open:
        return "error"
      case ERiskFindingStatus.Submitted:
        return "success"
      case ERiskFindingStatus.VendorCommited:
        return "success"
      case ERiskFindingStatus.Closed:
        return "warning"
      default:
        return "error"
    }
  }

  const isCC = riskFinding.assessment?.is_corl_cleared
  const isRem = riskFinding.assessment?.status?.includes("remediation")

  const finalTask = RiskFindingUtils.getFinalTask(riskFinding.tasks)

  return (
    <div className="risk-finding">
      <Alert
        className="risk-finding-container"
        type={getAlertType()}
        message={""}
        description={
          <div className="risk-finding-body">
            <div className="risk-finding-body-header">
              <Row justify="space-around" align="middle">
                <Col span={12}>
                  <RiskFindingStatus
                    riskFinding={riskFinding}
                  ></RiskFindingStatus>
                </Col>
                <Col span={8}>
                  <div className="risk-finding-tags">
                    {riskFinding.client_required && (
                      <Tag
                        className="risk-finding-tag"
                        color="red"
                        icon={<WarningOutlined />}
                      >
                        Required
                      </Tag>
                    )}
                    {riskFinding.is_key_control && (
                      <Tag
                        className="risk-finding-tag"
                        color="green"
                        icon={<KeyOutlined />}
                      >
                        Key Control
                      </Tag>
                    )}
                    {riskFinding.update_date &&
                      riskFinding.create_date &&
                      riskFinding.update_date > riskFinding.create_date && (
                        <Tag className="risk-finding-tag" color="blue">
                          Last Action{" "}
                          {moment(riskFinding.update_date).format(
                            "MMM DD, YYYY"
                          )}
                        </Tag>
                      )}
                    {riskFinding.corl_recommended_due_date && (
                      <Tag className="risk-finding-tag" color="blue">
                        Due Date{" "}
                        {moment(riskFinding.corl_recommended_due_date).format(
                          "MMM DD, YYYY"
                        )}
                      </Tag>
                    )}
                  </div>
                </Col>
                <Col span={4}>
                  <RiskReductionBadge
                    riskFinding={riskFinding}
                  ></RiskReductionBadge>
                </Col>
              </Row>
            </div>
            <Alert
              className="risk-finding-control-details"
              type={"info"}
              description={
                <div>
                  <div style={{ fontSize: 16 }}>
                    <strong>Control Question</strong>:{" "}
                    {riskFinding.response?.control_question?.text ||
                      riskFinding.control_question_text}
                  </div>
                  <div style={{ fontSize: 14, marginTop: "12px" }}>
                    <strong>CORL Notes</strong>:{" "}
                    {riskFinding.corl_communication}
                  </div>
                </div>
              }
            ></Alert>
            <Alert
              className="risk-finding-drawer-container"
              type={"warning"}
              message={
                <div style={{ color: "#e7872b" }}>
                  {isRem ? "Risk Finding Details" : "Gap Validation Details"}
                </div>
              }
              description={
                <div className="risk-finding-drawer-container-content">
                  <div style={{ marginBottom: "8px" }}>
                    {riskFinding.questionnaire && (
                      <div style={{ marginTop: "12px" }}>
                        <strong>Questionnaire</strong>:{" "}
                        {riskFinding.questionnaire?.title}
                      </div>
                    )}
                    {isCC && isRem && riskFinding.remediation_scenario && (
                      <div style={{ marginTop: "12px" }}>
                        <div>
                          <strong>Reason for Task</strong>:{" "}
                        </div>
                        {riskFinding.remediation_scenario}
                      </div>
                    )}
                    {!isCC && riskFinding.remediation_verbiage && (
                      <div style={{ marginTop: "12px" }}>
                        <div>
                          <strong>Remediation Verbiage</strong>:{" "}
                        </div>
                        {riskFinding.remediation_verbiage}
                      </div>
                    )}
                    {riskFinding?.remediation_pathway?.name
                      ?.toLowerCase()
                      ?.includes("hitrust") && (
                      <Button
                        className="register-hitrust-button"
                        color="blue"
                        icon={
                          <ArrowRightOutlined className="register-hitrust-button-icon" />
                        }
                        iconPosition="left"
                      >
                        <a
                          className="register-hitrust-button-text"
                          target="_blank"
                          rel="noreferrer"
                          href="https://info.hitrustalliance.net/attaining-hitrust-certification"
                        >
                          If you are following CORL Cleared's preferred HITRUST
                          pathway follow this link to register
                        </a>
                      </Button>
                    )}
                    {!isCC && riskFinding.risk_summary_inadequate_verbiage && (
                      <div style={{ marginTop: "12px" }}>
                        <div>
                          <strong>Risk Summary Inadequate Verbiage</strong>:{" "}
                        </div>
                        {riskFinding.risk_summary_inadequate_verbiage}
                      </div>
                    )}
                    {!isRem &&
                      riskFinding.response?.control_question?.isKeyControl &&
                      riskFinding.documentation_request && (
                        <div style={{ marginTop: "12px" }}>
                          <div>
                            <strong>Documentation Request</strong>:{" "}
                          </div>
                          {riskFinding.documentation_request}
                        </div>
                      )}
                    {isCC && !isRem && riskFinding.outcome && (
                      <div style={{ marginTop: "12px" }}>
                        <div>
                          <strong>Outcome</strong>:{" "}
                        </div>
                        {riskFinding.outcome}
                      </div>
                    )}
                    {isCC &&
                      isRem &&
                      riskFinding.tasks &&
                      riskFinding.tasks.length > 0 &&
                      finalTask && (
                        <div style={{ marginTop: "12px" }}>
                          <div>
                            <strong>To Remediate this risk finding</strong>:{" "}
                          </div>
                          {finalTask?.description}
                        </div>
                      )}
                  </div>
                  {(riskFinding.status === ERiskFindingStatus.Submitted ||
                    riskFinding.status === ERiskFindingStatus.Closed ||
                    riskFinding.status === ERiskFindingStatus.Resolved) && (
                    <div style={{ marginTop: "12px" }}>
                      <RiskFindingDrawer
                        onSubmit={onFormSubmit}
                        disabled={disabled}
                        riskFinding={riskFinding}
                        loading={loading}
                        remediationPathways={remediationPathways}
                        activeRiskFindingTab={"details"}
                      />
                    </div>
                  )}
                </div>
              }
            />
            {(riskFinding.status === ERiskFindingStatus.Open ||
              riskFinding.status === ERiskFindingStatus.VendorCommited) && (
              <div className="risk-finding-drawer-container-actions">
                {riskFinding.status === ERiskFindingStatus.Open && (
                  <div style={{ width: "100%" }}>
                    <RiskFindingDrawer
                      onSubmit={onFormSubmit}
                      disabled={disabled}
                      asButton
                      riskFinding={riskFinding}
                      loading={loading}
                      remediationPathways={remediationPathways}
                      activeRiskFindingTab={
                        riskFinding.vendor_remediate ? "remediation" : "details"
                      }
                    />
                  </div>
                )}

                {riskFinding.status === ERiskFindingStatus.VendorCommited && (
                  <div style={{ width: "100%" }}>
                    <RiskFindingDrawer
                      onSubmit={onFormSubmit}
                      disabled={disabled}
                      asButton
                      riskFinding={riskFinding}
                      remediationPathways={remediationPathways}
                      loading={loading}
                      activeRiskFindingTab={"remediation"}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        }
      />
    </div>
  )
}

export default RiskFinding
