import React, { useEffect, useState } from "react"
import qs from "qs"

import "./styles.scss"

import { Table } from "antd"
import StrapiUtils from "../../../../global/utils/StrapiUtils"
import { IUser } from "../../../../api/User/UserTypes"
import StrapiPermissionsUtil from "../../../../api/utils/StrapiPermissionsUtil"
import DefaultLoader from "../../../common/DefaultLoader/DefaultLoader"
import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import RiskFindingApi from "../../../../api/RiskFinding/RiskFindingApi"
import RiskFindingFilters from "./RiskFindingFilters"
import { RiskFindingFilterFormTypes } from "./Types"
import { RiskFindingsTableConfig } from "./RiskFindingsTableConfig"
import { StickyContainer } from "react-sticky-17"
import AppStore from "../../../../stores/App/AppStore"
import { IRemediationPathway } from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import RemediationPathwayApi from "../../../../api/RemediationPathway/RemediationPathwayApi"

interface Props {
  user?: IUser
  vendorId?: number
}

function RiskFindingsTable({ user, vendorId }: Props) {
  const [riskFindings, setRiskFindings] = useState<IRiskFinding[]>()
  const [remediationPathways, setRemediationPathways] = useState<
    IRemediationPathway[]
  >([])
  const [curPage, setCurPage] = useState(1)
  const [numRiskFindings, setNumRiskFindings] = useState<number>(0)
  const [curQuery, setCurQuery] = useState<string>(
    StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user)
      ? qs.stringify({
          _sort: "update_date:DESC",
          status: ERiskFindingStatus.Submitted,
        })
      : qs.stringify({ _sort: "update_date:DESC" })
  )
  const [loading, setLoading] = useState<boolean>(false)

  let limit = 25

  async function getRiskFindings(params?: any, qs?: string) {
    let curParams: any = {
      _start: (curPage - 1) * limit,
      _limit: limit,
    }
    if (params) curParams = { ...curParams, ...params }
    if (vendorId) curParams.vendor_id = vendorId
    let curRiskFindings = await RiskFindingApi.getRiskFindings(curParams, qs)
    if (curRiskFindings) setRiskFindings(curRiskFindings)

    let count = await RiskFindingApi.getRiskFindingCount(curParams, qs)
    if (count) setNumRiskFindings(count)
  }

  async function getRemediationPathways() {
    let remediationPathways =
      await RemediationPathwayApi.getRemediationPathways()
    if (remediationPathways) setRemediationPathways(remediationPathways)
  }

  useEffect(() => {
    getRiskFindings({}, curQuery)
    getRemediationPathways()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onFiltersSubmit(values: any) {
    let toArray = Object.keys(values).map((key) => {
      return { [key]: values[key] }
    })
    let query = qs.stringify({
      _where: toArray,
      _sort: "update_date:DESC",
    })

    setCurQuery(query)

    resetPagination()
    await getRiskFindings(undefined, query)
  }

  function resetPagination() {
    setCurPage(1)
  }

  async function onPageChange(page: number, sorter: any) {
    setCurPage(page)
    let params: any = {
      _start: (page - 1) * limit,
    }
    let sortString = StrapiUtils.tableSortToSortString(
      sorter.field,
      sorter.order
    )
    if (sortString) {
      params._sort = sortString
    }
    await getRiskFindings(params, curQuery)
  }

  let pagination = {
    current: curPage,
    total: numRiskFindings,
    pageSize: limit,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
  }

  async function onTableChange(
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) {
    switch (extra.action) {
      case "paginate":
        await onPageChange(pagination.current, sorter)
        break
      case "filter":
        break
    }
  }

  function disabledFilters() {
    if (user) {
      if (!StrapiPermissionsUtil.isCorlAdminOrEmployee(user)) {
        return [RiskFindingFilterFormTypes.Vendor]
      }
    }
    return []
  }

  async function onRiskFindingSubmit(values: any) {
    setLoading(true)
    await getRiskFindings({}, curQuery)
    setLoading(false)
  }

  async function onRiskFindingStatusChange() {
    setLoading(true)
    await getRiskFindings({}, curQuery)
    setLoading(false)
  }

  if (!riskFindings) {
    return (
      <div className="default-loader-full">
        <DefaultLoader text="Loading Risk Findings..." />
      </div>
    )
  }

  return (
    <div className="risk-findings-table">
      <div className="risk-findings-table-header">
        <div className="title">Risk Findings</div>
      </div>

      <div className="filters">
        <RiskFindingFilters
          onSubmit={onFiltersSubmit}
          disabledFilters={disabledFilters()}
        />
      </div>
      <div className="risk-findings-table-content">
        <StickyContainer style={{ width: "100%" }}>
          <Table
            rowKey="id"
            dataSource={riskFindings}
            columns={RiskFindingsTableConfig(
              loading,
              onRiskFindingSubmit,
              onRiskFindingStatusChange,
              remediationPathways
            )}
            pagination={pagination}
            onChange={onTableChange}
          />
        </StickyContainer>
      </div>
    </div>
  )
}

export default RiskFindingsTable
