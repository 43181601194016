import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';

import './styles.scss';

import Logo from '../../../assets/logo.png';

interface Props {
    text:string,
    disableAnimations?:boolean
}

function DefaultLoader(props:Props) {

    const {text, disableAnimations} = props;

    return (
        <div className='default-loader'>
            <div className={disableAnimations ? '' : 'bounce'}>
                <img alt="Logo" src={Logo} style={{height: 50, width: 50}}/>
            </div>
            
            <div className='loading-text'>
                {!disableAnimations && <LoadingOutlined spin/>}
                {text}
            </div>
        </div>
    )
}

export default DefaultLoader;