import "./styles.scss"
import React, { useState } from "react"
import { Form, Input, notification, Select } from "antd"
import "react-quill/dist/quill.snow.css"
import {
  IClient,
  DataReuseType,
  EOutreachType,
} from "../../../api/Client/ClientTypes"
import Button from "../../common/Button/Button"
import ClientAPI from "../../../api/Client/ClientAPI"
import { EMAIL_TYPES, ROLES, TOAST_DURATION } from "../../../constants"
import { StringUtils } from "../../../global/utils/string"
import AppStore from "../../../stores/App/AppStore"
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil"
import ClientOutreachSettings from "./ClientOutreachSettings"

const { Option } = Select

interface Props {
  client: IClient
  onClientUpdate: (client: IClient) => void
}

function ClientSettings({ client, onClientUpdate }: Props) {
  const [clientCcForm] = Form.useForm()
  const [clientSettingsForm] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)

  const isCorlAdmin = AppStore?.user?.role.name === ROLES.CORL_ADMIN
  const isAdminUser = StrapiPermissionsUtil.isClientAdmin(AppStore.user)

  let initialValues = {
    name: client.name,
    outreach_message: client.outreach_message,
    outreach_subject: client.outreach_subject,
    ivpq_outreach_message: client.ivpq_outreach_message,
    ivpq_outreach_subject: client.ivpq_outreach_subject,
    cc_outreach_message: client.cc_outreach_message,
    cc_outreach_subject: client.cc_outreach_subject,
    remediation_outreach_message: client.remediation_outreach_message,
    remediation_outreach_subject: client.remediation_outreach_subject,
    data_reuse_type: client.data_reuse_type,
    outreach_enabled:
      client.outreach_enabled === null ? true : client.outreach_enabled, //If null value, default to true
  }

  const clientCCList = client.email_settings?.ccList
  let initialValuesCCList = {
    questionnaireCreated: "",
    questionnaireSubmitted: "",
    questionnaireReopened: "",
    openAssessmentReminder: "",
    remediationEvents: "",
  }

  async function onDataReuseFinish(values: any) {
    if (submitting) return
    setSubmitting(true)

    let curClient = await ClientAPI.update(client.id, {
      data_reuse_type: values.data_reuse_type,
    })
    if (!curClient) {
      notification.error({
        message: "Error changing client data reuse settings",
        description: (
          <span>Please make sure you have selected a data reuse option.</span>
        ),
        duration: TOAST_DURATION,
      })
    }

    if (curClient) {
      notification.success({
        message: "Successfully changed client data reuse settings.",
        description: <span>Client data reuse settings updated.</span>,
        duration: TOAST_DURATION,
      })
      if (onClientUpdate) onClientUpdate(curClient)
    }

    setSubmitting(false)
  }

  async function onVendorOutreachSettingFinish(values: any) {
    if (submitting) return
    setSubmitting(true)

    let curClient = await ClientAPI.update(client.id, {
      outreach_enabled: values.outreach_enabled ? true : false,
    })

    if (!curClient) {
      notification.error({
        message: "Error changing client outreach settings",
        description: (
          <span>
            Please make sure you have selected a Vendor Outreach option.
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }

    if (curClient) {
      notification.success({
        message: "Successfully changed client outreach settings.",
        description: <span>Client outreach settings updated.</span>,
        duration: TOAST_DURATION,
      })
      if (onClientUpdate) onClientUpdate(curClient)
    }

    setSubmitting(false)
  }

  function onClientCcChange(e: any) {
    let sanitizedField = clientCcForm
      .getFieldValue(e.target.id)
      .replace(",", "")
    clientCcForm.setFieldsValue({ [e.target.id]: sanitizedField })
  }

  function renderCCListFields() {
    if (clientCCList) {
      const questionnaireCreated = clientCCList
        .find((c) => c.emailType === EMAIL_TYPES.QUESTIONNAIRE_CREATED)
        ?.emails.join(",")
      if (questionnaireCreated)
        initialValuesCCList.questionnaireCreated = questionnaireCreated

      const questionnaireSubmitted = clientCCList
        .find((c) => c.emailType === EMAIL_TYPES.QUESTIONNAIRE_SUBMITTED)
        ?.emails.join(",")
      if (questionnaireSubmitted)
        initialValuesCCList.questionnaireSubmitted = questionnaireSubmitted

      const questionnaireReopened = clientCCList
        .find((c) => c.emailType === EMAIL_TYPES.QUESTIONNAIRE_REOPENED)
        ?.emails.join(",")
      if (questionnaireReopened)
        initialValuesCCList.questionnaireReopened = questionnaireReopened

      const openAssessmentReminder = clientCCList
        .find((c) => c.emailType === EMAIL_TYPES.OPEN_ASSESSMENT_REMINDER)
        ?.emails.join(",")
      if (openAssessmentReminder)
        initialValuesCCList.openAssessmentReminder = openAssessmentReminder

      const remediationEvents = clientCCList
        .find((c) => c.emailType === EMAIL_TYPES.REMEDIATION_INITIATED)
        ?.emails.join(",")
      if (remediationEvents)
        initialValuesCCList.remediationEvents = remediationEvents
    }
    return (
      <div>
        <Form.Item
          label="Questionnaire is created and assigned to Vendor"
          name="questionnaireCreated"
        >
          <Input
            size="large"
            disabled={!isAdminUser}
            onChange={onClientCcChange}
          />
        </Form.Item>
        <Form.Item
          label="Vendor submits Questionnaire"
          name="questionnaireSubmitted"
        >
          <Input
            size="large"
            disabled={!isAdminUser}
            onChange={onClientCcChange}
          />
        </Form.Item>
        <Form.Item
          label={`Questionnaire initial review fails and is sent back to Vendor`}
          name="questionnaireReopened"
        >
          <Input
            size="large"
            disabled={!isAdminUser}
            onChange={onClientCcChange}
          />
        </Form.Item>
        <Form.Item
          label="Weekly Assessment reminders"
          name="openAssessmentReminder"
        >
          <Input
            size="large"
            disabled={!isAdminUser}
            onChange={onClientCcChange}
          />
        </Form.Item>
        <Form.Item label="Remediation events" name="remediationEvents">
          <Input
            size="large"
            disabled={!isAdminUser}
            onChange={onClientCcChange}
          />
        </Form.Item>
        {isAdminUser && (
          <Form.Item>
            <Button htmlType="submit" loading={submitting}>
              Save
            </Button>
          </Form.Item>
        )}
      </div>
    )
  }

  async function onEmailCCListFinish(values: any) {
    if (submitting) return
    setSubmitting(true)
    const updatedCCList = [
      {
        emailType: EMAIL_TYPES.QUESTIONNAIRE_CREATED,
        emails: values.questionnaireCreated.split(","),
      },
      {
        emailType: EMAIL_TYPES.QUESTIONNAIRE_SUBMITTED,
        emails: values.questionnaireSubmitted.split(","),
      },
      {
        emailType: EMAIL_TYPES.QUESTIONNAIRE_REOPENED,
        emails: values.questionnaireReopened.split(","),
      },
      {
        emailType: EMAIL_TYPES.OPEN_ASSESSMENT_REMINDER,
        emails: values.openAssessmentReminder.split(","),
      },
      {
        emailType: EMAIL_TYPES.REMEDIATION_INITIATED,
        emails: values.remediationEvents.split(","),
      },
      {
        emailType: EMAIL_TYPES.REMEDIATION_PLAN_SUBMITTED,
        emails: values.remediationEvents.split(","),
      },
      {
        emailType: EMAIL_TYPES.OPEN_REMEDIATION_REMINDER,
        emails: values.remediationEvents.split(","),
      },
      {
        emailType: EMAIL_TYPES.REMEDIATION_REOPENED,
        emails: values.remediationEvents.split(","),
      },
      {
        emailType: EMAIL_TYPES.GAP_VALIDATION_INITIATED,
        emails: values.remediationEvents.split(","),
      },
      {
        emailType: EMAIL_TYPES.GAP_VALIDATION_PLAN_SUBMITTED,
        emails: values.remediationEvents.split(","),
      },
    ]
    const updatedEmailSettings = {
      ...client.email_settings,
      ccList: [...updatedCCList],
    }
    let curClient = await ClientAPI.update(client.id, {
      email_settings: updatedEmailSettings,
    })
    if (!curClient) {
      notification.error({
        message: "Error changing client outreach settings",
        description: <span>Please try again later.</span>,
        duration: TOAST_DURATION,
      })
    }

    if (curClient) {
      notification.success({
        message: "Successfully changed client outreach settings.",
        description: <span>Client CC list settings updated.</span>,
        duration: TOAST_DURATION,
      })
      if (onClientUpdate) onClientUpdate(curClient)
    }

    setSubmitting(false)
  }

  async function onClientNameChange(values: any) {
    if (submitting) return
    setSubmitting(true)

    let curClient = await ClientAPI.update(client.id, { name: values.name })

    if (!curClient) {
      notification.error({
        message: "Error changing client name",
        description: <span>Please make sure specified a client name.</span>,
        duration: TOAST_DURATION,
      })
    }

    if (curClient) {
      notification.success({
        message: "Successfully changed client name!",
        duration: TOAST_DURATION,
      })
      if (onClientUpdate) onClientUpdate(curClient)
    }

    setSubmitting(false)
  }

  return (
    <div className="client-settings">
      <div className="client-settings-section">
        {isCorlAdmin && (
          <Form
            form={clientSettingsForm}
            layout="vertical"
            initialValues={initialValues}
            onFinish={(values) => onClientNameChange({ ...values })}
            className="form"
          >
            <Form.Item
              label="Client Name"
              name="name"
              rules={[{ required: true, message: "Client must have a name." }]}
            >
              <Input size="large" />
            </Form.Item>
            <Form.Item>
              <Button
                enabled={!submitting}
                htmlType="submit"
                loading={submitting}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
        <div className="client-settings-header">
          <div className="client-settings-header-title">Vendor Data Reuse</div>
          <div className="client-settings-header-description">
            Changing the data reuse option to 'None' will not affect current
            questionnaires. Only newly created questionnaires will respect this
            option.
          </div>
          <div className="client-settings-header-description">
            Changing the option to 'Approval' WILL affect currently In Progress
            questionnaires for vendors associated to this client.
          </div>
        </div>
        <Form
          className="client-settings-select"
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values) => onDataReuseFinish({ ...values })}
        >
          <Form.Item
            label="Data Reuse Type for Vendors Assigned to this Client"
            name="data_reuse_type"
          >
            <Select
              size="large"
              optionLabelProp="children"
              placeholder="Data Reuse Type"
              defaultValue={DataReuseType.Standard}
              disabled={!isAdminUser}
            >
              {Object.values(DataReuseType).map((dataReuseOption) => (
                <Option value={dataReuseOption} key={dataReuseOption}>
                  {StringUtils.capitalize(dataReuseOption)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {isAdminUser && (
            <Form.Item>
              <Button htmlType="submit" loading={submitting}>
                Save
              </Button>
            </Form.Item>
          )}
        </Form>
      </div>
      <ClientOutreachSettings
        client={client}
        outreachType={EOutreachType.IVPQ}
        fieldLabel="IVPQ"
        headerLabel="IVPQ"
        canSave={isAdminUser}
        messageFieldName="ivpq_outreach_message"
        subjectFieldName="ivpq_outreach_subject"
        initialValues={initialValues}
        onClientUpdate={onClientUpdate}
      />
      <ClientOutreachSettings
        client={client}
        outreachType={EOutreachType.VSQ}
        fieldLabel="VSQ"
        headerLabel="VSQ"
        canSave={isAdminUser}
        messageFieldName="outreach_message"
        subjectFieldName="outreach_subject"
        initialValues={initialValues}
        onClientUpdate={onClientUpdate}
      />
      <ClientOutreachSettings
        client={client}
        outreachType={EOutreachType.CorlCleared}
        fieldLabel="CC"
        headerLabel="CORL Cleared"
        canSave={isAdminUser}
        messageFieldName="cc_outreach_message"
        subjectFieldName="cc_outreach_subject"
        initialValues={initialValues}
        onClientUpdate={onClientUpdate}
      />
      <ClientOutreachSettings
        client={client}
        outreachType={EOutreachType.Remediation}
        fieldLabel="Remediation"
        headerLabel="Remediation"
        canSave={isAdminUser}
        messageFieldName="remediation_outreach_message"
        subjectFieldName="remediation_outreach_subject"
        initialValues={initialValues}
        onClientUpdate={onClientUpdate}
      />
      <div className="client-settings-section">
        <div className="client-settings-header">
          <div className="client-settings-header-title">Email Settings</div>
          <div className="client-settings-header">
            <div className="client-settings-header-title">Outreach</div>
            <div className="client-settings-header-description">
              By default, any vendor associated to this client will receive
              communications on behalf of this client from CORL. If you would
              like to change this setting, please reach out to CORL support.
            </div>
          </div>
          <Form
            className="client-settings-select"
            layout="vertical"
            initialValues={{
              outreach_enabled: initialValues.outreach_enabled ? 1 : 0,
            }}
            onFinish={(values) => onVendorOutreachSettingFinish({ ...values })}
          >
            <Form.Item label="Vendor Outreach" name="outreach_enabled">
              <Select
                size="large"
                optionLabelProp="children"
                placeholder="Data Reuse Type"
                disabled={!isCorlAdmin}
              >
                <Option value={1}>
                  {`${client.name} wants CORL to outreach Vendors on its behalf`}
                </Option>
                <Option value={0}>
                  {`${client.name} DOES NOT want CORL to outreach to any vendor`}
                </Option>
              </Select>
            </Form.Item>
            {isCorlAdmin && (
              <Form.Item>
                <Button htmlType="submit" loading={submitting}>
                  Save
                </Button>
              </Form.Item>
            )}
          </Form>
          <div className="client-settings-header">
            <div className="client-settings-header-title">CC List</div>
          </div>
          <div className="client-settings-header-description">
            Whenever one of the events listed below occurs, an email of that
            event will be sent to the corresponding vendor (If the Vendor
            Outreach setting is not disabled).
          </div>
          <div className="client-settings-header-description">
            You may specify a semi-colon-separated list of emails that should be
            CC'd whenever any of these communication are sent out. Please do not
            include corlvsrm@corltech.com in any of these CC lists, as it is
            added automatically.
          </div>
        </div>
        <Form
          form={clientCcForm}
          layout="vertical"
          initialValues={initialValuesCCList}
          onFinish={(values) => onEmailCCListFinish({ ...values })}
        >
          {renderCCListFields()}
        </Form>
      </div>
    </div>
  )
}

export default ClientSettings
