import React, {useEffect, useState} from 'react';
import {Select} from 'antd';

import './styles.scss';

import {IImplementation} from "../../../api/Implementation/ImplementationTypes";
import ImplementationAPI from "../../../api/Implementation/ImplementationAPI";

const {Option} = Select;

interface Props {
    onChange?: (type: string) => void,
    loading?: boolean,
    className?: string,
    option?: number
    implementations?: IImplementation[],
    disabled?: boolean,
    value?:any,
}

function ImplementationSelect(props: Props) {
    const {className, onChange, loading, option, disabled, value} = props;
    const [implementations, setImplementations] = useState<IImplementation[]>([]);

    async function getImplementations() {
        let implementations = await ImplementationAPI.getImplementations();
        if (implementations) {
            setImplementations(implementations);
        }
    }

    useEffect(()=> {
        getImplementations();
    },[])

    function renderDropdown(menu) {
        return (
            <div>
                {menu}
            </div>
        )
    }

    return (
        <Select className={className} size='large' optionLabelProp='children' placeholder='Choose Implementation'
                defaultValue={option} onSelect={onChange as any} loading={loading} dropdownRender={renderDropdown}
                disabled={loading || disabled} value={value}>
            {implementations.map(implementation =>
                <Option value={implementation.id} key={implementation.id}>
                    {implementation.name}
                </Option>
            )}
        </Select>
    )
}

export default ImplementationSelect;