import axios from 'axios';
import {INotification} from "./NotificationTypes";

export default class NotificationsAPI {
    static async getNotifications() {
        try {
            let res = await axios.get('/notifications')
            return res.data as INotification[]
        } catch (e) {
            console.log('Could not fetch notifications', e.message)
        }
    }

    static async getUnreadNotifications() {
        try {
            let res = await axios.get('/notifications/unread')
            return res.data as INotification[]
        } catch (e) {
            console.log('Could not fetch notifications', e.message)
        }
    }

    static async updateNotification(notificationId: number) {
        try {
            await axios.put(`/notifications/${notificationId}`)
        } catch (e) {
            console.log('Could not update notifications', e.message)
        }
    }
}