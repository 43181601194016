import React from 'react';

import './styles.scss';

import cx from "classnames";
import {StringUtils} from "../../../global/utils/string";
import StatusIcon from "./StatusIcon";

interface Props {
    status:string,
}

function Status(props:Props) {
    const {status} = props;
    return (
        <div className={cx('status', status)}>
            <StatusIcon status={status}/>
            <div className='text'>{StringUtils.camelCaseToRegular(status, false, true)}</div>
        </div>
    )
}

export default Status;