import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

export class FileUtils {

    static getExtension(fileName: string) {
        let parts = fileName.split('.');
        return parts[parts.length - 1];
    }

    static isBase64(fileName: string) {
        let ext = FileUtils.getExtension(fileName);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return true;
        }
        return false;
    }

    static isText(fileName: string){
        let ext = FileUtils.getExtension(fileName);
        switch (ext.toLowerCase()) {
            case 'csv':
                return true;
        }
        return false;
    }

    static createCsvFile(data: (string|number|boolean)[][]) {
        let csvFile = '';
        for (let i = 0; i < data.length; i++) {
            csvFile += FileUtils.processCsvRow(data[i]);
        }
        return csvFile;
    }

    static processCsvRow(row: any[]) {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
            let innerValue = (row[j] === undefined || row[j] === null) ? '' : row[j].toString();
            let result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    }

    static downloadZipFile(fileName: string, zipFile: JSZip) {
        zipFile.generateAsync({type: "blob"})
            .then((blob) => {
                FileUtils.downloadFile(fileName, blob, 'zip');
            });
    }

    static downloadCsvFile(fileName: string, data: (string|number|boolean)[][]) {
        let csvFile = FileUtils.createCsvFile(data);
        let csvBlob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        FileUtils.downloadFile(fileName, csvBlob, 'csv');
    }

    static urlToPromise(url: string) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    static downloadFile(fileName: string, fileData: Blob, fileType: string) {
        const url = URL.createObjectURL(fileData);
        FileUtils.downloadFileFromUrl(`${fileName}.${fileType}`, url);
    }

    static downloadFileFromUrl(fileNameWithExtension: string, url: string) {
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `${fileNameWithExtension}`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}