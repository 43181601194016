import React from 'react';
import {Popover, Typography} from "antd";
import {StringUtils} from "../../../global/utils/string";

export const QuestionTableConfig = [
    {
        title: 'Control #', dataIndex: 'control_number', key: 'control_number',
    },
    {
        title: 'Text', dataIndex: 'text', key: 'text',
        render: (text: string) => {
            return (
                <Popover content={text}>
                    <Typography.Paragraph ellipsis={{row: 1}} style={{maxWidth: 200, marginBottom: 0}}>
                        {text}
                    </Typography.Paragraph>
                </Popover>
            )
        }
    },
    {
        title: 'Control Group', dataIndex: 'control_group', key: 'control_group',
    },
    {
        title: 'IRN', dataIndex: ['irn', 'description'], key: 'irn',
    },
    {
        title: 'Type', dataIndex: 'question_type', key: 'question_type',
        render: (question_type) => {
            if (question_type) return StringUtils.camelCaseToRegular(question_type, false, true);
        }
    },

];