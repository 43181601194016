import {AppRoutesObject} from "./AppRoutes";

export interface AppRoute extends AppRouteHeader, AppRouteRender {}

export interface AppRouteRender {
    render:any,
}
export interface AppRouteHeader {
    icon:any,
    path:string,
    access:string[]
    key?:string,
    hide?:boolean,
}

export interface IAppRoutesObject {
    [key:string]:AppRoute,
}

function getAppRoutesRenders(routesObject:IAppRoutesObject):AppRouteRender[]{
    return Object.keys(routesObject).map(key => routesObject[key].render)
}

function getAppRoutesHeaders(routesObject:IAppRoutesObject):AppRouteHeader[]{
    let ret:AppRouteHeader[] = [];
    Object.keys(routesObject).forEach(key => {
        const {hide} = routesObject[key];
        if (!hide) ret.push({...routesObject[key], key} as any);
    });
    return ret;
}

export const AppRoutesRenders =  () => ([
    getAppRoutesRenders(AppRoutesObject()),
    //This must go last since there's a / path here
]);

export const AppSidebarItemsMap = () => [
    ...getAppRoutesHeaders(AppRoutesObject())
];

export const AppHeaderItemsMap:AppRouteHeader[] = [
    ...getAppRoutesHeaders({
    })
];

export const AppHeaderItems:{[key:string]: AppRouteHeader[]} = {

};

export const AppRoutesPathReference = () => ({
    ...AppRoutesObject()
});



