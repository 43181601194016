import React from 'react';

import './styles.scss';

import {Progress} from "antd";

interface Props {
    progress:number
}

function AssessmentProgress(props: Props) {
    const { progress } = props;
    return (
        <div className="questionnaires-progress" style={{width: 200}}>
            Questionnaires Completed
            <Progress percent={progress} size="small"/>
        </div>
    )
}

export default AssessmentProgress;