import axios from "axios";

export default class CustomUserApi {
    static async Get(token: any){
        let res = await axios.get('/authentication/user', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
        return res.data
    }
}