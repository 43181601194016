import React from 'react';
import { List, Badge, Empty } from 'antd';
import moment from "moment";
import './styles.scss';
import { INotification } from '../../../api/Notifications/NotificationTypes';

interface Props {
    notifications: INotification[]
}

function NotificationList(props: Props) {
    return (
            <div>
                {props.notifications && props.notifications.length > 0 ?
                    <List
                        dataSource={props.notifications}
                        renderItem={item =>
                            <List.Item className={item.read ? 'notification' : 'unread-notification'}>
                            <div className='datetime'>
                                {moment(item.created_at).format('LLLL')}
                            </div>
                            <div className='notification-text'>
                                {!item.read && <Badge className='indicator' color="red"/>}
                                {item.content}
                            </div>
                        </List.Item>
                        }
                    />
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"You have no new notifications."} />
                }
            </div>
    )
};

export default NotificationList;