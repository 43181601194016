import React, {useCallback, useState} from 'react';
import {Select} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

import './styles.scss';
import {IQuestion} from "../../../api/Question/QuestionTypes";
import _ from "lodash";
import QuestionAPI from "../../../api/Question/QuestionAPI";
import qs from 'qs';

const {Option} = Select;

interface Props {
    onChange?: (question?: number | IQuestion) => void,
    className?: string,
    option?: number,
    fullQuestion?: boolean
}

function QuestionSelect(props: Props) {
    const {onChange, option, fullQuestion} = props;
    const [questions, setQuestions] = useState<Record<number, IQuestion>>({})
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let debouncedOnSearch = useCallback(_.debounce(onSearch, 200), []);

    async function onSearch(value: string) {
        setLoading(true);
        setQuestions([])
        let questions = await QuestionAPI.getQuestions(undefined, {}, qs.stringify({
            _where: {
                _or: [
                    {text_contains: value},
                    {'irn.description_contains': value},
                ]
            }
        }));
        if (questions) {
            let byId = {};
            questions.forEach(question => byId[question.id] = question);
            setQuestions(byId);
        }
        setLoading(false);
    }

    function onSelect(questionId: number) {
        if (onChange) {
            if (fullQuestion) onChange(questions[questionId]);
            else return onChange(questionId);
        }
    }

    function renderControlDependencies(q: IQuestion) {
        if (q.control_dependencies.length > 0) {
            return (
                <div className='control-dependencies'>
                    {q.control_dependencies.map((cd, index) => {
                        if (cd.depends_on?.irn?.description) {
                            return (
                                <div key={index} className='control-dependency'>
                                    {cd.depends_on.irn.description}
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })}
                </div>
            )
        } else {
            return null;
        }
    }


    return (
        <Select className='question-select' size='large' optionLabelProp='children' onSearch={debouncedOnSearch}
                placeholder='Search Question'
                defaultValue={option} onSelect={onSelect} loading={loading} showSearch filterOption={false}
                notFoundContent={loading ? <LoadingOutlined spin/> : null} allowClear
                onClear={() => onChange(undefined)}>
            {Object.values(questions).map(q => {
                let controlDependencies = renderControlDependencies(q);
                return (
                    <Option className='question-select-option' value={q.id} key={q.id}>
                        <div className='question-select-option-top'>
                        <span className='question-select-irn'>
                        {q.irn?.description}
                    </span>
                            {q.text}
                        </div>
                        <div className='question-select-option-bottom'>
                            <div style={{marginRight: 6}}>{controlDependencies ? 'Depends On' : 'No Dependencies'}</div>
                            {controlDependencies}
                        </div>

                    </Option>
                )
            })}
        </Select>
    )
}

export default QuestionSelect;