import React, { useEffect, useState } from "react";
import { Redirect, Switch, useLocation, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { Layout } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";
import config from "react-global-configuration";

import "./styles.scss";

/* App Imports */
import { AppRoutesRenders } from "./RoutesRegister";
import AppStore from "../../stores/App/AppStore";
import BrowserRouter, { BrowserRoutes } from "../../stores/App/BrowserRouter";
import AppSidebar from "./Sidebar/AppSidebar";
import CustomUserApi from "../../api/CustomUser/CustomUserAPI";
import { useAuth0 } from "@auth0/auth0-react";
import DefaultLoader from "../../components/common/DefaultLoader/DefaultLoader";
import AppSettingsApi from "../../api/AppSettings/AppSettingsApi";
import { capitalize } from "lodash";
//import TermsAndConditionsModal from "../../components/specific/TermsAndConditions/TermsAndConditions";

const { Content } = Layout;

function Protected() {
  const location = useLocation();
  const { getAccessTokenSilently, logout, user } = useAuth0();
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    AppStore.init();
    initUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initUser() {
    setLoadingUser(true);
    const authToken = await getAccessTokenSilently();
    if (authToken) {
      const { exp } = jwt_decode(authToken);
      AppStore.authTokenExpiration = exp * 1000;
    }
    try {
      // ensure user is in backend
      const authenticatedUser = await CustomUserApi.Get(authToken);
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
      AppStore.user = { ...authenticatedUser, picture: user?.picture };

      // portal 2 redirect
      const pathName = location.pathname;
      if (pathName === "/") {
        if (authenticatedUser.role.name.toLowerCase().includes("vendor")) {
          if (process.env.REACT_APP_ENV === "production") {
            window.location.href = "https://supplier.corltech.com/";
          } else if (process.env.REACT_APP_ENV === "development") {
            window.location.href = "https://uat-supplier.corltech.com/";
          }
        }
      }

      axios.interceptors.request.use(async function (configuration) {
        let authTokenWillExpire =
          AppStore.authTokenExpiration - Date.now() < 15 * 60 * 1000;
        if (AppStore.authTokenExpiration && authTokenWillExpire) {
          try {
            let curAuthToken = await getAccessTokenSilently();
            if (curAuthToken) {
              const { exp } = jwt_decode(curAuthToken);
              AppStore.authTokenExpiration = exp * 1000;
              configuration.headers.common[
                "Authorization"
              ] = `Bearer ${curAuthToken}`;
            }
          } catch (e) {
            console.log("e", e);
          }
        }
        return configuration;
      });

      // init app settings
      const appSettings = await AppSettingsApi.getAppSettings();
      config.set({ appSettings });

      // init zendesk widget
      try {
        let organization = "CORL";
        if (authenticatedUser.vendor)
          organization = authenticatedUser.vendor.name;
        if (authenticatedUser.client)
          organization = authenticatedUser.client.name;
        zE("webWidget", "identify", {
          name: `${capitalize(
            authenticatedUser.user_datum.first_name
          )} ${capitalize(authenticatedUser.user_datum.last_name)}`,
          email: authenticatedUser.email,
          organization,
        });
        zE("webWidget", "prefill", {
          name: {
            value: `${capitalize(
              authenticatedUser.user_datum.first_name
            )} ${capitalize(authenticatedUser.user_datum.last_name)}`,
            readOnly: true,
          },
          email: { value: authenticatedUser.email, readOnly: true },
        });
      } catch (error) {
        console.log(`ZenDesk Widget Error`, error);
      }
    } catch (e: any) {
      const message: string = e.message;
      console.log("error", message);
      // access token is invalid/timed out/something went wrong setting up user
      if (!message.includes("react-global-configuration")) logout();
    }
    setLoadingUser(false);
  }

  function renderContent() {
    if (loadingUser) {
      return (
        <div className="default-loader-full" style={{ height: "100vh" }}>
          <DefaultLoader text="Loading user..." />
        </div>
      );
    } else {
      return (
        <Switch>
          {/* {!AppStore.user?.user_datum?.accepted_terms_and_conditions && (
            <TermsAndConditionsModal
              open={!AppStore.user?.user_datum?.accepted_terms_and_conditions}
            />
          )} */}
          {AppRoutesRenders()}

          <Redirect to={BrowserRoutes.assessments} />
        </Switch>
      );
    }
  }

  return (
    <Layout style={{ background: "white" }}>
      {!loadingUser && <AppSidebar />}
      <Layout
        style={{
          marginLeft: AppStore.sidebarCollapsed ? 80 : 250,
          background: "white",
        }}
      >
        {/*<HAppHeader hideLogo/>*/}
        <Content className="app-content">{renderContent()}</Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(observer(Protected));
