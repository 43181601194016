import React, {useEffect, useState} from 'react';
import './styles.scss';
import TemplateAPI from "../../../api/Template/TemplateAPI";
import {ITemplate} from "../../../api/Template/TemplateTypes";
import QuestionTable from "../QuestionTable/QuestionTable";

interface Props {
    templateId:number;
}

function Template(props:Props){
    const {templateId} = props;
    const [template, setTemplate] = useState<ITemplate>();

    async function getTemplate(){
        let template = await TemplateAPI.getTemplate(templateId)
        if (template) setTemplate(template);
    }

    useEffect(() => {
        getTemplate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[templateId])

    if (!template) return null;

    return(
        <div className='template-container'>
            <QuestionTable templateId={templateId}/>
        </div>
    )
}
export default Template;