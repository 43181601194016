import axios from "axios";
import { EFileUploadTypes } from "./FileTypes";

export default class FileApi {
    static async UploadFiles(files: File[], vendorId:number, entityId?: number, fileUploadType?: EFileUploadTypes){
        let response: any[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            let fileUploadSuccess = await this.UploadFile(file, vendorId, entityId, fileUploadType);
            response.push(fileUploadSuccess);
        }
        return response;
    }
    static async UploadFile(file:File, vendorId:number, entityId?:number, fileUploadType?: EFileUploadTypes) {
        let formData = new FormData();
        formData.append('files.attachment', file);

        const data = {
            vendorId,
            entityId,
            fileUploadType
        }

        formData.append('data', JSON.stringify(data));

        try{
            let res = await axios.post('/upload-file', formData);
            return {
                success: res.data.success,
                message: res.data.message
            };
        } catch (e: any){
            console.error('Could not upload file', e.message);
            return {
                success: false,
                message: e.message
            };
        }
    }
    static async GetFileUrl(fileId:string, vendorId:number) {
        try{
            let body = {
                vendorId,
                fileId,
            }
            let res = await axios.post(`/download-file`, body);
            return res.data as string;
        } catch (e: any){
            console.log('Could not download file', e.message);
            return undefined;
        }
    }
}