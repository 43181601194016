import axios from "axios";
import {IBaseFramework} from "./BaseFrameworkTypes";

export default class BaseFrameworkAPI {

    static async getBaseFrameworks(){
        try {
            let res = await axios.get('/base-frameworks');
            return res.data as IBaseFramework[];
        } catch (e){
            console.log('Could not get base frameworks', e.message);
            return undefined;
        }
    }

    static async createBaseFramework(values:any){
        try {
            let res = await axios.post('/base-frameworks', values);
            return res.data as IBaseFramework;
        } catch (e){
            console.log('Could not create base framework', e.message);
            return undefined;
        }
    }
}