import axios from "axios"
import { IInvitation, IUser } from "./UserTypes"

export default class UserAPI {
  static async getAdminUsersForClient(clientId: number, params?: any) {
    try {
      let res = await axios.get(`/clients/${clientId}/admin-users`, { params })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getAccountUsersForClient(clientId: number, params?: any) {
    try {
      let res = await axios.get(`/clients/${clientId}/account-users`, {
        params,
      })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getClientInvitations(clientId: number) {
    try {
      let res = await axios.get(`/invitations/client/${clientId}`)
      if (res) return res.data as IInvitation[]
    } catch (e) {}
  }

  static async inviteClientAdmin(values: any, clientId: number) {
    try {
      let res = await axios.post(
        `/invite-user/client-admin/${clientId}`,
        values
      )
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async inviteClientEmployee(values: any, clientId: number) {
    try {
      let res = await axios.post(
        `/invite-user/client-employee/${clientId}`,
        values
      )
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async deleteClientUser(clientId: number, userId: number) {
    try {
      let res = await axios.delete(`/clients/${clientId}/delete-user/${userId}`)
      if (res) return res.data
    } catch (e) {}
  }

  static async getAdminUsersForVendor(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`/vendors/${vendorId}/admin-users`, { params })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getAccountUsersForVendor(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`/vendors/${vendorId}/account-users`, {
        params,
      })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getVendorInvitations(vendorId: number) {
    try {
      let res = await axios.get(`/invitations/vendor/${vendorId}`)
      if (res) return res.data as IInvitation[]
    } catch (e) {}
  }
  static async getAdminUsersForCORL(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`/corl-users/admin-users`, { params })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getAccountUsersForCORL(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`/corl-users/account-users`, { params })
      if (res) return res.data as IUser[]
    } catch (e) {}
  }

  static async getCORLInvitations() {
    try {
      let res = await axios.get(`/invitations/corl-user`)
      if (res) return res.data as IInvitation[]
    } catch (e) {}
  }

  static async inviteCORLAdmin(values: any) {
    try {
      let res = await axios.post(`/invite-user/corl-admin`, values)
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async inviteCORLEmployee(values: any) {
    try {
      let res = await axios.post(`/invite-user/corl-employee`, values)
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async deleteCORLUser(corlUserId: number) {
    try {
      let res = await axios.delete(`/corl-users/${corlUserId}`)
      if (res) return res.data
    } catch (e) {}
  }

  static async getInvitation(otp) {
    try {
      let res = await axios.get(`/invitations/otp/${otp}`)
      if (res) return res.data as IInvitation
    } catch (e) {}
  }

  static async acceptInvitation(otp, password) {
    try {
      let res = await axios.post(`/invite-user/create-account`, {
        otp,
        password,
      })
      if (res) return res.data
    } catch (e) {}
  }

  static async inviteVendorAdmin(values, vendorId) {
    try {
      let res = await axios.post(
        `/invite-user/vendor-admin/${vendorId}`,
        values
      )
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async inviteVendorEmployee(values, vendorId) {
    try {
      let res = await axios.post(
        `/invite-user/vendor-employee/${vendorId}`,
        values
      )
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async deleteVendorUser(vendorId: number, userId: number) {
    try {
      let res = await axios.delete(`/vendors/${vendorId}/delete-user/${userId}`)
      if (res) return res.data
    } catch (e) {}
  }
  static async reinviteUser(email: string) {
    try {
      let res = await axios.post(`/invite-user/reinvite/${email}`)
      if (res) return res.data
    } catch (e) {
      return { error: e.response.data.message }
    }
  }
  static async updateUserData(userDataId: number, values: any) {
    try {
      let res = await axios.put(`/user-data/${userDataId}`, values)
      if (res) return res.data
    } catch (e: any) {
      return { error: e.response.data.message }
    }
  }
}
