import React, {useState} from 'react';
import {Drawer, Form, Input} from "antd";
import {DeleteOutlined} from '@ant-design/icons';

import './styles.scss';

import Button from "../../common/Button/Button";

interface Props {
    onSubmit?: (values: any) => void,
    asButton?: boolean
}

function ClearDruFormDrawer(props: Props) {
    const {onSubmit, asButton} = props;
    const [showForm, setShowForm] = useState(!asButton);
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});

    const onButtonClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
    }

    function onValuesChange(v) {
        setValuesForm(v);
    }

    const onFormSubmit = async (e: any) => {
        e.stopPropagation();
        setShowForm(false);
        if (onSubmit) await onSubmit(form.getFieldsValue());
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            {asButton &&
                <Button icon={<DeleteOutlined/>} color='red' onClick={onButtonClick} className='clear-dru-form-drawer-button'>
                    Clear Pre-Answered Responses
                </Button>}
                <Drawer title='Clear Pre-Answered Responses' width='30%' keyboard={false} open={showForm}
                        destroyOnClose={true} onClose={() => setShowForm(false)}
                        footer={null} className='clear-dru-form-drawer'>
                    <div className='description'>If you are unsatisfied with the automatically generated responses for this questionnaire, you may start fresh and clear them all with this option.</div>
                    <div className='description'><strong> This process CANNOT be reversed once it is done.</strong></div>
                    <div className='description'>Additionally, you may also overwrite your current generated responses instead of clearing them.</div>
                    <Form layout='vertical' form={form} onValuesChange={onValuesChange} className='content'>
                        <Form.Item label='If possible, please tell us why you would like to clear your pre-answered responses.' name={'remarks'}
                                rules={[{required: false}]}>
                            <Input size='large'/>
                        </Form.Item>
                    </Form>
                    <div className='form-stickied-footer'>
                        <Button onClick={onFormSubmit} htmlType='submit' icon={<DeleteOutlined/>} iconPosition='right' color='red'>
                            Clear Pre-Answered Responses
                        </Button>
                    </div>
            </Drawer>
        </div>
    )
}

export default ClearDruFormDrawer;