import React, {useEffect, useState} from 'react';

import {Alert, Form, Input, notification, Radio} from "antd";

import cx from 'classnames';
import './styles.scss';

import {TemplateBuilderFormTypes} from "./Types";
import Button from "../../common/Button/Button";
import TemplateAPI from "../../../api/Template/TemplateAPI";
import {ArrowRightOutlined} from "@ant-design/icons";
import BaseFrameworkSelect from "../BaseFrameworkSelect/BaseFrameworkSelect";
import TemplateQuestionBuilder from "../TemplateQuestionBuilder/TemplateQuestionBuilder";
import {IQuestion} from "../../../api/Question/QuestionTypes";
import {ITemplate} from "../../../api/Template/TemplateTypes";
import {RequiredMark} from "antd/es/form/Form";
import {TOAST_DURATION, ERROR_TOAST_DURATION} from '../../../constants';
import {EQuestionnaireType} from "../../../api/Template/TemplateTypes";

interface Props {
    onSubmit?: () => void,
    applyDrawerStyles?: boolean,
    template?: ITemplate
}

function TemplateBuilder(props: Props) {
    const {onSubmit, applyDrawerStyles, template} = props;
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [submittingNewTemplateForm, setSubmittingNewTemplateForm] = useState(false);

    function renderNewTemplateButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit' loading={submittingNewTemplateForm}
                            icon={<ArrowRightOutlined/>} iconPosition='right'>
                        Create Template
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit' loading={submittingNewTemplateForm}
                        icon={<ArrowRightOutlined/>}
                        iconPosition='right'>
                    Create Template
                </Button>
            )
        }
    }

    useEffect(() => {
        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const formProps = {
        requiredMark: "optional" as RequiredMark,
        colon: false,
        className: cx('template-form', {'as-drawer': applyDrawerStyles})
    };

    async function onSubmitClick() {
        if (submittingNewTemplateForm) return false;
        setSubmittingNewTemplateForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields) {
                let values = form.getFieldsValue();
                let questions = values[TemplateBuilderFormTypes.ControlQuestions] as IQuestion[];
                let keyQuestions = values[TemplateBuilderFormTypes.KeyControls] as IQuestion[];

                if(values[TemplateBuilderFormTypes.TemplateType] === 'scoping') {
                    values[TemplateBuilderFormTypes.QuestionnaireType] = EQuestionnaireType.IVPQ;
                }
                
                // Right now, the base framework is only selectable by one, so we need to set it accordingly.
                // When we allow more than one base framework, we'll change this.
                if (values[TemplateBuilderFormTypes.BaseFrameworks]) {
                    values[TemplateBuilderFormTypes.BaseFrameworks] = [values[TemplateBuilderFormTypes.BaseFrameworks]];
                }

                // We keep values as the full question so we can pass them back and forth between the components and
                // have them render the correct information. Therefore, we need to map the question IDs to send them
                // back to Strapi here.
                if (questions && keyQuestions) {
                    let keyQuestionsById:Record<number, IQuestion> = {};
                    let questionsById:Record<number, IQuestion> = {};
                    keyQuestions.forEach(keyQuestion => keyQuestionsById[keyQuestion.id] = keyQuestion);
                    questions.forEach(question => {
                        // If the question exists within the keyQuestions, then remove it from the questions.
                        if (!keyQuestionsById[question.id]){
                            questionsById[question.id] = question;
                        }
                    })
                    values[TemplateBuilderFormTypes.ControlQuestions] = Object.keys(questionsById);
                    values[TemplateBuilderFormTypes.KeyControls] = Object.keys(keyQuestionsById);
                }

                let templateCreateResponse = await TemplateAPI.createTemplate(values);
                if (templateCreateResponse) {
                    if(templateCreateResponse.errors.length === 0 && templateCreateResponse.data) {
                        let template = templateCreateResponse.data
                        notification.success({
                            message: 'Success!',
                            description: <span>Your template, <b>{template.name}</b>, has been successfully created. You may now use it to create new questionnaires.</span>,
                            duration: TOAST_DURATION,
                        })
                        form.resetFields();
                        if (onSubmit) onSubmit();
                    } else {
                        templateCreateResponse.errors.forEach(error => {
                            notification.error({
                                message: 'Error creating template',
                                description: <span>{error}</span>,
                                duration: ERROR_TOAST_DURATION,
                            })
                        });
                    }
                }
            }
        } catch (e) {

        }
        setSubmittingNewTemplateForm(false);
    }

    function onValuesChange(v: any) {
        setValuesForm(v);
    }

    let initialValues = {
        [TemplateBuilderFormTypes.BaseFrameworks]: template?.base_frameworks?.[0]?.id,
        [TemplateBuilderFormTypes.TemplateType]: template?.template_type,
        [TemplateBuilderFormTypes.ControlQuestions]: template?.control_questions,
        [TemplateBuilderFormTypes.KeyControls]: template?.key_controls
    }

    return (
        <div className='template-form-wrapper'>
            <Form {...formProps} layout='vertical' form={form} onValuesChange={onValuesChange}
                  initialValues={initialValues} style={{width: '100%'}}>
                {template &&
                <Alert style={{width: '100%', marginBottom: 24}} type='info'
                       message={`Editing ${template.name} will instead create a new Template`}
                       description={
                           <div className='alert-content'>
                               <div>
                                   When you submit this edited Template, it will not change {template.name}. Instead,
                                   it will create a new Template with the new changes you make. Any changes made here
                                   will <i>not</i> affect questionnaires made with {template.name}.
                               </div>
                           </div>}
                />}
                <Form.Item label='Template Name' name={TemplateBuilderFormTypes.Name}
                           validateTrigger={false} style={{width: '100%'}}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Base Framework' name={TemplateBuilderFormTypes.BaseFrameworks}
                           validateTrigger={false} style={{width: '100%'}}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <BaseFrameworkSelect loadBaseFrameworks/>
                </Form.Item>
                <Form.Item label='Template Type' name={TemplateBuilderFormTypes.TemplateType}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Radio.Group>
                        <Radio value='control'>
                            Control
                        </Radio>
                        <Radio value='scoping'>
                            Scoping
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                {form.getFieldsValue()[TemplateBuilderFormTypes.TemplateType] === 'control' &&
                    <Form.Item label='Questionnaire Type' name={TemplateBuilderFormTypes.QuestionnaireType}
                    rules={[{required: form.getFieldsValue()[TemplateBuilderFormTypes.TemplateType] === 'control', message: 'This field is required.'}]}>
                    <Radio.Group>
                        <Radio value='PSQ'>
                            PSQ
                        </Radio>
                        <Radio value='VSQ'>
                            VSQ
                        </Radio>
                    </Radio.Group>
                </Form.Item>}
                <Form.Item style={{width: '100%'}} label='Control Questions'
                           name={TemplateBuilderFormTypes.ControlQuestions}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <TemplateQuestionBuilder/>
                </Form.Item>
                <Form.Item style={{width: '100%'}} label='Key Controls'
                           name={TemplateBuilderFormTypes.KeyControls}>
                    <TemplateQuestionBuilder/>
                </Form.Item>
            </Form>
            {renderNewTemplateButton()}
        </div>
    )
}

export default TemplateBuilder;
