import axios from "axios";
import {ITemplateItem} from "./TemplateItemTypes";

export default class TemplateItemAPI {
    
    static async getTemplateItemsByTemplateId(templateId:number){
        try {
            let res = await axios.get(`/templates/${templateId}/template-items`);
            return res.data as ITemplateItem[];
        } catch (e) {
            console.error('Could not get template items', e.message);
            return undefined;
        }
    }
}