import { Checkbox } from "antd"
import React, { useEffect, useState } from "react"
import RiskFindingApi from "../../../../api/RiskFinding/RiskFindingApi"
import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import Button from "../../../common/Button/Button"
import DefaultLoader from "../../../common/DefaultLoader/DefaultLoader"
import RiskFinding from "../RiskFindings/RiskFinding"
import { ArrowRightOutlined } from "@ant-design/icons"
import NoData from "../../../../assets/undraw/no_data.svg"

import "./styles.scss"
import {
  EAssessmentStatus,
  IAssessment,
} from "../../../../api/Assessment/AssessmentTypes"
import RiskFindingsProgressBar from "../RiskFindings/RiskFindingsProgressBar"
import { IRemediationPathway } from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import RemediationPathwayApi from "../../../../api/RemediationPathway/RemediationPathwayApi"
import { StickyContainer, Sticky } from "react-sticky-17"
import RiskFindingUtils from "../../../../global/utils/riskFindingUtils"

interface Props {
  assessment: IAssessment
  onSubmit?: (values: any) => void
}

function AssessmentRemediation({ assessment, onSubmit }: Props) {
  const isRem = assessment.status.includes("remediation")
  const isGapVal = assessment.status.includes("gap_validation")

  const [loading, setLoading] = useState<boolean>(false)
  const [riskFindings, setRiskFindings] = useState<IRiskFinding[]>([])
  const [remediationPathways, setRemediationPathways] = useState<
    IRemediationPathway[]
  >([])
  const [filteredRiskFindings, setFilteredRiskFindings] = useState<
    IRiskFinding[]
  >([])
  const [showRequiredRiskFindingsOnly, setShowRequiredRiskFindingsOnly] =
    useState(isRem)

  async function getRiskFindings() {
    let riskFindings = await RiskFindingApi.getRiskFindings(
      {},
      `assessment=${assessment.id}`
    )
    if (riskFindings) {
      setRiskFindings(riskFindings)
      if (showRequiredRiskFindingsOnly) {
        setFilteredRiskFindings(
          sortRiskFindings(riskFindings.filter((r) => r.client_required))
        )
      } else {
        setFilteredRiskFindings(riskFindings)
      }
    }
  }

  async function getRemediationPathways() {
    let remediationPathways =
      await RemediationPathwayApi.getRemediationPathways()
    if (remediationPathways) setRemediationPathways(remediationPathways)
  }

  function sortRiskFindings(riskFindings: any[]) {
    const sortingScheme = ["high", "medium", "low", null]
    return riskFindings.sort((a, b) => {
      const indexOfa = sortingScheme.indexOf(a.risk_reduction_impact)
      const indexOfb = sortingScheme.indexOf(b.risk_reduction_impact)
      if (indexOfa > indexOfb) return 1
      if (indexOfa < indexOfb) return -1
      return 0
    })
  }

  async function onRiskFindingSubmit(values: any) {
    setLoading(true)
    if (onSubmit) await onSubmit(values)
    setLoading(false)
    setShowRequiredRiskFindingsOnly(showRequiredRiskFindingsOnly)
  }

  async function onRiskFindingChange(values: any) {
    await getRiskFindings()
  }

  useEffect(() => {
    setLoading(true)
    getRiskFindings()
    getRemediationPathways()
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  if (loading) {
    return (
      <div className="default-loader-full">
        <DefaultLoader text="Loading Risk Findings..." />
      </div>
    )
  }

  function onRequiredFilterChange(value: any) {
    setShowRequiredRiskFindingsOnly(!showRequiredRiskFindingsOnly)
    if (value.target.checked) {
      setFilteredRiskFindings(
        sortRiskFindings(riskFindings.filter((r) => r.client_required))
      )
    } else {
      setFilteredRiskFindings(sortRiskFindings(riskFindings))
    }
  }

  function enableSubmitButton() {
    if (isGapVal) {
      return (
        !riskFindings.some((r) => r.status === ERiskFindingStatus.Open) &&
        assessment.status !== EAssessmentStatus.GapValidationSubmitted
      )
    }

    return (
      riskFindings.filter(
        (r) => r.client_required && RiskFindingUtils.isRiskFindingAnswered(r)
      ).length === riskFindings.filter((r) => r.client_required).length &&
      assessment.status !== EAssessmentStatus.RemediationSubmitted
    )
  }

  function renderRemediationDescription() {
    return (
      <div className="remediation-instructions">
        <div>
          Your assessment requires remediation. There are two parts to the
          remediation process:<br></br> (1) Respond to the remediation details
          form for each risk finding and click on the{" "}
          <strong>Submit Remediation Plan</strong> button<br></br> (2) Then,
          continue to respond on remediation responses{" "}
          <div>
            <strong>
              You must respond to each finding requiring remediation before you
              can select "Submit Remediation Plan".{" "}
            </strong>
          </div>
        </div>
      </div>
    )
  }

  function renderGapValidationDescription() {
    return (
      <div className="remediation-instructions">
        <div>
          CORL has concluded our initial review of your submission, however what
          we received did not include all the evidence required given the scope
          of your engagement. We are providing you with this opportunity to
          submit additional evidence and/or reasoning as to why you believe
          these items are not applicable. If you believe the evidence provided
          should have sufficiently covered the requirement, please provide a
          detailed explanation in the notes section and/ or provide any other
          supporting evidence that addresses the requirement. The final Security
          Assessment Report will be issued documenting all current risks and
          shared with the business sponsor/stakeholder within 5 business days
          from the date risk findings were added in the portal.
        </div>
      </div>
    )
  }

  function isRemediation() {
    return (
      assessment.status === EAssessmentStatus.RemediationInProgress ||
      assessment.status === EAssessmentStatus.RemediationSubmitted
    )
  }

  return (
    <StickyContainer style={{ width: "100%" }}>
      <Sticky>
        {({ style, isSticky }) => (
          <div
            className="assessment-remediation-headers"
            style={{
              ...style,
              borderBottom: isSticky ? "1px solid #ecedf1" : "0",
            }}
          >
            <div className="title">
              {isRemediation() ? "Remediation" : "Gap Validation"}/Risk Findings
            </div>
            <div className="buttons">
              {(assessment.status === EAssessmentStatus.RemediationInProgress ||
                assessment.status ===
                  EAssessmentStatus.GapValidationInProgress) && (
                <Button
                  onClick={onRiskFindingSubmit}
                  loading={loading}
                  icon={<ArrowRightOutlined />}
                  iconPosition="right"
                  enabled={enableSubmitButton()}
                  style={{ backgroundColor: "#3f4461", color: "white" }}
                >
                  {assessment.status === EAssessmentStatus.RemediationInProgress
                    ? "Submit Remediation Plan"
                    : "Submit Gap Validation Plan"}
                </Button>
              )}
            </div>
          </div>
        )}
      </Sticky>
      <div className="assessment-remediation">
        {isRemediation() && renderRemediationDescription()}
        {!isRemediation() && renderGapValidationDescription()}
        <RiskFindingsProgressBar riskFindings={riskFindings} />
        {isRem && (
          <div>
            <Checkbox
              onChange={onRequiredFilterChange}
              checked={showRequiredRiskFindingsOnly}
            >
              Show Required Risk Findings Only
            </Checkbox>
          </div>
        )}
        <div className="risk-findings">
          {filteredRiskFindings &&
            filteredRiskFindings.length > 0 &&
            filteredRiskFindings.map((r, i) => (
              <RiskFinding
                key={i}
                riskFinding={r}
                remediationPathways={remediationPathways}
                disabled={
                  assessment.status !==
                    EAssessmentStatus.RemediationInProgress &&
                  assessment.status !==
                    EAssessmentStatus.GapValidationInProgress
                }
                onSubmit={onRiskFindingChange}
                loading={loading}
              ></RiskFinding>
            ))}
          {filteredRiskFindings.length === 0 && (
            <div className="global-empty">
              <img alt="" src={NoData} />
              <div>You don't have any data in here yet</div>
            </div>
          )}
        </div>
      </div>
    </StickyContainer>
  )
}

export default AssessmentRemediation
