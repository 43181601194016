import React from 'react';
import {Select} from 'antd';

import './style.scss';

import Status from "../Status/Status";
import { ERiskFindingStatus } from '../../../api/RiskFinding/RiskFindingTypes';

const {Option} = Select;

export interface Props {
    onChange?: (values: any) => void,
    defaultValue?:ERiskFindingStatus,
    loading?:boolean,
    value?:any
    disabled?:boolean
}

function RiskFindingStatusSelect({onChange, defaultValue, loading, value, disabled}:Props) {
    
    function onClear() {
        if (onChange) onChange(undefined);
    }

    return (
        <div className='assessment-status-select' onClick={e=>e.stopPropagation()}>
            <Select className='assessment-status-select-search' size='large' placeholder='Choose status' loading={loading} value={value}
                    optionLabelProp='children' disabled={disabled} onChange={onChange} defaultValue={defaultValue} allowClear onClear={onClear}>
                {Object.keys(ERiskFindingStatus).map(key => (
                    <Option value={ERiskFindingStatus[key]} key={ERiskFindingStatus[key]}>
                        <Status status={ERiskFindingStatus[key]}/>
                    </Option>
                ))}
            </Select>
        </div>
    )
}
export default RiskFindingStatusSelect;