import axios from "axios";
import { IRiskFinding } from "./RiskFindingTypes";

export default class RiskFindingApi {

    static async getRiskFindings(params?:any, qs?:string){
        try{
            let url = '/risk-findings';
            if (qs) url += `?${qs}`;
            let res = await axios.get(url, {params});
            return res.data as IRiskFinding[];
        } catch (e:any){
            console.log('Could not get risk findings', e.message);
            return undefined;
        }
    }

    static async getRiskFindingCount(params?:any, qs?:string){
        let url = '/risk-findings/count';
        if (qs) url += `?${qs}`;
        try {
            let res = await axios.get(url, {params});
            return res.data as number;
        } catch (e:any){
            console.log('Could not get risk-findings', e.message);
            return undefined;
        }
    }

    static async updateRiskFinding(riskFindingId: number, values:any) {
        try {
            let res = await axios.put(`/risk-findings/${riskFindingId}`, values);
            return res.data as IRiskFinding;
        } catch (e:any) {
            console.error('Could not submit Risk Finding details', e.message);
            return undefined;
        }
    }

    static async createRiskFinding(values: any) {
        try {
            let res = await axios.post('/risk-findings', values);
            return res.data as IRiskFinding;
        } catch (e: any){
            console.log('Could not create risk findings', e.message);
            return undefined;
        }
    }
}