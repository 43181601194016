import React, {useState} from 'react';

import './styles.scss';

import {Checkbox, Modal, Table} from "antd";
import {ImportOutlined} from '@ant-design/icons';
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {useImportJobs} from "../../../hooks/ImportJobs.hook";
import Button from '../../common/Button/Button';
import {ImportJobTableConfig} from "./ImportJobTable.config";
import {StringUtils} from "../../../global/utils/string";
import ImportJobAPI from "../../../api/ImportJob/ImportJobAPI";
import {IImportJob} from "../../../api/ImportJob/ImportJobTypes";
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";

function ImportJobTable() {
    const [showImportDrawer, setShowImportDrawer] = useState(false);
    const [selectedValues, setSelectedValues] = useState();
    const {
        importJobs,
        fetchingImportJobs,
        importJobsRequestError,
        getImportJobs
    } = useImportJobs()

    if (!fetchingImportJobs && !importJobs) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text="Loading import jobs..."/>
            </div>
        )
    }
    if (importJobsRequestError) {
        return <div>There was an error getting your import jobs.</div>
    }

    function getOptions() {
        let csvNames = ['vendors', 'clients', 'products', 'questionnaires', 'responses'];
        return csvNames.map(name => ({label: StringUtils.capitalize(name), value: name}))
    }

    async function submitImportJob() {
        let res = await ImportJobAPI.startImportJob(selectedValues);
        if (res){
            await getImportJobs();
        }
        setShowImportDrawer(false);
    }

    const onRow = (record:IImportJob) => {
        return {
            onClick: () => BrowserRouter.push(BrowserRoutes.getImportJobById(record.id))
        }
    }

    return (
        <div className='question-table'>
            <div className='question-table-header'>
                <div className='title'>
                    Import Jobs
                </div>
                <Button icon={<ImportOutlined/>} onClick={() => setShowImportDrawer(true)}>
                    Start New Import Job
                </Button>
            </div>
            <div className='question-table-content'>
                <Table rowKey='id' dataSource={importJobs} columns={ImportJobTableConfig} onRow={onRow}/>
            </div>
            <Modal title='Start Import Job' width='50%' keyboard={false} open={showImportDrawer}
                   destroyOnClose onCancel={() => setShowImportDrawer(false)}
                   className='template-form-drawer' onOk={submitImportJob}>
                <div style={{padding: 24}}>
                    <Checkbox.Group options={getOptions()} onChange={v => setSelectedValues(v)}/>
                </div>
            </Modal>
        </div>
    )
}

export default ImportJobTable;