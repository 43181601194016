import axios from "axios";

const route = '/user-requests'

export default class UserRequestApi {
    static async create(params: any) {
        try {
            let res = await axios.post(`${route}`, params);
            return res.data;
        } catch (e:any) {
            console.log('Could not create user request', e.message);
            return undefined;
        }
    }

    static async update(id:number, values:any){
        try {
            let res = await axios.put(`${route}/${id}`, values);
            return res.data;
        } catch (e:any) {
            console.log('Could not update user request', e.message);
            return undefined;
        }
    }

    static async get(params?:any) {
        try {
            let res = await axios.get(`${route}`, {params});
            return res.data;
        } catch (e:any) {
            console.log('Could not get user requests', e.message);
            return undefined;
        }
    }

    static async getCount(params?:any) {
        try {
            let res = await axios.get(`${route}/count`, {params});
            return res.data;
        } catch (e:any) {
            console.log('Could not count user requests', e.message);
            return undefined;
        }
    }
}