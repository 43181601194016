import React, {useState} from 'react';
import {Drawer} from "antd";
import {PlusOutlined} from '@ant-design/icons';

import './styles.scss';
import Button from "../../common/Button/Button";
import QuestionnaireForm from "./QuestionnaireForm";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";

interface Props {
    className?: string,
    onSubmit?: (values: string) => void,
    asButton?: boolean,
    assessment:IAssessment
}

function QuestionnaireFormDrawer(props: Props) {
    const {className, onSubmit, asButton, assessment} = props;
    const [showForm, setShowForm] = useState(!asButton);

    const onButtonClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
    }

    const onFormSubmit = async (values: any) => {
        if (onSubmit) await onSubmit(values);
        setShowForm(false);
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            {asButton &&
            <Button icon={<PlusOutlined/>} className={className} onClick={onButtonClick}>
                Create Questionnaire
            </Button>}
            <Drawer title='Create Questionnaire' width='50%' keyboard={false} open={showForm}
                    destroyOnClose onClose={() => setShowForm(false)}
                    footer={null} className='questionnaire-form-drawer'>
                <QuestionnaireForm onSubmit={onFormSubmit} applyDrawerStyles assessment={assessment}/>
            </Drawer>
        </div>
    )
}

export default QuestionnaireFormDrawer;