import React, {useCallback, useEffect, useState} from 'react';
import './styles.scss';
import UserTable from "../UserTable/UserTable";
import {IUser, UserRoles} from "../../../api/User/UserTypes";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import UserAPI from "../../../api/User/UserAPI";
import AppStore from "../../../stores/App/AppStore";
import InviteUserFormModalAsButton from "../InviteUserForm/InviteUserFormModalAsButton";
import {notification} from "antd";
import _ from "lodash";

function CorlUserManagement() {
    const [adminUsers, setAdminUsers] = useState<IUser[]>();
    const [accountUsers, setAccountUsers] = useState<IUser[]>();

    async function getAdminUsers(params?: any) {
        let users = await UserAPI.getAdminUsersForCORL(params);
        if (users) setAdminUsers(users);
    }

    async function getAccountUsers(params?: any) {
        let users = await UserAPI.getAccountUsersForCORL(params);
        if (users) setAccountUsers(users);
    }

    function isAllowedToSeeAdminUsers() {
        return StrapiPermissionsUtil.isRole([UserRoles.CorlAdmin], AppStore.user)
    }

    useEffect(() => {
        getAdminUsers();
        getAccountUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function renderMessageError(message:any){
        return message?.[0]?.messages?.[0]?.message ? message?.[0]?.messages?.[0]?.message : 'Undefined error.'
    }

    async function onAdminInviteSubmit(values:any) {
        let inviteSent = await UserAPI.inviteCORLAdmin(values);
        if (!inviteSent.error) {
            notification.success({
                message: 'Success!',
                description: <span>You have successfully invited <b>{values.email}</b> as a CORL Admin. They should see an invitation in their email within the next few minutes.</span>,
                duration: 2.5,
            })
            getAdminUsers();
            getAccountUsers();
        } else {
            notification.error({
                message: 'Error sending invitation!',
                description:
                    <div>
                        <span>Unfortunately, we were not able to send your invitation to <b>{values.email}</b></span>.
                        <div>Error: {renderMessageError(inviteSent.error)}</div>
                    </div>,
                duration: 10,
            })
        }
    }

    async function onAccountInviteSubmit(values:any) {
        let inviteSent = await UserAPI.inviteCORLEmployee(values);
        if (!inviteSent.error) {
            notification.success({
                message: 'Success!',
                description: <span>You have successfully invited <b>{values.email}</b> as a CORL Account. They should see an invitation in their email within the next few minutes.</span>,
                duration: 2.5,
            })
            getAdminUsers();
            getAccountUsers();
        } else {
            notification.error({
                message: 'Error sending invitation!',
                description:
                    <div>
                        <span>Unfortunately, we were not able to send your invitation to <b>{values.email}</b></span>.
                        <div>Error: {inviteSent.error}</div>
                    </div>,
                duration: 10,
            })
        }
    }

    async function onCORLAdminSearch(text:string) {
        await getAdminUsers({email_contains: text})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedCORLAdminSearch = useCallback(_.debounce(onCORLAdminSearch, 300), []);

    async function onCORLEmployeeSearch(text:string) {
        await getAccountUsers({email_contains: text})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedCORLEmployeeSearch = useCallback(_.debounce(onCORLEmployeeSearch, 300), []);

    async function onDeleteUser(id: number) {
        if (AppStore.user?.id !== id) {
            await UserAPI.deleteCORLUser(id);
            getAdminUsers();
            getAccountUsers();
        }
    }

    if (!isAllowedToSeeAdminUsers()) return null;

    return (
        <div className='corl-user-management'>
            {isAllowedToSeeAdminUsers() &&
            <div className='users'>
                <div className='corl-user-management-left'>
                    <div className='header'>
                        CORL Admins
                    </div>
                    <div className='description'>
                        CORL Admins can add and remove CORL Employees and other CORL Admins.
                    </div>
                    {StrapiPermissionsUtil.isRole([UserRoles.CorlAdmin], AppStore.user) &&
                    <InviteUserFormModalAsButton key='admin' inviteText='Invite CORL Admin'
                                                 onSubmit={onAdminInviteSubmit}/>}
                </div>
                <div className='corl-user-management-right'>
                    <UserTable users={adminUsers} onSearch={debouncedCORLAdminSearch}
                               onDeleteUser={adminUsers && adminUsers.length > 1 ? onDeleteUser : undefined}/>
                </div>
            </div>}
            <div className='users'>
                <div className='corl-user-management-left'>
                    <div className='header'>
                        CORL Employees
                    </div>
                    <div className='description'>
                        CORL Employees can answer questionnaires.
                    </div>
                    {StrapiPermissionsUtil.isRole([UserRoles.CorlAdmin], AppStore.user) &&
                    <InviteUserFormModalAsButton key='account' inviteText='Invite CORL Employee'
                                                 onSubmit={onAccountInviteSubmit}/>}
                </div>
                <div className='corl-user-management-right'>
                    <UserTable users={accountUsers} onSearch={debouncedCORLEmployeeSearch}
                               onDeleteUser={onDeleteUser}/>
                </div>
            </div>
        </div>
    )
}

export default CorlUserManagement;