import React from 'react';
import {Select} from 'antd';

import './style.scss';

import {EAssessmentStatus} from "../../../api/Assessment/AssessmentTypes";
import Status from "../Status/Status";

const {Option} = Select;

export interface Props {
    onChange?: (selectedStatus?: number) => void,
    defaultValue?:EAssessmentStatus,
    loading?:boolean,
    value?:any
    disabled?:boolean,
}

function StatusSelect(props:Props){
    const {onChange, defaultValue, loading, value, disabled} = props;

    function onClear(){
        if (onChange) onChange(undefined);
    }

    return (
        <div className='assessment-status-select' onClick={e=>e.stopPropagation()}>
            <Select className='assessment-status-select-search' size='large' placeholder='Choose status' loading={loading} value={value}
                    optionLabelProp='children' disabled={disabled} onChange={onChange} defaultValue={defaultValue} allowClear onClear={onClear}>
                {Object.keys(EAssessmentStatus).map(key => (
                    <Option value={EAssessmentStatus[key]} key={EAssessmentStatus[key]}>
                        <Status status={EAssessmentStatus[key]}/>
                    </Option>
                ))}
            </Select>
        </div>
    )
}
export default StatusSelect;