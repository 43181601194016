import React from 'react';
import {Switch, Route} from 'react-router-dom';

import './styles.scss';

import {BrowserRoutes} from "../../stores/App/BrowserRouter";
import ClientTable from "../../components/specific/ClientTable/ClientTable";
import ClientManagement from "../../components/specific/ClientManagement/ClientManagement";

function ClientsContainer(){

    return (
        <section className='client-container'>
            <Switch>
                <Route exact path={BrowserRoutes.clients} render={() => (
                    <div style={{padding:24, width:'100%', height:'100%'}}>
                        <ClientTable/>
                    </div>
                )}/>
                <Route path={BrowserRoutes.clientsByIdParam} render={({match})=><ClientManagement clientId={match.params.clientId}/>}/>
            </Switch>
        </section>
    )
}

export default ClientsContainer;