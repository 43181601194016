import axios from "axios";
import {IAssessmentQuestionnaireStatusExport, IClientVendorExport, ISecurityControlQuestionExport, ISecurityControlQuestionImport, IVendorUserExport} from './ExportJobTypes';

export default class ExportJobAPI {
    static async assessmentQuestionnaireStatusExport() {
        try {
            let res = await axios.get('/export-jobs/assessment-questionnaire-status-export');
            return res.data as IAssessmentQuestionnaireStatusExport[];
        } catch (e: any) {
            console.error('Could not complete export job', e.message);
            return [] as IAssessmentQuestionnaireStatusExport[];
        }
    }

    static async securityControlQuestionsExport() {
        try {
            let res = await axios.get('/export-jobs/security-control-questions-export');
            return res.data as ISecurityControlQuestionExport[];
        } catch (e: any) {
            console.error('Could not complete export job', e.message);
            return [] as ISecurityControlQuestionExport[];
        }
    }

    static async securityControlQuestionsImport(importFile: File) {
        let formData = new FormData();
        formData.append('files.attachment', importFile);
        
        try{
            let res = await axios.post('/export-jobs/security-control-questions-import', formData);
            return res.data as ISecurityControlQuestionImport[];
        } catch (e: any){
            console.error('Could not complete export job', e.message);
            return [] as ISecurityControlQuestionImport[];
        }
    }

    static async backfillLiveSyncQuestionnaires(importFile: File) {
        let formData = new FormData();
        formData.append('files.attachment', importFile);
        
        try{
            await axios.post('/export-jobs/livesync-questionnaire-backfill-import', formData);
        } catch (e: any){
            console.error('Could not complete export job', e.message);
            return;
        }
    }

    static async clientVendorExport() {
        try {
            let res = await axios.get('/export-jobs/client-vendor-export');
            return res.data as IClientVendorExport[];
        } catch (e: any) {
            console.error('Could not complete export job', e.message);
            return [] as IClientVendorExport[];
        }
    }

    static async assessmentReminders() {
        try {
            await axios.post('/export-jobs/send-assessment-reminders');
            return;
        } catch (e: any) {
            console.error('Could not complete job', e.message);
            return;
        }
    }
    
    static async vendorUsersExport() {
        try {
            let res = await axios.get('/export-jobs/vendor-users-export');
            return res.data as IVendorUserExport[];
        } catch (e: any) {
            console.error('Could not complete export job', e.message);
            return [] as IVendorUserExport[];
        }
    }

    static async remediationReminders() {
        try {
            await axios.post('/export-jobs/send-remediation-reminders');
            return;
        } catch (e: any) {
            console.error('Could not complete job', e.message);
            return;
        }
    }
}