export enum AssessmentBuilderFormTypes {
    Title = 'title',
    Client = 'client',
    Vendor = 'vendor',
}

export enum TemplateBuilderFormTypes {
    Name = 'name',
    TemplateType = "template_type",
    BaseFrameworks = 'base_frameworks',
    ControlQuestions = 'control_questions',
    ImportFile = 'import_file',
    KeyControls = 'key_controls',
    QuestionnaireType = 'questionnaire_type'
}