import React from "react"
import moment from "moment"
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileOutlined,
  FileUnknownOutlined,
  FileJpgOutlined,
  FilePptOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import Button from "../../common/Button/Button"
import { IVendorFile } from "../../../api/VendorFile/VendorFileTypes"

export const VendorFilesTableConfig = (
  onDeleteFile: (e: any, file: IVendorFile) => void,
  disableActions?: boolean,
  loading?: boolean
) => {
  let config = [
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
      render: (file_name: string) => {
        let vendorFileType = file_name.split(".")
        let vendorFileTypeString = vendorFileType[1]
        let marginStyle = { margin: "0 8px" }
        let renderSwitchItems = () => {
          switch (vendorFileTypeString?.toLowerCase()) {
            case "pdf":
              return <FilePdfOutlined style={marginStyle} />
            case "xlsx" || "xls" || "xlsm" || "xlsb":
              return <FileExcelOutlined style={marginStyle} />
            case "txt":
              return <FileTextOutlined style={marginStyle} />
            case "jpg" || "jpeg":
              return <FileJpgOutlined style={marginStyle} />
            case "png":
              return <FileImageOutlined style={marginStyle} />
            case "doc" || "docx":
              return <FileWordOutlined style={marginStyle} />
            case "csv" || "msg":
              return <FileOutlined style={marginStyle} />
            case "pptx" || "ppt":
              return <FilePptOutlined style={marginStyle} />
            default:
              return <FileUnknownOutlined style={marginStyle} />
          }
        }

        return (
          <div className="file-name">
            {renderSwitchItems()}
            {file_name}
          </div>
        )
      },
    },
    {
      title: "Date Uploaded",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => {
        return moment(created_at).format("MMM DD, YYYY")
      },
    },
  ]

  if (!disableActions) {
    config.push({
      title: "Actions",
      key: "actions",
      render: (file: IVendorFile) => {
        return (
          <div className="actions">
            <Button
              className="delete-file"
              color="red"
              icon={<DeleteOutlined />}
              onClick={(e: any) => onDeleteFile(e, file)}
              loading={loading}
            >
              Delete
            </Button>
          </div>
        )
      },
    })
  }

  return config
}
