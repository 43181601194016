import React, { useEffect, useState } from "react"
import { Form, Radio } from "antd"

import "./styles.scss"

import QuestionText from "./QuestionText"
import { RESPONSE_CHOICES } from "../../../constants"
import QuestionnaireUtils from "../../../global/utils/questionnaireUtils"

interface Props {
  entityId: number
  disabled?: boolean
  initialValues?: any
  requireComment?: boolean
  onChange: (values: any) => void
  onSubmit?: (values: any) => void
}

function QuestionResponseForm({
  entityId,
  initialValues,
  disabled,
  requireComment,
  onChange,
  onSubmit,
}: Props) {
  const [showCommentField, setShowCommentField] = useState(
    initialValues?.response_choice ? true : false
  )
  const [commentRequired, setCommentRequired] = useState(
    QuestionnaireUtils.isCommentRequired(
      requireComment || initialValues?.response_choice
    )
  )
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const commentRequiredText = requireComment
    ? "This field is required."
    : "This field is required when answer is Partial or N/A."

  useEffect(() => {
    return function cleanup() {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    form.validateFields(["response_text"])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRequired])

  function responseChoiceIsSetOrIsInitialized() {
    return (
      !!form.getFieldValue("response_choice") || initialValues?.response_choice
    )
  }

  async function onResponseChange(values: any) {
    if (loading) return
    setLoading(true)
    if (form.getFieldValue("response_choice")) {
      if (requireComment) setCommentRequired(true)
      else
        setCommentRequired(
          QuestionnaireUtils.isCommentRequired(
            form.getFieldValue("response_choice")
          )
        )
      setShowCommentField(true)
    }
    if (onChange) await onChange(values)
    setLoading(false)
  }

  async function onFinish(values: any) {
    setLoading(true)
    if (onSubmit) await onSubmit(values)
    setLoading(false)
  }

  return (
    <Form
      id={`question-response-form-${entityId}`}
      form={form}
      layout="vertical"
      onValuesChange={onResponseChange}
      initialValues={initialValues}
      onFinish={onFinish}
      style={{ width: "100%" }}
    >
      <Form.Item
        name="response_choice"
        className="response"
        rules={[{ required: true, message: "This field is required." }]}
      >
        <Radio.Group disabled={disabled || loading}>
          <Radio value={RESPONSE_CHOICES.Yes}>Yes</Radio>
          <Radio value={RESPONSE_CHOICES.No}>No</Radio>
          <Radio value={RESPONSE_CHOICES.Partial}>Partial</Radio>
          <Radio value={RESPONSE_CHOICES.NA}>N/A</Radio>
        </Radio.Group>
      </Form.Item>
      {showCommentField && (
        <Form.Item
          name="response_text"
          label="Comments"
          className="comments"
          rules={[
            {
              required: commentRequired,
              message: commentRequiredText,
            },
          ]}
        >
          <QuestionText
            id={`comments-${entityId}`}
            style={{ resize: "none" }}
            autoSize={{ minRows: 3 }}
            disabled={
              disabled || loading || !responseChoiceIsSetOrIsInitialized()
            }
          />
        </Form.Item>
      )}
    </Form>
  )
}

export default QuestionResponseForm
