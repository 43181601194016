import React from "react";
import {Tooltip, Typography} from "antd";
import {SendOutlined, EditOutlined} from '@ant-design/icons';
import {StringUtils} from "../../../global/utils/string";
import {TQuestionType} from "../../../api/Base/BaseTypes";
import QuestionnaireStatusSelectProtected from "../StatusSelect/QuestionnaireStatusSelectProtected";
import {IQuestionnaire, EQuestionnaireStatus} from "../../../api/Questionnaire/QuestionnaireTypes";
import {IProduct} from "../../../api/Product/ProductTypes";
import {IImplementation} from "../../../api/Implementation/ImplementationTypes";
import ExportQuestionnaire from "../ExportQuestionnaire/ExportQuestionnaire";
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import AppStore from "../../../stores/App/AppStore";


export const QuestionnaireTableConfig = (onChange: () => void, onClickEdit?: (questionnaireId: number) => void, onClickSendQuestionnaireCreateEmail?: (questionnaireId: number) => void) => [

    {
        title: 'Questionnaire', dataIndex: 'title', key: 'title',
        render: (title: string, questionnaire:IQuestionnaire) => {
            function onClick(){
                BrowserRouter.push(BrowserRoutes.getQuestionnaireById(questionnaire.assessment.id, questionnaire.id))
            }
            return (
                <div className='questionnaire' onClick={onClick}>
                    <Tooltip title={title}>
                        <Typography.Paragraph className='typography' ellipsis={{rows: 1}}>
                            {title}
                        </Typography.Paragraph>
                    </Tooltip>
                </div>
            )
        }
    },
    {
        title: 'Type', dataIndex: ['template', 'template_type'], key: 'template',
        render: (templateType: TQuestionType) => {
            if (templateType) {
                return StringUtils.camelCaseToRegular(templateType, false, true);
            }
        }
    },
    {
        title: 'Template', dataIndex: ['template', 'name'], key: 'template_name',
    },
    {
        title: 'Type', dataIndex: ['template', 'template_type'], key: 'template_type',
        render: (templateType: TQuestionType) => {
            if (templateType) {
                return StringUtils.camelCaseToRegular(templateType, false, true);
            }
        }
    },
    {
        title: 'Product', dataIndex: 'product', key: 'product',
        render: (product: IProduct) => {
            if (product) {
                return StringUtils.camelCaseToRegular(product.name, false, true);
            }
        }
    },
    {
        title: 'Implementation', dataIndex: 'implementation', key: 'implementation',
        render: (implementation: IImplementation) => {
            if (implementation) {
                return StringUtils.camelCaseToRegular(implementation.name, false, true);
            }
        }
    },
    {
        title: 'Status', dataIndex: 'status', key: 'status',
        render: (status: string, fullRecord: IQuestionnaire) => {
            return <QuestionnaireStatusSelectProtected onChange={onChange} questionnaire={fullRecord}/>;
        }
    },
    {
        title: '', key: 'actions',
        render: (questionnaire: IQuestionnaire) => {
            return (
                <div className='assessment-table-actions'>
                    {StrapiPermissionsUtil.isCorlAdmin(AppStore.user) && onClickEdit &&
                        <div className='edit-button' style={{marginRight: 12}}>
                            <Tooltip title='Edit'>
                                <EditOutlined onClick={() => onClickEdit(questionnaire.id)}/>
                            </Tooltip>
                        </div>}
                    {StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user) && questionnaire.status === EQuestionnaireStatus.InProgress && onClickSendQuestionnaireCreateEmail && 
                        <div className='edit-button' style={{marginRight: 12}}>
                            <Tooltip title='Re-Send Questionnaire Created Email'>
                                <SendOutlined onClick={() => onClickSendQuestionnaireCreateEmail(questionnaire.id)}/>
                            </Tooltip>
                        </div>}
                    {/* {StrapiPermissionsUtil.isCorlAdmin(AppStore.user) && onClickDelete && questionnaire.responses.length === 0 &&
                        <div className='delete-button' style={{textAlign: 'center', color: 'red'}}>
                            <Tooltip title='Delete Questionnaire'>
                                <DeleteOutlined onClick={(e: any) => {
                                    onClickDelete(questionnaire.id);
                                    e.stopPropagation();
                                }}/>
                            </Tooltip>
                        </div>} */}
                    {questionnaire.status !== EQuestionnaireStatus.InProgress &&
                        <ExportQuestionnaire questionnaire={questionnaire}/>}
                </div>
            )
        }
    }
];
