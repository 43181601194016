import { IAssessment } from "../Assessment/AssessmentTypes"
import { IQuestionnaire } from "../Questionnaire/QuestionnaireTypes"
import { IRemediationFile } from "../RemediationFile/RemediationFileTypes"
import {
  IRemediationPathway,
  ISecCertType,
} from "../RemediationPathway/RemediationPathwayTypes"
import { IResponse } from "../Response/ResponseTypes"
import { ITask } from "../Task/TaskTypes"
import { IUser } from "../User/UserTypes"

export interface IRiskFinding {
  id: number
  response: IResponse
  status: ERiskFindingStatus
  vendor_valid: boolean
  client_required: boolean
  corl_recommended_due_date?: Date
  vendor_remediate: boolean
  vendor_action: string
  vendor_commitment_date: Date
  remediation_response: string
  remediation_response_text: string
  remediation_files: IRemediationFile[]
  is_key_control: boolean
  risk_finding_histories: IRiskFindingHistory[]
  questionnaire: IQuestionnaire
  assessment: IAssessment
  vendor_id: number
  assessment_id: number
  questionnaire_id: number
  risk_reduction_impact: ERiskReductionImpact
  risk_summary_inadequate_verbiage: string
  documentation_request: string
  remediation_verbiage: string
  vendor_valid_reason: string
  vendor_remediate_reason: string
  corl_communication: string
  control_number_text: string
  control_question_text: string
  outcome: string
  remediation_pathway: IRemediationPathway
  sec_cert_type: ISecCertType
  remediation_scenario: string
  is_ipi_generation_irregular?: boolean
  create_date?: Date
  update_date?: Date
  tasks?: ITask[]
}

export interface IRiskFindingHistory {
  id: number
  author: IUser
  message: string
  submitted_date: Date
}

export enum ERiskFindingStatus {
  Open = "open",
  Submitted = "submitted",
  VendorCommited = "vendor_committed",
  Closed = "closed",
  Resolved = "resolved",
}

export enum ERiskFindingUpdateEvents {
  DetailsSubmitted = "risk_finding_details_submitted",
  RemediatedResponseSubmitted = "risk_finding_remediated_response_submitted",
}

export enum ERiskReductionImpact {
  Low = "low",
  Medium = "medium",
  High = "high",
}
