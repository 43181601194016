import React, {useEffect, useState} from 'react';
import {useLocation, Switch, Route, Redirect} from 'react-router-dom';
import {TeamOutlined, SettingOutlined} from '@ant-design/icons';
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import cx from 'classnames';
import './styles.scss';
import ClientAPI from "../../../api/Client/ClientAPI";
import {IClient} from "../../../api/Client/ClientTypes";
import ClientUserManagement from "../ClientUserManagement/ClientUserManagement";
import ClientSettings from "../ClientSettings/ClientSettings";

interface Props {
    clientId: number;
}

function ClientManagement(props: Props) {
    let location = useLocation();
    const {clientId} = props;
    const [client, setClient] = useState<IClient>();

    async function getClient() {
        let curClient = await ClientAPI.getClientById(clientId);
        if (curClient) setClient(curClient);
    }

    async function onClientUpdate(client: IClient) {
        setClient(client);
    }

    useEffect(() => {
        getClient();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let tabs = {
        users: {
            id: 'users',
            display: 'Users',
            path: BrowserRoutes.getClientUsersByClientId(clientId),
            icon: <TeamOutlined/>
        },
        settings: {
            id: 'settings',
            display: 'Settings',
            path: BrowserRoutes.getClientSettingsByClientId(clientId),
            icon: <SettingOutlined/>
        },
    }

    function getSelectedKey() {
        for (let key in tabs) {
            if (location.pathname.includes(tabs[key].path)) {
                return key;
            }
        }
        return '';
    }

    function renderTabs() {
        return Object.keys(tabs).map(key => {
            const tab = tabs[key];
            const {display, path, icon} = tab;
            let selected = key === getSelectedKey()

            function onClick() {
                if (!selected) {
                    BrowserRouter.push(path)
                }
            }

            return (
                <div key={key} className={cx('client-management-tabs-tab', {selected})} onClick={onClick}>
                    {icon} {display}
                </div>
            )
        })
    }

    if (!client) return null;

    return (
        <div className='client-management-container'>
            <div className='client-management-container-header'>
                {client.name} Management
            </div>
            <Route path={BrowserRoutes.clientsByIdParam} render={() => {
                return (
                    <div className='client-management-tabs'>
                        {renderTabs()}
                    </div>
                )
            }}/>
            <Switch>
                <Route exact path={BrowserRoutes.getClientUsersByClientId(clientId)}
                       render={() => (<ClientUserManagement clientId={clientId}/>)}/>
                <Route exact path={BrowserRoutes.getClientSettingsByClientId(clientId)} render={() => (
                    <ClientSettings onClientUpdate={onClientUpdate}
                                    client={client}/>)}/>
                <Redirect to={BrowserRoutes.getClientUsersByClientId(clientId)}/>
            </Switch>

        </div>
    )
}

export default ClientManagement;