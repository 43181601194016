import React, {useState} from 'react';
import {Input} from "antd";

interface Props {
    onChange?: (value: any) => void,
    value?: any,
    disabled?: boolean
    id: string,
    style?: any,
    autoSize?: any
}

function QuestionText(props: Props) {
    const {disabled, onChange, value, id} = props;
    const [text, setText] = useState(value);
    const [lastTextChange, setLastTextChange] = useState(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    //let debouncedOnValuesChange = useCallback(_.debounce(onChange as any, 400), []);

    function onChangeInternal(e: any){
        setText(e.target.value);
    }

    function onBlurInternal(e: any){
        //debouncedOnValuesChange(e.target.value);
        if(text === lastTextChange) return;
        if(onChange) onChange(e?.target?.value);
        setLastTextChange(e?.target?.value);
    }

    return (
        <Input.TextArea id={`comments-${id}`} style={{resize: "none"}} autoSize={{minRows: 3}} value={text}
                        disabled={disabled} onChange={onChangeInternal} onBlur={onBlurInternal}/>
    )
}

export default QuestionText;