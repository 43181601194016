import React from 'react';
import { Switch, Route } from 'react-router-dom';

import './styles.scss';

import { RouteComponentProps } from 'react-router-dom';
import { BrowserRoutes } from "../../stores/App/BrowserRouter";
import NotificationsOverview from '../../components/specific/NotificationsOverview/NotificationsOverview';

interface Props extends RouteComponentProps {

}

function NotificaitionsView(props: Props) {

    function renderNotifications() {
        return <NotificationsOverview />
    }

    return (
        <div className='notifications-view'>
            <Switch>
                <Route key={0} exact path={BrowserRoutes.notifications} render={renderNotifications} />
            </Switch>
        </div>
    )
}
export default NotificaitionsView;