import React from 'react';
import {ExportOutlined} from '@ant-design/icons';
import Button from '../../common/Button/Button';

export const ExportJobTableConfig = (enableButton:boolean) => [
    {
        title: 'Job Name', dataIndex: 'exportJob', key: 'exportJob',
        render: (exportJob:string) => {
            return exportJob;
        }
    },
    {
        title: 'Description', dataIndex: 'description', key: 'description',
        render: (description:string) => {
            return description;
        }
    },
    {
        title: '', dataIndex: 'jobControls', key: 'jobControls',
        render: (jobControls:any[]) => {
            var items = jobControls?.map((job, index) => {
                return <Button enabled={enableButton} key={index} className='job-button' icon={<ExportOutlined/>} onClick={() => job.callback()}>
                        {job.name}
                    </Button>
            })
            return <div className='job-controls'>{items}</div>;
        }
    },
];