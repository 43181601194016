import { List, Tabs, Tag } from "antd"
import React, { useState } from "react"
import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import { KeyOutlined } from "@ant-design/icons"
import RiskFindingStatus from "./RiskFindingStatus"
import RiskFindingDetailsForm from "./RiskFindingDetailsForm"
import RiskFindingResponseForm from "./RiskFindingResponseForm"
import RiskReductionBadge from "./RiskReductionBadge"
import { IResponseFile } from "../../../../api/ResponseFile/ResponseFileTypes"
import { IRemediationPathway } from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import RiskFindingHistory from "../RiskFindingHistory/RiskFindingHistory"
import CorlClearedResponseForm from "./CorlClearedResponseForm"
import { EAssessmentStatus } from "../../../../api/Assessment/AssessmentTypes"

interface Props {
  riskFinding: IRiskFinding
  remediationPathways: IRemediationPathway[]
  activeTab?: string
  disabled?: boolean
  onSubmit?: (values: any) => void
  onChange?: (values: any) => void
}

function RiskFindingRemediation({
  riskFinding,
  remediationPathways,
  activeTab,
  disabled,
  onSubmit,
  onChange,
}: Props) {
  const [tabKey, setTabKey] = useState(activeTab ? activeTab : "details")

  const isGapVal = riskFinding.assessment?.status?.includes("gap_validation")
  const isCC = riskFinding.assessment?.is_corl_cleared

  function renderItem(file: IResponseFile) {
    return (
      <List.Item key={file.id}>
        <div style={{ marginRight: "8px", textDecoration: "underline" }}>
          {file.vendor_file.file_name}
        </div>
      </List.Item>
    )
  }

  async function onDetailsSubmit(values: any) {
    if (onSubmit) await onSubmit(values)
  }

  async function onResponseSubmit(values: any) {
    if (onSubmit) await onSubmit(values)
  }

  async function onRiskFindingChange(values: any) {
    if (onChange) await onChange(values)
  }

  const renderTabBar = (props: any, DefaultTabBar: any) => (
    <DefaultTabBar {...props} className="site-custom-tab-bar" />
  )

  let tabs = [
    {
      key: "details",
      label: "Details",
      children: (
        <div className="risk-finding-vendor-actions">
          <div className="risk-finding-remediation-details-container">
            <div className="risk-finding-remediation-details-title">
              Risk Finding Details
            </div>
            <div>
              Please fill out the form below. If you agree to remediate this
              risk finding, you must provide an update before the due date.
            </div>
            {/* Removing for now without gap val */}
            {/* <div className="risk-finding-remediation-details-block">
              <div style={{ fontWeight: 600, marginTop: "6px" }}>Details</div>
              <div>
                {!isGapVal && riskFinding.remediation_verbiage}
                {isGapVal && riskFinding.corl_communication}
              </div>
            </div> */}
            <div
              className="risk-finding-remediation-details-form-title"
              style={{ fontWeight: 600, marginTop: "6px" }}
            >
              {isGapVal ? "Gap Validation" : "Remediation"} Details Form
            </div>
            <RiskFindingDetailsForm
              riskFinding={riskFinding}
              remediationPathways={remediationPathways}
              onSubmit={onDetailsSubmit}
              onChange={onRiskFindingChange}
              disabled={
                riskFinding.status !== ERiskFindingStatus.Open || disabled
              }
              gapValidation={isGapVal}
            />
          </div>
        </div>
      ),
    },
    {
      key: "remediation",
      label: "Remediation",
      children: (
        <div className="risk-finding-remediation-response-tab">
          <div className="risk-finding-remediation-response-container">
            <div className="risk-finding-remediation-response-title">
              Risk Finding Remediation
            </div>
            {!isCC && (
              <RiskFindingResponseForm
                riskFinding={riskFinding}
                disabled={
                  riskFinding.status !== ERiskFindingStatus.VendorCommited &&
                  riskFinding.assessment.status !==
                    EAssessmentStatus.GapValidationInProgress &&
                  riskFinding.assessment.status !==
                    EAssessmentStatus.RemediationInProgress
                }
                onSubmit={onResponseSubmit}
                onChange={onRiskFindingChange}
              />
            )}
            {isCC && riskFinding.tasks && riskFinding.tasks.length > 0 && (
              <CorlClearedResponseForm
                riskFinding={riskFinding}
                onSubmit={onResponseSubmit}
                onChange={onRiskFindingChange}
                disabled={
                  riskFinding.status !== ERiskFindingStatus.VendorCommited &&
                  riskFinding.assessment.status !==
                    EAssessmentStatus.GapValidationInProgress &&
                  riskFinding.assessment.status !==
                    EAssessmentStatus.RemediationInProgress
                }
              />
            )}
            {isCC &&
              ((riskFinding.tasks && riskFinding.tasks.length === 0) ||
                !riskFinding.tasks) &&
              riskFinding.sec_cert_type &&
              riskFinding.sec_cert_type.id && (
                <div className="risk-finding-response-form-wrapper">
                  <div style={{ marginBottom: "8px" }}>
                    <strong>
                      Based on the selected remediation pathway the
                      incremental/sequential tasks will be shown here after the
                      auditors review. Please check back again shortly.
                    </strong>
                  </div>
                </div>
              )}
          </div>
        </div>
      ),
    },
    {
      key: "history",
      label: "History",
      children: (
        <div className="risk-finding-history-tab">
          <RiskFindingHistory riskFinding={riskFinding}></RiskFindingHistory>
        </div>
      ),
    },
  ]

  return (
    <div className="risk-finding-remediation-container">
      <div className="risk-finding-remediation-top">
        <div className="risk-finding-remediation-top-header">
          <RiskFindingStatus riskFinding={riskFinding}></RiskFindingStatus>
          <RiskReductionBadge riskFinding={riskFinding}></RiskReductionBadge>
        </div>
        <div className="risk-finding-remediation-header">
          {riskFinding.is_key_control && (
            <Tag
              className="risk-finding-tag"
              color="green"
              icon={<KeyOutlined />}
            >
              Key Control
            </Tag>
          )}
        </div>
        <div
          style={{
            fontWeight: 600,
            fontSize: 16,
            width: "100%",
            marginTop: "8px",
          }}
        >
          {riskFinding.control_question_text ||
            riskFinding.response?.control_question?.text}
        </div>
        {riskFinding.response && (
          <div className="risk-finding-remediation-response-container">
            <div className="risk-finding-remediation-response-top">
              <div className="risk-finding-remediation-response-field">
                <div style={{ fontWeight: 600 }}>Your Response</div>
                <div>{riskFinding.response?.response_choice}</div>
              </div>
              <div
                className="risk-finding-remediation-response-field"
                style={{ marginLeft: "32px" }}
              >
                <div style={{ fontWeight: 600 }}>Attachments</div>
                <div>
                  {riskFinding.response?.response_files?.length > 0 && (
                    <List
                      grid={{ xs: 1 }}
                      className="attachments-list"
                      renderItem={renderItem}
                      dataSource={riskFinding.response?.response_files}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className="risk-finding-remediation-response-field"
              style={{ marginTop: "16px" }}
            >
              <div style={{ fontWeight: 600 }}>Your Comments</div>
              <div>{riskFinding.response?.response_text}</div>
            </div>
          </div>
        )}
      </div>
      <Tabs
        className="risk-finding-remediation-bottom"
        style={{ height: "100%" }}
        activeKey={tabKey}
        renderTabBar={renderTabBar}
        onChange={(key) => setTabKey(key)}
        items={tabs}
      />
    </div>
  )
}

export default RiskFindingRemediation
