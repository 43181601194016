import React, {useState} from 'react';

import './styles.scss';

import {Table, notification} from "antd";
import {ExportJobTableConfig} from "./ExportJobTable.config";
import {TOAST_DURATION} from '../../../constants';
import { ExportManager } from '../../../global/managers/ExportManager';
import UploadSecurityQuestionsImport from './UploadSecurityQuestionsImport';
import UploadQuestionnaireBackfillImport from './UploadQuestionnaireBackfillImport';

function ExportJobTable() {
    const [showImportSecurityQuestionsUpload, setShowImportSecurityQuestionsUpload] = useState(false);
    const [showQuestionnaireBackfillUpload, setShowQuestionnaireBackfillUpload] = useState(false);
    const [enableButtons, setEnableButtons] = useState(true);

    async function executeExport(callback:any, params:any, message?:string) {
        setEnableButtons(false);
        notification.success({
            message: 'Job Started',
            description:
                <span>{message || 'Job may take a while, please stay on this page until you receive the download.'}</span>,
            duration: TOAST_DURATION,
        });

        await callback(params);
        setEnableButtons(true);
    }

    const exportAssessmentQuestionnaireStatus = () => executeExport(ExportManager.exportAssessmentQuestionnaireStatus, {});

    const exportSecurityControlQuestions = () => executeExport(ExportManager.exportSecurityControlQuestions, {});

    const importSecurityControlQuestions = (file: File) => executeExport(ExportManager.importSecurityControlQuestions, file);

    const exportClientsVendors = () => executeExport(ExportManager.exportClientsVendors, {});

    //const assessmentReminders = () => executeExport(ExportManager.assessmentReminders, {}, 'Job started. You can monitor progress in the application logs.');

    //const remediationReminders = () => executeExport(ExportManager.remediationReminders, {}, 'Job started. You can monitor progress in the application logs.');

    async function backfillLiveSyncQuestionnaires(file: File) {
        setShowQuestionnaireBackfillUpload(false);
        await executeExport(ExportManager.backfillLiveSyncQuestionnaires, file,
             'Job may take a while, please monitor the livesync logs to ensure messages are being processed. If possible please leave this page open.');
    }

    const exportVendorUsers = () => executeExport(ExportManager.exportVendorUsers, {});

    const jobs = [
        {
            id: 1,
            exportJob: 'Assessment and Questionnaire Status Export',
            description: 'Exports current statuses of all vendor assessments and questionnaires',
            jobControls: [
                {name: 'Export Job', callback: exportAssessmentQuestionnaireStatus}
            ]
        },
        {
            id: 2,
            exportJob: 'Security Control Questions Update',
            description: 'Export all current controls in the portal with the ability to import updated versions',
            jobControls: [
                {name: 'Export Questions', callback: exportSecurityControlQuestions},
                {name: 'Import Questions', callback: () => setShowImportSecurityQuestionsUpload(true)}
            ]
        },
        {
            id: 3,
            exportJob: 'Backfill Questionnaires to Dyanamics',
            description: 'Backfill questionnaires created before the Dynamics Live Sync',
            jobControls: [
                {name: 'Import Questionnaire Ids', callback: () => setShowQuestionnaireBackfillUpload(true)}
            ]
        },
        {
            id: 4,
            exportJob: 'Client and Vendor Export',
            description: 'Exports all clients and vendors in the system',
            jobControls: [
                {name: 'Export Clients and Vendors', callback: exportClientsVendors}
            ]
        },
        {
            id: 5,
            exportJob: 'Vendor Users Export',
            description: 'Exports all vendors and their users',
            jobControls: [
                {name: 'Export Vendor Users', callback: exportVendorUsers}
            ]
        },
        // {
        //     id: 6,
        //     exportJob: 'Send Assessment Reminder Emails',
        //     description: 'This will trigger the weekly job that sends out emails to all vendors for assessments in the In Progress status',
        //     jobControls: [
        //         {name: 'Assessment Reminders', callback: assessmentReminders}
        //     ]
        // },
        // {
        //     id: 7,
        //     exportJob: 'Send Remediation Reminder Emails',
        //     description: 'This will trigger the weekly job that sends out emails to all vendors for remediations that are open or due soon',
        //     jobControls: [
        //         {name: 'Remediation Reminders', callback: remediationReminders}
        //     ]
        // }
    ]

    return (
        <div>
            <div className='question-table-header'>
                <div className='title'>
                    Jobs
                </div>
            </div>
            <div className='job-table'>
                <Table className='job-table-content' rowKey='id' dataSource={jobs} columns={ExportJobTableConfig(enableButtons)}/>
            </div>
            <UploadSecurityQuestionsImport showUpload={showImportSecurityQuestionsUpload} onSubmit={importSecurityControlQuestions} onCancel={() => setShowImportSecurityQuestionsUpload(false)}/>
            <UploadQuestionnaireBackfillImport showUpload={showQuestionnaireBackfillUpload} onSubmit={backfillLiveSyncQuestionnaires} onCancel={() => setShowQuestionnaireBackfillUpload(false)}/>
        </div>
    )
}

export default ExportJobTable;