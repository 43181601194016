import { IClient } from "../Client/ClientTypes"
import { IVendor } from "../Vendor/VendorTypes"
import { IQuestionnaire } from "../Questionnaire/QuestionnaireTypes"
import { IProduct } from "../Product/ProductTypes"
import { IImplementation } from "../Implementation/ImplementationTypes"

export interface IAssessment {
  id: number
  title: string
  vendor: IVendor
  client: IClient
  status: EAssessmentStatus
  questionnaires: IQuestionnaire[]
  due_date: string
  completion_date: string
  product?: IProduct
  implementation?: IImplementation
  point_of_contact: string
  point_of_contact_name: string
  is_corl_cleared?: boolean
}

export enum EAssessmentStatus {
  InProgress = "in_progress",
  Submitted = "submitted",
  GapValidationInProgress = "gap_validation_in_progress",
  GapValidationSubmitted = "gap_validation_submitted",
  PendingClientReview = "pending_client_review",
  RemediationInProgress = "remediation_in_progress",
  RemediationSubmitted = "remediation_submitted",
  Canceled = "canceled",
  OnHold = "on_hold",
}
