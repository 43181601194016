import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import Questionnaire from "./Questionnaire";
import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes";
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";

// This Component is used when we want to make a direct request for the Questionnaire, rather than piping it in from the
// higher level component. Normally used when we are grabbing data from a react-router param

interface Props extends RouteComponentProps<Record<"assessmentId" | "questionnaireId", string>> {


}

function QuestionnaireRequest(props: Props) {
    const {match} = props;
    const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>();

    async function getQuestionnaire() {
        let questionnaire = await QuestionnaireAPI.getQuestionnaire(
            parseInt(match.params.questionnaireId)
        )
        if (questionnaire) setQuestionnaire(questionnaire);
    }

    useEffect(() => {
        getQuestionnaire();
        // eslint-disable-next-line
    }, [match.params.questionnaireId, match.params.assessmentId])

    if (!questionnaire) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading questionnaire...'/>
            </div>
        )
    }
    return <Questionnaire questionnaire={questionnaire}/>;
}

export default QuestionnaireRequest;