import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, notification, Tooltip} from "antd";
import cx from 'classnames';
import {TOAST_DURATION} from '../../../constants';

import './styles.scss';

import {AssessBuilderFormTypes} from "./Types";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes"
import VendorSelect from "../VendorSelect/VendorSelect";
import ClientSelect from "../ClientSelect/ClientSelect";
import Button from "../../common/Button/Button";

import AssessmentAPI from "../../../api/Assessment/AssessmentAPI";
import {EAssessmentStatus} from "../../../api/Assessment/AssessmentTypes";
import ProductSelect from "../ProductSelect/ProductSelect";
import {IProduct} from "../../../api/Product/ProductTypes";
import ProductAPI from "../../../api/Product/ProductAPI";
import ImplementationSelect from "../ImplementationSelect/ImplementationSelect";
import {IImplementation} from "../../../api/Implementation/ImplementationTypes";
import ImplementationAPI from "../../../api/Implementation/ImplementationAPI";
import StatusSelect from "../StatusSelect/StatusSelect";
import {ArrowRightOutlined, QuestionCircleOutlined} from "@ant-design/icons";


interface Props {
    onSubmit?: () => void,
    applyDrawerStyles?: boolean,
    assessment?: IAssessment
}

function AssessmentForm(props: Props) {
    const {onSubmit, applyDrawerStyles} = props;
    const [form] = Form.useForm();
    const [products, setProducts] = useState<IProduct[]>([]);
    const [implementations, setImplementations] = useState<IImplementation[]>([]);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [pocNameRequired, setPocNameRequired] = useState(false);


    async function getProducts(vendorId: number) {
        let products = await ProductAPI.getProductsByVendorId(vendorId);
        if (products) {
            setProducts(products);
        }
    }

    async function getImplementations() {
        let implementations = await ImplementationAPI.getImplementations();
        if (implementations) {
            setImplementations(implementations);
        }
    }

    useEffect(() => {
        getImplementations();

        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const formProps = {
        requiredMark: 'optional',
        colon: false,
        className: cx('assessment-form', {'as-drawer': applyDrawerStyles})
    };

    async function onSubmitClick() {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields) {
                let values = form.getFieldsValue();
                let assessment = await AssessmentAPI.createAssessment(values);
                if (assessment) {
                    const {vendor, client, title} = assessment;
                    notification.success({
                        message: 'Success!',
                        description: <span>Your assessment for {vendor?.name} on behalf of {client.name}, <b>{title}</b>, has been successfully created. {vendor?.name} may now start.</span>,
                        duration: TOAST_DURATION,

                    })
                    form.resetFields();
                    if (onSubmit) onSubmit();
                } else {
                    notification.error({
                        message: 'Error creating assessment!',
                        description: <span>Your assessment <b>{values.title}</b> could not be created.</span>,
                        duration: TOAST_DURATION,
                    })
                }
            }
        } catch (e) {
        }
        setSubmittingForm(false);
    }

    let initialValues = {[AssessBuilderFormTypes.Status]: EAssessmentStatus.InProgress}

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit'
                            icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                        Create Assessment
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit'
                        icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                    Create Assessment
                </Button>
            )
        }
    }

    function onValuesChange(v) {
        if (v[AssessBuilderFormTypes.Vendor]) {
            getProducts(v[AssessBuilderFormTypes.Vendor]);
            form.resetFields([AssessBuilderFormTypes.Product])
        }
    }

    function onPocValueChange(e) {
        if(form.getFieldValue(AssessBuilderFormTypes.PointOfContact)) setPocNameRequired(true)
        else setPocNameRequired(false)
    }

    return (
        <div>
            <Form {...formProps} layout='vertical' form={form} initialValues={initialValues}
                  onValuesChange={onValuesChange} className='assessment-form-wrapper'>
                <Form.Item label='Assessment Name' name={AssessBuilderFormTypes.Title}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Status' name={AssessBuilderFormTypes.Status}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <StatusSelect/>
                </Form.Item>
                <Form.Item label='Client' name={AssessBuilderFormTypes.Client} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <ClientSelect clients={[]}/>
                </Form.Item>
                <Form.Item label='Vendor' name={AssessBuilderFormTypes.Vendor} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <VendorSelect vendors={[]}/>
                </Form.Item>
                <Form.Item label='Product' name={AssessBuilderFormTypes.Product} validateTrigger='onSubmit' help={
                    !form.getFieldValue(AssessBuilderFormTypes.Vendor) ?
                        <div style={{marginBottom: 12}}>You must choose a Vendor before choosing a Product.</div>
                        :
                        undefined
                }>
                    <ProductSelect products={products} disabled={!form.getFieldValue(AssessBuilderFormTypes.Vendor)}
                                   vendorId={form.getFieldValue(AssessBuilderFormTypes.Vendor)}/>
                </Form.Item>
                <Form.Item label='Implementation' name={AssessBuilderFormTypes.Implementation}
                           validateTrigger='onSubmit'>
                    <ImplementationSelect implementations={implementations}/>
                </Form.Item>
                <Form.Item label='Due Date' name={AssessBuilderFormTypes.DueDate} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <DatePicker style={{width: '100%'}} size='large' format='MMM DD, YYYY'/>
                </Form.Item>
                <Form.Item label={<span>Point of Contact Email&nbsp;<Tooltip title="This should be a valid email. Only one email should be listed here."><QuestionCircleOutlined/></Tooltip></span>} 
                            name={AssessBuilderFormTypes.PointOfContact}
                           onChange={onPocValueChange}
                           rules={[{required: false, type: 'email', message: 'The input is not a valid email'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label={<span>Point of Contact Name&nbsp;
                    <Tooltip title="This should be the first and last name of the Point of Contact. Any email communications to this PoC will use this name."><QuestionCircleOutlined/></Tooltip></span>} 
                            name={AssessBuilderFormTypes.PointOfContactName}
                            validateTrigger='onSubmit'
                            rules={[{required: pocNameRequired, message: 'If specifying Point of Contact Email, you must also provide a name.'}]}>
                    <Input size='large'/>
                </Form.Item>
            </Form>
            {renderButton()}
        </div>
    )
}

export default AssessmentForm;