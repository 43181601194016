import axios from "axios";

export default class RemediationFileApi {
    static async deleteRemediationFile(remediationFileId:number){
        try{
            await axios.delete(`/remediation-files/${remediationFileId}`);
            return true;
        } catch (e:any){
            console.log('Could not delete attachment', e.message);
            return undefined;
        }
    }
}