import React, { useEffect, useState } from 'react';
import './styles.scss';
import { INotification } from '../../../api/Notifications/NotificationTypes';
import NotificationsAPI from '../../../api/Notifications/NotificationsAPI';
import NotificationList from '../NotificationList/NotificationList';
import DataManipulation from "../../../global/utils/DataManipulation";

function NotificationsOverview() {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    async function getNotifications() {
        let curNotifications = await NotificationsAPI.getNotifications();
        if (curNotifications) {
            let sorted = curNotifications.sort(
                (a, b) => DataManipulation.compare(
                    new Date(b.created_at).getTime(), new Date(a.created_at).getTime()
                )).filter(notification => notification.read)
            setNotifications(sorted);
        }
    }

    useEffect(() => {
        getNotifications();
    }, [])

    return (
        <div className='notifications-overview'>
            <div className='notifications-overview-header'>
                <div className='title'>
                    Notifications
                </div>
            </div>
            <div className='notifications-content'>
                <NotificationList notifications={notifications}/>
            </div>
        </div>
    )
};

export default NotificationsOverview;