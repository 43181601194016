import axios from "axios";
import JSZip from 'jszip';

import { IQuestionnaire, EQuestionnaireStatus } from "../../api/Questionnaire/QuestionnaireTypes";
import { IQuestion } from "../../api/Question/QuestionTypes";
import TemplateAPI from "../../api/Template/TemplateAPI";
import { IResponse } from "../../api/Response/ResponseTypes";
import { IVendorFile, IResponseFile } from "../../api/ResponseFile/ResponseFileTypes";
import {FileUtils} from "../utils/FileUtils";
import { IAssessment } from '../../api/Assessment/AssessmentTypes';
import FileApi from "../../api/File/FileAPI";
import ResponseAPI from "../../api/Response/ResponseAPI";
import ExportJobAPI from "../../api/ExportJob/ExportJobAPI";
import {IAssessmentQuestionnaireStatusExport, IClientVendorExport, ISecurityControlQuestionExport, ISecurityControlQuestionImport, IVendorUserExport} from "../../api/ExportJob/ExportJobTypes";

export class ExportManager {

    static async getQuestionnaireCsvData(questionnaire:IQuestionnaire, assessment?:IAssessment) {
        // To export ALL questions and not just responses, we need to grab all controls from the template of the questionnaire
        let questionnaireAssessment = assessment ? assessment : questionnaire.assessment;
        let allQuestions = await TemplateAPI.getQuestionnaireTemplateQuestions(questionnaireAssessment.id, questionnaire.id);
        const controls = allQuestions ? allQuestions['control_questions'] : [];
        let responses = questionnaire.responses ? questionnaire.responses : [];
        let unansweredQuestions: IQuestion[] = [];
        controls?.forEach((control: IQuestion) => {
            if(!responses.find(r => r.control_question.id === control.id)) unansweredQuestions.push(control);
        });

        // Create unanswered responses to export
        unansweredQuestions.forEach((control: IQuestion) => {
            let response:any = {
                response_choice: '',
                response_text: '',
                response_files: [],
                control_question: control,
            };
            responses.push(response);
        });

        // Sort by ID before exporting
        let sortedResponses = responses.sort((a,b) => a.control_question.id > b.control_question.id ? 1 : -1);

        let data: string[][] = [
            ['Assessment', 'Questionnaire', 'Control Identifier', 'Control Question', 'Response', 'Comment', 'Evidence']
        ]

        sortedResponses.forEach((response: IResponse) => {
            let newRow = [
                questionnaireAssessment.title,
                questionnaire.title,
                response.control_question.control_number,
                response.control_question.text,
                response.response_choice,
                response.response_text,
                response.response_files && ExportManager.parseResponseFileNames(response.response_files)
            ]
            data.push(newRow);
        });

        return FileUtils.createCsvFile(data);
    }

    static async getQuestionnaireResponseFiles(questionnaire:IQuestionnaire) {
        let responseFiles: any[] = [];
        if(!questionnaire.responses) {
            let responses = await ResponseAPI.getResponsesForQuestionnaire(questionnaire.id);
            if(responses) questionnaire.responses = responses;
            else return [];
        };

        for (let i = 0; i < questionnaire.responses.length; i++) {
            let response = questionnaire.responses[i];
            if (response.response_files) {
                for (let j = 0; j < response.response_files.length; j++) {
                    let resp_file = response.response_files[j];
                    let file: IVendorFile = resp_file.vendor_file;
                    let arrayBuffer = await ExportManager.fetchResponseFile(file.file_id, file.vendor);
                    if (arrayBuffer) responseFiles.push({fileName: file.file_name, fileContent: arrayBuffer});
                }
            }
        }
        return responseFiles;
    }

    static async fetchResponseFile(fileId: string, vendorId: number) {
        let fileURL = await FileApi.GetFileUrl(fileId, vendorId);
        // We need to set the headers to undefined in this instance to remove our own Auth key from sending
        if (fileURL) {
            if (FileUtils.isText(fileId)) {
                let instance = axios.create();
                delete instance.defaults.headers.common['Authorization']
                try {
                    let res = await instance.get(fileURL);
                    return res.data;
                } catch (e) {
                    return undefined
                }
            }  else {
                return await FileUtils.urlToPromise(fileURL);
            }
        }
    }

    static parseResponseFileNames(responseFiles: IResponseFile[]) {
        let files: string[] = []
        responseFiles.forEach(file => {
            files.push(file.vendor_file.file_name);
        })
        return files.join(', ');
    }

    static async exportQuestionnaire(questionnaire:IQuestionnaire) {
        let csvFile = await ExportManager.getQuestionnaireCsvData(questionnaire);
        let csvBlob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        
        const zip = new JSZip();
        zip.file(`${questionnaire.title}.csv`, csvBlob);

        let questionnaireFileObjects = await ExportManager.getQuestionnaireResponseFiles(questionnaire);
        questionnaireFileObjects.forEach(fileObject => {
            const {fileName, fileContent} = fileObject;
            zip.file(fileName, fileContent);
        });

        FileUtils.downloadZipFile(questionnaire.title, zip);
    }

    static async exportAssessment(assessment: IAssessment) {
        if(!assessment.questionnaires) return;

        const zip = new JSZip();

        // Create a folder for each questionnaire not in-progress
        let questionnairesToExport = assessment.questionnaires.filter(questionnaire => questionnaire.status !== EQuestionnaireStatus.InProgress);
        for(let i = 0; i < questionnairesToExport.length; i++) {
            let questionnaire = questionnairesToExport[i];
            const folderName = questionnaire.title;
            let questionnaireData = await ExportManager.getQuestionnaireCsvData(questionnaire, assessment);
            let csvBlob = new Blob([questionnaireData], {type: 'text/csv;charset=utf-8;'});
            zip.folder(folderName)?.file(`${questionnaire.title}.csv`, csvBlob);

            let questionnaireFileObjects = await ExportManager.getQuestionnaireResponseFiles(questionnaire);
            questionnaireFileObjects.forEach(fileObject => {
                const {fileName, fileContent} = fileObject;
                zip.folder(folderName)?.file(fileName, fileContent);
            });
        }

        FileUtils.downloadZipFile(assessment.title, zip);
    }

    static async exportAssessmentQuestionnaireStatus() {
        let data = await ExportJobAPI.assessmentQuestionnaireStatusExport();
        let csvData: string[][] = 
        [
            ['Date (DD-MM-YYYY)',
             'Client Id', 'Client',
             'Vendor Id', 'Vendor',
             'Assessment Id', 'Assessment Name', 'Assessment Status',
             'Questionnaire Id', 'Questionnaire Name', 'Questionnaire Status', 'Questionnaire Sent', 'Questionnaire Started', 'Questionnaire Submitted'
            ]
        ];
        
        data.forEach((row: IAssessmentQuestionnaireStatusExport) => {
            let newRow = [
                row.date,
                row.clientId,
                row.clientName,
                row.vendorId,
                row.vendorName,
                row.assessmentId,
                row.assessmentName,
                row.assessmentStatus,
                row.questionnaireId,
                row.questionnaireName,
                row.questionnaireStatus,
                row.questionnaireSent,
                row.questionnaireStarted,
                row.questionnaireSubmitted
            ]
            csvData.push(newRow);
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}-${(currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
        let fileName = `${formattedDate}_Assessment-Questionnaire-Status-Export`;
        
        FileUtils.downloadCsvFile(fileName, csvData);
    }
    
    static async exportSecurityControlQuestions() {
        let data = await ExportJobAPI.securityControlQuestionsExport();
        let csvData: (string|number)[][] = 
        [
            [
                'Id',
                'Control Number',
                'Control Name',
                'Control Group',
                'Dynamics Control Guid',
                'Text',
                'IRN Id',
                'Dynamics IRN Guid',
                'IRN',
                'New IRN',
                'New Dynamics IRN Guid'
            ]
        ];
        
        data.forEach((row: ISecurityControlQuestionExport) => {
            let newRow = [
                row.id,
                row.controlNumber,
                row.controlName,
                row.controlGroup,
                row.controlGuid,
                row.controlText,
                row.irnId,
                row.irnGuid,
                row.irnDescription,
                row.newIrnDescription,
                row.newIrnGuid
            ]
            csvData.push(newRow);
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}-${(currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
        let fileName = `${formattedDate}_Security-Control-Question-Export`;
        
        FileUtils.downloadCsvFile(fileName, csvData);
    }

    static async importSecurityControlQuestions(importFile: File) {
        let data = await ExportJobAPI.securityControlQuestionsImport(importFile);
        let csvData: (string|number|boolean)[][] = 
        [
            [
                'Id',
                'Control Number',
                'Control Name',
                'Control Group',
                'Dynamics Control Guid',
                'Text',
                'IRN Id',
                'Old Dynamics IRN Guid',
                'Old IRN',
                'New Current IRN',
                'New Dynamics IRN Guid',
                'Success',
                'Error Message'
            ]
        ];
        
        data.forEach((row: ISecurityControlQuestionImport) => {
            let newRow = [
                row.id,
                row.controlNumber,
                row.controlName,
                row.controlGroup,
                row.controlGuid,
                row.controlText,
                row.irnId,
                row.irnGuid,
                row.irnDescription,
                row.newIrnDescription,
                row.newIrnGuid,
                row.success,
                row.errorMessage
            ]
            csvData.push(newRow);
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}-${(currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
        let fileName = `${formattedDate}_Security-Control-Question-Import-Results`;
        
        FileUtils.downloadCsvFile(fileName, csvData);
    }

    static async backfillLiveSyncQuestionnaires(importFile: File) {
        await ExportJobAPI.backfillLiveSyncQuestionnaires(importFile);
    }

    static async exportClientsVendors() {
        let data = await ExportJobAPI.clientVendorExport();
        let csvData: string[][] = 
        [
            ['Entity Id',
             'Entity Type',
             'Entity Name'
            ]
        ];
        
        data.forEach((row: IClientVendorExport) => {
            let newRow = [
                row.entityId,
                row.entityType,
                row.entityName
            ]
            csvData.push(newRow);
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}-${(currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
        let fileName = `${formattedDate}_Client-Vendor-Export`;
        
        FileUtils.downloadCsvFile(fileName, csvData);
    }

    static async assessmentReminders() {
        await ExportJobAPI.assessmentReminders();
    }

    static async remediationReminders() {
        await ExportJobAPI.remediationReminders();
    }
    
    static async exportVendorUsers() {
        let data = await ExportJobAPI.vendorUsersExport();
        let csvData: string[][] = 
        [
            [
                'Vendor Id',
                'Vendor Name',
                'Vendor Point of Contact',
                'User Id',
                'Email',
                'Role'
            ]
        ];
        
        data.forEach((row: IVendorUserExport) => {
            let newRow = [
                row.vendorId,
                row.vendorName,
                row.pointOfContact,
                row.userId,
                row.email,
                row.role
            ]
            csvData.push(newRow);
        });

        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate()}-${(currentDate.getMonth() + 1)}-${currentDate.getFullYear()}`;
        let fileName = `${formattedDate}_Vendor-Users-Export`;
        
        FileUtils.downloadCsvFile(fileName, csvData);
    }
}