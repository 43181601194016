import React, {useState} from 'react';

import './styles.scss';

import {Modal, Alert} from "antd";
import {InboxOutlined, CloudUploadOutlined, CloseOutlined} from '@ant-design/icons';
import {UploadFile, UploadChangeParam} from "antd/es/upload/interface";
import WDragger from "../../common/WDragger/WDragger"
import Button from "../../common/Button/Button"
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import AppStore from "../../../stores/App/AppStore";

interface Props {
    showUpload: boolean,
    onSubmit?: (file: File) => void,
    onCancel?: () => void,
}

function UploadSecurityQuestionsImport(props: Props) {
    const {showUpload, onSubmit, onCancel} = props;
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [isCORLAdmin] = useState(StrapiPermissionsUtil.isCorlAdmin(AppStore.user));
    const [validationFailed, setValidationFailed] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    function getFiles() {
        if (!files || files.length === 0) return null;
        return (
            <div className='files'>
                {files.map((file: UploadFile, i: number) => {
                    return (
                        <div key={i} className='file'>
                            <div className='file-name'>{file.name}</div>
                            <CloseOutlined onClick={() => setFiles(files.filter((file, index) => i !== index))}/>
                        </div>
                    )
                })}
            </div>
        )
    }

    const enableImportSecurityControlQuestionsButton = () => {
        return files.length !== 0;
    }

    const onUploadChange = async (info: UploadChangeParam) => {
        const {file,} = info;

        let fileValid = await validatePreUpload(file);
        if(fileValid) {
            setFiles([file]);
            setValidationFailed(false);
        };
    }

    async function validatePreUpload(file:any) {
        let isValid = true;
        const failedValidation = (message: string) => {
            setValidationFailed(true);
            setValidationMessage(message);
            isValid = false;
        };

        let acceptedFileTypes = [
            'text/csv',
        ]

        if(!acceptedFileTypes.includes(file.type)) failedValidation(`Only the following file types are allowed: ${acceptedFileTypes.join(', ')}`);
        if(!isValid) return isValid;

        return isValid;
    }

    const onUploadFiles = async (e: any) => {
        e.stopPropagation();
        // must be a vendor user or CORL Admin to upload files
        if (!isCORLAdmin || validationFailed) return;

        if(onSubmit) onSubmit(files.map(file => file.originFileObj as File)[0]);
        setFiles([]);
    };

    return (
        <Modal open={showUpload}
                   title='Upload Security Control Questions Import File'
                   bodyStyle={{padding: 24}}
                   className='upload-modal'
                   centered
                   onOk={onUploadFiles}
                   onCancel={onCancel}
                   destroyOnClose
                   footer={
                       <div className='upload-modal-footer'>
                           <Button key="back" color='clear' onClick={onCancel} style={{marginRight: 12}}
                                   icon={<CloseOutlined/>}>
                               Cancel
                           </Button>
                           <Button key="submit" enabled={enableImportSecurityControlQuestionsButton()} icon={<CloudUploadOutlined/>}
                                   onClick={onUploadFiles}>
                               Upload File
                           </Button>
                       </div>
                   }>
                <Alert message='Upload notes' description={
                    <div>
                        <div>This upload will only accept an import file with the same headers and format as the corresponding Security Control Questions export file, NOT the import results file produced from this upload.</div>
                        <div style={{marginTop:12}}>Be VERY sure on your changes BEFORE importing this file, as this has the ability to impact ALL security control questions currently in the system. 
                        Furthermore, this upload will only adjust questions specified in the import file and only change IRN values for specified new values.
                        </div>
                        <div style={{marginTop:12}}>To check if the job is working, simply look at the control questions in Strapi to see if they have been adjusted.
                        </div>
                    </div>
                } style={{marginBottom: 12}}/>
                <WDragger multiple={false} customRequest={() => null} showUploadList={false}
                          onChange={onUploadChange}
                          className='custom-upload' fileList={files} accept={'xlsx'}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined/>
                    </p>
                    <p className="ant-upload-text">Click or drag files to this area</p>
                </WDragger>
                {getFiles()}
                {validationFailed &&
                <span className="warning-text">{validationMessage}</span>
                }
            </Modal>
    )
}

export default UploadSecurityQuestionsImport;