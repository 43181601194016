import React from 'react';

import './styles.scss';
import QuestionTable from "../../components/specific/QuestionTable/QuestionTable";

function QuestionsView(){

    return (
        <div className='questions-view'>
            <QuestionTable/>
        </div>
    )
}

export default QuestionsView;