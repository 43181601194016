import axios from "axios"
import { IRemediationFile } from "../RemediationFile/RemediationFileTypes"
import { IResponseFile } from "../ResponseFile/ResponseFileTypes"
import { ITaskFile } from "../TaskFile/TaskFileTypes"

const route = "/vendor-files"

export default class VendorFileAPI {
  static async attachVendorFileToResponseFile(
    vendorFileId: number,
    responseId: number
  ) {
    try {
      let res = await axios.post(
        `${route}/${vendorFileId}/response/${responseId}`
      )
      return res.data as IResponseFile
    } catch (e: any) {
      console.log("Could not save vendor file to response file", e.message)
      return undefined
    }
  }
  static async attachVendorFileToRemediationFile(
    vendorFileId: number,
    riskFindingId: number
  ) {
    try {
      let res = await axios.post(
        `${route}/${vendorFileId}/risk-findings/${riskFindingId}`
      )
      return res.data as IRemediationFile
    } catch (e: any) {
      console.log("Could not save vendor file to remediation file", e.message)
      return undefined
    }
  }
  static async attachVendorFileToTaskFile(
    vendorFileId: number,
    taskId: number
  ) {
    try {
      let res = await axios.post(`${route}/${vendorFileId}/tasks/${taskId}`)
      return res.data as ITaskFile
    } catch (e: any) {
      console.log("Could not save vendor file to task file", e.message)
      return undefined
    }
  }
  static async getVendorFilesByVendorId(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`${route}/vendor/${vendorId}`, { params })
      return res.data
    } catch (e: any) {
      console.log("Could not get vendor files", e.message)
      return undefined
    }
  }

  static async getVendorFileCountByVendorId(vendorId: number, params?: any) {
    try {
      let res = await axios.get(`${route}/vendor/${vendorId}/count`, { params })
      return res.data
    } catch (e: any) {
      console.log("Could not count vendor files", e.message)
      return undefined
    }
  }

  static async deleteById(vendorFileId: number) {
    try {
      await axios.delete(`${route}/${vendorFileId}`)
    } catch (e: any) {
      console.log("Could not delete vendor file", e.message)
    }
  }
}
