import React from "react"
import { Route } from "react-router-dom"

import { ReactComponent as Templates } from "../../assets/icons/brief-design.svg"
import { ReactComponent as Assessments } from "../../assets/icons/site-mapping.svg"
import { ReactComponent as Questions } from "../../assets/icons/flow-chart.svg"
import { ReactComponent as Clients } from "../../assets/icons/networking.svg"
import { ReactComponent as Vendors } from "../../assets/icons/sitemap.svg"
import { ReactComponent as RiskFindings } from "../../assets/icons/cloud-security.svg"
import { ReactComponent as Settings } from "../../assets/icons/data-manage.svg"
//import {ReactComponent as Inbox} from '../../assets/icons/inbox.svg';
import { ReactComponent as Files } from "../../assets/icons/folder-management.svg"
import { BrowserRoutes } from "../../stores/App/BrowserRouter"
import AssessmentView from "../AssessmentsView/AssessmentsView"
import VendorsContainer from "../VendorsContainer/VendorsContainer"
import TemplatesBrowserContainer from "../TemplateBrowserContainer/TemplateBrowserContainer"
import QuestionsView from "../QuestionsView/QuestionsView"
import { ROLES } from "../../constants"
import SettingsContainer from "../SettingsContainer/SettingsContainer"
import ClientsContainer from "../ClientsContainer/ClientsContainer"
import NotificationsView from "../NotificationsView/NotificationsView"
import VendorFilesContainer from "../VendorFilesContainer/VendorFilesContainer"
import OverviewContainer from "../OverviewContainer/OverviewContainer"
import UserRequestsContainer from "../UserRequestsContainer/UserRequestsContainer"
import IncidentResponsePortalRedirectContainer from "../IncidentResponsePortalRedirectContainer/IncidentResponsePortalRedirectContainer"
import RiskFindingsContainer from "../RiskFindingsContainer/RiskFindingsContainer"

export enum EAppSidebarGroups {
  General = "General",
  RiskProfiles = "Risk Profiles",
  IncidentResponse = "Incident Response (Beta)",
  Administration = "Administration",
  Hidden = "Hidden",
}

export const AppRoutesObject = () => {
  return {
    // 'Dashboard': {
    //     render: <Route key={0} exact path={BrowserRoutes.home + '/'} component={Dashboard}/>,
    //     path: BrowserRoutes.home,
    //     icon: <DashboardIcon/>,
    //     group: EAppSidebarGroups.General
    // },
    Templates: {
      render: (
        <Route
          key={1}
          path={BrowserRoutes.templates}
          component={TemplatesBrowserContainer}
        />
      ),
      path: BrowserRoutes.templates,
      icon: <Templates />,
      access: [ROLES.CORL_ADMIN, ROLES.CORL_EMPLOYEE],
      group: EAppSidebarGroups.RiskProfiles,
    },
    Assessments: {
      render: (
        <Route
          key={2}
          path={BrowserRoutes.assessments}
          component={AssessmentView}
        />
      ),
      path: BrowserRoutes.assessments,
      icon: <Assessments />,
      access: [
        ROLES.CORL_ADMIN,
        ROLES.CORL_EMPLOYEE,
        ROLES.VENDOR,
        ROLES.VENDOR_ADMIN,
        ROLES.CLIENT_ADMIN,
        ROLES.CLIENT,
      ],
      group: EAppSidebarGroups.RiskProfiles,
    },
    Questions: {
      render: (
        <Route
          key={3}
          path={BrowserRoutes.questions}
          component={QuestionsView}
        />
      ),
      path: BrowserRoutes.questions,
      icon: <Questions />,
      access: [ROLES.CORL_ADMIN, ROLES.CORL_EMPLOYEE],
      group: EAppSidebarGroups.RiskProfiles,
    },
    "User Requests": {
      render: (
        <Route
          key={12}
          path={BrowserRoutes.userRequests}
          component={UserRequestsContainer}
        />
      ),
      path: BrowserRoutes.userRequests,
      icon: <Files />,
      access: [ROLES.CORL_ADMIN],
      group: EAppSidebarGroups.Administration,
    },
    Vendors: {
      render: (
        <Route
          key={4}
          path={BrowserRoutes.vendors}
          component={VendorsContainer}
        />
      ),
      path: BrowserRoutes.vendors,
      icon: <Vendors />,
      access: [
        ROLES.CORL_ADMIN,
        ROLES.CORL_EMPLOYEE,
        ROLES.CLIENT,
        ROLES.CLIENT_ADMIN,
      ],
      group: EAppSidebarGroups.Administration,
    },
    Clients: {
      render: (
        <Route
          key={5}
          path={BrowserRoutes.clients}
          component={ClientsContainer}
        />
      ),
      path: BrowserRoutes.clients,
      icon: <Clients />,
      access: [ROLES.CORL_ADMIN, ROLES.CORL_EMPLOYEE],
      group: EAppSidebarGroups.Administration,
    },
    Settings: {
      render: (
        <Route
          key={6}
          path={BrowserRoutes.settings}
          component={SettingsContainer}
        />
      ),
      path: BrowserRoutes.settings,
      icon: <Settings />,
      access: [
        ROLES.CORL_ADMIN,
        ROLES.CORL_EMPLOYEE,
        ROLES.CLIENT_ADMIN,
        ROLES.VENDOR_ADMIN,
      ],
      group: EAppSidebarGroups.Administration,
    },
    // 'Messages': {
    //     render: <Route key={7} path={BrowserRoutes.messages} component={MessagesView}/>,
    //     path: BrowserRoutes.messages,
    //     icon: <Inbox/>,
    //     group: EAppSidebarGroups.General
    // },
    // 'Message Governance': {
    //     render: <Route key={9} path={BrowserRoutes.messageGovernance} component={MessageGovernanceView}/>,
    //     path: BrowserRoutes.messageGovernance,
    //     icon: <Inbox/>,
    //     access: [ROLES.CORL_ADMIN, ROLES.CORL_EMPLOYEE],
    //     group: EAppSidebarGroups.General
    // },
    Notifications: {
      render: (
        <Route
          key={8}
          path={BrowserRoutes.notifications}
          component={NotificationsView}
        />
      ),
      path: BrowserRoutes.notifications,
      group: EAppSidebarGroups.Hidden,
    },
    Files: {
      render: (
        <Route
          key={10}
          path={BrowserRoutes.files}
          component={VendorFilesContainer}
        />
      ),
      path: BrowserRoutes.files,
      icon: <Files />,
      access: [ROLES.VENDOR, ROLES.VENDOR_ADMIN],
      group: EAppSidebarGroups.General,
    },
    Overview: {
      render: (
        <Route
          key={11}
          path={BrowserRoutes.overview}
          component={OverviewContainer}
        />
      ),
      path: BrowserRoutes.overview,
      icon: <Clients />,
      access: [ROLES.CLIENT_ADMIN, ROLES.CLIENT],
      group: EAppSidebarGroups.RiskProfiles,
      hide: true,
    },
    // "Incident Response Profile": {
    //   render: (
    //     <Route
    //       key={13}
    //       path={BrowserRoutes.incidentResponsePortalRedirect}
    //       component={IncidentResponsePortalRedirectContainer}
    //     />
    //   ),
    //   path: BrowserRoutes.incidentResponsePortalRedirect,
    //   icon: <Clients />,
    //   access: [
    //     ROLES.CORL_ADMIN,
    //     ROLES.CORL_EMPLOYEE,
    //     ROLES.VENDOR,
    //     ROLES.VENDOR_ADMIN,
    //   ],
    //   group: EAppSidebarGroups.IncidentResponse,
    // },
    "Risk Findings": {
      render: (
        <Route
          key={14}
          path={BrowserRoutes.riskFindings}
          component={RiskFindingsContainer}
        />
      ),
      path: BrowserRoutes.riskFindings,
      icon: <RiskFindings />,
      access: [
        ROLES.CORL_ADMIN,
        ROLES.CORL_EMPLOYEE,
        ROLES.VENDOR,
        ROLES.VENDOR_ADMIN,
      ],
      group: EAppSidebarGroups.RiskProfiles,
    },
  }
}
