export interface IClient {
    id: number,
    name: string,
    point_of_contact: string,
    outreach_message:string,
    outreach_subject:string,
    ivpq_outreach_message:string,
    ivpq_outreach_subject:string,
    cc_outreach_message:string,
    cc_outreach_subject:string,
    remediation_outreach_message:string,
    remediation_outreach_subject:string,
    data_reuse_type: DataReuseType,
    outreach_enabled?: boolean,
    email_settings: IEmailSettings
}

export interface IEmailSettings {
    ccList: ICcListSettings[]
}

export interface ICcListSettings {
    emailType: string,
    emails: string[]
}

export enum DataReuseType {
    Standard = 'standard',
    Approval = 'approval',
    None = 'none',
}

export enum EOutreachType {
    VSQ = 'VSQ',
    IVPQ = 'IVPQ',
    CorlCleared = 'CorlCleared',
    Remediation = 'Remediation',
}