import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import * as serviceWorker from "./serviceWorker"
import App from "./containers/App/App"
import { Router } from "react-router-dom"
import BrowserRouter from "./stores/App/BrowserRouter"
import { Auth0Provider } from "@auth0/auth0-react"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"

function Auth0ProviderWithNavigation(props) {
  const history = useHistory();

  const onRedirectCallback = (appState) => {
    if (!appState?.returnTo) { return; }

    try{
      let redirectPath = new URL(appState?.returnTo).pathname;
      history.push(redirectPath);
    } catch { 
      // in case url is invalid, just ignore it and do nothing
    }
  };

  return (
    <Auth0Provider
      {...props}   
      onRedirectCallback={onRedirectCallback}
    >
      {props.children}
    </Auth0Provider>
  );
}

ReactDOM.render(
  <Router history={BrowserRouter.history}>
    <Auth0ProviderWithNavigation
      domain={
        process.env.REACT_APP_ENV === "production"
          ? "corlexchange.us.auth0.com"
          : "corlportaluat.us.auth0.com"
      }
      clientId={
        process.env.REACT_APP_ENV === "production"
          ? "ygQsY1iG1JNxI3WnKUE1cJAGXtvehTRr"
          : "x4FKWRCwVxwZkHbDud5pQv1rUvg5Rae1"
      }
      redirectUri={
        process.env.REACT_APP_ENV === "production"
          ? "https://exchange.corltech.com"
          : process.env.REACT_APP_ENV === "development"
          ? "https://dev-corl-exchange.corltech.com"
          : "http://localhost:3000"
      }
      audience={"https://exchange.corltech.com"}
      useRefreshTokens
    >
      <Helmet>
        <script
          id="ze-snippet"
          src={
            process.env.REACT_APP_ENV === "production"
              ? "https://static.zdassets.com/ekr/snippet.js?key=3a0c45cb-3b9a-42f8-a375-dc3bbaf88073"
              : "https://static.zdassets.com/ekr/snippet.js?key=80173648-45b8-4a91-a5c2-46829185690f"
          }
        >
          {" "}
        </script>
      </Helmet>
        <App />
    </Auth0ProviderWithNavigation>
  </Router>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
