import React, { useState, useEffect } from "react"
import { DatePicker, Divider, Form, notification, Radio, Select } from "antd"

import "./styles.scss"

import { ArrowRightOutlined, InfoCircleOutlined } from "@ant-design/icons"
import {
  ERiskFindingStatus,
  ERiskFindingUpdateEvents,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import { TOAST_DURATION } from "../../../../constants"
import Button from "../../../common/Button/Button"
import RiskFindingApi from "../../../../api/RiskFinding/RiskFindingApi"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"
import QuestionEvidence from "../../Question/QuestionEvidence"
import { EFileUploadTypes } from "../../../../api/File/FileTypes"
import RemediationFileApi from "../../../../api/RemediationFile/RemediationFileApi"
import { IVendorFile } from "../../../../api/VendorFile/VendorFileTypes"
import VendorFileAPI from "../../../../api/VendorFile/VendorFileApi"
import {
  IRemediationPathway,
  ISecCertType,
} from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import Modal from "antd/lib/modal/Modal"
import TasksContainer from "./Tasks/TasksContainer"
import { EAssessmentStatus } from "../../../../api/Assessment/AssessmentTypes"

const { Option } = Select

interface Props {
  riskFinding: IRiskFinding
  remediationPathways: IRemediationPathway[]
  onSubmit?: (values: any) => void
  onChange?: (values: any) => void
  disabled?: boolean
  gapValidation?: boolean
}

export enum RiskFindingFormTypes {
  VendorValid = "vendor_valid",
  VendorRemediate = "vendor_remediate",
  VendorAction = "vendor_action",
  VendorCommitmentDate = "vendor_commitment_date",
  VendorRemediateReason = "vendor_remediate_reason",
  VendorValidReason = "vendor_valid_reason",
  RemediationPathway = "remediation_pathway",
  SecCertType = "sec_cert_type",
}

function RiskFindingDetailsForm({
  riskFinding,
  remediationPathways,
  onSubmit,
  onChange,
  disabled,
  gapValidation,
}: Props) {
  const isCC = riskFinding.assessment?.is_corl_cleared
  // const isDisabled =
  //   disabled ||
  //   (isCC &&
  //     riskFinding.assessment.status === EAssessmentStatus.RemediationInProgress)
  const isDisabled = disabled

  remediationPathways = remediationPathways
    ?.filter((r) => !r.disabled)
    ?.sort((a, b) => a.id - b.id)

  const initialValues = {
    vendor_valid: riskFinding.vendor_valid,
    vendor_remediate: riskFinding.vendor_remediate,
    vendor_action: riskFinding.vendor_action,
    vendor_commitment_date: riskFinding.vendor_commitment_date
      ? moment(riskFinding.vendor_commitment_date)
      : isDisabled
      ? null
      : riskFinding.corl_recommended_due_date
      ? moment(riskFinding.corl_recommended_due_date)
      : null,
    vendor_remediate_reason: riskFinding.vendor_remediate_reason,
    vendor_valid_reason: riskFinding.vendor_valid_reason,
    remediation_pathway: riskFinding.remediation_pathway?.id,
    sec_cert_type: riskFinding.sec_cert_type?.id,
  }

  const [form] = Form.useForm()

  useEffect(() => {
    return function cleanup() {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  // eslint-disable-next-line
  const [submittingForm, setSubmittingForm] = useState(false)
  const [validFieldRequired, setValidFieldRequired] = useState(false)
  const [remediateFieldRequired, setRemediateFieldRequired] = useState(false)
  const [showValidReasonField, setShowValidReasonField] = useState(
    riskFinding.vendor_valid === null ? false : !riskFinding.vendor_valid
  )
  const [showAgreeToRemediateReasonField, setShowAgreeToRemediateReasonField] =
    useState(
      riskFinding.vendor_remediate === null
        ? false
        : !riskFinding.vendor_remediate && riskFinding.vendor_valid
    )
  const [showFormDetails, setShowFormDetails] = useState(
    riskFinding.vendor_remediate !== null &&
      riskFinding.vendor_remediate &&
      riskFinding.vendor_valid !== null &&
      riskFinding.vendor_valid
  )
  const [enableActionField, setEnableActionField] = useState(
    enableVendorActionField()
  )
  const [showRemediationPathwayHelpModal, setShowRemediationPathwayHelpModal] =
    useState(false)
  const [showVendorCommitmentDateField, setShowVendorCommitmentDateField] =
    useState(disableRemediateField() || riskFinding.vendor_remediate)
  const [secCertOptions, setSecCertOptions] = useState<ISecCertType[]>(
    getInitialSecCertOptions()
  )

  const formProps = {
    hideRequiredMark: false,
    colon: false,
    className: "",
  }

  async function onSubmitClick() {
    if (submittingForm) return false
    let valid = await form.validateFields()
    if (!valid.errorFields) {
      setSubmittingForm(true)
      let values = form.getFieldsValue()

      // DEV-3554
      // if (gapValidation) {
      //   if (values.vendor_remediate && values.vendor_valid) {
      //     values.status = ERiskFindingStatus.VendorCommited
      //   } else values.status = ERiskFindingStatus.Submitted
      // } else {
      //   if (
      //     values.vendor_remediate &&
      //     values.vendor_action &&
      //     values.vendor_valid
      //   )
      //     values.status = ERiskFindingStatus.VendorCommited
      //   else values.status = ERiskFindingStatus.Submitted
      // }

      // DEV-4380
      values.status = ERiskFindingStatus.Submitted

      let response = await RiskFindingApi.updateRiskFinding(riskFinding.id, {
        ...values,
        event: ERiskFindingUpdateEvents.DetailsSubmitted,
      })
      if (response) {
        let message = `Your response has been saved. You must fill out all Risk Findings
        requiring remediation before you can submit your Remediation Plan.
        Once you have responded to all required risk findings, please
        select "Save Remediation Plan".`

        if (gapValidation) {
          message = `Your response has been saved. You must fill out all Risk Findings requiring remediation before you can submit your Gap Validation Plan. Once you have responded to all required risk findings, please select "Save Gap Validation Plan".`
        }
        notification.success({
          message: "Success!",
          description: <span>{message}</span>,
          duration: TOAST_DURATION,
        })
        form.resetFields()
        if (onSubmit) onSubmit(values)
      } else {
        let message =
          "Your Risk Finding Remediation submission could not be submitted. Please reach out to support for help."
        //if(StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user)) message = 'CORL users cannot submit Risk Findings, only vendor users can submit.'
        notification.error({
          message: "Error submitting Risk Finding Remediation!",
          description: <span>{message}</span>,
          duration: TOAST_DURATION,
        })
      }
    }
    setSubmittingForm(false)
  }

  function disableRemediateField() {
    if (isDisabled) return true

    let values = form.getFieldsValue()
    if (values.hasOwnProperty("vendor_valid")) {
      return !values.vendor_valid
    } else {
      return !initialValues.vendor_valid
    }
  }

  function enableVendorActionField() {
    if (isDisabled) return false

    let values = form.getFieldsValue()
    if (values.hasOwnProperty("vendor_remediate")) {
      if (values.vendor_remediate) return true
      else return false
    } else {
      if (initialValues.vendor_remediate) return true
      else return false
    }
  }

  function onValidFieldChange(values: any) {
    if (!values.target.value) {
      setValidFieldRequired(false)
      setShowValidReasonField(true)
      setShowAgreeToRemediateReasonField(false)
      setShowFormDetails(false)
      setShowVendorCommitmentDateField(false)
      form.setFieldsValue({ vendor_remediate: null })
      form.setFieldsValue({ vendor_action: null })
      form.setFieldsValue({ vendor_valid_reason: null })
      form.setFieldsValue({ vendor_remediate_reason: null })
      form.setFieldsValue({ vendor_commitment_date: null })
      form.setFieldsValue({ remediation_pathway: null })
      form.setFieldsValue({ sec_cert_type: null })
    } else {
      setValidFieldRequired(true)
      setShowValidReasonField(false)
      setShowAgreeToRemediateReasonField(false)
      setShowVendorCommitmentDateField(true)
      form.setFieldsValue({ vendor_action: initialValues.vendor_action })
      form.setFieldsValue({
        vendor_commitment_date: initialValues.vendor_commitment_date,
      })
    }
  }

  function onRemediateFieldChange(values: any) {
    if (!values.target.value) {
      setRemediateFieldRequired(false)
      setShowAgreeToRemediateReasonField(true)
      setEnableActionField(false)
      setShowFormDetails(false)
      setShowVendorCommitmentDateField(false)
      form.setFieldsValue({ vendor_action: null })
      form.setFieldsValue({ vendor_valid_reason: null })
      form.setFieldsValue({ vendor_remediate_reason: null })
      form.setFieldsValue({ vendor_commitment_date: null })
      form.setFieldsValue({ remediation_pathway: null })
      form.setFieldsValue({ sec_cert_type: null })
    } else {
      setRemediateFieldRequired(true)
      setShowAgreeToRemediateReasonField(false)
      setEnableActionField(true)
      setShowVendorCommitmentDateField(true)
      form.setFieldsValue({ vendor_action: initialValues.vendor_action })
      form.setFieldsValue({
        vendor_commitment_date: initialValues.vendor_commitment_date,
      })
      if (form.getFieldValue("vendor_valid")) setShowFormDetails(true)
    }
  }

  async function deleteRemediationFile(remediationFileId: number) {
    setSubmittingForm(true)
    await RemediationFileApi.deleteRemediationFile(remediationFileId)
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  async function onClickVendorRow(vendorFile: IVendorFile) {
    setSubmittingForm(true)
    let responseFile = await VendorFileAPI.attachVendorFileToRemediationFile(
      vendorFile.id,
      riskFinding.id
    )
    if (!responseFile) {
      notification.error({
        message: "Error uploading file to Risk Finding",
        description: (
          <span>
            {vendorFile.file_name} may already be attached to your Risk Finding
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  async function onFilesChanged() {
    setSubmittingForm(true)
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  function displayRemediationPathways() {
    let values = form.getFieldsValue()
    return (
      (values.vendor_remediate || riskFinding.remediation_pathway) &&
      riskFinding.is_ipi_generation_irregular
    )
  }

  function remediationPathwayHelpModal() {
    const urlDetectionRegex = /https?:\/\/[^\s()]+/g

    return (
      <div>
        <div
          onClick={() => setShowRemediationPathwayHelpModal(true)}
          style={{
            marginBottom: 12,
            cursor: "pointer",
            textDecoration: "underline",
            marginTop: "4px",
          }}
        >
          <InfoCircleOutlined size={18} style={{ marginRight: "4px" }} />
          Click here to learn about the different remediation pathway options
        </div>
        {showRemediationPathwayHelpModal && (
          <Modal
            open={showRemediationPathwayHelpModal}
            title={
              <div className="remediation-pathway-modal-title">
                <InfoCircleOutlined size={18} style={{ marginRight: "8px" }} />{" "}
                Remediation Pathways
              </div>
            }
            bodyStyle={{ padding: 24 }}
            className="upload-modal"
            width={800}
            footer={null}
            onCancel={() => setShowRemediationPathwayHelpModal(false)}
            destroyOnClose
          >
            {remediationPathways.map((r, index) => {
              const description = r.description
                .replaceAll("{VENDOR}", riskFinding.assessment.vendor.name)
                .replaceAll(urlDetectionRegex, (match) => {
                  return `<a class="link" target="_blank" href="${match}">${match}</a>`
                })
              return (
                <div>
                  <div className="remediation-pathway-header-title">
                    {`Option ${index + 1}: ${r.name}`}
                  </div>
                  <div
                    className="remediation-pathway-header-description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                  {index !== remediationPathways.length - 1 && <Divider />}
                </div>
              )
            })}
          </Modal>
        )}
      </div>
    )
  }

  function onRemediationPathwayChange(id: number) {
    form.setFieldsValue({ sec_cert_type: null })
    const pathways = remediationPathways.filter((p) => p.id === id)
    if (pathways.length === 0) {
      setSecCertOptions([])
      return
    }

    const pathway = pathways[0]
    if (!pathway.sec_cert_types) {
      setSecCertOptions([])
      return
    }

    setSecCertOptions(pathway.sec_cert_types)
  }

  function getInitialSecCertOptions() {
    if (!riskFinding.remediation_pathway) return []

    const pathways = remediationPathways.filter(
      (p) => p.id === riskFinding.remediation_pathway.id
    )
    if (pathways.length === 0) return []

    const pathway = pathways[0]
    if (!pathway.sec_cert_types) return []

    return pathway.sec_cert_types
  }

  return (
    <div className="risk-finding-details-form-wrapper">
      <Form
        {...formProps}
        layout="vertical"
        form={form}
        onValuesChange={() => {}}
        initialValues={initialValues}
      >
        <div className="risk-finding-details-form-checkboxes">
          <Form.Item
            label="Do you agree this risk finding is valid?"
            name={RiskFindingFormTypes.VendorValid}
            validateTrigger="onSubmit"
            rules={[{ required: true, message: "This field is required." }]}
            style={{ marginRight: "64px" }}
          >
            <Radio.Group disabled={isDisabled} onChange={onValidFieldChange}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={
              gapValidation
                ? `Do you agree that you're not meeting the control now, but will remediate the risk finding if asked?`
                : "Do you agree to remediate the finding by the required due date?"
            }
            name={RiskFindingFormTypes.VendorRemediate}
            validateTrigger="onSubmit"
            rules={[
              {
                required: validFieldRequired,
                message: "This field is required.",
              },
            ]}
          >
            <Radio.Group
              disabled={disableRemediateField()}
              onChange={onRemediateFieldChange}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        {showVendorCommitmentDateField && (
          <div>
            <Form.Item
              label="I will complete the action and notify CORL/Client by"
              name={RiskFindingFormTypes.VendorCommitmentDate}
              validateTrigger="onSubmit"
              rules={[{ required: false, message: "" }]}
            >
              <DatePicker size="large" format="MMM DD, YYYY" disabled={true} />
            </Form.Item>
          </div>
        )}
        {showValidReasonField && (
          <div>
            <Form.Item
              label="Please tell us why you do not find this risk finding valid"
              name={RiskFindingFormTypes.VendorValidReason}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: showValidReasonField,
                  message: "This field is required.",
                },
              ]}
            >
              <TextArea
                size="large"
                disabled={isDisabled || !showValidReasonField}
              />
            </Form.Item>
            <div
              style={{ marginTop: "8px", marginBottom: "16px", width: "100%" }}
            >
              <QuestionEvidence
                files={riskFinding.remediation_files}
                entityId={riskFinding.id}
                vendorId={riskFinding.vendor_id}
                disabled={isDisabled || submittingForm}
                fileUploadType={EFileUploadTypes.Remediation}
                onFilesChanged={onFilesChanged}
                onFileDelete={deleteRemediationFile}
                onRowClick={onClickVendorRow}
              />
            </div>
          </div>
        )}
        {showAgreeToRemediateReasonField && (
          <div>
            <Form.Item
              label="Please tell us why you do not agree to remediate this risk finding"
              name={RiskFindingFormTypes.VendorRemediateReason}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: showAgreeToRemediateReasonField,
                  message: "This field is required.",
                },
              ]}
            >
              <TextArea
                size="large"
                disabled={isDisabled || !showAgreeToRemediateReasonField}
              />
            </Form.Item>
            <div
              style={{ marginTop: "8px", marginBottom: "16px", width: "100%" }}
            >
              <QuestionEvidence
                files={riskFinding.remediation_files}
                entityId={riskFinding.id}
                vendorId={riskFinding.vendor_id}
                disabled={isDisabled || submittingForm}
                fileUploadType={EFileUploadTypes.Remediation}
                onFilesChanged={onFilesChanged}
                onFileDelete={deleteRemediationFile}
                onRowClick={onClickVendorRow}
              />
            </div>
          </div>
        )}
        {showFormDetails && (
          <div>
            {displayRemediationPathways() && (
              <>
                <Form.Item
                  label="Remediation Pathways"
                  name={RiskFindingFormTypes.RemediationPathway}
                  validateTrigger="onSubmit"
                  rules={[
                    {
                      required: remediateFieldRequired,
                      message: "This field is required.",
                    },
                  ]}
                  help={remediationPathwayHelpModal()}
                >
                  <Select
                    size="large"
                    optionLabelProp="children"
                    placeholder="Choose Remediation Pathway"
                    loading={submittingForm}
                    defaultValue={riskFinding.remediation_pathway?.id}
                    disabled={isDisabled}
                    onChange={onRemediationPathwayChange}
                  >
                    {remediationPathways?.map((r) => (
                      <Option value={r.id} key={r.id}>
                        {r.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {secCertOptions.length > 0 && (
                  <Form.Item
                    label="Sec Cert Type"
                    name={RiskFindingFormTypes.SecCertType}
                    validateTrigger="onSubmit"
                    rules={[
                      {
                        required:
                          remediateFieldRequired &&
                          secCertOptions.length > 0 &&
                          riskFinding.is_ipi_generation_irregular,
                        message: "This field is required.",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      optionLabelProp="children"
                      placeholder="Choose Sec Cert Type"
                      loading={submittingForm}
                      disabled={isDisabled}
                    >
                      {secCertOptions?.map((r) => (
                        <Option value={r.id} key={r.id}>
                          {r.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item
              label={
                gapValidation
                  ? "Comments"
                  : "I will take the following action to remediate this risk finding"
              }
              name={RiskFindingFormTypes.VendorAction}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: remediateFieldRequired,
                  message: "This field is required.",
                },
              ]}
            >
              <TextArea
                size="large"
                disabled={!enableActionField}
                autoSize
                placeholder={riskFinding.remediation_scenario}
              />
            </Form.Item>
            <div className="risk-finding-details-form-help">
              If you can remediate the finding now, click{" "}
              <strong>Save Details</strong> and navigate to the{" "}
              <strong>Remediation</strong> tab above to provide more
              information.
            </div>
          </div>
        )}
        {riskFinding.assessment.is_corl_cleared &&
          riskFinding.tasks &&
          riskFinding.tasks.length > 0 && (
            <TasksContainer
              tasks={riskFinding.tasks}
              loading={submittingForm}
              disabled={isDisabled}
            />
          )}
        {!isDisabled && (
          <Button
            onClick={onSubmitClick}
            htmlType="submit"
            loading={submittingForm}
            icon={<ArrowRightOutlined />}
            iconPosition="right"
            style={{ float: "left", marginTop: "16px" }}
            enabled={!isDisabled}
          >
            Save Details
          </Button>
        )}
      </Form>
    </div>
  )
}

export default RiskFindingDetailsForm
