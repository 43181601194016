import React from "react"
import { Layout, Tooltip, Popover } from "antd"
import { RouteComponentProps, withRouter } from "react-router-dom"
import cx from "classnames"

import "./styles.scss"

import AppStore from "../../../stores/App/AppStore"
import { IUser } from "../../../api/User/UserTypes"
import { AppRoutesPathReference, AppSidebarItemsMap } from "../RoutesRegister"
import { ReactComponent as Logo } from "../../../assets/corl-logo-white-fixed.svg"
import BrowserRouter from "../../../stores/App/BrowserRouter"
import { LogoutOutlined, LeftOutlined } from "@ant-design/icons"
import AppActions from "../../../actions/AppActions"
import { EAppSidebarGroups } from "../AppRoutes"
import { observer } from "mobx-react"
import NotificationsPopover from "../../../components/specific/NotificationsPopover/NotificationsPopover"
import { useAuth0 } from "@auth0/auth0-react"
import Button from "../../../components/common/Button/Button"
import UserPicture from "../../../components/common/UserPicture/UserPicture"
import { UserOutlined } from "@ant-design/icons"
import { StringUtils } from "../../../global/utils/string"

interface Props extends RouteComponentProps {}

function AppSidebar(props: Props) {
  const { logout } = useAuth0()

  function hasAccess(user: IUser, appRouteHeaderAccess: string[]) {
    if (!appRouteHeaderAccess || appRouteHeaderAccess.length === 0) return true

    return appRouteHeaderAccess.find((access) => user.role.name === access)
  }

  function getSelectedKey() {
    for (let key in AppRoutesPathReference()) {
      if (
        props.location.pathname.includes(AppRoutesPathReference()[key].path)
      ) {
        // special case for vendor portfolio pages since the tabs inside the vendor page
        // include routing key words like 'assessments' which confuses the following path check
        if (props.location.pathname.includes("vendors")) {
          return "Vendors"
        }
        return key
      }
    }
    return ""
  }

  function renderMenuItems() {
    const user = AppStore.user
    if (!user) return

    let groups = {}
    Object.keys(EAppSidebarGroups).forEach((group) => {
      groups[EAppSidebarGroups[group]] = []
    })

    AppSidebarItemsMap().forEach((appRouteHeader) => {
      const { key, icon, path, access, group } = appRouteHeader
      if (hasAccess(user, access) && group !== "Hidden") {
        groups[group].push(
          <div
            key={key}
            className={cx("sidebar-item", {
              selected: getSelectedKey() === key,
              collapsed: AppStore.sidebarCollapsed,
            })}
            onClick={() => {
              if (
                path === "/assessments" &&
                user.role.name.toLowerCase().includes("vendor")
              ) {
                if (process.env.REACT_APP_ENV === "production") {
                  window.location.href =
                    "https://supplier.corltech.com/assessments-overview"
                } else if (process.env.REACT_APP_ENV === "development") {
                  window.location.href =
                    "https://uat-supplier.corltech.com/assessments-overview"
                } else BrowserRouter.push(path)
              } else BrowserRouter.push(path)
            }}
          >
            {icon}
            {!AppStore.sidebarCollapsed && (
              <div className="sidebar-item-title">{key}</div>
            )}
          </div>
        )
      }
    })

    let groupRender: any[] = []

    Object.keys(groups).forEach((group: any) => {
      const render = groups[group]
      if (render.length > 0) {
        groupRender.push(
          <div key={group} className="sidebar-group">
            {!AppStore.sidebarCollapsed && (
              <div className="group-header">{group}</div>
            )}
            <div className="sidebar-group-items">{render}</div>
          </div>
        )
      }
    })
    return groupRender
  }
  function content() {
    return (
      <div className="app-sidebar-popover-content">
        <div className="user-info-container">
          <div className="user-picture">
            <UserPicture></UserPicture>
          </div>
          <div className="user-info">
            {AppStore?.user?.user_datum?.first_name &&
              AppStore?.user?.user_datum?.last_name && (
                <div className="name">
                  {StringUtils.capitalize(
                    AppStore.user?.user_datum?.first_name
                  )}{" "}
                  {StringUtils.capitalize(AppStore.user?.user_datum?.last_name)}
                </div>
              )}
            <div className="email">{AppStore.user?.email}</div>
          </div>
        </div>

        <div className="policies">
          <a href="eula.pdf" download="eula.pdf">
            EULA
          </a>
          {/* <a
            href="https://exchange.corltech.com/privacy_policy.pdf"
            target="_blank"
            rel="noreferrer"
            download="privacy_policy.pdf"
          >
            Privacy Policy
          </a> */}
        </div>
      </div>
    )
  }

  function bottomIdentifier() {
    if (AppStore.user?.client) return AppStore.user.client.name
    else if (AppStore.user?.vendor) return AppStore.user.vendor.name
    else return AppStore.user?.role.name
  }

  function renderPopoverTitle() {
    return (
      <div className="app-sidebar-popover-title">
        <div className="vendor">{bottomIdentifier()}</div>
        <Button
          icon={<LogoutOutlined />}
          color="clear"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Sign Out
        </Button>
      </div>
    )
  }

  return (
    <Layout.Sider
      theme="light"
      className="app-sidebar"
      width={250}
      collapsed={AppStore.sidebarCollapsed}
    >
      <div className="white-logo">
        {!AppStore.sidebarCollapsed && <Logo style={{ fill: "orange" }} />}
        <Tooltip
          title={`${AppStore.sidebarCollapsed ? "Expand" : "Collapse"} Sidebar`}
        >
          <LeftOutlined
            className={cx("toggle-collapse", {
              collapsed: AppStore.sidebarCollapsed,
            })}
            onClick={AppActions.toggleSidebarCollapse}
          />
        </Tooltip>
      </div>
      <div className="app-sidebar-menu">{renderMenuItems()}</div>
      <div className="notifications-container">
        <NotificationsPopover collapsed={AppStore.sidebarCollapsed} />
      </div>
      {!AppStore.sidebarCollapsed && (
        <Popover
          title={renderPopoverTitle()}
          content={content()}
          trigger={["click"]}
          placement="top"
          overlayStyle={{ minWidth: 400 }}
        >
          <div
            className={cx("user-container", {
              collapsed: AppStore.sidebarCollapsed,
            })}
          >
            {AppStore?.user?.user_datum?.first_name &&
              AppStore?.user?.user_datum?.last_name && (
                <div className="user">
                  <Button
                    className="name"
                    icon={<UserOutlined />}
                    iconPosition="left"
                    color="clear"
                  >
                    {StringUtils.capitalize(
                      AppStore.user?.user_datum?.first_name
                    )}{" "}
                    {StringUtils.capitalize(
                      AppStore.user?.user_datum?.last_name
                    )}
                  </Button>
                </div>
              )}
            {!AppStore?.user?.user_datum?.first_name &&
              !AppStore?.user?.user_datum?.last_name && (
                <div className="user">
                  <Button
                    className="name"
                    icon={<UserOutlined />}
                    iconPosition="left"
                    color="clear"
                  >
                    {StringUtils.capitalize(AppStore.user?.email)}
                  </Button>
                </div>
              )}
          </div>
        </Popover>
      )}
      {!AppStore.sidebarCollapsed && (
        <div className="navbar-footer">
          <a
            href="https://corltech.com/vendor-software-terms-of-service/"
            target="_blank"
            rel="noreferrer"
            style={{ marginRight: 4 }}
          >
            Terms and Conditions
          </a>
          |
          <a
            href="https://corltech.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            style={{ marginLeft: 4 }}
          >
            Privacy Policy
          </a>
        </div>
      )}
    </Layout.Sider>
  )
}

export default withRouter(observer(AppSidebar))
