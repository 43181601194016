import React, {useEffect, useState} from 'react';
import {DeleteOutlined} from '@ant-design/icons';
import './styles.scss';

import QuestionSelect from "../QuestionSelect/QuestionSelect";
import {IQuestion} from "../../../api/Question/QuestionTypes";

interface Props {
    value?: IQuestion[],
    onChange?:(questions:IQuestion[])=>void,
}

function TemplateQuestionBuilder(props: Props) {
    const {value: propQuestions, onChange} = props;
    const [questions, setQuestions] = useState<Record<number, IQuestion>>({});

    useEffect(() => {
        if (propQuestions) {
            let propQuestionsById = {};
            propQuestions.forEach(question => propQuestionsById[question.id] = question);
            setQuestions(propQuestionsById);
        }
    }, [propQuestions])

    function onChangeQuestions(curQuestions:Record<number, IQuestion>) {
        if (onChange){
            onChange(Object.values(curQuestions));
        }
    }

    function onQuestionSelectChange(question: IQuestion) {
        let newQuestions = {...questions};
        newQuestions[question.id] = question;
        setQuestions(newQuestions);
        onChangeQuestions(newQuestions);
    }

    function onDeleteQuestion(questionId:number){
        let newQuestions = {...questions};
        delete newQuestions[questionId];
        setQuestions(newQuestions);
        onChangeQuestions(newQuestions);
    }

    return (
        <div className='template-question-builder'>
            <QuestionSelect fullQuestion onChange={onQuestionSelectChange}/>
            <div className='questions'>
                {Object.values(questions).map(question => {
                    return (
                        <div key={question.id} className='single-question'>
                            <div className='irn'>
                                {question.irn?.description}
                            </div>
                            <div className='text'>
                                {question.text}
                            </div>
                            <div className='delete'>
                                <DeleteOutlined onClick={()=>onDeleteQuestion(question.id)}/>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TemplateQuestionBuilder;