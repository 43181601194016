import axios from "axios";
import {IDataSyncMessage} from "./DataSyncTypes";

export default class DataSyncAPI {
    static readonly url = '/data-sync';

    static async getDeadletterMessages(){
        try {
            let res = await axios.get(`${this.url}/messages`);
            return res.data as IDataSyncMessage[];
        } catch (e:any){
            console.log('Could not get dead-lettered data sync messages', e.message);
            return [];
        }
    }

    static async resendMessage(message:IDataSyncMessage){
        try {
            await axios.post(`${this.url}/resend-message`, message);
            return true;
        } catch (e:any){
            console.log('Could not send data sync message', e.message);
            return false;
        }
    }

    static async sendMessage(message: any){
        try {
            await axios.post(`${this.url}/send-message`, message);
            return true;
        } catch (e:any){
            console.log('Could not send data sync message', e.message);
            return false;
        }
    }
}
