import React, {useState, useEffect} from 'react'
import {Drawer, Table} from 'antd';
import qs from 'qs';
import './styles.scss';

import {IUser} from "../../../api/User/UserTypes";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import TemplateAPI from '../../../api/Template/TemplateAPI'
import {TemplateTableConfig} from './TemplateTableConfig'
import {ITemplate} from '../../../api/Template/TemplateTypes'
import TemplateBuilderDrawer from '../TemplateBuilder/TemplateBuilderDrawer';
import TemplateBuilder from "../TemplateBuilder/TemplateBuilder";
import TemplateFilters from "../TemplateFilters/TemplateFilters";
import {TemplateFilterFormTypes} from '../TemplateFilters/Types';
import StrapiUtils from "../../../global/utils/StrapiUtils";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";

interface Props {
    onChange?: (selectedFramework: number[]) => void,
    user?: IUser,
}

function TemplateTable(props: Props) {
    const {user} = props;
    const [templates, setTemplates] = useState<ITemplate[]>();
    const [curTemplate, setCurTemplate] = useState<ITemplate>();
    const [curPage, setCurPage] = useState(1);
    const [curQuery, setCurQuery] = useState<string>();
    const [numAssessments, setNumTemplates] = useState<number>();

    let limitTemplates = 25;

    async function getTemplates(params?: any, qs?: string) {
        let curParams: any = {
            _start: (curPage - 1) * limitTemplates,
            _limit: limitTemplates,
        }
        if (params) curParams = {...curParams, ...params};
        let allTemplates = await TemplateAPI.getTemplates(curParams, qs);
        if (allTemplates) setTemplates(allTemplates);

        let count = await TemplateAPI.getTemplateCount(curParams, qs);
        if (count) setNumTemplates(count);
    }


    function onTemplateEditClick(templateId: number) {
        if (!templates) return;
        let filtered = templates.filter(template => template.id === templateId);
        if (filtered.length === 1) {
            setCurTemplate(filtered[0])
        }
    }

    function resetPagination() {
        setCurPage(1);
    }

    function disabledFilters() {
        if (user) {
            if (StrapiPermissionsUtil.isCorlAdminOrEmployee(user)) {
                return [TemplateFilterFormTypes.Name];
            }
        } else if (StrapiPermissionsUtil.isClientUser(user) || StrapiPermissionsUtil.isVendorUser(user)) {
            return undefined;
        }
    }

    async function onFiltersSubmit(values: any) {
        let toArray = Object.keys(values).map(key => {
            if (key === TemplateFilterFormTypes.Name) {
                return {[`${key}_contains`]: values[key]}
            } else {
                return {[key]: values[key]};
            }
        });
        let query = qs.stringify({
            _where: toArray
        })

        setCurQuery(query);

        resetPagination();
        await getTemplates(undefined, query);
    }

    let pagination = {
        current: curPage,
        total: numAssessments,
        pageSize: limitTemplates,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total} items`,
    }

    async function onSort(sorter: any, page: number) {
        let sortString = StrapiUtils.tableSortToSortString(sorter.field, sorter.order);
        let params: any = {_start: (page - 1) * limitTemplates};
        if (sortString) {
            params._sort = sortString;
        }
        await getTemplates(params, curQuery)
    }

    async function onPageChange(page:number, sorter:any) {
        setCurPage(page);
        let params:any = {
            _start: (page - 1) * limitTemplates
        }
        let sortString = StrapiUtils.tableSortToSortString(sorter.field, sorter.order);
        if (sortString) {
            params._sort = sortString;
        }
        await getTemplates(params, curQuery);
    }

    async function onTableChange(pagination:any, filters:any, sorter:any, extra:any) {
        switch (extra.action) {
            case "paginate":
                await onPageChange(pagination.current, sorter)
                break;
            case "sort":
                await onSort(sorter, pagination.current);
                break;
            case "filter":
                break;
        }
    }

    useEffect(() => {
        getTemplates();
        //getBaseFrameworks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSubmitEditedTemplate() {
        setCurTemplate(undefined);
        getTemplates();
    }

    if (!templates) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading templates...'/>
            </div>
        )
    }

    return (
        <div className='template-table'>
            <div className='template-table-header'>
                <div className='title'>
                    Template Library
                </div>
                <div className='template-buttons'>
                    <TemplateBuilderDrawer asButton onSubmit={getTemplates}/>
                </div>
            </div>
            <div className='filters'>
                <TemplateFilters onSubmit={onFiltersSubmit} disabledFilters={disabledFilters()}/>
            </div>
            <div className='template-table-content'>
                <Table rowKey='id' dataSource={templates} columns={TemplateTableConfig(onTemplateEditClick)}
                       onChange={onTableChange} pagination={pagination}/>
            </div>
            <Drawer title='Edit Template' width='50%' keyboard={false} open={!!curTemplate}
                    destroyOnClose onClose={() => setCurTemplate(undefined)}
                    footer={null} className='template-form-drawer'>
                <TemplateBuilder applyDrawerStyles template={curTemplate} onSubmit={onSubmitEditedTemplate}/>
            </Drawer>
        </div>
    )
}

export default TemplateTable;