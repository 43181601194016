import React from 'react';

import './styles.scss';

interface Props {
    icon?: any,
    text: string,
    help?: string,
}

function FormSectionHeader(props: Props) {
    const {icon, text, help} = props;
    return (
        <div className='form-section-header'>
            <div className='top'>
                {icon &&
                <div className='form-section-header-icon'>
                    {icon}
                </div>}
                <div className='form-section-header-text'>
                    {text}
                </div>
            </div>
            <div className='bottom'>
                <div className='help'>{help}</div>
            </div>
        </div>
    )

}

export default FormSectionHeader;