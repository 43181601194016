import { createBrowserHistory } from 'history';

/*
 * The browser router class creates an instance of BrowserHistory so we can track the browser's history in a single-page
 * application. Only use this if you are using React as a single-page app. This allows the user to use the native back
 * button or forward buttons as if they were on a regular application. This is closely tied to React Router v4, so check
 * documentation at https://github.com/ReactTraining/react-router for more details. You'll see this instantiated in
 * App.tsx in RR4's <Router/> component.
 *
 */

class BrowserRouterClass {
    history:any;

    constructor(){
        this.history = createBrowserHistory();
    }

    push(url:string){
        this.history.push(url);
        window.scroll(0, 0);
    }

}

const BrowserRouter = new BrowserRouterClass();
export default BrowserRouter;

/*
 * Use this class to statically type the exact browser routes you'd like to handle. If your route uses a param, make
 * make sure to use the "exampleByIdParam" route. It acts as a catch-all, so it will route to your component whether
 * or not you have the ID param in the url.
 */

export class BrowserRoutes {

    static BASE = '';

    /* Data Type Route Chunks */

    static AUTH = 'auth';
    static ACCOUNT = 'account';
    static DEBUGGER = 'debugger';
    static REQUEST_PASSWORD_RESET = 'request_password_reset';
    static PASSWORD_RESET = 'reset_password';
    static LOGIN = 'login';
    static SIGNUP = 'signup';
    static ADMIN = 'admin';
    static DASHBOARD = 'dashboard';

    static ASSESSMENT_BUILDER = 'assessment_builder'
    static ASSESSMENTS = 'assessments';
    static OVERVIEW = 'overview';
    static TEMPLATES = 'templates';
    static QUESTIONNAIRES = 'questionnaires';
    static QUESTIONS = 'questions';
    static VENDORS = 'vendors';
    static CLIENTS = 'clients';
    static USERS = 'users';
    static IMPORT_JOBS = 'import-jobs';
    static EXPORT_JOBS = 'export-jobs';
    static SETTINGS = 'settings';
    static NOTIFICATIONS = 'notifications';
    static ACCEPT_INVITE = 'accept-invite';
    static MESSAGES = 'messages';
    static FILES = 'files';
    static MESSAGE_GOVERNANCE = 'message-governance';
    static DATA_SYNC_AUDIT = 'data-sync-audit';
    static USER_REQUESTS = 'user-requests';
    static INCIDENT_RESPONSE_PORTAL_REDIRECT = 'incident-response-portal';
    static RISK_FINDINGS = 'risk-findings';

    static get debugger(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.DEBUGGER}`;
    }

    static get home(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.DASHBOARD}`;
    }
    static get admin(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ADMIN}`;
    }
    static get auth(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.AUTH}`;
    }

    /* Data Type Static Routes */

    static get account(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ACCOUNT}`
    }
    static get login(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.LOGIN}`
    }
    static get acceptInvite(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ACCEPT_INVITE}`
    }
    static get signup(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.SIGNUP}`
    }
    static get requestPasswordReset(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.REQUEST_PASSWORD_RESET}`;
    }
    static get passwordReset(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.PASSWORD_RESET}`;
    }

    static get assessmentBuilder(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ASSESSMENT_BUILDER}`;
    }
    static get overview(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.OVERVIEW}`;
    }
    static get assessments(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ASSESSMENTS}`;
    }
    static get assessmentsByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.ASSESSMENTS}/:assessmentId`;
    }

    static get templates(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.TEMPLATES}`;
    }
    static get templatesByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.TEMPLATES}/:templateId`;
    }
    static getTemplateById(templateId:number){
        return `${BrowserRoutes.templates}/${templateId}`
    }

    static get questions(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.QUESTIONS}`;
    }
    static get questionsByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.QUESTIONS}/:questionId`;
    }

    static get vendors(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.VENDORS}`;
    }
    static get vendorsByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.VENDORS}/:vendorId`;
    }
    static getVendorById(vendorId:number){
        return `${BrowserRoutes.vendors}/${vendorId}`;
    }

    static get clients(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.CLIENTS}`;
    }
    static get clientsByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.CLIENTS}/:clientId`;
    }
    static getClientById(clientId:number){
        return `${BrowserRoutes.clients}/${clientId}`;
    }
    static getClientUsersByClientId(clientId:number){
        return `${BrowserRoutes.getClientById(clientId)}/${BrowserRoutes.USERS}`;
    }
    static getClientSettingsByClientId(clientId:number){
        return `${BrowserRoutes.getClientById(clientId)}/${BrowserRoutes.SETTINGS}`;
    }

    static get users(){
        return `${BrowserRoutes.settings}/${BrowserRoutes.USERS}`;
    }
    static get importJobs(){
        return `${BrowserRoutes.settings}/${BrowserRoutes.IMPORT_JOBS}`;
    }
    static getImportJobById(importJobId:number){
        return `${BrowserRoutes.settings}/${BrowserRoutes.IMPORT_JOBS}/${importJobId}`;
    }
    static get importJobsByIdParam(){
        return `${BrowserRoutes.settings}/${BrowserRoutes.IMPORT_JOBS}/:importJobId`;
    }
    static get exportJobs(){
        return `${BrowserRoutes.settings}/${BrowserRoutes.EXPORT_JOBS}`;
    }
    static get notificationsSettings(){
        return `${BrowserRoutes.settings}/${BrowserRoutes.NOTIFICATIONS}`;
    }
    static get dataSyncAudit() {
        return `${BrowserRoutes.settings}/${BrowserRoutes.DATA_SYNC_AUDIT}`;
    }

    static get settings(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.SETTINGS}`;
    }
    static get settingsById(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.SETTINGS}/:settingId`;
    }

    static getAssessmentById(assessmentId:number){
        return `${BrowserRoutes.assessments}/${assessmentId}`;
    }

    static getQuestionnaireById(assessmentId:number, questionnaireId:number){
        return `${BrowserRoutes.getAssessmentById(assessmentId)}/${BrowserRoutes.QUESTIONNAIRES}/${questionnaireId}`
    }

    static get messages(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.MESSAGES}`;
    }
    static get messagesByIdParam(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.MESSAGES}/:chatId`;
    }
    static getMessageThreadById(chatId:number){
        return `${BrowserRoutes.messages}/${chatId}`;
    }

    static getVendorAssessmentsById(vendorId:number){
        return `${BrowserRoutes.getVendorById(vendorId)}/${BrowserRoutes.ASSESSMENTS}`;
    }

    static getVendorFilesById(vendorId:number){
        return `${BrowserRoutes.getVendorById(vendorId)}/${BrowserRoutes.FILES}`;
    }

    static getVendorUsersByVendorId(vendorId:number) {
        return `${BrowserRoutes.getVendorById(vendorId)}/${BrowserRoutes.USERS}`;
    }

    static getVendorSettingsByVendorId(vendorId:number){
        return `${BrowserRoutes.getVendorById(vendorId)}/${BrowserRoutes.SETTINGS}`;
    }

    static get files(){
        return `${BrowserRoutes.BASE}/${BrowserRoutes.FILES}`;
    }

    static get notifications() {
        return `${BrowserRoutes.BASE}/${BrowserRoutes.NOTIFICATIONS}`;
    }

    static get messageGovernance() {
        return `${BrowserRoutes.BASE}/${BrowserRoutes.MESSAGE_GOVERNANCE}`;
    }

    static get userRequests() {
        return `${BrowserRoutes.BASE}/${BrowserRoutes.USER_REQUESTS}`;
    }

    static get incidentResponsePortalRedirect() {
        return `${BrowserRoutes.BASE}/${BrowserRoutes.INCIDENT_RESPONSE_PORTAL_REDIRECT}`;
    }

    static get riskFindings() {
        return `${BrowserRoutes.BASE}/${BrowserRoutes.RISK_FINDINGS}`;
    }
}
