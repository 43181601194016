import './styles.scss';
import React, {useState} from 'react';
import {Form, Input, notification} from "antd";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {IClient, EOutreachType} from "../../../api/Client/ClientTypes";
import Button from "../../common/Button/Button";
import ClientAPI from "../../../api/Client/ClientAPI";
import {TOAST_DURATION} from "../../../constants";

interface Props {
    client: IClient,
    outreachType: EOutreachType,
    messageFieldName: string,
    subjectFieldName: string,
    initialValues: any,
    fieldLabel: string,
    headerLabel: string,
    canSave: boolean,
    onClientUpdate: (client: IClient) => void,
}

const EMPTY_OUTREACH_MESSAGE = "<p><br></p>";

function ClientOutreachSettings({client, outreachType, fieldLabel, headerLabel, canSave, messageFieldName, subjectFieldName, initialValues, onClientUpdate}: Props) {
    const [outreachForm] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);
    const [required, setRequired] = useState(false);

    function onOutreachChange() {
        let subject = outreachForm.getFieldValue(subjectFieldName);
        let message = outreachForm.getFieldValue(messageFieldName);
        if(!subject && message === EMPTY_OUTREACH_MESSAGE) {
            setRequired(false);
            return;
        }
        if(subject && message === EMPTY_OUTREACH_MESSAGE) {
            setRequired(true);
            return;
        }
        if(!subject && message) {
            setRequired(true);
            return;
        }
        setRequired(false);
        return;
    }

    async function onOutreachFinish(values: any) {
        if(submitting) return;
        if(required) return;

        setSubmitting(true);
        if(values[messageFieldName] === EMPTY_OUTREACH_MESSAGE) values[messageFieldName] = '';
        let payload = {
            [messageFieldName]: values[messageFieldName],
            [subjectFieldName]: values[subjectFieldName],
            outreach_type: outreachType
        }
        
        let curClient = await ClientAPI.changeOutreachMessage(client.id, payload);
        if (!curClient) {
            notification.error({
                message: 'Error changing outreach message and/or outreach subject',
                description: <span>Please make sure you have both an outreach subject and an outreach message.</span>,
                duration: TOAST_DURATION,
            })
        }

        if (curClient && onClientUpdate) {
            onClientUpdate(curClient);
            notification.success({
                message: 'Successfully changed client outreach.',
                description: <span>Vendor assessment assignments will now use the specified verbiage in email notifications.</span>,
                duration: TOAST_DURATION,
            })
        }

        setSubmitting(false);
    }

    async function onTestOutreach() {
        let subject = outreachForm.getFieldValue(subjectFieldName);
        let message = outreachForm.getFieldValue(messageFieldName);
        setSubmitting(true);
        let res = await ClientAPI.testOutreach({
            clientId: client.id,
            outreachType,
            templateType: outreachType === EOutreachType.IVPQ ? 'scoping' : 'control',
            message,
            subject
        });
        if(res) {
            notification.success({
                message: `Testing ${headerLabel} Outreach Email`,
                description: <span>You should receive a test email in your inbox shortly.</span>,
                duration: TOAST_DURATION,
            })
        } else {
            notification.error({
                message: 'Oops!',
                description: <span>Something went wrong testing outreach email. Please try again later.</span>,
                duration: TOAST_DURATION,
            })
        }
        setSubmitting(false);
    }

    return (
        <div className='client-outreach'>
            <div className='client-outreach-header'>
                <div className='client-outreach-header-title'>
                    {`${headerLabel} Client Outreach`}
                </div>
                <div className='client-outreach-header-description'>
                    {`The outreach message and subject uses the template literal system. Anytime you want to dynamically input a
                    value, such as a vendor's name, you would type {{VENDOR}}. The currently available template
                    items are {{VENDOR}}, {{CLIENT}}, {{PRODUCT}}, and {{QUESTIONNAIRE NAME}}. Use {{B}} and {{/B}} to emphasize the portion
                    of the text in one paragraph. Do not add extra blank lines, the space between paragraphs is maintained by the email template. 
                    Please use the Test ${fieldLabel} Outreach button to test oureach messages before saving - you will receive a test email.
                    If subject or message are empty, the default subject and message will be used instead.`}
                </div>
            </div>
            <Form form={outreachForm} layout='vertical' initialValues={initialValues} onFinish={onOutreachFinish}>
                <Form.Item label={`${fieldLabel} Outreach Subject`} name={subjectFieldName} 
                        onChange={onOutreachChange}
                        rules={[{required: required, message: ''}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label={`${fieldLabel} Outreach Message`} name={messageFieldName}
                            rules={[{required: required, message: ''}]}
                            style={{marginBottom: 24}}>
                    <ReactQuill theme="snow" onChange={onOutreachChange}/>
                </Form.Item>
                <div className='client-outreach-buttons'>
                    <Button className='test-button' htmlType='button' enabled={!required} onClick={() => onTestOutreach()} loading={submitting}>{`Test ${fieldLabel} Outreach`}</Button>
                    {canSave &&
                        <Form.Item>
                            <Button enabled={!required} htmlType='submit' loading={submitting}>Save</Button>
                        </Form.Item>}
                </div>
            </Form>
        </div>
    )
}

export default ClientOutreachSettings;