import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../api/RiskFinding/RiskFindingTypes"
import { ITask } from "../../api/Task/TaskTypes"

export default class RiskFindingUtils {
  static getFinalTask(tasks?: ITask[]): ITask | undefined {
    if (!tasks) return undefined
    if (tasks.length === 0) return undefined
    return tasks.reduce((maxElement, currentElement) => {
      return currentElement.sequence &&
        maxElement.sequence &&
        currentElement.sequence > maxElement.sequence
        ? currentElement
        : maxElement
    })
  }

  static isRiskFindingAnswered(riskFinding: IRiskFinding) {
    //   if (riskFinding.vendor_valid === false) {
    //     return (
    //       riskFinding.vendor_valid_reason !== null &&
    //       riskFinding.vendor_valid_reason !== undefined &&
    //       riskFinding.vendor_valid_reason !== ""
    //     )
    //   }
    //   if (riskFinding.vendor_remediate === false) {
    //     return (
    //       riskFinding.vendor_remediate_reason !== null &&
    //       riskFinding.vendor_remediate_reason !== undefined &&
    //       riskFinding.vendor_remediate_reason !== ""
    //     )
    //   }
    //   return (
    //     riskFinding.vendor_valid === true &&
    //     riskFinding.vendor_remediate === true &&
    //     riskFinding.vendor_action !== null &&
    //     riskFinding.vendor_action !== undefined &&
    //     riskFinding.vendor_action !== ""
    //   )
    return riskFinding.status !== ERiskFindingStatus.Open
  }
}
