import axios from "axios"
import { EAssessmentStatus, IAssessment } from "./AssessmentTypes"

export default class AssessmentAPI {
  static async createAssessment(values) {
    try {
      let res = await axios.post("/assessments", values)
      return res.data as IAssessment
    } catch (e: any) {
      console.log("Could not get assessments", e.message)
      return undefined
    }
  }

  static async editAssessment(assessmentId: number, values: any) {
    try {
      let res = await axios.put(`/assessments/${assessmentId}`, values)
      return res.data as IAssessment
    } catch (e: any) {
      console.log("Could not get assessments", e.message)
      return undefined
    }
  }

  static async getAssessments(params?: any, qs?: string) {
    let url = "/assessments"
    if (qs) {
      url += `?${qs}`
    }
    try {
      let res = await axios.get(url, { params })
      return res.data as IAssessment[]
    } catch (e: any) {
      console.log("Could not get assessments", e.message)
      return undefined
    }
  }
  static async getAssessmentCount(params?: any, qs?: string) {
    let url = "/assessments/count"
    if (qs) {
      url += `?${qs}`
    }
    try {
      let res = await axios.get(url, { params })
      return res.data as number
    } catch (e: any) {
      console.log("Could not get assessments", e.message)
      return undefined
    }
  }
  static async getAssessmentById(assessmentId: number) {
    try {
      let res = await axios.get(`/assessments/${assessmentId}`)
      return res.data as IAssessment
    } catch (e: any) {
      console.log("Could not get own assessments", e.message)
      return undefined
    }
  }
  static async deleteAssessment(assessmentId: number) {
    try {
      let res = await axios.delete(`/assessments/${assessmentId}`)
      return res.data
    } catch (e: any) {
      console.error("Could not delete questionnaire", e.message)
      return undefined
    }
  }

  static async changeAssessmentStatus(
    assessmentId: number,
    status: EAssessmentStatus
  ) {
    try {
      let res = await axios.put(`/assessments/${assessmentId}`, { status })
      return res.data as IAssessment
    } catch (e: any) {
      console.error("Could not change Assessment status", e.message)
      return undefined
    }
  }

  static async submitRiskFindings(
    assessmentId: number,
    status: EAssessmentStatus
  ) {
    try {
      let res = await axios.put(
        `/assessments/${assessmentId}/risk-findings/submit`,
        { status }
      )
      return res.data as IAssessment
    } catch (e: any) {
      console.error("Could not submit remediation plan", e.message)
      return undefined
    }
  }

  static async submitGapValidation(assessmentId: number) {
    try {
      let res = await axios.post(
        `/assessments/${assessmentId}/gap-validation/submit`
      )
      return res.data as IAssessment
    } catch (e: any) {
      console.error("Could not submit gap validation plan", e.message)
      return undefined
    }
  }
}
