import React, { useState } from "react"
import { Drawer } from "antd"

import "./styles.scss"

import { IRiskFinding } from "../../../../api/RiskFinding/RiskFindingTypes"
import Button from "../../../common/Button/Button"
import RiskFindingRemediation from "./RiskFindingRemediation"
import { IRemediationPathway } from "../../../../api/RemediationPathway/RemediationPathwayTypes"
import { SyncOutlined, EditOutlined } from "@ant-design/icons"

interface Props {
  riskFinding: IRiskFinding
  remediationPathways: IRemediationPathway[]
  onSubmit?: (values: any) => void
  asButton?: boolean
  loading?: boolean
  disabled?: boolean
  activeTab?: string
  activeRiskFindingTab?: string
}

function RiskFindingDrawer({
  riskFinding,
  remediationPathways,
  onSubmit,
  asButton,
  loading,
  disabled,
  activeRiskFindingTab,
}: Props) {
  const [showForm, setShowForm] = useState(false)

  const onButtonClick = async (e: any) => {
    e.stopPropagation()
    setShowForm(true)
  }

  const onFormSubmit = async (values: any) => {
    if (onSubmit) await onSubmit(values)
    setShowForm(false)
  }

  const onRiskFindingChange = async (values: any) => {
    if (onSubmit) await onSubmit(values)
  }

  const renderButtonText = () => {
    if (activeRiskFindingTab === "details")
      return riskFinding?.assessment?.status?.includes("gap_validation")
        ? "View Details"
        : "Remediate Risk"
    if (activeRiskFindingTab === "remediation")
      return "Continue with Remediation Submission"

    return "View Details"
  }

  const renderButtonIcon = () => {
    if (activeRiskFindingTab === "details")
      return riskFinding?.assessment?.status?.includes("gap_validation") ? (
        <EditOutlined />
      ) : (
        <SyncOutlined />
      )
    if (activeRiskFindingTab === "remediation") return <SyncOutlined />

    return <EditOutlined />
  }

  const renderButtonColor = () => {
    if (activeRiskFindingTab === "remediation") return "#182A53"

    return "#e7872b"
  }

  const renderButtonTextColor = () => {
    if (activeRiskFindingTab === "remediation") return "white"

    return "black"
  }

  return (
    <div onClick={(e) => e.stopPropagation()} style={{ width: "100%" }}>
      {asButton && (
        <Button
          onClick={onButtonClick}
          style={{
            backgroundColor: renderButtonColor(),
            width: "100%",
            color: renderButtonTextColor(),
          }}
          icon={renderButtonIcon()}
          iconPosition="left"
          loading={loading}
        >
          {renderButtonText()}
        </Button>
      )}
      {!asButton && (
        <div
          style={{ cursor: "pointer", color: "#0083E9" }}
          onClick={onButtonClick}
        >
          View my responses
        </div>
      )}
      <Drawer
        closable={false}
        width="40%"
        keyboard={false}
        open={showForm}
        destroyOnClose
        onClose={() => setShowForm(false)}
        footer={null}
        className="risk-finding-form-drawer"
      >
        <div className="risk-finding-drawer-tabs">
          <div className="risk-finding-remediation-tab">
            <RiskFindingRemediation
              disabled={disabled}
              onSubmit={onFormSubmit}
              onChange={onRiskFindingChange}
              riskFinding={riskFinding}
              remediationPathways={remediationPathways}
              activeTab={activeRiskFindingTab}
            />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default RiskFindingDrawer
