import React, {useEffect, useState} from 'react';
import {Divider, Input, Select, Form} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import './styles.scss';

import {IIRN} from "../../../api/IRN/IRNTypes";
import Button from "../../common/Button/Button";
import IRNAPI from "../../../api/IRN/IRNAPI";


const {Option} = Select;

interface Props {
    onChange?: (type: number) => void,
    loading?: boolean,
    className?: string,
    option?: number
    IRNs?: IIRN[],
    loadIRNs?: boolean,
    value?:number
}

function IRNSelect(props: Props) {
    const [form] = Form.useForm();
    const {className, onChange, loading, option, IRNs, loadIRNs, value} = props;
    const [internalIRNs, setInternalIRNs] = useState<IIRN[]>();
    const [open, setOpen] = useState(false);

    async function getAllIRNs() {
        let irns = await IRNAPI.getIRNs();
        if (irns) setInternalIRNs(irns);
    }

    async function createIRN(e: any) {
        e.preventDefault();
        let value = form.getFieldValue('description')
        if (value) {
            let newIRN = await IRNAPI.createIRN({description: value});
            if (newIRN){
                await getAllIRNs();
                setOpen(false);
                if (onChange) onChange(newIRN.id)
            }
        }
    }

    useEffect(() => {
        if (loadIRNs) {
            getAllIRNs();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function renderDropdown(menu: any) {
        return (
            <div>
                {menu}
                <Divider style={{margin: '4px 0'}}/>
                <div className='irn-select-add-irn'>
                    <Form form={form} className="irn-form" requiredMark='optional'>
                        <Form.Item name="description" className="irn-form-input">
                            <Input size='large' placeholder='Add IRN'/>
                        </Form.Item>
                        <Button style={{marginLeft: 12, height: '100%'}} onClick={createIRN} icon={<PlusOutlined/>}>Add
                            IRN</Button>
                    </Form>
                </div>
            </div>
        )
    }

    function renderIRNs() {
        let IRNStoUse = IRNs;
        if (loadIRNs) {
            IRNStoUse = internalIRNs
        }
        if (IRNStoUse) {
            return IRNStoUse.map(IRN =>
                <Option value={IRN.id} key={IRN.id}>
                    {IRN.description}
                </Option>
            );
        }
    }

    function onDropdownVisibleChange(open){
        setOpen(open);
    }

    return (
        <Select className={className} size='large' optionLabelProp='children' placeholder='Choose IRN' value={value}
                defaultValue={option} onSelect={onChange as any} loading={loading} dropdownRender={renderDropdown}
                disabled={loading} open={open} onDropdownVisibleChange={onDropdownVisibleChange}>
            {renderIRNs()}
        </Select>
    )
}

export default IRNSelect;