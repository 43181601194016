import React, {useCallback, useEffect, useState} from 'react';
import _ from 'lodash';
import {notification} from 'antd';

import './styles.scss';

import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes";
import {Input, Table} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import VendorAPI from "../../../api/Vendor/VendorAPI";
import {VendorTableConfig} from "./VendorTableConfig";
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import {IVendor} from "../../../api/Vendor/VendorTypes";
import VendorFormModalAsButton from "../VendorForm/VendorFormModalAsButton";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {TOAST_DURATION} from "../../../constants";

interface Props {
    disableFilters?: boolean
}

function VendorTable(props: Props) {
    const {disableFilters} = props;
    const [vendors, setVendors] = useState<IQuestionnaire[]>();
    const [curPage, setCurPage] = useState<number>(1);
    const [numVendors, setNumVendors] = useState<number>();
    const [search, setSearch] = useState<string>('');

    const vendorLimit = 25;

    async function getVendors(params?: any) {
        let curParams = {
            _limit: vendorLimit,
            _start: (curPage - 1) * vendorLimit,
            _sort: 'name:ASC'
        }

        if (params) {
            curParams = {...curParams, ...params};
        }

        let curVendors = await VendorAPI.getVendors(curParams);
        if (curVendors) setVendors(curVendors);

        let numVendors = await VendorAPI.getVendorCount(curParams);
        setNumVendors(numVendors);
    }

    useEffect(() => {
        getVendors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onRow(record: any) {
        return {
            onClick: (e: any) => onClickRow(record),
        }
    }

    async function onCreateVendor(values: any) {
        var vendorSearchResponse = await VendorAPI.getVendors({name_eq: values.name});
        if(vendorSearchResponse.length > 0) {
            notification.error({
                message: 'Error creating vendor!',
                description: <span>A Vendor with the name <b>{values.name}</b> already exists.</span>,
                duration: TOAST_DURATION,
            })
            return;
        }
        let res = await VendorAPI.createVendor(values);
        if (!res) {
            notification.error({
                message: 'Error creating vendor!',
                description: <span>Vendor, <b>{values.name}</b>, could not be created. This is most likely because the Point of Contact's email is already registered.</span>,
                duration: TOAST_DURATION,
            })
        } else {
            notification.success({
                message: 'Success!',
                description: <span>Your vendor, <b>{values.name}</b> and the initial user, <b>{values.email}</b> has been successfully created. You may now create assessments and questionnaires for this Vendor. An invitation email will be sent upon creation of the first questionnaire.</span>,
                duration: TOAST_DURATION,

            })
        }
        await getVendors();
    }

    function onClickRow(vendor: IVendor) {
        BrowserRouter.push(BrowserRoutes.getVendorById(vendor.id))
    }

    async function onInputChange(value: string) {
        setSearch(value);
        await getVendors({name_contains: value});
        resetPagination();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(_.debounce(onInputChange, 500), []);

    async function onTableChange(pagination: any, filter: any, sorter: any, extra: any) {
        if (extra.action === 'paginate') {
            setCurPage(pagination.current);
            await getVendors({_start: (pagination.current - 1) * vendorLimit, name_contains: search})
        }
    }

    function resetPagination() {
        setCurPage(1);
    }

    let pagination = {
        pageSize: vendorLimit,
        total: numVendors,
        current: curPage,
        showTotal: (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total} items`,
    }

    if (!vendors) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading vendors...'/>
            </div>
        )
    }

    return (
        <div className='vendor-table'>
            {!disableFilters &&
            <div className='filters'>
                <div className='search-bar'>
                    <Input className='vendor-input' size='large' onChange={e => debouncedOnChange(e.target.value)}
                           bordered={false}
                           prefix={<SearchOutlined/>} placeholder='Search by Vendor name'/>
                </div>
                <VendorFormModalAsButton onSubmit={onCreateVendor}/>
            </div>}
            <div className='questionnaire-table-content'>
                <Table rowKey='id' dataSource={vendors} columns={VendorTableConfig}
                       pagination={pagination} onChange={onTableChange}
                       onRow={onRow}/>
            </div>
        </div>

    )
}

export default VendorTable;