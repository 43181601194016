import React, {useEffect, useState} from 'react';
import {Divider, Input, Select, Form} from 'antd';
import {PlusOutlined} from '@ant-design/icons';

import './styles.scss';

import {IBaseFramework} from "../../../api/BaseFramework/BaseFrameworkTypes";
import Button from "../../common/Button/Button";
import BaseFrameworkAPI from "../../../api/BaseFramework/BaseFrameworkAPI";


const {Option} = Select;

interface Props {
    onChange?: (type: number) => void,
    loading?: boolean,
    className?: string,
    option?: number
    baseFrameworks?: IBaseFramework[],
    loadBaseFrameworks?: boolean,
    value?:number
}

function BaseFrameworkSelect(props: Props) {
    const [form] = Form.useForm();
    const {className, onChange, loading, option, baseFrameworks, loadBaseFrameworks, value} = props;
    const [internalBaseFrameworks, setInternalBaseFrameworks] = useState<IBaseFramework[]>();
    const [open, setOpen] = useState(false)

    async function getAllBaseFrameworks() {
        let baseFrameworks = await BaseFrameworkAPI.getBaseFrameworks();
        if (baseFrameworks) setInternalBaseFrameworks(baseFrameworks);
    }

    async function createBaseFramework(e: any) {
        e.preventDefault();
        let value = form.getFieldValue('description')
        if (value) {
            let newBaseFramework = await BaseFrameworkAPI.createBaseFramework({name: value});
            if (newBaseFramework){
                await getAllBaseFrameworks();
                setOpen(false);
                if (onChange) onChange(newBaseFramework.id)
            }
        }
    }

    useEffect(() => {
        if (loadBaseFrameworks) {
            getAllBaseFrameworks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function renderDropdown(menu: any) {
        return (
            <div>
                {menu}
                <Divider style={{margin: '4px 0'}}/>
                <div className='base-framework-select-add-base-framework'>
                    <Form form={form} className="base-framework-form" requiredMark='optional'>
                        <Form.Item name="description" className="base-framework-form-input">
                            <Input size='large' placeholder='Add BaseFramework'/>
                        </Form.Item>
                        <Button style={{marginLeft: 12, height: '100%'}} onClick={createBaseFramework} icon={<PlusOutlined/>}>Add
                            Base Framework</Button>
                    </Form>
                </div>
            </div>
        )
    }

    function renderBaseFrameworks() {
        let frameworksToUse = baseFrameworks;
        if (loadBaseFrameworks) {
            frameworksToUse = internalBaseFrameworks
        }
        if (frameworksToUse) {
            return frameworksToUse.map(baseFramework =>
                <Option value={baseFramework.id} key={baseFramework.id}>
                    {baseFramework.name}
                </Option>
            );
        }
    }

    function onDropdownVisibleChange(open){
        setOpen(open);
    }

    return (
        <Select className={className} size='large' optionLabelProp='children' placeholder='Choose Base Framework' value={value}
                defaultValue={option} onSelect={onChange as any} loading={loading} dropdownRender={renderDropdown}
                disabled={loading} open={open} onDropdownVisibleChange={onDropdownVisibleChange}>
            {renderBaseFrameworks()}
        </Select>
    )
}

export default BaseFrameworkSelect;