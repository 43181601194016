import axios from "axios"
import { IRemediationPathway } from "./RemediationPathwayTypes"

export default class RemediationPathwayApi {
  static async getRemediationPathways(): Promise<
    IRemediationPathway[] | undefined
  > {
    try {
      const res = await axios.get(`/remediation-pathways`)
      return res.data
    } catch (e: any) {
      console.log("Could not get remediation pathways", e.message)
      return undefined
    }
  }
}
