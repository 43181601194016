import React, {useState} from "react";

import './style.scss';

import {EAssessmentStatus} from "../../../api/Assessment/AssessmentTypes";
import {IUserRole} from "../../../api/User/UserTypes";
import QuestionnaireStatusSelect from "./QuestionnaireStatusSelect";
import Status from "../Status/Status";
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI";
import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import AppStore from "../../../stores/App/AppStore";

interface QuestionnaireInfoStatusProps {
    questionnaire:IQuestionnaire,
    role?:IUserRole,
    onChange?:()=>void,
}

function QuestionnaireStatusSelectProtected(props: QuestionnaireInfoStatusProps) {
    const {questionnaire, onChange} = props;
    const [loading, setLoading] = useState(false);

    async function onChangeStatus(curStatus: EAssessmentStatus) {
        setLoading(true);
        await QuestionnaireAPI.updateQuestionnaire(questionnaire.id, {status: curStatus});
        if (onChange) await onChange();
        setLoading(false);
    }

    if (StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user)) {
        return <QuestionnaireStatusSelect onChange={onChangeStatus} defaultValue={questionnaire.status} loading={loading}/>
    } else {
        return <Status status={questionnaire.status}/>;
    }
}

export default QuestionnaireStatusSelectProtected;