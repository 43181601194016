import React, {useEffect, useState} from 'react';
import {Form, Input, notification, Radio, Upload} from "antd";
import cx from 'classnames';
import './styles.scss';

import {TemplateBuilderFormTypes} from "./Types";
import Button from "../../common/Button/Button";
import TemplateAPI from "../../../api/Template/TemplateAPI";
import {ArrowRightOutlined, InboxOutlined} from "@ant-design/icons";
import BaseFrameworkSelect from "../BaseFrameworkSelect/BaseFrameworkSelect";
import PapaParseUtils from '../../../global/utils/PapaParseUtils';
import {EQuestionnaireType} from "../../../api/Template/TemplateTypes";

interface Props {
    onSubmit?: () => void,
    applyDrawerStyles?: boolean,
}

const {Dragger} = Upload;

function TemplateImporter(props: Props) {
    const {onSubmit, applyDrawerStyles} = props;
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [submittingTemplateImportForm, setSubmittingTemplateImportForm] = useState(false);

    function renderTemplateImportButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onTemplateImportSubmit} htmlType='submit' loading={submittingTemplateImportForm}
                            icon={<ArrowRightOutlined/>} iconPosition='right'>
                        Import Template
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onTemplateImportSubmit} htmlType='submit' loading={submittingTemplateImportForm}
                        icon={<ArrowRightOutlined/>}
                        iconPosition='right'>
                    Import Template
                </Button>
            )
        }
    }

    useEffect(() => {
        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const formProps = {
        requiredMark: "optional" as RequiredMark,
        colon: false,
        className: cx('template-form', {'as-drawer': applyDrawerStyles})
    };

    async function onTemplateImportSubmit() {
        if (submittingTemplateImportForm) return false;
        setSubmittingTemplateImportForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields) {
                let values = form.getFieldsValue();

                if(values[TemplateBuilderFormTypes.TemplateType] === 'scoping') {
                    values[TemplateBuilderFormTypes.QuestionnaireType] = EQuestionnaireType.IVPQ;
                }

                // Right now, the base framework is only selectable by one, so we need to set it accordingly.
                // When we allow more than one base framework, we'll change this.
                if (values[TemplateBuilderFormTypes.BaseFrameworks]) {
                    values[TemplateBuilderFormTypes.BaseFrameworks] = [values[TemplateBuilderFormTypes.BaseFrameworks]];
                }

                if (values[TemplateBuilderFormTypes.ImportFile]) {
                    let file = values[TemplateBuilderFormTypes.ImportFile].file.originFileObj;

                    let results = await PapaParseUtils.parse(file, {skipEmptyLines: 'greedy'});

                    //array of expected keys based on control question import
                    const expectedKeys = ['control_guid', 'control_number', 'irn_guid', 'irn', 'text', 'control_group', 'control_name', 'question_type', 'key_control', 'depends_on', 'dependency_text'];
                    let fileKeys = [];

                    //array of keys based on input csv
                    for (let key in results[0]) {
                        fileKeys.push(key);
                    }

                    //check input keys against expected set of keys
                    if (JSON.stringify(fileKeys) === JSON.stringify(expectedKeys)) {
                        let template = await TemplateAPI.importTemplate(values, results); //values = template properties, results = JSON object for control questions within input csv
                        if (template) {
                            notification.success({
                                message: 'Success!',
                                description: <span>Your template, <b>{values.name}</b>, has been successfully imported. You may now use it to create new questionnaires.</span>,
                                duration: 2.5,
                            })
                            form.resetFields();
                            if (onSubmit) onSubmit();
                        } else {
                            notification.error({
                                message: 'Error importing template',
                                description: <span>Your template <b>{values.name}</b> could not be imported.</span>,
                                duration: 10,
                            })
                        }

                    } else {
                        notification.error({
                            message: 'Error when importing template',
                            description: <span> The columns within your template <b>{values.name}</b> did not match the expected set of columns.</span>,
                            duration: 3,
                        })
                    }

                }
            }
        } catch (e) {

        }
        setSubmittingTemplateImportForm(false);
    }

    function onValuesChange(v) {
        setValuesForm(v);
    }

    return (
        <div className='template-form-wrapper'>
            <Form {...formProps} layout='vertical' form={form} onValuesChange={onValuesChange} style={{width: '100%'}}>
                <Form.Item label='Template Name' name={TemplateBuilderFormTypes.Name}
                           validateTrigger={false} style={{width: '100%'}}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Base Framework' name={TemplateBuilderFormTypes.BaseFrameworks}
                           validateTrigger={false} style={{width: '100%'}}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <BaseFrameworkSelect loadBaseFrameworks/>
                </Form.Item>
                <Form.Item label='Template Type' name={TemplateBuilderFormTypes.TemplateType}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Radio.Group>
                        <Radio value='control'>
                            Control
                        </Radio>
                        <Radio value='scoping'>
                            Scoping
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                {form.getFieldsValue()[TemplateBuilderFormTypes.TemplateType] === 'control' &&
                    <Form.Item label='Questionnaire Type' name={TemplateBuilderFormTypes.QuestionnaireType}
                    rules={[{required: form.getFieldsValue()[TemplateBuilderFormTypes.TemplateType] === 'control', message: 'This field is required.'}]}>
                    <Radio.Group>
                        <Radio value='PSQ'>
                            PSQ
                        </Radio>
                        <Radio value='VSQ'>
                            VSQ
                        </Radio>
                    </Radio.Group>
                </Form.Item>}
                <Form.Item name={TemplateBuilderFormTypes.ImportFile}>
                    <Dragger className="template-import-dragger" accept=".csv" maxCount={1}>
                        <p className="template-import-icon">
                            <InboxOutlined style={{fontSize: "40px", marginBottom: "20px"}}/>
                        </p>
                        <p className="template-import-text">
                            Browse your local directory or drag a csv file to import a template
                        </p>
                    </Dragger>
                </Form.Item>
            </Form>
            {renderTemplateImportButton()}
        </div>
    )
}

export default TemplateImporter;