import React from 'react';
import moment from 'moment';
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";
import {ArrowRightOutlined, EditOutlined, DeleteOutlined} from '@ant-design/icons';
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import StatusAsTag from "../../common/StatusAsTag/StatusAsTag";
import {Tooltip} from "antd";
import StrapiPermissionsUtil from '../../../api/utils/StrapiPermissionsUtil';
import AppStore from '../../../stores/App/AppStore';

export const AssessmentTableConfig = (onClickEdit: (assessmentId:number) => void, onClickDelete: (assessment:IAssessment) => void) => [
    {
        title: 'Assessment', dataIndex: 'title', key: 'title',
        render: (title:string, assessment: IAssessment) => {
            function onClick(e:any) {
                e.stopPropagation();
                BrowserRouter.push(BrowserRoutes.getAssessmentById(assessment.id))
            }

            return (
                <div className='assessment-name'>
                    <div className='bottom'>
                        <span className='title' onClick={onClick}>
                            {title}
                        </span>
                        <div className='assignments'>
                            {!assessment.client && <div className='client' style={{color:'red'}}>This assessment doesn't have a Client</div>}
                            <div className='client'>{assessment.client?.name}</div>
                            <ArrowRightOutlined/>
                            {!assessment.vendor && <div className='vendor' style={{color:'red'}}>This assessment doesn't have a Vendor</div>}
                            <div className='vendor'>{assessment.vendor?.name}</div>
                        </div>
                    </div>
                </div>
            )
        }
    },
    {
        title: 'Status', dataIndex: 'status', key: 'status',
        render: (status:string) => {
            return <StatusAsTag status={status}/>
        }
    },
    {
        title: 'Due', dataIndex: 'due_date', key: 'due_date', sorter: true,
        render: (due_date:string) => {
            return moment(due_date).format('MMM DD, YYYY');
        }
    },
    {
        title: 'Completed On', dataIndex: 'completion_date', key: 'completion_date', sorter: true,
        render: (completion_date:string) => {
            if (completion_date){
                return moment(completion_date).format('MMM DD, YYYY');
            } else {
                return "None";
            }
        }
    },
    {
        title: 'Point of Contact', key: 'point_of_contact', sorter: false,
        render: (assessment: IAssessment) => {
            return <div className='point-of-contact-column'>
                <div>
                    {assessment.point_of_contact ? `Email: ${assessment.point_of_contact}` : ""}
                </div>
                <div>
                    {assessment.point_of_contact_name ? `Name: ${assessment.point_of_contact_name}` : ""}
                </div>
            </div>
        }
    },
    {
        title: '', key: 'actions',
        render: (assessment: IAssessment) => {
            if(!onClickDelete && !onClickEdit) return null;
            
            return (
                <div className='assessment-table-actions'>
                    <div className='edit-button' style={{marginRight: 12}}>
                        <Tooltip title='Edit Assessment'>
                            <EditOutlined onClick={() => onClickEdit(assessment.id)}/>
                        </Tooltip>
                    </div>
                    {StrapiPermissionsUtil.isCorlAdmin(AppStore.user) &&
                        <div className='delete-button'>
                            <Tooltip title='Delete Assessment'>
                                <DeleteOutlined onClick={(e: any) => {
                                    onClickDelete(assessment);
                                    e.stopPropagation();
                                }}/>
                            </Tooltip>
                        </div>
                    }
                    
                </div>
            )
        }
    },
];
