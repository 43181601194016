import axios from "axios";
import {IImportJob} from "./ImportJobTypes";

export default class ImportJobAPI {

    static async startImportJob(names:string[]) {
        try {
            let res = await axios.post('/import-jobs/full-import', { names });
            return res.data as IImportJob[];
        } catch (e) {
            console.error('Could not start import job', e.message);
            return e.message;
        }
    }

    static async getImportJobs(){
        try {
            let res = await axios.get('/import-jobs?_sort=created_at:DESC');
            return res.data as IImportJob[];
        } catch (e) {
            console.error('Could not get import jobs', e.message);
            return undefined;
        }
    }

    static async getImportJobById(importJobId:number){
        try {
            let res = await axios.get(`/import-jobs/${importJobId}`);
            return res.data as IImportJob;
        } catch (e) {
            console.error('Could not get import jobs', e.message);
            return undefined;
        }
    }

}