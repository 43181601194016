import React, {useEffect, useState} from 'react';
import {useLocation, RouteComponentProps, Switch, Route, Redirect} from 'react-router-dom';
import {BarsOutlined, ContainerOutlined, TeamOutlined, SettingOutlined} from '@ant-design/icons';
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import cx from 'classnames';
import './styles.scss';
import VendorApi from '../../../api/Vendor/VendorAPI'
import AssessmentTable from "../../../components/specific/AssessmentTable/AssessmentTable";
import {IVendor} from '../../../api/Vendor/VendorTypes';
import AppStore from "../../../stores/App/AppStore";
import VendorUserManagement from "../VendorUserManagement/VendorUserManagement";
import VendorFilesContainer from "../../../containers/VendorFilesContainer/VendorFilesContainer";
import VendorSettings from '../VendorSettings/VendorSettings';

interface Props extends RouteComponentProps {
    vendorId: number;
}

function VendorPortfolio(props: Props) {
    let location = useLocation();
    const {vendorId} = props;
    const [vendor, setVendor] = useState<IVendor>();

    async function getVendor() {
        let curVendor = await VendorApi.getById(props.vendorId);
        if (curVendor) setVendor(curVendor);
    }

    function onUpdateVendor(updatedVendor: any) {
        setVendor(updatedVendor);
    }

    useEffect(() => {
        getVendor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let tabs = {
        assessments: {
            id: 'assessments',
            display: 'Assessments',
            path: BrowserRoutes.getVendorAssessmentsById(vendorId),
            icon: <BarsOutlined/>,
        },
        files: {
            id: 'files',
            display: "Files",
            path: BrowserRoutes.getVendorFilesById(vendorId),
            icon: <ContainerOutlined/>
        },
        users: {
            id: 'users',
            display: 'Users',
            path: BrowserRoutes.getVendorUsersByVendorId(vendorId),
            icon: <TeamOutlined/>
        },
        settings: {
            id: 'settings',
            display: 'Settings',
            path: BrowserRoutes.getVendorSettingsByVendorId(vendorId),
            icon: <SettingOutlined/>
        },
    }

    function getSelectedKey() {
        for (let key in tabs) {
            if (location.pathname.includes(tabs[key].path)) {
                return key;
            }
        }
        return '';
    }

    function renderTabs() {
        return Object.keys(tabs).map(key => {
            const tab = tabs[key];

            const {display, path, icon} = tab;
            let selected = key === getSelectedKey()

            function onClick() {
                if (!selected) {
                    BrowserRouter.push(path)
                }
            }

            return (
                <div key={key} className={cx('portfolio-tab', {selected})} onClick={onClick}>
                    {icon} {display}
                </div>
            )
        })
    }

    return (
        <div className='vendor-portfolio-container'>
            <div className='vendor-portfolio-container-header'>
                {vendor?.name} Portfolio
            </div>
            <Route path={BrowserRoutes.vendorsByIdParam} render={() => {
                return (
                    <div className='vendor-portfolio-tabs'>
                        {renderTabs()}
                    </div>
                )
            }}/>
            <Switch>
                <Route exact path={BrowserRoutes.getVendorAssessmentsById(vendorId)} render={() => (
                    <AssessmentTable portfolioMode={true} user={AppStore.user} vendorId={vendorId}/>)}/>
                <Route exact path={BrowserRoutes.getVendorFilesById(vendorId)} render={() => (
                    <VendorFilesContainer vendorId={vendorId}/>
                )}/>
                <Route exact path={BrowserRoutes.getVendorUsersByVendorId(vendorId)}
                       render={() => (<VendorUserManagement vendorId={vendorId}/>)}/>
                <Route exact path={BrowserRoutes.getVendorSettingsByVendorId(vendorId)} render={() => (
                    <VendorSettings vendorId={vendorId} onVendorUpdate={onUpdateVendor}/>)}/>
                <Redirect to={BrowserRoutes.getVendorAssessmentsById(vendorId)}/>
            </Switch>

        </div>
    )
}

export default VendorPortfolio;