import React, { useState } from "react"
import { Drawer, Form, Input, Select } from "antd"
import { SendOutlined } from "@ant-design/icons"

import "./styles.scss"
import Button from "../../common/Button/Button"

const { Option } = Select

interface Props {
  onSubmit?: (values: any) => void
  asButton?: boolean
}

function SendMessageDrawer(props: Props) {
  const { onSubmit, asButton } = props
  const [showForm, setShowForm] = useState(!asButton)
  const [idLabel, setIdLabel] = useState("Entity Id")
  const [form] = Form.useForm()
  // eslint-disable-next-line
  const [valuesForm, setValuesForm] = useState({})

  const onButtonClick = async (e: any) => {
    e.stopPropagation()
    setShowForm(true)
  }

  function onValuesChange(v: any) {
    setValuesForm(v)
  }

  const onFormSubmit = async (e: any) => {
    e.stopPropagation()
    setShowForm(false)
    if (onSubmit) await onSubmit(form.getFieldsValue())
  }

  const events = [
    {
      eventName: "CreateAssessment",
      entityName: "Assessment",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "CreateScopingQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "SubmitScopingQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "ReopenScopingQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "CreateQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "SubmitQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "ReopenQuestionnaire",
      entityName: "Questionnaire",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "SubmitRemediationDetailsPlan",
      entityName: "Assessment",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "SubmitRemediation",
      entityName: "Risk Finding",
      eventOrigin: "CORL Portal",
    },
    {
      eventName: "SubmitGapValidationPlan",
      entityName: "Assessment",
      eventOrigin: "CORL Portal",
    },
    { eventName: "SubmitTask", entityName: "Task", eventOrigin: "CORL Portal" },
    { eventName: "CreateVRAS", entityName: "VRAS", eventOrigin: "Dynamics" },
    {
      eventName: "CreateControlQuestionnaireSummary",
      entityName: "Questionnaire Summary",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "InitiateObtainRem",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "InitiateFollowRem",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "InitiateObtainValidation",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "InitiateReportValidation",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "CreateRemScen",
      entityName: "Remediation Scenario",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "SyncAssessment",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "UpdateCorlTask",
      entityName: "CORL Task",
      eventOrigin: "Dynamics",
    },
    {
      eventName: "InitiateReportRem",
      entityName: "VRAS",
      eventOrigin: "Dynamics",
    },
  ]

  function eventSelected(e: any) {
    let event = events.find((i) => i.eventName === e)
    if (!event) return
    setIdLabel(`${event.entityName} Id`)
    form.setFieldsValue({ entityId: undefined })
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {asButton && (
        <Button
          icon={<SendOutlined />}
          color="green"
          onClick={onButtonClick}
          className="send-data-sync-message-drawer-button"
        >
          Send Data Sync Message
        </Button>
      )}
      <Drawer
        title="Send Data Sync Message"
        width="50%"
        keyboard={false}
        open={showForm}
        destroyOnClose={true}
        onClose={() => setShowForm(false)}
        footer={null}
        className="send-data-sync-message-drawer"
      >
        <div className="description">
          If you can't find a failed event message to re-send, you may manually
          re-send it here.<br></br>
          An event from the Portal will have an integer Id e.g. 5431<br></br>
          An event from Dynamics will have a guid Id e.g.
          ba05546e-9640-eb11-a813-00224803d098
        </div>
        <div className="description">
          If you would like to monitor the sync event messages as they are sent
          to the application, you can watch them live through&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://portal.azure.com/#@corltech.com/resource/subscriptions/f5e43f68-1b70-41c2-aaf2-5be6ec39ae31/resourceGroups/CORL_Frontend_Portal_Development/providers/Microsoft.Web/sites/corl-exchange-dynamics-event-handler/logStream"
          >
            <u>the Azure Application Log Stream.</u>
          </a>
        </div>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={onValuesChange}
          className="content"
        >
          <Form.Item
            label="Data Sync Event"
            name={"event"}
            rules={[{ required: true }]}
          >
            <Select
              size="large"
              optionLabelProp="children"
              placeholder="Choose Event"
              onSelect={eventSelected}
            >
              {events.map((event) => (
                <Option value={event.eventName} key={event.eventName}>
                  {event.eventOrigin}: {event.eventName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={idLabel}
            name={"entityId"}
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
        </Form>
        <div className="form-stickied-footer">
          <Button
            onClick={onFormSubmit}
            htmlType="submit"
            icon={<SendOutlined />}
            iconPosition="right"
            color="green"
            enabled={form.getFieldValue("entityId") !== undefined}
          >
            Send Message
          </Button>
        </div>
      </Drawer>
    </div>
  )
}

export default SendMessageDrawer
