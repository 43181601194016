import React from "react"
import { List, Tooltip } from "antd"
import {
  CloseOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileOutlined,
  FileUnknownOutlined,
  FileJpgOutlined,
  FilePptOutlined,
} from "@ant-design/icons"

import "./styles.scss"

import FileUpload from "../../common/FileUpload/FileUpload"
import { IResponseFile } from "../../../api/ResponseFile/ResponseFileTypes"
import VendorFileWidget from "../VendorFileWidget/VendorFileWidget"
import { EFileUploadTypes } from "../../../api/File/FileTypes"
import { IVendorFile } from "../../../api/VendorFile/VendorFileTypes"

interface Props {
  files: any[]
  vendorId?: number
  disabled?: boolean
  hideUpload?: boolean
  entityId?: number
  hideHeader?: boolean
  fileUploadType?: EFileUploadTypes
  onFilesChanged?: () => void
  onFileDelete?: (values: any) => void
  onRowClick?: (vendorFile: IVendorFile) => void
}

function QuestionEvidence({
  files,
  vendorId,
  disabled,
  hideUpload,
  entityId,
  fileUploadType,
  onFilesChanged,
  onFileDelete,
  onRowClick,
  hideHeader,
}: Props) {
  function renderItem(file: IResponseFile) {
    let vendorFileName = file.vendor_file.file_name
    let vendorFileType = vendorFileName.split(".")
    let vendorFileTypeString = vendorFileType[1]
    let marginStyle = { margin: "0 8px" }
    let renderSwitchItems = () => {
      switch (vendorFileTypeString?.toLowerCase()) {
        case "pdf":
          return <FilePdfOutlined style={marginStyle} />
        case "xlsx" || "xls" || "xlsm" || "xlsb":
          return <FileExcelOutlined style={marginStyle} />
        case "txt":
          return <FileTextOutlined style={marginStyle} />
        case "jpg" || "jpeg":
          return <FileJpgOutlined style={marginStyle} />
        case "png":
          return <FileImageOutlined style={marginStyle} />
        case "doc" || "docx":
          return <FileWordOutlined style={marginStyle} />
        case "csv" || "msg":
          return <FileOutlined style={marginStyle} />
        case "pptx" || "ppt":
          return <FilePptOutlined style={marginStyle} />
        default:
          return <FileUnknownOutlined style={marginStyle} />
      }
    }
    return (
      <List.Item>
        <div className="attachment">
          <div className="attachment-left">
            {renderSwitchItems()}
            {file.vendor_file.file_name}
          </div>
          <div className="attachment-right">
            {!disabled && (
              <Tooltip title="Delete Attachment">
                <CloseOutlined onClick={() => onDelete(file.id)} />
              </Tooltip>
            )}
          </div>
        </div>
      </List.Item>
    )
  }

  async function onDelete(fileId: number) {
    if (disabled) return
    if (onFileDelete) await onFileDelete(fileId)
  }

  return (
    <div>
      {!hideHeader && <div className="attachments-header">Attachments</div>}
      {files?.length > 0 && (
        <List
          grid={{ gutter: 16, sm: 2, md: 4 }}
          className="attachments-list"
          renderItem={renderItem}
          dataSource={files}
        />
      )}
      {!hideUpload && (
        <div className="attachments-actions">
          <FileUpload
            entityId={entityId}
            vendorId={vendorId}
            fileUploadType={fileUploadType}
            onFilesUploaded={onFilesChanged}
            disabled={disabled}
          />
          <VendorFileWidget
            vendorId={vendorId}
            onRowClick={onRowClick}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}

export default QuestionEvidence
