import React, {useState} from 'react';
import {Drawer} from "antd";
import {PlusOutlined} from '@ant-design/icons';

import './styles.scss';
import Button from "../../common/Button/Button";
import AssessmentForm from "./AssessmentForm";

interface Props {
    className?: string,
    onSubmit?: (values: string) => void,
    asButton?: boolean,
    shouldHide?: boolean
}

function AssessmentFormDrawer(props: Props) {
    const {className, onSubmit, asButton, shouldHide} = props;
    const [showForm, setShowForm] = useState(!asButton);

    const onButtonClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
    }

    const onFormSubmit = async (values: any) => {
        if (onSubmit) onSubmit(values);
        setShowForm(false);
    }

    return (
        <div onClick={e => e.stopPropagation()} className={shouldHide ? 'hidden' : ''}>
            {asButton &&
            <Button icon={<PlusOutlined/>} className={className} onClick={onButtonClick}>
                Create Assessment
            </Button>}
            <Drawer title='Create Assessment' width='50%' keyboard={false} open={showForm}
                    destroyOnClose onClose={() => setShowForm(false)}
                    footer={null} className='assessment-form-drawer'>
                <AssessmentForm onSubmit={onFormSubmit} footerStickied applyDrawerStyles/>
            </Drawer>
        </div>
    )
}

export default AssessmentFormDrawer;