import axios from "axios";
import {IImplementation} from "./ImplementationTypes";

export default class ProductAPI {

    static async getImplementations() {
        try {
            let res = await axios.get('/implementations');
            return res.data as IImplementation[];
        } catch (e) {
            console.error('Could not get implementations', e.message);
            return undefined;
        }
    }

    static async getImplementationById(implementationId: number) {

    }

    static async createImplementation(values:any){
        try {
            let res = await axios.post('/implementations', values);
            return res.data as IImplementation;
        } catch (e) {
            console.error('Could not create implementation', e.message);
            return undefined;
        }
    }

}