import React from 'react';
import {Switch, Route} from 'react-router-dom';

import './styles.scss';

import VendorPortfolio from "../../components/specific/VendorPortfolio/VendorPortfolio";
import VendorTable from "../../components/specific/VendorTable/VendorTable";
import {BrowserRoutes} from "../../stores/App/BrowserRouter";

function VendorsContainer(){
    return (
        <section className='vendor-container'>
            <Switch>
                <Route exact path={BrowserRoutes.vendors} render={() => (
                    <div style={{padding:24, width:'100%', height:'100%'}}>
                        <VendorTable/>
                    </div>
                )}/>
                <Route path={BrowserRoutes.vendorsByIdParam} render={({match})=><VendorPortfolio vendorId={match.params.vendorId}/>}/>
            </Switch>
        </section>
    )
}

export default VendorsContainer;