import React from 'react';
import {Select} from 'antd';

import './style.scss';

import {ITemplate} from "../../../api/Template/TemplateTypes";
import {EQuestionType} from "../../../api/Base/BaseTypes";

const {Option, OptGroup} = Select;

export interface Props {
    onChange?: (selectedTemplate: number[]) => void,
    templates: ITemplate[],
    value?: number,
    disabled?: boolean
}

function TemplateSelect(props: Props) {
    const {templates, onChange, value, disabled} = props;
    let curTemplate = templates.filter(template => template.id === value);

    let scopingTemplates = [];
    let controlTemplates = [];

    function sortTemplates(template1: ITemplate, template2: ITemplate) {
        var textA = template1.name?.toUpperCase();
        var textB = template2.name?.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }

    templates.sort(sortTemplates).forEach(template => {
        if (template.template_type === EQuestionType.Control) {
            controlTemplates.sort().push(template);
        } else if (template.template_type === EQuestionType.Scoping) {
            scopingTemplates.push(template);
        }
    })

    return (
        <div className='template-select'>
            <Select className='template-select-search' size='large' placeholder='Choose template' disabled={disabled}
                    optionLabelProp='children' onChange={onChange} value={value as any} optionFilterProp='children'>

                <OptGroup label='Scoping'>
                    {scopingTemplates.map(template => (
                        <Option value={template.id} key={template.id}>
                            {template.name}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label='Control'>
                    {controlTemplates.map(template => (
                        <Option value={template.id} key={template.id}>
                            {template.name}
                        </Option>
                    ))}
                </OptGroup>
            </Select>
            {curTemplate && curTemplate[0] &&
            <div className='field-info'>
                This template is for <u>{curTemplate[0].template_type}</u> questionnaires.
            </div>}
        </div>
    )
}

export default TemplateSelect;