import React from "react"
import "./styles.scss"
import moment from "moment"
import {
  ETaskStatuses,
  ETaskTypes,
  ITask,
} from "../../../../../api/Task/TaskTypes"
import Case from "case"
import Button from "../../../../common/Button/Button"
import { ToolOutlined } from "@ant-design/icons"

interface Props {
  task?: ITask
  isFinal?: boolean
  form?: any
}

function Task({ task, isFinal, form }: Props) {
  let statusToDisplay = task?.status || ETaskStatuses.InProgress

  const renderTaskResponse = () => {
    if (task?.type === ETaskTypes.Attestation) {
      return (
        <span style={{ marginLeft: "4px" }}>
          {task.attestation ? "Yes" : "No"}
        </span>
      )
    }
    if (task?.type === ETaskTypes.Evidence) {
      return (
        <span style={{ marginLeft: "4px" }}>
          {task.task_files.map((f) => f.vendor_file.file_name).join(",")}
        </span>
      )
    }
    return <span style={{ marginLeft: "4px" }}>{task?.text}</span>
  }

  return (
    <div className="task">
      <div className="task-header">
        <div>
          <strong>{isFinal ? `Final Task` : `Step ${task?.sequence}`}</strong>:{" "}
          <strong>{Case.capital(statusToDisplay)}</strong>
        </div>
        <strong>
          Due Date: {moment(task?.due_date).format("MMM DD, YYYY")}
        </strong>
      </div>
      <div>{task?.description}</div>
      {task?.status && task?.status !== ETaskStatuses.InProgress && (
        <div
          className="task-response"
          style={
            task?.status === ETaskStatuses.Inadequate
              ? { color: "#9D2727" }
              : {}
          }
        >
          A: {renderTaskResponse()}
        </div>
      )}
      {form && task?.status === ETaskStatuses.Inadequate && (
        <Button
          style={{
            backgroundColor: "#9D2727",
            width: "100%",
            color: "white",
          }}
          icon={<ToolOutlined />}
          iconPosition="left"
        >
          Resolve
        </Button>
      )}
    </div>
  )
}

export default Task
