import React from 'react';
import RiskFindingsTable from '../../components/specific/Remediation/RiskFindingsTable/RiskFindingsTable';
import AppStore from '../../stores/App/AppStore';

import './styles.scss';

function RiskFindingsContainer() {

    return (
        <div className='risk-findings-container'>
            <RiskFindingsTable user={AppStore.user} vendorId={AppStore.user?.vendor?.id}/>
        </div>
    )
}

export default RiskFindingsContainer;