export default class StrapiUtils {
  static tableSortToSortString(
    field: string,
    sortString?: "ascend" | "descend"
  ) {
    let curSortString
    if (sortString === "ascend") {
      curSortString = "ASC"
    } else if (sortString === "descend") {
      curSortString = "DESC"
    }

    if (sortString) return `${field}:${curSortString}`
    else return undefined
  }
}
