import React, {useEffect, useState} from 'react';
import qs from 'qs';

import './styles.scss';

import {Drawer, Input, Modal, Table} from "antd";
import AssessmentAPI from "../../../api/Assessment/AssessmentAPI";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";
import {AssessmentTableConfig} from "./AssessmentTableConfig";
import AssessmentFilters from "../AssessmentFilters/AssessmentFilters";
import StrapiUtils from "../../../global/utils/StrapiUtils";
import {IUser} from "../../../api/User/UserTypes";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import AssessmentFormDrawer from "../AssessmentForm/AssessmentFormDrawer";
import EditAssessmentForm from "../EditAssessmentForm/EditAssessmentForm";
import {AssessmentFilterFormTypes} from "../AssessmentFilters/Types";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import Button from "../../common/Button/Button";
import {CloseOutlined, DeleteOutlined} from "@ant-design/icons";


interface Props {
    user?: IUser
    vendorId?: number
    clientId?: number,
    portfolioMode?: boolean
}

function AssessmentTable(props: Props) {
    const {user, vendorId, clientId, portfolioMode} = props;
    const [assessments, setAssessments] = useState<IAssessment[]>();
    const [curPage, setCurPage] = useState(1);
    const [numAssessments, setNumAssessments] = useState<number>();
    const [curQuery, setCurQuery] = useState<string>();
    const [curAssessment, setCurAssessment] = useState<IAssessment>();
    const [curDeleteAssessment, setCurDeleteAssessment] = useState<IAssessment>();
    const [deletingAssessment, setDeletingAssessment] = useState(false);
    const [input, setInput] = useState();

    let limitAssessments = 25;

    async function getAssessments(params?: any, qs?: string) {
        let curParams: any = {
            _start: (curPage - 1) * limitAssessments,
            _limit: limitAssessments,
        }
        if (params) curParams = {...curParams, ...params};
        if (vendorId) curParams.vendor = vendorId;
        if (clientId) curParams.client = clientId;
        let curAssessments = await AssessmentAPI.getAssessments(curParams, qs);
        if (curAssessments) setAssessments(curAssessments);

        let count = await AssessmentAPI.getAssessmentCount(curParams, qs);
        if (count) setNumAssessments(count);
    }

    async function deleteAssessment(assessmentId: number) {
        let deleted = await AssessmentAPI.deleteAssessment(assessmentId);
        if (deleted) await getAssessments();
    }

    useEffect(() => {
        getAssessments({_sort: 'due_date:DESC'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSubmitEditAssessment() {
        setCurAssessment(undefined);
        getAssessments();
    }

    function onAssessmentEditClick(assessmentId: number) {
        if (!assessments) return;
        let filtered = assessments.filter(assessment => assessment.id === assessmentId);
        if (filtered.length === 1) {
            setCurAssessment(filtered[0])
        }
    }

    async function onFiltersSubmit(values: any) {
        let toArray = Object.keys(values).map(key => {
            if (key === 'title') {
                return {[`${key}_contains`]: values[key]}
            } else {
                return {[key]: values[key]};
            }
        });
        let query = qs.stringify({
            _where: toArray
        })

        setCurQuery(query);

        resetPagination();
        await getAssessments(undefined, query);
    }

    function resetPagination() {
        setCurPage(1);
    }

    async function onPageChange(page:number, sorter:any) {
        setCurPage(page);
        let params:any = {
            _start: (page - 1) * limitAssessments
        }
        let sortString = StrapiUtils.tableSortToSortString(sorter.field, sorter.order);
        if (sortString) {
            params._sort = sortString;
        }
        await getAssessments(params, curQuery);
    }

    let pagination = {
        current: curPage,
        total: numAssessments,
        pageSize: limitAssessments,
        showTotal: (total:number, range:number[]) => `${range[0]}-${range[1]} of ${total} items`,
    }

    async function onSort(sorter:any, page:number) {
        let sortString = StrapiUtils.tableSortToSortString(sorter.field, sorter.order);
        let params:any = {_start: (page - 1) * limitAssessments};
        if (sortString) {
            params._sort = sortString;
        }
        await getAssessments(params)
    }

    async function onTableChange(pagination:any, filters:any, sorter:any, extra:any) {
        switch (extra.action) {
            case "paginate":
                await onPageChange(pagination.current, sorter)
                break;
            case "sort":
                await onSort(sorter, pagination.current);
                break;
            case "filter":
                break;
        }
    }

    function disabledFilters() {
        if (user) {
            if (StrapiPermissionsUtil.isVendorUser(user) || portfolioMode) {
                return [AssessmentFilterFormTypes.Vendor, AssessmentFilterFormTypes.Client];
            } else if (StrapiPermissionsUtil.isClientUser(user)) {
                let disabled = [AssessmentFilterFormTypes.Client]
                if (vendorId) {
                    disabled.push(AssessmentFilterFormTypes.Vendor);
                }
                return disabled;
            }
        }
    }

    async function onDeleteAssessmentInternal() {
        setDeletingAssessment(true);
        if (curDeleteAssessment) {
            await deleteAssessment(curDeleteAssessment.id);
        }
        setDeletingAssessment(false);
        setCurDeleteAssessment(undefined);
    }

    function onDeleteAssessmentClick(assessment: IAssessment) {
        setCurDeleteAssessment(assessment);
    }

    function onInputChange(e: any) {
        setInput(e.target.value);
    }


    if (!assessments) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading assessments...'/>
            </div>
        )
    }

    return (
        <div className='assessment-table'>
            <div className='assessment-table-header'>
                <div className='title'>
                    Assessments
                </div>

                <AssessmentFormDrawer asButton onSubmit={getAssessments}
                                      shouldHide={!StrapiPermissionsUtil.isCorlAdminOrEmployee(user) || portfolioMode}/>
            </div>

            <div className='filters'>
                <AssessmentFilters onSubmit={onFiltersSubmit} disabledFilters={disabledFilters()}/>
            </div>
            <div className='assessment-table-content'>
                <Table rowKey='id' dataSource={assessments}
                       columns={StrapiPermissionsUtil.isCorlAdminOrEmployee(user) ? AssessmentTableConfig(onAssessmentEditClick, onDeleteAssessmentClick) : AssessmentTableConfig(null, null)}
                       pagination={pagination} onChange={onTableChange}/>
            </div>
            <Drawer title='Edit Assessment' width='50%' keyboard={false} open={!!curAssessment}
                    destroyOnClose onClose={() => setCurAssessment(undefined)}
                    footer={null} className='assessment-form-drawer'>
                <EditAssessmentForm applyDrawerStyles assessment={curAssessment}
                                    onSubmit={onSubmitEditAssessment}/>
            </Drawer>
            <Modal open={!!curDeleteAssessment}
                   title='Delete Assessment'
                   bodyStyle={{padding: 24}}
                   className='vendor-file-widget-modal'
                   centered
                   width={600}
                   onCancel={() => setCurDeleteAssessment(undefined)}
                   destroyOnClose
                   footer={
                       <div className='upload-modal-footer'>
                           <Button key="back" color='clear' onClick={() => setCurDeleteAssessment(undefined)}
                                   style={{marginRight: 12}}
                                   icon={<CloseOutlined/>}>
                               Cancel
                           </Button>
                           <Button key="submit" color="red"
                                   enabled={input?.toLowerCase() === curDeleteAssessment?.title.toLowerCase()}
                                   icon={<DeleteOutlined/>}
                                   loading={deletingAssessment} onClick={onDeleteAssessmentInternal}>
                               I Understand, Delete
                           </Button>
                       </div>
                   }>
                <div className='user-table-delete-modal-content'>
                    <div>You are about to delete <b>{curDeleteAssessment?.title}</b>. Users across the platform will no
                        longer be able to see this assessment. However, the questionnaires, responses, and response
                        files will remain intact. If you would like to delete questionnaires under this assessment,
                        please delete them before deleting this assessment. You can also simply change the status of the assessment
                        to canceled instead of deleting it. Enter the tile of the assessment <b>{curDeleteAssessment?.title}</b> below to confirm the deletion.
                    </div>
                    <Input size='large' onChange={onInputChange} style={{marginTop: 9}}
                           placeholder={curDeleteAssessment?.title}/>
                </div>
            </Modal>
        </div>
    )
}

export default AssessmentTable;