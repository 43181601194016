import React, {useEffect, useState} from 'react';
import {Divider, Input, Select} from 'antd';

import './styles.scss';

import Button from "../../common/Button/Button";
import {PlusOutlined} from "@ant-design/icons";
import ProductAPI from "../../../api/Product/ProductAPI";
import {IProduct} from "../../../api/Product/ProductTypes";

const {Option} = Select;

interface Props {
    onChange?: (type: string) => void,
    loading?: boolean,
    className?: string,
    option?: number
    products?: IProduct[],
    disabled?: boolean,
    vendorId:number,
    value?:any,
}

function ProductSelect(props: Props) {
    const {className, onChange, loading, option, disabled, vendorId, value} = props;
    const [products, setProducts] = useState<IProduct[]>([]);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [productName, setProductName] = useState<string>();

    async function getProducts() {
        if(!vendorId) return;
        setLoadingProducts(true);
        let products = await ProductAPI.getProductsByVendorId(vendorId);
        if (products) {
            setProducts(products);
        }
        setLoadingProducts(false);
    }

    useEffect(()=> {
        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vendorId])

    async function onAddProductClick(){
        if (!productName) {
            return null;
        } else {
            let product = await ProductAPI.createProduct({name: productName, vendor:vendorId});
            if (product){
                setProducts([...products, product]);
                setProductName(undefined);
            }
        }
    }

    function renderDropdown(menu) {
        return (
            <div>
                {menu}
                <Divider style={{margin: '4px 0'}}/>
                <div className='product-select-add-product'>
                    <Input size='large' placeholder='Product Name' value={productName} onChange={e => setProductName(e.target.value)}/>
                    <Button style={{marginLeft: 12}} icon={<PlusOutlined/>} onClick={onAddProductClick}>
                        Add Product
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Select className={className} size='large' optionLabelProp='children' placeholder='Choose Product'
                defaultValue={option} onSelect={onChange as any} loading={loading || loadingProducts} dropdownRender={renderDropdown}
                disabled={loading || disabled || loadingProducts} value={value}>
            {products.map(product =>
                <Option value={product.id} key={product.id}>
                    {product.name}
                </Option>
            )}
        </Select>
    )
}

export default ProductSelect;