import React, {useState} from 'react';
import {Form} from "antd";
import cx from "classnames";
import {SearchOutlined} from '@ant-design/icons';

import './styles.scss';

import VendorSelect from "../../VendorSelect/VendorSelect";
import Button from "../../../common/Button/Button";
import {RiskFindingFilterFormTypes} from "./Types";
import RiskFindingStatusSelect from '../../StatusSelect/RiskFindingStatusSelect';

interface Props {
    onSubmit?: (values: any) => void,
    disabledFilters?: RiskFindingFilterFormTypes[]
}

function RiskFindingFilters(props: Props) {
    const {onSubmit, disabledFilters} = props;
    const [form] = Form.useForm();
    const [submittingForm, setSubmittingForm] = useState(false);
    const {Vendor, Status} = RiskFindingFilterFormTypes;

    async function onFormSubmit(values: any) {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && onSubmit) {
                onSubmit(values);
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    const formProps = {
        hideRequiredMark: false,
        colon: false,
        className: cx('risk-finding-filters-form')
    };

    function isDisabled(name: RiskFindingFilterFormTypes) {
        return disabledFilters && disabledFilters.indexOf(name) !== -1;
    }

    return (
        <div className='risk-finding-filters-form-wrapper'>
            <Form {...formProps} onFinish={onFormSubmit} layout='vertical' form={form}>
                {!isDisabled(Vendor) &&
                <Form.Item name={Vendor}>
                    <VendorSelect/>
                </Form.Item>}
                {!isDisabled(Status) &&
                <Form.Item name={Status}>
                    <RiskFindingStatusSelect/>
                </Form.Item>}
                <Form.Item>
                    <Button htmlType='submit' icon={<SearchOutlined/>}>
                        Search
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default RiskFindingFilters;