import { IQuestion } from "../../api/Question/QuestionTypes"
import { IResponse } from "../../api/Response/ResponseTypes"
import { RESPONSE_CHOICES } from "../../constants"

export default class QuestionnaireUtils {
  static isCommentRequired(responseChoice?: string) {
    if (!responseChoice) return false
    return (
      responseChoice === RESPONSE_CHOICES.NA ||
      responseChoice === RESPONSE_CHOICES.Partial
    )
  }

  static isQuestionComplete(
    response?: IResponse,
    question?: IQuestion,
    approvalRequired?: boolean,
    isKeyControl?: boolean
  ) {
    if (!response) return false
    if (response.response_choice === null) return false

    let questionComplete = true

    // Check for vendor approval
    if (response.previous_response && approvalRequired)
      questionComplete = response.approved

    // Check for comment on n/a or partial
    if (
      (response.response_choice === RESPONSE_CHOICES.NA ||
        response.response_choice === RESPONSE_CHOICES.Partial) &&
      (!response.response_text || response?.response_text?.trim() === "")
    )
      questionComplete = false

    // Check for evidence required
    if (
      question &&
      isKeyControl &&
      response.response_choice === RESPONSE_CHOICES.Yes
    ) {
      if (response.response_files && response.response_files.length <= 0)
        questionComplete = false
    }

    return questionComplete
  }
}
