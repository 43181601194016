import React from 'react';
import { ERiskReductionImpact, IRiskFinding } from '../../../../api/RiskFinding/RiskFindingTypes';
import { Badge } from 'antd';
import { StringUtils } from '../../../../global/utils/string';

interface Props {
    riskFinding: IRiskFinding,
}

function RiskReductionBadge({riskFinding}: Props) {

    function getColor() {
        switch(riskFinding.risk_reduction_impact)
        {
            case(ERiskReductionImpact.Low):
                return '#16c784';
            case(ERiskReductionImpact.Medium):
                return '#e7872b';
            case(ERiskReductionImpact.High):
                return '#ff203c';
            default:
                return '';
        }
    }

    return (
        <div className='risk-reduction-badge'>
            <Badge color={getColor()} count={StringUtils.capitalize(riskFinding.risk_reduction_impact)} style={{fontWeight: 600}}> </Badge>
        </div>
    )
}

export default RiskReductionBadge;