import React, {useEffect, useState} from 'react';

import './styles.scss';

import {Table} from "antd";
import {VendorFilesTableConfig} from "./VendorFilesTableConfig";
import VendorFileAPI from '../../../api/VendorFile/VendorFileApi';
import {IVendorFile} from '../../../api/VendorFile/VendorFileTypes';
import FileUpload from "../../common/FileUpload/FileUpload";
import AppStore from "../../../stores/App/AppStore";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {notification} from "antd";
import {ERROR_TOAST_DURATION} from "../../../constants";

interface Props {
    vendorId?: number,
    disableFilters?: boolean,
    disableHeader?: boolean,
    disableActions?: boolean,
    onClickRow?:(vendorFile:IVendorFile, vendorId?:number)=>void,
}

function VendorFilesTable(props: Props) {
    let {vendorId, disableHeader, onClickRow, disableActions} = props;
    const [vendorFiles, setVendorFiles] = useState<IVendorFile[]>();
    const [curPage, setCurPage] = useState<number>(1);
    const [numVendorFiles, setNumVendorFiles] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    const vendorFileLimit = 10;

    async function getFiles(params?: any) {
        if (!vendorId) return;
        setLoading(true);

        let curParams = {
            _limit: vendorFileLimit,
            _start: (curPage - 1) * vendorFileLimit,
            _sort: 'created_at:DESC',
            voided_on_null: true
        }
        if (params) {
            curParams = {...curParams, ...params};
        }

        let curVendorFiles = await VendorFileAPI.getVendorFilesByVendorId(vendorId, curParams);
        if (curVendorFiles) setVendorFiles(curVendorFiles);

        let numVendorFiles = await VendorFileAPI.getVendorFileCountByVendorId(vendorId, curParams);
        setNumVendorFiles(numVendorFiles);
        setLoading(false);
    }

    useEffect(() => {
        getFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onRow(record: any) {
        return {
            onClick: (e: any) => onClickRowInternal(record),
        }
    }

    async function onClickRowInternal(vendorFile: IVendorFile) {
        if (onClickRow) onClickRow(vendorFile, vendorId);
    }

    async function onTableChange(pagination: any, filter: any, sorter: any, extra: any) {
        setLoading(true);
        if (extra.action === 'paginate') {
            setCurPage(pagination.current);
            await getFiles({_start: (pagination.current - 1) * vendorFileLimit})
        }
        setLoading(false);
    }

    async function onDeleteFile(e:any, vendorFile: IVendorFile)
    {
        e.stopPropagation();
        setLoading(true);
        try {
            await VendorFileAPI.deleteById(vendorFile.id);
            getFiles();
        } catch (error) {
            notification.error({
                message: 'Could not delete file',
                description:
                    <div>
                        <span>Please contact support.</span>.
                    </div>,
                duration: ERROR_TOAST_DURATION,
            })   
        }
        setLoading(false);
    }

    let pagination = {
        pageSize: vendorFileLimit,
        total: numVendorFiles,
        current: curPage,
        showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
    }


    if (!vendorFiles) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading vendor files...'/>
            </div>
        )
    }

    return (
        <div className='vendor-files-table'>
            {!disableHeader &&
            <div className='vendor-files-table-header'>
                <div className='title'>
                    Files
                </div>
                {(StrapiPermissionsUtil.isVendorUser(AppStore.user) || StrapiPermissionsUtil.isCorlAdmin(AppStore.user)) &&
                <FileUpload vendorId={vendorId} onFilesUploaded={getFiles}/>}
            </div>}
            <div className='vendor-files-table-content'>
                <Table rowKey='id' dataSource={vendorFiles} columns={VendorFilesTableConfig(onDeleteFile, disableActions, loading)}
                       pagination={pagination} onChange={onTableChange}
                       onRow={onRow}/>
            </div>
        </div>

    )
}

export default VendorFilesTable;