import axios from "axios";
import { IQuestionnaire } from "../Questionnaire/QuestionnaireTypes";
import {IResponse} from "./ResponseTypes";

export default class ResponseAPI {

    static async getResponsesForQuestionnaire(questionnaireId:number){
        try{
            let res = await axios.get(`/questionnaires/${questionnaireId}/responses`);
            return res.data as IResponse[];
        } catch (e:any){
            console.log('Could not get control responses for assessment', e.message);
            return undefined;
        }
    }

    static async saveControlResponse(questionId:number, questionnaire:IQuestionnaire, data: { response_choice:string, response_text:string, responseId?:number } ){
        if(!questionnaire) return undefined;
        if (data.responseId){ // Save existing response
            try{
                let res = await axios.put(`/questionnaires/${questionnaire.id}/responses/${data.responseId}`, {control_question: questionId, ...data});
                return res.data;
            } catch (e:any){
                console.error('Could not update control response', e.message);
                return undefined;
            }
        } else { // Create new response
            try{
                let curData = {control_question:questionId, questionnaire: questionnaire.id, implementation: questionnaire.implementation.id, product: questionnaire.product.id, ...data}
                let res = await axios.post(`/questionnaires/${questionnaire.id}/responses`, curData);
                return res.data;
            } catch (e:any){
                console.error('Could not update control response', e.message);
                return undefined;
            }
        }
    }

    static async approveControlResponse(questionnaireId:number, data: { responseId:number, approved:boolean } ) {
        try{
            let res = await axios.put(`/questionnaires/${questionnaireId}/responses/${data.responseId}`, data);
            return res.data;
        } catch (e:any){
            console.error('Could not update control response', e.message);
            return undefined;
        }
    }

    static async getControlResponse(questionnaireId:number, responseId:number){
        try{
            let res = await axios.get(`/questionnaires/${questionnaireId}/responses/${responseId}`);
            return res.data as IResponse;
        } catch (e:any){
            console.log('Could not get control responses for assessment', e.message);
            return undefined;
        }
    }

    static async deleteResponseFile(questionnaireId:number, responseFileId:number){
        try{
            await axios.delete(`/questionnaires/${questionnaireId}/response-files/${responseFileId}`);
            return true;
        } catch (e:any){
            console.log('Could not delete attachment', e.message);
            return undefined;
        }
    }

    static async setSkipEvidence(questionnaireId:number, data: { responseId:number, skip_evidence:boolean } ) {
        try{
            let res = await axios.put(`/questionnaires/${questionnaireId}/responses/${data.responseId}`, data);
            return res.data;
        } catch (e:any){
            console.error('Could not update control response', e.message);
            return undefined;
        }
    }
}