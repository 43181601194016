import React, {useState} from 'react';

import './styles.scss';
import {Input, Modal, Table} from "antd";
import {IUser} from "../../../api/User/UserTypes";
import {UserTableConfig} from "./UserTableConfig";
import Button from "../../common/Button/Button";
import {CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import UserAPI from "../../../api/User/UserAPI";
import {notification} from "antd";
import {TOAST_DURATION, ERROR_TOAST_DURATION} from "../../../constants";

interface Props {
    users?: IUser[]
    onSearch?: (text: string) => void,
    onDeleteUser?: (userId: number) => void,
}

function UserTable(props: Props) {
    const {users, onSearch, onDeleteUser} = props;
    const [curDeleteUser, setCurDeleteUser] = useState<IUser>();
    const [input, setInput] = useState();
    const [deletingUser, setDeletingUser] = useState(false);

    function onSearchInternal(e: any) {
        if (onSearch) onSearch(e.target.value)
    }

    function onDeleteUserInternal() {
        setDeletingUser(true);
        if (onDeleteUser && curDeleteUser) onDeleteUser(curDeleteUser.id);
        setDeletingUser(false);
        setCurDeleteUser(undefined);
    }

    function onDeleteUserClick(user: IUser) {
        setCurDeleteUser(user);
    }

    function onInputChange(e: any) {
        setInput(e.target.value);
    }

    async function reinviteUser(user: IUser) {
        try {
            await UserAPI.reinviteUser(user.email);
            notification.success({
                message: 'Success!',
                description: <span>You have successfully reinvited <b>{user.email}</b>. They should see an invitation in their email within the next few minutes.</span>,
                duration: TOAST_DURATION,
            })
        } catch (error) {
            notification.error({
                message: 'Error sending invitation!',
                description:
                    <div>
                        <span>Unfortunately, we were not able to reinvite <b>{user.email}</b></span>.
                        <div>Error: Invitation email failed to send</div>
                    </div>,
                duration: ERROR_TOAST_DURATION,
            })
        }
    }

    if (!users) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading users...'/>
            </div>
        )
    }

    return (
        <div className='user-table'>
            <Input size='large' placeholder='Search Users by Email' style={{marginBottom: 12}}
                   onChange={onSearchInternal}/>
            <Table rowKey='id' dataSource={users}
                   columns={UserTableConfig(reinviteUser, onDeleteUser ? onDeleteUserClick : undefined)}/>
            <Modal open={!!curDeleteUser}
                   title='Delete User'
                   bodyStyle={{padding: 24}}
                   className='vendor-file-widget-modal'
                   centered
                   width={600}
                   onCancel={() => setCurDeleteUser(undefined)}
                   destroyOnClose
                   footer={
                       <div className='upload-modal-footer'>
                           <Button key="back" color='clear' onClick={() => setCurDeleteUser(undefined)}
                                   style={{marginRight: 12}}
                                   icon={<CloseOutlined/>}>
                               Cancel
                           </Button>
                           <Button key="submit" color="red" enabled={input?.toLowerCase() === curDeleteUser?.email}
                                   icon={<DeleteOutlined/>}
                                   loading={deletingUser} onClick={onDeleteUserInternal}>
                               I Understand, Delete
                           </Button>
                       </div>
                   }>
                <div className='user-table-delete-modal-content'>
                    <div>You are about to delete <b>{curDeleteUser?.email}</b>. All of their information will be
                        irretrievable, and they will no longer be able to login or interact with any data. Enter the
                        email <b>{curDeleteUser?.email}</b> below to confirm the deletion.
                    </div>
                    <Input size='large' onChange={onInputChange} style={{marginTop: 9}}
                           placeholder={curDeleteUser?.email}/>
                </div>
            </Modal>
        </div>
    )
}

export default UserTable;