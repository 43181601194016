import React, { useEffect, useState } from "react"

import {
  CheckCircleOutlined,
  LoadingOutlined,
  CheckOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons"
import BrowserRouter, { BrowserRoutes } from "../../../stores/App/BrowserRouter"
import { TOAST_DURATION } from "../../../constants"

import { Progress, Tabs, Tooltip, notification } from "antd"
import { Sticky, StickyContainer } from "react-sticky-17"

import "./styles.scss"

import Button from "../../common/Button/Button"
import { IQuestion } from "../../../api/Question/QuestionTypes"
import { IResponse } from "../../../api/Response/ResponseTypes"
import ResponseAPI from "../../../api/Response/ResponseAPI"
import {
  IQuestionnaire,
  EQuestionnaireStatus,
} from "../../../api/Questionnaire/QuestionnaireTypes"
import { ITemplate } from "../../../api/Template/TemplateTypes"
import TemplateAPI from "../../../api/Template/TemplateAPI"
import { ITemplateItem } from "../../../api/TemplateItem/TemplateItemTypes"
import TemplateItemAPI from "../../../api/TemplateItem/TemplateItemAPI"
import Question from "../Question/Question"
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI"
import AssessmentBreadcrumb from "../AssessmentBreadcrumb/AssessmentBreadcrumb"
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader"
import ClearDruFormDrawer from "./ClearDruDrawer"
import AppStore from "../../../stores/App/AppStore"
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil"
import QuestionnaireUtils from "../../../global/utils/questionnaireUtils"

interface Props {
  questionnaire: IQuestionnaire
}

function Questionnaire(props: Props) {
  const { questionnaire } = props
  const [responses, setResponses] = useState<Record<number, IResponse>>()
  const [controlsByCategory, setControlsByCategory] = useState<
    Record<string, IQuestion[]>
  >({})
  const [controlsById, setControlsById] = useState<Record<number, IQuestion>>(
    {}
  )
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [submittingQuestionnaire, setSubmittingQuestionnaire] = useState(false)
  const [template, setTemplate] = useState<ITemplate>()
  const [clearDRUVisible, setClearDRUVisible] = useState<boolean>(
    (questionnaire.data_reuse_occurred &&
      questionnaire.status === EQuestionnaireStatus.InProgress) ||
      false
  )
  const [tabKey, setTabKey] = useState(0)
  const [isDataReuseApprovalRequired] = useState(
    questionnaire.client.data_reuse_type === "approval"
  )

  async function getTemplate() {
    let template = await TemplateAPI.getQuestionnaireTemplateQuestions(
      questionnaire.assessment.id,
      questionnaire.id
    )
    if (template) {
      setTemplate(template)
      let curQuestions = template.control_questions
      let keyControls = template.key_controls
      // We need a way to differentiate between a key control and a regular control question, so we are going to
      // attach an arbitrary property to show that it is a key control.
      if (keyControls) {
        keyControls = keyControls.map((keyControl) => ({
          ...keyControl,
          isKeyControl: true,
        }))
        curQuestions = [...curQuestions, ...keyControls]
      }
      setQuestions(curQuestions)
      let responses = await getResponsesForQuestionnaire()
      let templateItems: any =
        await TemplateItemAPI.getTemplateItemsByTemplateId(template.id)
      getControlsByCategory(curQuestions, responses, templateItems)
    }
  }

  async function getResponsesForQuestionnaire() {
    let responses = await ResponseAPI.getResponsesForQuestionnaire(
      questionnaire.id
    )
    if (responses) {
      let ret: any = {}
      responses.forEach((response) => {
        ret[response.control_question.id] = response
      })
      setResponses(ret)
      return ret
    }
  }

  function getControlsByCategory(
    controls: IQuestion[],
    responses: Record<number, IQuestion>,
    templateItems: ITemplateItem[]
  ) {
    let curControlsByCategory: any = {}
    let controlsById: Record<number, IQuestion> = {}
    //sort controls by template item sort order
    if (templateItems) {
      let sortedControlIds = templateItems.map((templateItem) => {
        return templateItem.control_question.id
      })
      controls.sort(function (a, b) {
        return sortedControlIds.indexOf(a.id) - sortedControlIds.indexOf(b.id)
      })
    }
    if (!responses) return null
    controls.forEach((control) => {
      let controlName = control.control_group
      if (!controlName) {
        controlName = "General"
      }
      let disabled = false
      control.control_dependencies.forEach((dep) => {
        const { dependency_text, depends_on } = dep
        let response = responses[depends_on]
        if (
          !response ||
          response?.response_choice === null ||
          response.response_choice?.toLowerCase() !==
            dependency_text.toLowerCase()
        ) {
          disabled = true // do not display dependent control question
        }
      })
      if (!disabled) {
        if (!curControlsByCategory[controlName]) {
          curControlsByCategory[controlName] = []
        }
        controlsById[control.id] = control
        curControlsByCategory[controlName].push(control)
      }
    })
    setControlsByCategory(curControlsByCategory)
    setControlsById(controlsById)
  }

  function tabIsCompleted(questions: IQuestion[]) {
    if (!responses) return false
    return questions.every((q) => {
      if (!responses[q.id]) return false
      return QuestionnaireUtils.isQuestionComplete(
        responses[q.id],
        q,
        isDataReuseApprovalRequired,
        q.isKeyControl
      )
    })
  }

  function getTabs() {
    return Object.keys(controlsByCategory).map((category, index) => {
      let controls = controlsByCategory[category]
      let completed = tabIsCompleted(controls)

      let tab: any = category
      if (completed) {
        tab = (
          <Tooltip title="All questions in this section have been answered.">
            <div className="completed-tab">
              <CheckOutlined />
              {category}
            </div>
          </Tooltip>
        )
      }
      return {
        key: index.toString(),
        label: tab,
        children: (
          <div className="tab-pane">
            {getTabContent(controls)}
            <div className="tab-footer">
              {index !== Object.keys(controlsByCategory).length - 1 && (
                <Button
                  onClick={() => setTabKey(tabKey + 1)}
                  color={"green"}
                  loading={submittingQuestionnaire}
                  icon={<ArrowRightOutlined />}
                  iconPosition="right"
                >
                  Next Section
                </Button>
              )}
            </div>
          </div>
        ),
      }
    })
  }

  function getTabContent(questions: IQuestion[]) {
    return questions.map((control: IQuestion, i: number) => {
      let disabled =
        questionnaire.status !== EQuestionnaireStatus.InProgress || isSaving
      // control.isKeyControl = template?.key_controls?.some(
      //   (c) => c.id.toString() === control.id.toString() && c.isKeyControl
      // )
      return (
        <Question
          key={control.id}
          num={i}
          questionnaire={questionnaire}
          disabled={disabled}
          question={control}
          response={responses?.[control.id]}
          vendorId={questionnaire.vendor.id}
          approvalRequired={isDataReuseApprovalRequired}
          onChange={onChange}
          onSave={onSave}
        />
      )
    })
  }

  async function onLoad() {
    await getTemplate()
    await getResponsesForQuestionnaire()
  }

  useEffect(() => {
    onLoad()
    //getMessageThread();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaire])

  function onChange(response: IResponse) {
    if (!response) return
    let newResponses: any = {
      ...responses,
      [response.control_question.id]: response,
    }
    //set empty array to satisfy expectation for templateItem parameter for getControlsByCategory; does not need to be populated for function to work as expected
    let templateItems: any[] = []
    getControlsByCategory(questions, newResponses, templateItems)
    setResponses(newResponses)
  }

  function onSave(time: "start" | "end") {
    switch (time) {
      case "start":
        setIsSaving(true)
        break
      case "end":
        setIsSaving(false)
    }
  }

  function enableSubmitButton() {
    if (isSaving) return false
    if (questionnaire.status !== EQuestionnaireStatus.InProgress) return false

    let tabsAreCompleted = true

    Object.values(controlsByCategory).forEach((controls) => {
      if (!tabIsCompleted(controls)) tabsAreCompleted = false
    })

    let percent = getPercentageComplete()
    return percent >= 100 && tabsAreCompleted
  }

  async function onSubmitQuestionnaire() {
    setSubmittingQuestionnaire(true)
    let curQuestionnaire = await QuestionnaireAPI.submitQuestionnaire(
      questionnaire.id,
      EQuestionnaireStatus.Submitted
    )
    if (curQuestionnaire) {
      notification.success({
        message: "Success!",
        description: (
          <span>
            Your questionnaire {questionnaire.title} has been successfully
            submitted. Thank you!
          </span>
        ),
        duration: TOAST_DURATION,
      })
      BrowserRouter.push(
        BrowserRoutes.getAssessmentById(questionnaire.assessment.id)
      )
    } else {
      let message = `We were unable to submit your questionnaire. Please contact support for more information.`
      if (StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user))
        message =
          "CORL users cannot submit Questionnaires, only Vendor users can submit."
      notification.error({
        message: "Error submitting Questionnaire!",
        description: <span>{message}</span>,
        duration: TOAST_DURATION,
      })
      setSubmittingQuestionnaire(false)
    }
  }

  function getAnsweredQuestions() {
    if (responses) {
      let filtered = Object.values(responses).filter((response) => {
        if (
          !QuestionnaireUtils.isQuestionComplete(
            response,
            response.control_question,
            isDataReuseApprovalRequired,
            controlsById[response.control_question.id]?.isKeyControl
          )
        ) {
          return false
        } else {
          return !!controlsById[response.control_question.id]
        }
      })
      return filtered.length
    }
    return 0
  }

  function getTotalNumQuestions() {
    let total = 0
    Object.values(controlsByCategory).forEach((val) => (total += val.length))
    return total
  }

  function getPercentageComplete() {
    let answered = getAnsweredQuestions()
    let total = getTotalNumQuestions()
    return Math.round((answered / total) * 100)
  }

  const renderTabBar = (props: any, DefaultTabBar: any) => (
    <Sticky bottomOffset={160}>
      {({ style }) => (
        <DefaultTabBar
          {...props}
          className="site-custom-tab-bar"
          style={{ ...style }}
        />
      )}
    </Sticky>
  )

  function renderSavingStatus() {
    switch (isSaving) {
      case true:
        return (
          <div className="saving-status saving">
            <LoadingOutlined spin />
            Saving assessment...
          </div>
        )
      case false:
        return (
          <div className="saving-status success">
            <Tooltip title="Assessment has been saved." placement="left">
              <CheckCircleOutlined style={{ fontSize: 24 }} />
            </Tooltip>
          </div>
        )
    }
  }

  if (!template || !responses) {
    return (
      <div className="default-loader-full">
        <DefaultLoader text="Loading questionnaire..." />
      </div>
    )
  }

  async function onClearDRU(values: any) {
    notification.success({
      message: "Clearing your pre-answered responses.",
      description: (
        <span>
          This may take a few moments. Please do not refresh the page.
        </span>
      ),
      duration: TOAST_DURATION,
    })

    setIsSaving(true)
    var success = await QuestionnaireAPI.clearDRUResponses(
      questionnaire.id,
      values
    )
    if (success) {
      await getResponsesForQuestionnaire()
      setClearDRUVisible(false)
    } else {
      notification.error({
        message: "Error clearing your pre-answered responses.",
        description: (
          <span>
            Please contact support for more information. In the meantime, you
            may continue to fill out this questionnaire by overwriting the
            current responses.
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }
    setIsSaving(false)
  }

  return (
    <div className="questionnaire">
      <div className="stats">
        <div className="stats-content">
          <div className="stats-content-top">
            <AssessmentBreadcrumb
              assessment={questionnaire.assessment}
              questionnaire={questionnaire}
            />
          </div>
          <div className="stats-content-bottom">
            <div className="questions-answered">
              <div className="answered-length">
                {getAnsweredQuestions()}/{getTotalNumQuestions()} Questions
                Answered
              </div>
              <Progress
                style={{ minWidth: 150 }}
                percent={getPercentageComplete()}
                size="small"
                trailColor="#c5c5c5"
                strokeColor="#16c784"
              />
            </div>
            <div className="buttons">
              {clearDRUVisible && (
                <ClearDruFormDrawer
                  asButton={true}
                  onSubmit={onClearDRU}
                  style={{ backgroundColor: "#999999" }}
                ></ClearDruFormDrawer>
              )}
              <Button
                onClick={onSubmitQuestionnaire}
                loading={submittingQuestionnaire}
                icon={<ArrowRightOutlined />}
                iconPosition="right"
                enabled={enableSubmitButton()}
                color="green"
              >
                Submit Questionnaire
              </Button>
            </div>
          </div>
        </div>
      </div>
      <StickyContainer style={{ width: "100%" }}>
        <Tabs
          activeKey={tabKey.toString()}
          renderTabBar={renderTabBar}
          onChange={(key) => setTabKey(Number.parseInt(key))}
          items={getTabs()}
        />
      </StickyContainer>
      <div className="questionnaire-footer">{renderSavingStatus()}</div>
    </div>
  )
}

export default Questionnaire
