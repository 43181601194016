import React from 'react'
import {ExportOutlined} from '@ant-design/icons';
import {IQuestionnaire} from '../../../api/Questionnaire/QuestionnaireTypes';
import {ExportManager} from '../../../global/managers/ExportManager';
import { Tooltip } from 'antd';

interface Props {
    questionnaire: IQuestionnaire;
}

function ExportQuestionnaire(props: Props) {
    const {questionnaire} = props;

    const exportQuestionnaire = async () => {
        await ExportManager.exportQuestionnaire(questionnaire);
    }

    return (
        <div style={{textAlign: 'center'}}>
            <Tooltip title='Export'>
                <ExportOutlined onClick={() => exportQuestionnaire()}/>
            </Tooltip>
        </div>
    )
}

export default ExportQuestionnaire;