import React, { useState } from "react"
import { notification } from "antd"

import "./styles.scss"

import { OrderedListOutlined, FileDoneOutlined } from "@ant-design/icons"
import {
  ETaskStatuses,
  ETaskTypes,
  ITask,
} from "../../../../../api/Task/TaskTypes"
import { Divider, Form, Input, Radio } from "antd"
import Task from "./Task"
import RiskFindingUtils from "../../../../../global/utils/riskFindingUtils"
import { IRiskFinding } from "../../../../../api/RiskFinding/RiskFindingTypes"
import {
  ICorlClearedTaskFormValues,
  IListFormWrapper,
} from "../CorlClearedResponseForm"
import QuestionEvidence from "../../../Question/QuestionEvidence"
import { EFileUploadTypes } from "../../../../../api/File/FileTypes"
import VendorFileAPI from "../../../../../api/VendorFile/VendorFileApi"
import { IVendorFile } from "../../../../../api/VendorFile/VendorFileTypes"
import { ERROR_TOAST_DURATION } from "../../../../../constants"
import TaskFileApi from "../../../../../api/TaskFile/TaskFileApi"

interface Props {
  riskFinding?: IRiskFinding
  tasks: ITask[]
  loading?: boolean
  disabled?: boolean
  form?: any
  onSubmit?: (values: IListFormWrapper<ICorlClearedTaskFormValues>) => void
  onChange?: (
    values: ICorlClearedTaskFormValues[],
    overrideSubmit?: boolean
  ) => void
}

function TasksContainer({
  riskFinding,
  tasks,
  loading,
  disabled,
  form,
  onSubmit,
  onChange,
}: Props) {
  const inProgressTasks = tasks
    .filter(
      (task) =>
        task.status === ETaskStatuses.InProgress ||
        task.status === ETaskStatuses.Inadequate
    )
    .sort((a, b) => {
      return a.id - b.id
    })
  const [submittingForm, setSubmittingForm] = useState(false)
  const [tabKey, setTabKey] = useState("sequential")
  const [isLoading, setIsLoading] = useState(loading)
  const [selectedTask, setSelectedTask] = useState(
    form
      ? inProgressTasks?.reduce((minTask, currentTask) => {
          // Skip tasks with null sequence
          if (
            currentTask.sequence === null ||
            currentTask.sequence === undefined ||
            minTask.sequence === null ||
            minTask.sequence === undefined
          ) {
            return minTask
          }

          // Compare the sequence of current task with the minimum task
          if (minTask === null || currentTask.sequence < minTask.sequence) {
            return currentTask
          }

          return minTask
        }, inProgressTasks[0])?.id
      : null
  )
  const [formValues, setFormValues] = useState<
    IListFormWrapper<ICorlClearedTaskFormValues>
  >({
    items: tasks.map((t) => {
      return {
        id: t.id.toString(),
        attestation: t.attestation,
        text: t.text,
      }
    }),
  })

  const formDisabled = disabled || isLoading || submittingForm
  const finalTask = RiskFindingUtils.getFinalTask(tasks)

  const handleModeChange = (e) => {
    const mode = e.target.value
    setTabKey(mode)
    const newValues = { ...formValues }
    if (mode === "final" && finalTask && form) {
      setSelectedTask(finalTask.id)
      if (onChange) {
        let canSubmit = finalTask.task_files.length > 0

        onChange(
          newValues.items.filter((i) => i.id === finalTask.id.toString()),
          canSubmit
        )
      }
    } else {
      if (onChange) onChange(newValues.items)
    }
  }

  const initialValues: any = {}
  tasks.forEach((t) => {
    initialValues[`attestation-${t.id}`] = t.attestation
    initialValues[`text-${t.id}`] = t.text
  })

  async function onResponseChange(values: any) {
    if (disabled) return

    setIsLoading(true)

    const newValues = { ...formValues }
    for (const key in values) {
      const splitKey = key.split("-")
      const value = values[key]
      const field = splitKey[0]
      const taskId = splitKey[1]

      const item = newValues.items.find((i) => i.id === taskId)
      if (item) item[field] = value
    }
    setFormValues(newValues)

    if (onChange) onChange(newValues.items)

    setIsLoading(false)
  }

  async function onFinish() {
    setIsLoading(true)
    if (onSubmit) await onSubmit(formValues)
    setIsLoading(false)
  }

  async function deleteRemediationFile(taskFileId: number) {
    setSubmittingForm(true)
    await TaskFileApi.deleteTaskFile(taskFileId)
    const newValues = { ...formValues }
    if (onChange) {
      if (tabKey === "final") {
        onChange(
          newValues.items,
          finalTask?.task_files.length !== 1 &&
            finalTask?.task_files.length !== 0
        )
      } else {
        onChange(newValues.items)
      }
    }
    setSubmittingForm(false)
  }

  async function onClickVendorRow(vendorFile: IVendorFile, task: ITask) {
    setSubmittingForm(true)
    let responseFile = await VendorFileAPI.attachVendorFileToTaskFile(
      vendorFile.id,
      task.id
    )
    if (!responseFile) {
      notification.error({
        message: "Error uploading file to Task",
        description: (
          <span>
            {vendorFile.file_name} may already be attached to your Task
          </span>
        ),
        duration: ERROR_TOAST_DURATION,
      })
    }
    if (onChange) {
      const newValues = { ...formValues }
      onChange(newValues.items, true)
    }
    setSubmittingForm(false)
  }

  async function onFilesChanged() {
    if (disabled) return
    setSubmittingForm(true)
    if (onChange) onChange([])
    setSubmittingForm(false)
  }

  const getTaskContainerClass = (task: ITask) => {
    let className = "unselected-task"
    if (form) {
      if (selectedTask === task.id) {
        className = "selected-task"
      } else {
        if (task.status !== ETaskStatuses.InProgress) {
          className = "completed-task"
        }
      }
    } else {
      if (task.status !== ETaskStatuses.InProgress) {
        className = "completed-task"
      }
    }

    if (task.status === ETaskStatuses.Inadequate) {
      className = "inadequate-task"
    }

    return className
  }

  return (
    <div className="tasks-container">
      <div className="tasks-container-header">
        <strong>Next Step:</strong>
      </div>
      <div className="tasks-container-description">
        You can remediate this risk finding in any/sequential order or provide
        evidence for the Final Task only by the provided due date. Please note
        that you only get one extension if the response is inadequate for any of
        the tasks mentioned below.
      </div>
      <Radio.Group
        onChange={handleModeChange}
        value={tabKey}
        className="tasks-container-tabs"
      >
        <Radio.Button
          className="tasks-container-tab tasks-container-tab-left"
          value="sequential"
        >
          <div
            className={
              tabKey === "sequential" ? "selected-tab" : "unselected-tab"
            }
            style={{ width: "100%" }}
          >
            <div>
              <OrderedListOutlined /> Remediate in Sequential Order
            </div>
          </div>
        </Radio.Button>
        <Radio.Button
          className="tasks-container-tab tasks-container-tab-right"
          value="final"
        >
          <div
            className={
              tabKey === "sequential" ? "unselected-tab" : "selected-tab"
            }
            style={{ width: "100%" }}
          >
            <div>
              <FileDoneOutlined /> Answer Final Task Only
            </div>
          </div>
        </Radio.Button>
      </Radio.Group>
      <Divider />
      <div className="tasks-container-content">
        {tabKey === "sequential" && (
          <div className="sequential-tab">
            <div>
              <strong>Overview of Required Steps:</strong>
            </div>
            <div className="tasks">
              {tasks
                .sort((a, b) => {
                  if (a.sequence === undefined && b.sequence === undefined) {
                    return 0
                  } else if (a.sequence === undefined) {
                    return 1
                  } else if (b.sequence === undefined) {
                    return -1
                  } else {
                    return a.sequence - b.sequence
                  }
                })
                .map((t, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (form) setSelectedTask(t.id)
                      }}
                      className={getTaskContainerClass(t)}
                    >
                      <Task
                        task={t}
                        isFinal={t.id === finalTask?.id}
                        form={form}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        )}
        {tabKey === "final" && (
          <div className="final-tab">
            <div>
              <strong>Overview of Required Steps:</strong>
            </div>
            <div>
              <Task
                task={RiskFindingUtils.getFinalTask(tasks)}
                isFinal={true}
              />
            </div>
          </div>
        )}
      </div>
      {form && (
        <div className="task-container-form">
          <Form
            id={`corl-cleared-response-form-${riskFinding?.id}`}
            form={form}
            layout="vertical"
            onValuesChange={onResponseChange}
            initialValues={initialValues}
            onFinish={onFinish}
            style={{ width: "100%" }}
          >
            {tasks
              .sort((a, b) => {
                if (a.sequence === undefined && b.sequence === undefined) {
                  return 0
                } else if (a.sequence === undefined) {
                  return 1
                } else if (b.sequence === undefined) {
                  return -1
                } else {
                  return a.sequence - b.sequence
                }
              })
              .map((t) => {
                return (
                  <React.Fragment key={t.id}>
                    {t.type === ETaskTypes.Attestation &&
                      selectedTask === t.id && (
                        <>
                          <Form.Item
                            name={`attestation-${t.id}`}
                            className="task-attestation-form"
                            style={{ width: "100%" }}
                          >
                            <Radio.Group
                              disabled={
                                formDisabled ||
                                t.status === ETaskStatuses.Complete ||
                                t.status === ETaskStatuses.Submitted
                              }
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item
                            name={`text-${t.id}`}
                            className="task-comments-form"
                            style={{ width: "100%" }}
                          >
                            <Input.TextArea
                              style={{ width: "100%" }}
                              autoSize={{ minRows: 3 }}
                              disabled={
                                formDisabled ||
                                t.status === ETaskStatuses.Complete ||
                                t.status === ETaskStatuses.Submitted
                              }
                              placeholder="Add additional comments"
                            />
                          </Form.Item>
                        </>
                      )}
                    {t.type === ETaskTypes.Evidence &&
                      selectedTask === t.id && (
                        <>
                          <div className="task-evidence-form">
                            <QuestionEvidence
                              files={t.task_files}
                              entityId={t.id}
                              vendorId={t.vendor_id}
                              disabled={
                                formDisabled ||
                                t.status === ETaskStatuses.Complete ||
                                t.status === ETaskStatuses.Submitted
                              }
                              hideUpload={disabled}
                              fileUploadType={EFileUploadTypes.Task}
                              onFilesChanged={onFilesChanged}
                              onFileDelete={deleteRemediationFile}
                              onRowClick={(vendorFile) =>
                                onClickVendorRow(vendorFile, t)
                              }
                              hideHeader
                            />
                          </div>
                          <Form.Item
                            name={`text-${t.id}`}
                            className="task-comments-form"
                            style={{ width: "100%" }}
                          >
                            <Input.TextArea
                              style={{ width: "100%" }}
                              autoSize={{ minRows: 3 }}
                              disabled={
                                formDisabled ||
                                t.status === ETaskStatuses.Complete ||
                                t.status === ETaskStatuses.Submitted
                              }
                              placeholder="Add additional comments"
                            />
                          </Form.Item>
                        </>
                      )}
                  </React.Fragment>
                )
              })}
          </Form>
        </div>
      )}
    </div>
  )
}

export default TasksContainer
