import React, { useEffect, useState } from "react"
import {
  PlusOutlined,
  InboxOutlined,
  CloudUploadOutlined,
  CloseOutlined,
  LoadingOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { Alert, Modal, Tooltip, notification } from "antd"
import { UploadFile, UploadChangeParam } from "antd/es/upload/interface"
import "./styles.scss"
import Button from "../Button/Button"
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil"
import WDragger from "../WDragger/WDragger"
import FileApi from "../../../api/File/FileAPI"
import AppStore from "../../../stores/App/AppStore"
import VendorFileAPI from "../../../api/VendorFile/VendorFileApi"
import { ERROR_TOAST_DURATION, TOAST_DURATION } from "../../../constants"
import { EFileUploadTypes } from "../../../api/File/FileTypes"

interface Props {
  entityId?: number
  vendorId?: number
  fileUploadType?: EFileUploadTypes
  loading?: boolean
  disabled?: boolean
  onFilesUploaded?: () => void
}

let acceptedFileTypes = [
  "text/csv",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "image/jpeg",
  "image/png",
]

const MAX_FILE_LIMIT = 10 // Amount of files a user can upload at once

function FileUpload({
  entityId,
  vendorId,
  fileUploadType,
  loading,
  disabled,
  onFilesUploaded,
}: Props) {
  const [showUpload, setShowUpload] = useState(false)
  const [files, setFiles] = useState<UploadFile[]>([])
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const [isVendorUser] = useState(
    StrapiPermissionsUtil.isVendorUser(AppStore.user)
  )
  const [isCORLAdmin] = useState(
    StrapiPermissionsUtil.isCorlAdmin(AppStore.user)
  )
  const [validationMessage, setValidationMessage] = useState("")
  const [fileStatusMap, setFileStatusMap] = useState({})

  useEffect(() => {
    getFiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileStatusMap])

  function getFiles() {
    if (!files || files.length === 0) return null
    return (
      <div className="files">
        {files.map((file: UploadFile, i: number) => {
          const status = fileStatusMap[file.name]
          let label = <div className={"file-name"}>{file.name}</div>
          if (status?.uploadSuccessful === false && !uploadingFiles)
            label = <div className={"warning-text"}>{file.name} - Failed</div>
          return (
            <Tooltip
              key={i}
              className="file"
              title={status?.message}
              placement="right"
            >
              {label}
              {!uploadingFiles && status?.uploadSuccessful === undefined && (
                <DeleteOutlined
                  onClick={() =>
                    setFiles(files.filter((file, index) => i !== index))
                  }
                />
              )}
              {uploadingFiles && !status?.uploadSuccessful && (
                <LoadingOutlined spin />
              )}
              {status?.uploadSuccessful === true && (
                <CheckOutlined
                  onClick={() =>
                    setFiles(files.filter((file, index) => i !== index))
                  }
                />
              )}
              {!uploadingFiles && status?.uploadSuccessful === false && (
                <CloseOutlined
                  onClick={() =>
                    setFiles(files.filter((file, index) => i !== index))
                  }
                />
              )}
            </Tooltip>
          )
        })}
      </div>
    )
  }

  const getVendorId = () => {
    if (isCORLAdmin) return vendorId
    return AppStore.user?.vendor?.id
  }

  const onUploadFiles = async (e: any) => {
    e.stopPropagation()
    // must be a vendor user or CORL Admin to upload files
    if (!isVendorUser && !isCORLAdmin) return
    if (disabled) return

    setUploadingFiles(true)
    for (let i = 0; i < files?.length; i++) {
      const file = files[i]
      const status = fileStatusMap[file.name]
      if (status?.uploadSuccessful) continue

      const { isValid, message } = await validatePreUpload(file)
      if (isValid) {
        let fileUploadResponses = await FileApi.UploadFiles(
          [file.originFileObj as File],
          getVendorId() ?? 0,
          entityId,
          fileUploadType
        )
        if (
          fileUploadResponses.every(
            (fileResponse) => fileResponse && fileResponse.success
          )
        ) {
          setFileStatusMap(
            Object.assign(fileStatusMap, {
              [file.name]: {
                uploadSuccessful: true,
                message: undefined,
                uploading: false,
              },
            })
          )
          notification.success({
            message: `${file.name} successfully uploaded!`,
            duration: TOAST_DURATION,
          })
        } else {
          setFileStatusMap(
            Object.assign(fileStatusMap, {
              [file.name]: {
                uploadSuccessful: false,
                message: fileUploadResponses[0].message,
                uploading: false,
              },
            })
          )
          notification.error({
            message: `Error uploading ${file.name}`,
            //description: <span>Error: {fileUploadResponses[0].message}</span>,
            duration: ERROR_TOAST_DURATION,
          })
          setValidationMessage(
            "One or more of your files failed to upload, hover over the file name for details."
          )
        }
      } else {
        setFileStatusMap(
          Object.assign(fileStatusMap, {
            [file.name]: {
              uploadSuccessful: false,
              message: message,
              uploading: false,
            },
          })
        )
        setValidationMessage(
          "One or more of your files failed to upload, hover over the file name for details."
        )
      }
    }
    setUploadingFiles(false)
    if (onFilesUploaded) onFilesUploaded()
  }

  const enableUploadButton = () => {
    if (!isVendorUser && !isCORLAdmin) return false
    return !uploadingFiles && files.length !== 0
  }

  const enableUploadDragger = () => {
    if (!isVendorUser && !isCORLAdmin) return false
    return !uploadingFiles
  }

  function toggleUpload() {
    setShowUpload(!showUpload)
  }

  const onUploadChange = (info: UploadChangeParam) => {
    const { fileList } = info
    const filterUniqueFiles = (fileArray: UploadFile<any>[]) => {
      const uniqueFiles: UploadFile<any>[] = []
      fileArray.forEach((file: UploadFile<any>) => {
        if (!uniqueFiles.some((f) => f.name === file.name)) {
          uniqueFiles.push(file)
        }
      })
      return uniqueFiles
    }

    const files = filterUniqueFiles(fileList.slice(0, MAX_FILE_LIMIT))
    if (files.length > MAX_FILE_LIMIT) {
      setValidationMessage(
        `Please upload up to ${MAX_FILE_LIMIT} files at a time.`
      )
    }
    setFiles(files)
  }

  async function validatePreUpload(file: any) {
    // This list comes from https://docs.microsoft.com/en-us/rest/api/storageservices/naming-and-referencing-shares--directories--files--and-metadata
    // Commas aren't listed in that page but have also broken our downloads
    let invalidCharacters = [
      ",",
      ":",
      "*",
      "?",
      '"',
      "<",
      ">",
      "|",
      '"',
      "\\",
      "/",
      "#",
      "%",
    ]

    let hasInvalidCharacters = false
    invalidCharacters.forEach((character) => {
      if (file.name.includes(character)) hasInvalidCharacters = true
    })
    if (hasInvalidCharacters) {
      return {
        isValid: false,
        message: `The following characters are not allowed in file names: " \\ / : | < > * ? , # %`,
      }
    }

    if (!acceptedFileTypes.includes(file.type)) {
      return {
        isValid: false,
        message: `Only the following file types are allowed: ${acceptedFileTypes.join(
          ", "
        )}`,
      }
    }

    let fileSizeInMB = file.size / 1000000
    if (fileSizeInMB > 64) {
      return {
        isValid: false,
        message: `File size must be less than 10MB`,
      }
    }

    let existingFile = await VendorFileAPI.getVendorFilesByVendorId(
      getVendorId() ?? 0,
      { file_name: file.name, voided_on_null: true }
    )
    if (existingFile.length > 0) {
      return {
        isValid: false,
        message: `A file with the name '${file.name}' already exists in your vendor file repository. Please add the existing file using the Add Existing File button or rename the file.`,
      }
    }

    return {
      isValid: true,
      message: "",
    }
  }

  const handleCancel = (e: any) => {
    e.stopPropagation()
    setShowUpload(false)
    setFiles([])
    setValidationMessage("")
    setUploadingFiles(false)
    setFileStatusMap({})
  }

  return (
    <div>
      <Button
        onClick={toggleUpload}
        icon={<PlusOutlined />}
        style={{ marginRight: "16px" }}
        loading={loading}
        enabled={!disabled}
        htmlType="button"
      >
        Upload New File
      </Button>
      {showUpload && (
        <Modal
          open={showUpload}
          title="Upload Attachment"
          bodyStyle={{ padding: 24 }}
          className="upload-modal"
          centered
          width={800}
          onOk={onUploadFiles}
          onCancel={handleCancel}
          destroyOnClose
          footer={
            <div className="upload-modal-footer">
              <Button
                key="back"
                color="clear"
                onClick={handleCancel}
                style={{ marginRight: 12 }}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                enabled={enableUploadButton()}
                icon={<CloudUploadOutlined />}
                loading={uploadingFiles}
                onClick={onUploadFiles}
              >
                {!uploadingFiles && "Upload Files"}
                {uploadingFiles && "Uploading..."}
              </Button>
            </div>
          }
        >
          <Alert
            message="Upload requirements"
            description={
              <div>
                <div>
                  Accepted file types: csv, pdf, docx, xlsx, pptx, jpg/jpeg,
                  png. Max file size 10mb.
                </div>
                <div style={{ marginTop: 12 }}>
                  If there is an error uploading your files, it may be that your
                  file is corrupted. If this is the case, or if you don't see
                  your file type on this list, contact a CORL Representative to
                  submit a manual review on your file.
                </div>
              </div>
            }
            style={{ marginBottom: 12 }}
          />
          <WDragger
            disabled={!enableUploadDragger()}
            multiple={true}
            customRequest={() => null}
            showUploadList={false}
            onChange={onUploadChange}
            className="custom-upload"
            fileList={files}
            accept={acceptedFileTypes.join(",")}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag files to this area</p>
          </WDragger>
          {getFiles()}
          {!isVendorUser && !isCORLAdmin && (
            <span className="warning-text">
              You must be a vendor user or a CORL admin to upload files.
            </span>
          )}
          {validationMessage && (
            <span className="warning-text">{validationMessage}</span>
          )}
        </Modal>
      )}
    </div>
  )
}

export default FileUpload
