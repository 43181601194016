import moment from "moment";
import {StringUtils} from "../../../global/utils/string";

export const ImportJobTableConfig = [
    {
        title: 'Started at', dataIndex: 'created_at', key: 'created_at',
        render: (created_at:string) => {
            return moment(created_at).format('MMM DD, YYYY');
        }
    },
    {
        title: 'Status', dataIndex: 'status', key: 'status',
        render: (status?:string) => {
            if (status) {
                return StringUtils.camelCaseToRegular(status, false, true);
            }
        }
    },
];