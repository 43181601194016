export enum AssessBuilderFormTypes {
    Title = 'title',
    Client = 'client',
    Vendor = 'vendor',
    Status = 'status',
    DueDate = 'due_date',
    Product = 'product',
    Implementation = 'implementation',
    PointOfContact = 'point_of_contact',
    PointOfContactName = 'point_of_contact_name'
}