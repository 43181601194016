import React from "react";
import {Avatar} from "antd";
import {RocketOutlined} from "@ant-design/icons";

import './styles.scss';

import AppStore from "../../../stores/App/AppStore";

interface Props {

}

function UserPicture(props: Props) {
    return (
        <div>
            {AppStore.user?.picture && <Avatar style={{marginRight: AppStore.sidebarCollapsed ? 0 : 12}}
                            size={40} icon={<img alt={AppStore.user?.email} src={AppStore.user?.picture}/>}/>}
            {!AppStore.user?.picture && <Avatar style={{backgroundColor: '#16c784', marginRight: AppStore.sidebarCollapsed ? 0 : 12}}
                            size={40} icon={<RocketOutlined/>}/>}
        </div>
    )
}

export default UserPicture;