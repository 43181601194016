import React from 'react';
import './styles.scss';


function OverviewContainer() {

    function renderFrame(){
        // let url = config.get("appSettings.pbiDashboardConfig.url");
        // if (url){
        //     return (
        //         <iframe title="Program Metrics v2.0.0" width="100%" height="100%"
        //             src={url}
        //             frameBorder="0" allowFullScreen={true}/>
        //     )
        // } else {
        //     return null;
        // }
        return null;
    }

    return (
        <div className='overview-container'>
            {renderFrame()}
        </div>
    )
}

export default OverviewContainer;