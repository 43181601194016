import React from "react"
import moment from "moment"
import { IRiskFinding } from "../../../../api/RiskFinding/RiskFindingTypes"
import RiskFindingDrawer from "../RiskFindings/RiskFindingDrawer"
import BrowserRouter, {
  BrowserRoutes,
} from "../../../../stores/App/BrowserRouter"
import RiskFindingStatusSelectProtected from "../../StatusSelect/RiskFindingStatusSelectProtected"
import Button from "../../../common/Button/Button"
import AppStore from "../../../../stores/App/AppStore"
import StrapiPermissionsUtil from "../../../../api/utils/StrapiPermissionsUtil"
import {
  ISecCertType,
  IRemediationPathwayControl,
} from "../../../../api/RemediationPathway/SecCertTypeTypes"

export const RiskFindingsTableConfig = (
  loading: boolean,
  onRiskFindingSubmit: (values: any) => void,
  onRiskFindingStatusChange: () => void,
  remediationPathways: ISecCertType[],
  remediationPathwayControls?: IRemediationPathwayControl
) => [
  {
    title: "Assessment",
    key: "assessment",
    render: (riskFinding: IRiskFinding) => {
      function onClick(e: any) {
        e.stopPropagation()
        BrowserRouter.push(
          BrowserRoutes.getAssessmentById(riskFinding.assessment_id)
        )
      }

      return (
        <div className="risk-finding-name">
          <div className="bottom">
            <span
              className="title"
              onClick={onClick}
              style={{ cursor: "pointer" }}
            >
              {riskFinding.assessment?.title}
            </span>
          </div>
        </div>
      )
    },
  },
  {
    title: "Questionnaire",
    key: "questionnaire",
    render: (riskFinding: IRiskFinding) => {
      function onClick(e: any) {
        e.stopPropagation()
        BrowserRouter.push(
          BrowserRoutes.getQuestionnaireById(
            riskFinding.assessment_id,
            riskFinding.questionnaire_id
          )
        )
      }

      return (
        <div className="risk-finding-name">
          {riskFinding.questionnaire_id && (
            <div className="bottom">
              <span
                className="title"
                onClick={onClick}
                style={{ cursor: "pointer" }}
              >
                {riskFinding.questionnaire?.title}
              </span>
            </div>
          )}
          {!riskFinding.questionnaire_id && (
            <div className="bottom">
              <span className="title">Pre-Assessment</span>
            </div>
          )}
        </div>
      )
    },
  },
  {
    title: "Risk Finding Control #",
    key: "control",
    render: (riskFinding: IRiskFinding) => {
      function onClick(e: any) {
        e.stopPropagation()
      }

      return (
        <div className="risk-finding-name">
          <div className="bottom">
            <span className="title" onClick={onClick}>
              {riskFinding.control_number_text ||
                riskFinding.response?.control_question?.control_number}
            </span>
            <div className="risk-findings"></div>
          </div>
        </div>
      )
    },
  },
  {
    title: "Required",
    key: "required",
    width: "10%",
    render: (riskFinding: IRiskFinding) => {
      return (
        <div>
          {riskFinding.client_required && <div>Yes</div>}
          {!riskFinding.client_required && <div>No</div>}
        </div>
      )
    },
  },
  {
    title: "Recommended Due Date",
    key: "corl_recommended_due_date",
    render: (riskFinding: IRiskFinding) => {
      if (!riskFinding.corl_recommended_due_date) return ""
      return moment(riskFinding.corl_recommended_due_date).format(
        "MMM DD, YYYY"
      )
    },
  },
  {
    title: "Updated On",
    dataIndex: "update_date",
    key: "update_date",
    render: (update_date: Date) => {
      return moment(update_date).format("MMM DD, YYYY")
    },
  },
  {
    title: "Risk Finding Status",
    key: "status",
    render: (riskFinding: IRiskFinding) => {
      return (
        <RiskFindingStatusSelectProtected
          onChange={onRiskFindingStatusChange}
          riskFinding={riskFinding}
        />
      )
    },
  },
  {
    title: "",
    key: "actions",
    render: (riskFinding: IRiskFinding) => {
      function onClick(e: any) {
        e.stopPropagation()
        BrowserRouter.push(
          BrowserRoutes.getAssessmentById(riskFinding.assessment_id)
        )
      }
      return (
        <div
          style={{
            display: "flex",
            placeContent: "center",
            flexDirection: "row",
          }}
        >
          {StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user) && (
            <RiskFindingDrawer
              onSubmit={onRiskFindingSubmit}
              asButton
              riskFinding={riskFinding}
              remediationPathways={remediationPathways}
              remediationPathwayControls={remediationPathwayControls}
              loading={loading}
              activeRiskFindingTab={"details"}
            />
          )}
          <Button onClick={onClick} style={{ marginLeft: "16px" }}>
            Go to Assessment
          </Button>
        </div>
      )
    },
  },
]
