import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"
import { observer } from "mobx-react"
import { ConfigProvider, Layout } from "antd"

import "./styles.scss"

/* App Imports */
import Protected from "./Protected"
import NoData from "../../assets/undraw/no_data.svg"
import { useAuth0 } from "@auth0/auth0-react"
import AuthShell from "../../components/common/AuthShell/AuthShell"
import DefaultLoader from "../../components/common/DefaultLoader/DefaultLoader"

function App() {
  const { isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return (
      <div className="default-loader-full" style={{ height: "100vh" }}>
        <DefaultLoader text="Authenticating..." />
      </div>
    )
  }
  if (!isAuthenticated) {
    return <AuthShell />
  }

  const renderEmpty = () => {
    return (
      <div className="global-empty">
        <img alt="" src={NoData} />
        <div>You don't have any data in here yet</div>
      </div>
    )
  }

  return (
    <ConfigProvider renderEmpty={renderEmpty}>
      <Layout className="app" id="app" style={{ minHeight: "100vh" }}>
        <Switch>
          <Route component={Protected} />
        </Switch>
      </Layout>
    </ConfigProvider>
  )
}

export default withRouter(observer(App))
