import React from 'react';
import moment from 'moment';
import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import Button from '../../common/Button/Button';
import { IUserRequest } from '../../../api/UserRequest/UserRequestTypes';

export const UserRequestsTableConfig = (onApprove: (userRequest: IUserRequest) => void, onDeny: (userRequest: IUserRequest) => void, enableActions:boolean) =>[
{
        title: 'Id', dataIndex: 'id', key: 'id',
        render: (id: number) => {
            return <div className='user-request-id'>{id}</div>;
        }
    },
    {
        title: 'Created On', dataIndex: 'created_at', key: 'created_at', sorter: true,
        render: (createdOn: Date) => {
            return moment(createdOn).format('MMM DD, YYYY hh:mm');
        }
    },
    {
        title: 'User', key: 'user',
        render: (userRequest: IUserRequest) => {
            return (
                <div className='user-request'>
                    {userRequest && 
                    <div>
                        <div className='email'>
                            Email: {userRequest.email}
                        </div>
                        <div className='name'>
                            Name: {userRequest.first_name} {userRequest.last_name}                            
                        </div>
                    </div>}
                </div>
            )   
        }
    },
    {
        title: 'Request', key: 'request',
        render: (userRequest: IUserRequest) => {
            return (
                <div className='user-request'>
                    {userRequest && 
                    <div>
                        <div className='role'>
                            Role: {userRequest.role}                          
                        </div>
                        {userRequest.vendor &&
                            <div className='vendor'>
                                Vendor: {userRequest.vendor.name}                          
                            </div>}
                        {userRequest.client &&
                            <div className='client'>
                                Client: {userRequest.client.name}                          
                            </div>}
                    </div>}
                </div>
            )   
        }
    },
    {
        title: 'Action Details', key: 'details',
        render: (userRequest: IUserRequest) => {
            return (
                <div className='action-details'>
                    {userRequest.approved_on && 
                    <div>
                        <div className='action-details-date'>
                            Approved on: {moment(userRequest.approved_on).format('MMM DD, YYYY hh:mm')}
                        </div>
                        <div className='action-details-user'>
                            Approved by: {userRequest.approved}
                        </div>
                    </div>}
                    {userRequest.denied_on &&
                    <div>
                        <div className='action-details-date'>
                            Denied on: {moment(userRequest.denied_on).format('MMM DD, YYYY hh:mm')}
                        </div>
                        <div className='action-details-user'>
                            Denied by: {userRequest.denied}
                        </div>
                    </div>}
                </div>
            );
        }
    },
    {
        title: 'Actions', key: 'actions',
        render: (userRequest: IUserRequest) => {
            var enabled = enableActions;
            if(userRequest.approved_on != null || userRequest.denied_on != null) enabled = false;
            return <div className='actions'>
                <Button className='deny-request' color='red' icon={<CloseOutlined/>} onClick={() => onDeny(userRequest)} enabled={enabled}>Deny</Button>
                <Button className='approve-request' color='green' icon={<CheckOutlined/>} onClick={() => onApprove(userRequest)} enabled={enabled}>Approve</Button>
            </div>
        }
    },
]