import React from 'react';
import {Switch, Route} from 'react-router-dom';

import './styles.scss';

import AssessmentTable from "../../components/specific/AssessmentTable/AssessmentTable";
import {BrowserRoutes} from "../../stores/App/BrowserRouter";
import AssessmentContainer from "../AssessmentContainer/AssessmentContainer";
import AppStore from "../../stores/App/AppStore";
import StrapiPermissionsUtil from "../../api/utils/StrapiPermissionsUtil";

function AssessmentView() {

    function renderAssessmentTable(){
        if (StrapiPermissionsUtil.isClientUser(AppStore.user)){
            return <AssessmentTable user={AppStore.user} clientId={AppStore.user?.client?.id}/>
        } else {
            return <AssessmentTable user={AppStore.user}/>
        }
    }

    return (
        <div className='assessment-view'>
            <Switch>
                <Route key={0} exact path={BrowserRoutes.assessments} render={renderAssessmentTable}/>
                <Route key={1} path={BrowserRoutes.assessmentsByIdParam} component={AssessmentContainer}/>
            </Switch>

        </div>
    )
}

export default AssessmentView;