import axios from "axios";
import {IQuestionnaire, EQuestionnaireStatus} from "./QuestionnaireTypes";

export default class QuestionnaireAPI {

    static async createQuestionnaire(values: any) {
        try {
            let res = await axios.post('/questionnaires', values);
            return res.data as IQuestionnaire;
        } catch (e:any) {
            console.log('Could not create questionnaire', e.message);
            return undefined;
        }
    }

    static async getQuestionnaire(questionnaireId: number) {
        try {
            let res = await axios.get(`/questionnaires/${questionnaireId}`);
            return res.data as IQuestionnaire;
        } catch (e:any) {
            console.log('Could not get questionnaire with provided ID', e.message);
            return undefined;
        }
    }

    static async getQuestionnaires(assessmentId: number) {
        try {
            let res = await axios.get(`/assessments/${assessmentId}/questionnaires`);
            return res.data as IQuestionnaire[];
        } catch (e:any) {
            console.log('Could not get questionnaires', e.message);
            return undefined;
        }
    }

    static async updateQuestionnaire(questionnaireId: number, values:any) {
        try {
            let res = await axios.put(`/questionnaires/${questionnaireId}`, values);
            return res.data as IQuestionnaire;
        } catch (e:any) {
            console.error('Could not change questionnaire values', e.message);
            return undefined;
        }
    }

    static async deleteQuestionnaire(questionnaireId: number) {
        try {
            let res = await axios.delete(`/questionnaires/${questionnaireId}`);
            return res.data;
        } catch(e){
            console.error('Could not delete questionnaire', e.message);
            return undefined;
        }
    }

    static async resendCreateQuestionnaireEmail(questionnaireId: number) {
        try {
            let res = await axios.post(`/questionnaires/${questionnaireId}/resend-create-email`);
            return res.data as IQuestionnaire;
        } catch (e:any) {
            console.log('Could not resend create questionnaire email', e.message);
            return undefined;
        }
    }

    static async submitQuestionnaire(questionnaireId:number, status:EQuestionnaireStatus){
        try {
            let res = await axios.put(`/questionnaires/${questionnaireId}/submit`, {status, 'submitted_on': new Date()});
            return res.data as IQuestionnaire;
        } catch (e:any){
            console.error('Could not change questionnaire status', e.message);
            return undefined;
        }
    }

    static async clearDRUResponses(questionnaireId:number, values:any){
        try {
            let res = await axios.post(`/questionnaires/${questionnaireId}/clear-dru`, values);
            return res.data as boolean;
        } catch (e:any){
            console.error('Could not clear questionnaire responses', e.message);
            return undefined;
        }
    }
}