import React, {useCallback, useEffect, useState} from 'react';
import _ from 'lodash';

import './styles.scss';

import {Input, notification, Table} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import ClientAPI from "../../../api/Client/ClientAPI";
import {ClientTableConfig} from "./ClientTableConfig";
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import {IClient} from "../../../api/Client/ClientTypes";
import ClientFormModalAsButton from "../ClientForm/ClientFormModalAsButton";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {TOAST_DURATION} from "../../../constants";

interface Props {
    disableFilters?:boolean,
    onClientClick?:(clientId:string)=>void
}

function ClientTable(props:Props) {
    const {disableFilters} = props;
    const [clients, setClients] = useState<IClient[]>();
    const [curPage, setCurPage] = useState<number>(1);
    const [numClients, setNumClients] = useState<number>()
    const [search, setSearch] = useState<string>('');

    const clientLimit = 25;

    async function getClients(params?:any) {
        let curParams = {
            _limit:clientLimit,
            _start: (curPage - 1) * clientLimit,
        }

        if (params){
            curParams = {...curParams, ...params};
        }

        let curClients = await ClientAPI.getClients(curParams);
        if (curClients) setClients(curClients);

        let numClients = await ClientAPI.getClientCount(curParams);
        setNumClients(numClients);
    }

    useEffect(() => {
        getClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onRow(record:any){
        return {
            onClick: (e:any) => onClickRow(record),
        }
    }

    async function onCreateClient(values:any){
        let res = await ClientAPI.createClient(values);
        if (!res) {
            notification.error({
                message: 'Error creating client!',
                description: <span>Client, <b>{values.name}</b>, could not be created. This is most likely because the Point of Contact's email is already registered.</span>,
                duration: TOAST_DURATION,
            })
        } else {
            notification.success({
                message: 'Success!',
                description: <span>Your client, <b>{values.name}</b> has been successfully created, and <b>{values.email}</b> has been sent an invitation via email.</span>,
                duration: TOAST_DURATION,

            })
        }
        await getClients();
    }

    function onClickRow(client:IClient){
        BrowserRouter.push(BrowserRoutes.getClientById(client.id))
    }

    async function onInputChange(value:string){
        setSearch(value);
        await getClients({name_contains: value});
        resetPagination();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(_.debounce(onInputChange, 500),[])

    async function onTableChange(pagination:any, filter:any, sorter:any, extra:any){
        if (extra.action === 'paginate'){
            setCurPage(pagination.current);
            await getClients({_start: (pagination.current - 1) * clientLimit, name_contains: search})
        }
    }

    function resetPagination(){
        setCurPage(1);
    }

    let pagination = {
        pageSize:clientLimit,
        total: numClients,
        current: curPage,
        showTotal:(total:number, range:number[]) => `${range[0]}-${range[1]} of ${total} items`,
    }

    if (!clients) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading clients...'/>
            </div>
        )
    }

    return (
        <div className='client-table'>
            {!disableFilters &&
            <div className='filters'>
                <div className='search-bar'>
                    <Input className='client-input' size='large' onChange={e => debouncedOnChange(e.target.value)} bordered={false}
                           prefix={<SearchOutlined/>} placeholder='Search by Client name'/>
                </div>
                <ClientFormModalAsButton onSubmit={onCreateClient}/>
            </div>}
            <div className='questionnaire-table-content'>
                <Table rowKey='id' dataSource={clients} columns={ClientTableConfig}
                       pagination={pagination} onChange={onTableChange}
                       onRow={onRow}/>
            </div>
        </div>

    )
}

export default ClientTable;