import React, {useCallback, useEffect, useState} from 'react';
import {Select} from 'antd';

import './styles.scss';
import {IVendor} from "../../../api/Vendor/VendorTypes";
import VendorAPI from "../../../api/Vendor/VendorAPI";
import _ from "lodash";
import {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

interface Props {
    onChange?: (vendor?: number) => void,
    loading?: boolean,
    className?: string,
    option?: number
    vendors?: IVendor[],
    curVendor?: IVendor,
    disabled?: boolean,
    value?: any,
}

function VendorSelect(props: Props) {
    const {onChange, loading, option, curVendor, vendors: propVendors, value, disabled} = props;
    const [stateVendors, setStateVendors] = useState<IVendor[]>();

    async function getVendors(params?: any) {
        let vendors = await VendorAPI.getVendors(params)
        if (vendors) setStateVendors(vendors);
    }

    async function onSearch(value: string) {
        if (!value) {
            return null;
        }
        await getVendors({name_contains: value});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(_.debounce(onSearch, 300), [])

    useEffect(() => {
        if (!propVendors) getVendors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let vendors = propVendors;
    if (stateVendors) {
        vendors = stateVendors;
    }

    function onClear() {
        if (onChange) {
            onChange(undefined);
            if (!propVendors) getVendors();
        }
    }

    return (
        <Select className='vendor-select' size='large' optionLabelProp='children' onSearch={debouncedSearch}
                placeholder={curVendor ? curVendor.name : 'Search Vendors'}
                defaultValue={curVendor ? curVendor.name : option} onSelect={onChange as any} loading={loading}
                showSearch filterOption={false}
                notFoundContent={loading ? <LoadingOutlined spin/> : null} allowClear onClear={onClear} value={value} disabled={disabled}>
            {vendors && vendors.map(vendor =>
                <Option value={vendor.id} key={vendor.id}>
                    <div className='vendor-select-name'>
                        {vendor.name}
                    </div>
                    <div className='vendor-select-point-of-contact'>
                        {vendor?.point_of_contact || "No point of contact"}
                    </div>
                </Option>
            )}
        </Select>
    )
}

export default VendorSelect;