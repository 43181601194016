import axios from 'axios';
import {IClient} from "./ClientTypes";

const route = '/clients'

export default class ClientActions {
    static async getClients(params?:any) {
        try {
            let res = await axios.get(route, {params});
            return res.data as IClient[];
        } catch (e: any) {
            console.error('Could not get clients', e.message);
            return undefined;
        }
    }

    static async changeOutreachMessage(clientId:number, values:any){
        try{
            let res = await axios.put(`${route}/${clientId}/change-outreach-message`, values);
            return res.data as IClient;
        } catch(e: any) {
            console.error('Could not change client outreach message', e.message);
            return undefined;
        }
    }

    static async update(clientId:number, values:any){
        try{
            let res = await axios.put(`${route}/${clientId}`, values);
            return res.data as IClient;
        } catch(e: any) {
            console.error('Could not update client', e.message);
            return undefined;
        }
    }

    static async createClient(data?:any){
        try{
            let res = await axios.post(route, data);
            return res.data;
        } catch (e: any) {
            console.error('Could not create client', e.message);
            return undefined;
        }
    }

    static async getClientCount(params?:any){
        let res = await axios.get(`${route}/count`, {params});
        return res.data as number;
    }

    static async getClientById(clientId:number){
        try {
            let res = await axios.get(`${route}/${clientId}`);
            return res.data as IClient;
        } catch (e: any) {
            console.error('Could not get client', e.message);
            return undefined;
        }
    }

    static async testOutreach(data?:any){
        try{
            let res = await axios.post(`${route}/test-outreach`, data);
            return res.data;
        } catch (e: any) {
            console.error('Could not test outreach data', e.message);
            return undefined;
        }
    }
}