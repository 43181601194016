import React, { useEffect, useState } from 'react';
import { Card, Badge, Popover } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import cx from 'classnames';

import './styles.scss';

import { INotification } from '../../../api/Notifications/NotificationTypes';
import NotificationsAPI from '../../../api/Notifications/NotificationsAPI';
import { Link } from 'react-router-dom';
import NotificationList from '../NotificationList/NotificationList';
import DataManipulation from "../../../global/utils/DataManipulation";
import Button from '../../common/Button/Button';

interface Props {
    collapsed?:boolean
}

function NotificationsPopover(props:Props) {
    const {collapsed} = props;
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [unread, setUnread] = useState<boolean>(false);
    const [isVisible, setVisible] = useState<boolean>(false);

    async function getNotifications() {
        let curNotifications = await NotificationsAPI.getUnreadNotifications();
        if (curNotifications) {
            let sorted = curNotifications.sort(
                (a, b) => DataManipulation.compare(
                    new Date(b.created_at).getTime(), new Date(a.created_at).getTime()
                ))
            setNotifications(sorted);
        }
    }

    async function updateNotifications() {
        notifications?.forEach((notification) => {
            NotificationsAPI.updateNotification(notification.id);
        })
        setTimeout(() => {
            setNotifications([])
            checkForUnread()
        }, 500)

    }

    function checkForUnread() {
        setUnread(notifications?.length > 0)
    }

    useEffect(() => {
        getNotifications();
    }, [])

    useEffect(() => {
        checkForUnread();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications])


    return (
        <div className={cx('notifications-wrapper', {collapsed})}>
            <Popover
                overlayClassName='popover-notifications'
                className="notifications-wrapper"
                trigger='click'
                placement="rightBottom"
                arrowPointAtCenter
                open={isVisible}
                onOpenChange={(visible) => {
                    if (visible === false) {
                        updateNotifications()
                    }
                    setVisible(visible)
                }}
                content={
                    <Card bordered={false} title="Notifications" extra={<Link to='/notifications' onClick={() => setVisible(false)}>View all</Link>}
                        bodyStyle={{ overflowY: 'scroll', height: '30rem', width: '30rem' }}>
                        <NotificationList notifications={notifications} />
                    </Card>
                }>
                {!collapsed && <Button style={{fontSize: 14, fontWeight: 600, width: '100%'}} icon={<Badge dot={unread}>
                    <BellOutlined />
                </Badge>} iconPosition="left" color="clear">Notifications</Button>}
            </Popover>
        </div>
    )
};

export default NotificationsPopover;