import React, { useState } from "react"
import { notification } from "antd"

import "./styles.scss"

import {
  ERiskFindingStatus,
  ERiskFindingUpdateEvents,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import { TOAST_DURATION } from "../../../../constants"
import RiskFindingApi from "../../../../api/RiskFinding/RiskFindingApi"
import QuestionResponseForm from "../../Question/QuestionResponseForm"
import QuestionEvidence from "../../Question/QuestionEvidence"
import { EFileUploadTypes } from "../../../../api/File/FileTypes"
import RemediationFileApi from "../../../../api/RemediationFile/RemediationFileApi"
import { IVendorFile } from "../../../../api/VendorFile/VendorFileTypes"
import VendorFileAPI from "../../../../api/VendorFile/VendorFileApi"
import Button from "../../../common/Button/Button"
import { ArrowRightOutlined, PaperClipOutlined } from "@ant-design/icons"

interface Props {
  riskFinding: IRiskFinding
  onSubmit?: (values: any) => void
  onChange?: (values: any) => void
  disabled?: boolean
}

export enum RiskFindingFormTypes {
  VendorValid = "vendor_valid",
  VendorRemediate = "vendor_remediate",
  VendorAction = "vendor_action",
  VendorCommitmentDate = "vendor_commitment_date",
}

function RiskFindingResponseForm({
  riskFinding,
  onSubmit,
  onChange,
  disabled,
}: Props) {
  const formDisabled = disabled
    ? disabled
    : (riskFinding.vendor_remediate === null ||
        riskFinding.vendor_remediate === undefined) &&
      (riskFinding.vendor_valid === undefined ||
        riskFinding.vendor_valid === null)
  const [submittingForm, setSubmittingForm] = useState(false)

  const initialValues = {
    response_choice: riskFinding.remediation_response,
    response_text: riskFinding.remediation_response_text,
  }

  async function onResponseFormSubmit(values: any) {
    if (formDisabled || submittingForm || !canSubmit()) return false
    setSubmittingForm(true)

    let payload = {
      remediation_response: values.response_choice,
      remediation_response_text: values.response_text,
      status: ERiskFindingStatus.Submitted,
    }

    let response = await RiskFindingApi.updateRiskFinding(riskFinding.id, {
      ...payload,
      event: ERiskFindingUpdateEvents.RemediatedResponseSubmitted,
    })
    if (response) {
      notification.success({
        message: "Success!",
        description: (
          <span>
            Thank you for providing an update on remediation for this Risk
            Finding. If your response does not meet the requirements for this
            control, CORL will reach out for additional information.
          </span>
        ),
        duration: TOAST_DURATION,
      })
      if (onSubmit) onSubmit(values)
    } else {
      notification.error({
        message: "Error submitting Risk Finding Remediation!",
        description: (
          <span>
            Your Risk Finding Remediation submission could not be submitted.
            Please reach out to support for help.
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }
    setSubmittingForm(false)
  }

  async function deleteRemediationFile(remediationFileId: number) {
    setSubmittingForm(true)
    await RemediationFileApi.deleteRemediationFile(remediationFileId)
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  async function onClickVendorRow(vendorFile: IVendorFile) {
    setSubmittingForm(true)
    let responseFile = await VendorFileAPI.attachVendorFileToRemediationFile(
      vendorFile.id,
      riskFinding.id
    )
    if (!responseFile) {
      notification.error({
        message: "Error uploading file to Risk Finding",
        description: (
          <span>
            {vendorFile.file_name} may already be attached to your Risk Finding
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  async function onFilesChanged() {
    setSubmittingForm(true)
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  function canSubmit() {
    if (formDisabled) return false
    if (!riskFinding.is_key_control) return true

    return riskFinding.remediation_files?.length !== 0
  }

  return (
    <div className="risk-finding-response-form-wrapper">
      <div style={{ marginBottom: "8px" }}>
        If you have remediated this Risk Finding, please update your response
        below. If the finding is against a key control, you must provide
        evidence. If you need to provide evidence over a screenshare or phone
        call, please submit a Help ticket.
      </div>
      <div
        className="risk-finding-remediation-response-form-title"
        style={{ fontWeight: 600, marginTop: "6px" }}
      >
        Remediated Response Form
      </div>
      {riskFinding.is_key_control && (
        <div className="key-control">
          <PaperClipOutlined className="icon" style={{ marginRight: 6 }} />
          <div>
            This is a Key Control. Please upload evidence file(s) for this
            Control Question.
          </div>
        </div>
      )}
      <QuestionResponseForm
        entityId={riskFinding.id}
        disabled={formDisabled}
        initialValues={initialValues}
        onChange={() => {}}
        requireComment={true}
        onSubmit={onResponseFormSubmit}
      />
      <QuestionEvidence
        files={riskFinding.remediation_files}
        entityId={riskFinding.id}
        vendorId={riskFinding.vendor_id}
        disabled={formDisabled || submittingForm}
        hideUpload={formDisabled}
        fileUploadType={EFileUploadTypes.Remediation}
        onFilesChanged={onFilesChanged}
        onFileDelete={deleteRemediationFile}
        onRowClick={onClickVendorRow}
        hideHeader={formDisabled}
      />
      <div className="risk-finding-response-submit-button">
        <Button
          form={`question-response-form-${riskFinding.id}`}
          loading={submittingForm}
          icon={<ArrowRightOutlined />}
          iconPosition="right"
          enabled={canSubmit()}
        >
          Save Remediated Response
        </Button>
      </div>
    </div>
  )
}

export default RiskFindingResponseForm
