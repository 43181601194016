import React, {useState} from 'react';

import './style.scss';

import Status from "../Status/Status";
import { ERiskFindingStatus, IRiskFinding } from '../../../api/RiskFinding/RiskFindingTypes';
import RiskFindingApi from '../../../api/RiskFinding/RiskFindingApi';
import StrapiPermissionsUtil from '../../../api/utils/StrapiPermissionsUtil';
import AppStore from '../../../stores/App/AppStore';
import RiskFindingStatusSelect from './RiskFindingStatusSelect';
import { TOAST_DURATION } from '../../../constants';
import { notification } from 'antd';

export interface Props {
    onChange?: ()=>void,
    riskFinding: IRiskFinding
}

function RiskFindingStatusSelectProtected({riskFinding, onChange}:Props) {
    const [loading, setLoading] = useState(false);

    async function onChangeStatus(status: ERiskFindingStatus) {
        setLoading(true);
        let response = await RiskFindingApi.updateRiskFinding(riskFinding?.id, {status: status});
        if(response) {
            notification.success({
                message: 'Risk Finding Status Updated',
                description: `Status changed to ${status}`,
                duration: TOAST_DURATION,
            });
        } else {
            notification.error({
                message: 'Risk Finding Status Could Not Be Updated',
                duration: TOAST_DURATION,
            });
        }
        if (onChange) await onChange();
        setLoading(false);
    }

    if (StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user)) {
        return <RiskFindingStatusSelect onChange={onChangeStatus} defaultValue={riskFinding.status} loading={loading}/>
    } else {
        return <Status status={riskFinding.status}/>;
    }
}
export default RiskFindingStatusSelectProtected;