import React, { useEffect, useState } from "react"
import moment from "moment"
import { notification } from "antd"
import { ArrowRightOutlined, ExportOutlined } from "@ant-design/icons"
import "./styles.scss"

import {
  EAssessmentStatus,
  IAssessment,
} from "../../../api/Assessment/AssessmentTypes"
import QuestionnaireTable from "../QuestionnaireTable/QuestionnaireTable"
import AssessmentProgress from "../AssessmentProgress/AssessmentProgress"
import QuestionnaireFormDrawer from "../QuestionnaireForm/QuestionnaireFormDrawer"
import AssessmentAPI from "../../../api/Assessment/AssessmentAPI"
import AssessmentBreadcrumb from "../AssessmentBreadcrumb/AssessmentBreadcrumb"
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil"
import { IUser } from "../../../api/User/UserTypes"
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader"
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI"
import {
  IQuestionnaire,
  EQuestionnaireStatus,
} from "../../../api/Questionnaire/QuestionnaireTypes"
import Button from "../../common/Button/Button"
import { ExportManager } from "../../../global/managers/ExportManager"
import { TOAST_DURATION } from "../../../constants"
import AssessmentRemediation from "../Remediation/AssessmentRemediation/AssessmentRemediation"
import AppStore from "../../../stores/App/AppStore"
import { StickyContainer } from "react-sticky-17"

interface Props {
  assessmentId: number
  user?: IUser
}

function Assessment(props: Props) {
  const { assessmentId, user } = props
  const [assessment, setAssessment] = useState<IAssessment>()
  const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>()

  async function getAssessment() {
    let curAssessment = await AssessmentAPI.getAssessmentById(assessmentId)
    setAssessment(curAssessment)
  }

  async function getQuestionnaires() {
    let curQuestionnaires = await QuestionnaireAPI.getQuestionnaires(
      assessmentId
    )
    if (curQuestionnaires) setQuestionnaires(curQuestionnaires)
  }

  function getQuestionnairesProgress() {
    if (questionnaires) {
      let numOfQuestionnaires = questionnaires.length
      let completedQuestionnaires = questionnaires.filter(
        (questionnaire) =>
          questionnaire.status === EQuestionnaireStatus.Submitted
      )
      return Math.round(
        (completedQuestionnaires?.length / numOfQuestionnaires) * 100
      )
    } else {
      return 0
    }
  }

  async function exportAssessment() {
    notification.success({
      message: "Assessment Export Started",
      description: (
        <span>
          Questionnaires not in progress will be exported. This may take a
          while, please stay on the page until you receive the download.
        </span>
      ),
      duration: TOAST_DURATION,
    })

    if (assessment) await ExportManager.exportAssessment(assessment)
  }

  useEffect(() => {
    getAssessment()
    getQuestionnaires()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId])

  if (!assessment)
    return (
      <div className="assessment">
        <DefaultLoader text="Loading assessment..." />
      </div>
    )

  async function onPlanSubmit(values: any) {
    if (!assessment) return

    const label =
      assessment.status === EAssessmentStatus.RemediationInProgress
        ? "Remediation"
        : "Gap Validation"

    let curAssessment
    if (assessment.status === EAssessmentStatus.RemediationInProgress)
      curAssessment = await AssessmentAPI.submitRiskFindings(
        assessment.id,
        EAssessmentStatus.RemediationSubmitted
      )
    if (assessment.status === EAssessmentStatus.GapValidationInProgress)
      curAssessment = await AssessmentAPI.submitGapValidation(assessment.id)

    if (curAssessment) {
      notification.success({
        message: "Success!",
        description: (
          <span>
            Your {label} Plan has been submitted. CORL will review your
            responses and let you know if additional information is required.
          </span>
        ),
        duration: TOAST_DURATION,
      })
      await getAssessment()
    } else {
      let message = `We were unable to submit your Risk Findings for this Assessment. Please contact support for more information.`
      if (StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user))
        message = `CORL users cannot submit a ${label} Plan, only Vendor users can submit.`
      notification.error({
        message: "Error submitting Risk Findings!",
        description: <span>{message}</span>,
        duration: TOAST_DURATION,
      })
    }
  }

  const { client, vendor, due_date } = assessment
  return (
    <div className="assessment">
      <div className="assessment-header">
        <div className="title">
          <AssessmentBreadcrumb assessment={assessment} />
        </div>
        <div className="assignments">
          {!client && (
            <div className="client" style={{ color: "red" }}>
              This assessment doesn't have a Client
            </div>
          )}
          <div className="client">{client?.name}</div>
          <ArrowRightOutlined />
          {!vendor && (
            <div className="vendor" style={{ color: "red" }}>
              This assessment doesn't have a Vendor
            </div>
          )}
          <div className="vendor">{vendor?.name}</div>
        </div>
      </div>
      <div className="assessment-content">
        <div className="info">
          <div className="info-left">
            {due_date && (
              <div>Due on {moment(due_date).format("MMM DD, YYYY")}</div>
            )}
            <div>
              {assessment.product?.name} {assessment.implementation?.name}
            </div>
          </div>
          <AssessmentProgress progress={getQuestionnairesProgress()} />
        </div>
        <div className="table">
          <div className="header">
            <div className="title">Questionnaires</div>
            <div className="assessment-controls">
              {StrapiPermissionsUtil.isCorlAdminOrEmployee(user) && (
                <QuestionnaireFormDrawer
                  className="create-questionnaire-button"
                  onSubmit={getQuestionnaires}
                  asButton
                  assessment={assessment}
                />
              )}
              {StrapiPermissionsUtil.isCorlAdminOrEmployee(user) && (
                <Button
                  className="export-button"
                  icon={<ExportOutlined />}
                  onClick={() => exportAssessment()}
                >
                  Export
                </Button>
              )}
            </div>
          </div>
          <QuestionnaireTable
            questionnaires={questionnaires || []}
            assessment={assessment}
            pagination={false}
            onQuestionnaireChange={getQuestionnaires}
          />
        </div>
        <StickyContainer style={{ width: "100%" }}>
          {(assessment.status === EAssessmentStatus.RemediationInProgress ||
            assessment.status === EAssessmentStatus.RemediationSubmitted ||
            assessment.status === EAssessmentStatus.GapValidationInProgress ||
            assessment.status === EAssessmentStatus.GapValidationSubmitted) && (
            <div className="remediation-section">
              <AssessmentRemediation
                assessment={assessment}
                onSubmit={onPlanSubmit}
              ></AssessmentRemediation>
            </div>
          )}
        </StickyContainer>
      </div>
    </div>
  )
}

export default Assessment
