export const ROLES = {
  CORL_ADMIN: "CORL Admin",
  CORL_EMPLOYEE: "CORL Employee",
  CLIENT_ADMIN: "Client Admin",
  VENDOR_ADMIN: "Vendor Admin",
  CLIENT: "Client",
  VENDOR: "Vendor",
}

export const TOAST_DURATION = 10
export const ERROR_TOAST_DURATION = 20

export const RESPONSE_CHOICES = {
  Yes: "Yes",
  No: "No",
  NA: "N/A",
  Partial: "Partial",
}

export const EMAIL_TYPES = {
  QUESTIONNAIRE_CREATED: "questionnaire_created",
  OPEN_ASSESSMENT_REMINDER: "open_assessment_reminder",
  QUESTIONNAIRE_SUBMITTED: "questionnaire_submitted",
  QUESTIONNAIRE_REOPENED: "questionnaire_reopened",
  NEW_USER_INVITATION: "new_user_invitation",
  REMEDIATION_INITIATED: "remediation_initiated",
  REMEDIATION_PLAN_SUBMITTED: "remediation_plan_submitted",
  OPEN_REMEDIATION_REMINDER: "open_remediation_reminder",
  REMEDIATION_REOPENED: "remediation_reopened",
  GAP_VALIDATION_INITIATED: "gap_validation_initiated",
  GAP_VALIDATION_PLAN_SUBMITTED: "gap_validation_plan_submitted",
}
