import * as React from 'react';

import { useAuth0 } from "@auth0/auth0-react";

const AuthShell = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    if(!isAuthenticated) {
        loginWithRedirect({
            appState: { returnTo: window.location.href }
        })
    }
    
    return (
        <div>
            
        </div>
)}

export default AuthShell
