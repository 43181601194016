import React from "react"
import "./styles.scss"
import { IRiskFinding } from "../../../../api/RiskFinding/RiskFindingTypes"
import moment from "moment"
import Logo from "../../../../assets/logo.png"
import { StringUtils } from "../../../../global/utils/string"

interface Props {
  riskFinding: IRiskFinding
}

function RiskFindingHistory({ riskFinding }: Props) {
  const renderHistory = () => {
    return riskFinding.risk_finding_histories
      ?.sort(
        (a, b) =>
          new Date(a.submitted_date).getTime() -
          new Date(b.submitted_date).getTime()
      )
      .map((h, index) => {
        return (
          <div
            className="message"
            style={{ alignSelf: "flex-start" }}
            key={index}
          >
            <div>
              <img alt="Logo" src={Logo} className={"logo"} />
            </div>
            <div>
              <div className="content" style={{ alignItems: "flex-start" }}>
                <div
                  className="text"
                  style={{ backgroundColor: "#efefef", color: "black" }}
                >
                  {h.message}
                </div>
              </div>
              <div className="details" style={{ justifyContent: "flex-start" }}>
                <div className={"timestamp"}>
                  {moment(h.submitted_date).format("MMM DD, YYYY - hh:mm A")}
                  {h.author.user_datum?.first_name &&
                    h.author.user_datum?.last_name && (
                      <div>
                        &nbsp;by{" "}
                        {StringUtils.capitalize(h.author.user_datum.first_name)}{" "}
                        {StringUtils.capitalize(h.author.user_datum.last_name)}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        )
      })
      .reverse()
  }

  return (
    <div className="risk-finding-history-container">
      <div className="risk-finding-history-title">Activity Timeline</div>
      <div className="risk-finding-history-timeline">{renderHistory()}</div>
    </div>
  )
}

export default RiskFindingHistory
