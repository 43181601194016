import React from "react"
import {
  ERiskFindingStatus,
  IRiskFinding,
} from "../../../../api/RiskFinding/RiskFindingTypes"
import {
  CloudUploadOutlined,
  CloseOutlined,
  ClockCircleOutlined,
  CheckOutlined,
} from "@ant-design/icons"
import { Avatar } from "antd"

interface Props {
  riskFinding: IRiskFinding
}

function RiskFindingStatus({ riskFinding }: Props) {
  let iconSize = 24
  let internalIconSize = 12

  function getColor() {
    switch (riskFinding.status) {
      case ERiskFindingStatus.Open:
        return "#9D1717"
      case ERiskFindingStatus.Submitted:
        return "#2C9543"
      case ERiskFindingStatus.VendorCommited:
        return "#2C9543"
      case ERiskFindingStatus.Closed:
        return "#54575A"
      case ERiskFindingStatus.Resolved:
        return "#145091"
      default:
        return ""
    }
  }

  function displayStatusDescription() {
    switch (riskFinding.status) {
      case ERiskFindingStatus.Open:
        return "RISK FINDING IDENTIFIED"
      case ERiskFindingStatus.Submitted:
        return "RISK FINDING PENDING REVIEW"
      case ERiskFindingStatus.VendorCommited:
        return "RISK FINDING REMEDIATION IN PROGRESS"
      case ERiskFindingStatus.Closed:
        return "RISK FINDING INADEQUATE"
      case ERiskFindingStatus.Resolved:
        return "RISK FINDING RESOLVED"
      default:
        return ""
    }
  }

  //   function displayStatusAction() {
  //     switch (riskFinding.status) {
  //       case ERiskFindingStatus.Open:
  //         return "(Your Action)"
  //       case ERiskFindingStatus.Submitted:
  //         return "(CORL Action)"
  //       case ERiskFindingStatus.VendorCommited:
  //         return "(Your Action)"
  //       case ERiskFindingStatus.Closed:
  //         return ""
  //       default:
  //         return ""
  //     }
  //   }

  function displayStatusIcon() {
    switch (riskFinding.status) {
      case ERiskFindingStatus.Open:
        return (
          <Avatar
            className="risk-finding-status-icon-avatar"
            style={{ backgroundColor: getColor(), fontWeight: 600 }}
            size={iconSize}
            icon={<CloseOutlined style={{ fontSize: internalIconSize }} />}
          />
        )
      case ERiskFindingStatus.Submitted:
        return (
          <Avatar
            className="risk-finding-status-icon-avatar"
            style={{ color: getColor(), backgroundColor: "white" }}
            size={iconSize}
            icon={
              <CloudUploadOutlined style={{ fontSize: internalIconSize * 2 }} />
            }
          />
        )
      case ERiskFindingStatus.VendorCommited:
        return (
          <Avatar
            className="risk-finding-status-icon-avatar"
            style={{ color: getColor(), backgroundColor: "white" }}
            size={iconSize}
            icon={
              <ClockCircleOutlined style={{ fontSize: internalIconSize * 2 }} />
            }
          />
        )
      case ERiskFindingStatus.Closed:
        return (
          <Avatar
            className="risk-finding-status-icon-avatar"
            style={{ backgroundColor: getColor() }}
            size={iconSize}
            icon={<CloseOutlined style={{ fontSize: internalIconSize }} />}
          />
        )
      case ERiskFindingStatus.Resolved:
        return (
          <Avatar
            className="risk-finding-status-icon-avatar"
            style={{ backgroundColor: getColor() }}
            size={iconSize}
            icon={<CheckOutlined style={{ fontSize: internalIconSize }} />}
          />
        )
      default:
        return <div></div>
    }
  }

  return (
    <div className="risk-finding-status-container">
      <div className="risk-finding-status-icon">{displayStatusIcon()}</div>
      <div className="risk-finding-status-content">
        <div className="risk-finding-status-content-description">
          <div
            style={{
              fontSize: "14px",
              fontWeight: 600,
              color: getColor(),
              marginRight: "4px",
              lineHeight: "15px",
            }}
          >
            {displayStatusDescription()}
          </div>
          {/* {displayStatusAction() && (
            <div
              style={{ fontSize: "18px", fontWeight: 600, color: "#8a8a8a" }}
            >
              {displayStatusAction()}
            </div>
          )} */}
        </div>
        <div className="risk-finding-status-content-label">
          {riskFinding.control_number_text ||
            riskFinding.response?.control_question?.control_number}
        </div>
      </div>
    </div>
  )
}

export default RiskFindingStatus
