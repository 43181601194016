import React, {useState} from 'react';
import {Drawer} from "antd";
import {PlusOutlined} from '@ant-design/icons';

import './styles.scss';
import Button from "../../common/Button/Button";
import InviteUserForm from "./InviteUserForm";

interface Props {
    className?: string,
    onSubmit?: (values: string) => void,
    inviteText:string
}

function InviteUserFormModalAsButton(props:Props){
    const {className, onSubmit, inviteText} = props;
    const [showForm, setShowForm] = useState(false);

    const onButtonClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
    }

    const onFormSubmit = async (values: any) => {
        if (onSubmit) await onSubmit(values);
        setShowForm(false);
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <Button icon={<PlusOutlined/>} className={className} onClick={onButtonClick}>
                {inviteText}
            </Button>
            <Drawer title={inviteText} width='50%' keyboard={false} open={showForm} destroyOnClose onClose={()=>setShowForm(false)}
                    className='invite-user-form-drawer' footer={null}>
                <InviteUserForm applyDrawerStyles onSubmit={onFormSubmit}/>
            </Drawer>
        </div>
    )
}

export default InviteUserFormModalAsButton;