import React from 'react';
import './styles.scss';

import UserRequestsTable from "../../components/specific/UserRequestsTable/UserRequestsTable";

function UserRequestsContainer() {
    return (
        <section className='user-requests-container'>
            <UserRequestsTable/>
        </section>
    )
}

export default UserRequestsContainer;