import React from 'react';
import cx from 'classnames';
import {DoubleRightOutlined} from '@ant-design/icons';
import './styles.scss';
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";
import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes";

interface Props {
    assessment: IAssessment,
    questionnaire?: IQuestionnaire,
}

function AssessmentBreadcrumb(props: Props) {
    const {assessment, questionnaire} = props;

    function onAssessmentTitleClick() {
        if (questionnaire) {
            BrowserRouter.push(BrowserRoutes.getAssessmentById(assessment.id));
        }
    }

    return (
        <div className='assessment-breadcrumb'>
            <div className='assessment-back' onClick={() => BrowserRouter.push(BrowserRoutes.assessments)}>
                Assessments
            </div>
            <DoubleRightOutlined className='separator'/>
            <div className={cx('assessment-title', {'has-questionnaire': !!questionnaire})}
                 onClick={onAssessmentTitleClick}>
                {assessment.title}
            </div>
            {!!questionnaire &&
            <>
                <DoubleRightOutlined className='separator'/>
                <div className='questionnaire-title'>
                    {questionnaire.title}
                </div>
            </>}
        </div>
    )
}

export default AssessmentBreadcrumb;