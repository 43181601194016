import React, {useState} from 'react';
import {Form, Input} from "antd";
import cx from "classnames";
import {SearchOutlined} from '@ant-design/icons';

import './styles.scss';

import VendorSelect from "../VendorSelect/VendorSelect";
import StatusSelect from "../StatusSelect/StatusSelect";
import Button from "../../common/Button/Button";
import ClientSelect from "../ClientSelect/ClientSelect";
import {AssessmentFilterFormTypes} from "./Types";

interface Props {
    onSubmit?: (values: any) => void,
    disabledFilters?: AssessmentFilterFormTypes[]
}

function AssessmentFilters(props: Props) {
    const {onSubmit, disabledFilters} = props;
    const [form] = Form.useForm();
    const [submittingForm, setSubmittingForm] = useState(false);
    const {Vendor, Status, Client, Title} = AssessmentFilterFormTypes;

    async function onFormSubmit(values: any) {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && onSubmit) {
                onSubmit(values);
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    const formProps = {
        requiredMark: 'optional',
        colon: false,
        className: cx('assessment-filters-form')
    };

    function isDisabled(name: AssessmentFilterFormTypes) {
        return disabledFilters && disabledFilters.indexOf(name) !== -1;
    }

    return (
        <div className='assessment-filters-form-wrapper'>
            <Form {...formProps} onFinish={onFormSubmit} layout='vertical' form={form}>
                {!isDisabled(Title) &&
                <Form.Item name={Title}>
                    <Input size='large' placeholder='Search by name'/>
                </Form.Item>}
                {!isDisabled(Vendor) &&
                <Form.Item name={Vendor}>
                    <VendorSelect/>
                </Form.Item>}
                {!isDisabled(Client) &&
                <Form.Item name={Client}>
                    <ClientSelect/>
                </Form.Item>}
                {!isDisabled(Status) &&
                <Form.Item name={Status}>
                    <StatusSelect/>
                </Form.Item>}
                <Form.Item>
                    <Button htmlType='submit' icon={<SearchOutlined/>}>
                        Search
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AssessmentFilters;