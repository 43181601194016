import React, { useEffect, useState } from "react"
import { Alert, Form, notification } from "antd"

import "./styles.scss"

import { IRiskFinding } from "../../../../api/RiskFinding/RiskFindingTypes"
import Button from "../../../common/Button/Button"
import { ArrowRightOutlined } from "@ant-design/icons"
import TasksContainer from "./Tasks/TasksContainer"
import TaskApi from "../../../../api/Task/TaskApi"
import {
  ETaskEvents,
  ETaskStatuses,
  ETaskTypes,
} from "../../../../api/Task/TaskTypes"
import { ERROR_TOAST_DURATION, TOAST_DURATION } from "../../../../constants"
import { isEmpty } from "lodash"
import RiskFindingUtils from "../../../../global/utils/riskFindingUtils"

interface Props {
  riskFinding: IRiskFinding
  onSubmit?: (values: any) => void
  onChange?: (values: any) => void
  disabled?: boolean
}

export interface IListFormWrapper<TFormValues> {
  items: TFormValues[]
}

export interface ICorlClearedTaskFormValues {
  id: string
  attestation?: boolean
  text: string
}

function CorlClearedResponseForm({
  riskFinding,
  onSubmit,
  onChange,
  disabled,
}: Props) {
  const isDisabled = disabled
    ? disabled
    : (riskFinding.vendor_remediate === null ||
        riskFinding.vendor_remediate === undefined) &&
      (riskFinding.vendor_valid === undefined ||
        riskFinding.vendor_valid === null)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)

  const [form] = Form.useForm<IListFormWrapper<ICorlClearedTaskFormValues>>()

  useEffect(() => {
    return function cleanup() {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  async function onFormSubmit(
    values: IListFormWrapper<ICorlClearedTaskFormValues>
  ) {
    if (submittingForm || !canSubmit) return

    setSubmittingForm(true)

    let errors: boolean = false
    for (const task of values.items) {
      const existingTask = riskFinding.tasks?.find(
        (t) => t.id.toString() === task.id
      )
      if (existingTask?.status === ETaskStatuses.Complete) continue

      const payload = {
        attestation: task.attestation,
        text: task.text,
      }

      const response = await TaskApi.updateTask(task.id, {
        ...payload,
        event: ETaskEvents.VendorSubmitted,
      })
      if (!response) errors = true
    }
    if (!errors) {
      notification.success({
        message: "Success!",
        description: (
          <span>
            Thank you for providing an update on remediation for this Risk
            Finding. If your response does not meet the requirements for this
            control, CORL will reach out for additional information.
          </span>
        ),
        duration: TOAST_DURATION,
      })
    } else {
      notification.error({
        message: "Error submitting Tasks",
        description: (
          <span>
            At least one of your tasks could not be submitted. Please reach out
            to support for help.
          </span>
        ),
        duration: ERROR_TOAST_DURATION,
      })
    }
    if (onSubmit) await onSubmit(values)
    setSubmittingForm(false)
  }

  async function onTaskChange(
    values: ICorlClearedTaskFormValues[],
    overrideSubmit?: boolean
  ) {
    if (overrideSubmit !== undefined && overrideSubmit !== null) {
      setCanSubmit(overrideSubmit)
    } else {
      const finalTask = RiskFindingUtils.getFinalTask(riskFinding.tasks)
      let canSave = false
      for (const value of values) {
        const task = riskFinding.tasks?.find(
          (t) => t.id.toString() === value.id
        )
        if (!task) continue

        let taskText = task.text?.trim()
        if (isEmpty(taskText)) taskText = ""

        if (value.attestation !== task.attestation) {
          canSave = true
          break
        }
      }
      setCanSubmit(canSave)
    }

    setSubmittingForm(true)
    if (onChange) onChange({})
    setSubmittingForm(false)
  }

  return (
    <div className="risk-finding-response-form-wrapper">
      <div style={{ marginBottom: "8px" }}>
        You have an action item for this risk finding, Please update your
        response below. If the finding is against any key control, you must
        provide evidence. If you need to provide evidence over a screenshare or
        phone call, please submit a Help ticket.
      </div>
      <div
        className="risk-finding-remediation-response-form-title"
        style={{ fontWeight: 600, marginTop: "6px" }}
      >
        Remediated Response Form
      </div>
      <Alert
        className="risk-finding-drawer-container"
        type={"warning"}
        message={
          <div>
            <strong>Reason for Task:</strong>
          </div>
        }
        description={
          <div className="risk-finding-drawer-container-content">
            {riskFinding.remediation_scenario}
          </div>
        }
      />
      <div style={{ marginTop: "16px" }}>
        {riskFinding.tasks && (
          <TasksContainer
            riskFinding={riskFinding}
            tasks={riskFinding.tasks}
            loading={submittingForm}
            disabled={isDisabled}
            form={form}
            onSubmit={onFormSubmit}
            onChange={onTaskChange}
          />
        )}
      </div>
      {!isDisabled && (
        <div className="risk-finding-response-submit-button">
          <Button
            form={`corl-cleared-response-form-${riskFinding.id}`}
            loading={submittingForm}
            icon={<ArrowRightOutlined />}
            iconPosition="right"
            enabled={canSubmit}
          >
            Save Remediated Response
          </Button>
        </div>
      )}
    </div>
  )
}

export default CorlClearedResponseForm
