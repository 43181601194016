import { ITaskFile } from "../TaskFile/TaskFileTypes"

export interface ITask {
  id: number
  description: string
  sequence?: number
  due_date?: Date
  status: ETaskStatuses
  type: ETaskTypes
  attestation?: boolean
  text: string
  task_files: ITaskFile[]
  vendor_id: number
  risk_finding_id?: number
  assessment_id?: number
}

export enum ETaskStatuses {
  InProgress = "in_progress",
  Inadequate = "inadequate",
  Complete = "complete",
  Submitted = "submitted",
}

export enum ETaskTypes {
  Attestation = "attestation",
  Evidence = "evidence",
  Text = "text",
}

export enum ETaskEvents {
  VendorSubmitted = "task_vendor_submit",
}
