import AppStore from "../stores/App/AppStore";
import {LSAuthTokenKey} from "../api/Base/BaseTypes";

export default class AppActions {

    static async toggleSidebarCollapse(){
        AppStore.sidebarCollapsed = !AppStore.sidebarCollapsed;
        localStorage.setItem('corl_sidebar_collapsed', AppStore.sidebarCollapsed.toString());
    }

    static logout(){
        try{
            localStorage.removeItem(LSAuthTokenKey);
            window.location.replace('/');
        }catch (e) {
            console.log('Could not logout user');
        }
    }
}