import React, {useEffect, useState} from 'react';
import {Form, Input, notification} from "antd";
import {TOAST_DURATION} from '../../../constants';

import './styles.scss';

import {QBuilderFormTypes} from "./Types";
import Button from "../../common/Button/Button";
import TemplateAPI from "../../../api/Template/TemplateAPI";
import {ITemplate} from "../../../api/Template/TemplateTypes";
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI";
import TemplateSelect from "../TemplateSelect/TemplateSelect";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";
import cx from "classnames";
import {AssessBuilderFormTypes} from "../AssessmentForm/Types";
import ProductSelect from "../ProductSelect/ProductSelect";
import ImplementationSelect from "../ImplementationSelect/ImplementationSelect";
import {ArrowRightOutlined} from "@ant-design/icons";


interface Props {
    applyDrawerStyles?: boolean
    assessment: IAssessment,
    onSubmit?: (values: any) => void,
}

function QuestionnaireForm(props: Props) {
    const {applyDrawerStyles, assessment, onSubmit} = props;
    const [form] = Form.useForm();

    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [submittingForm, setSubmittingForm] = useState(false);

    async function getTemplates() {
        let templates = await TemplateAPI.getTemplates();
        if (templates) setTemplates(templates);
    }

    useEffect(() => {
        getTemplates();

        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const initialValues = {}
    const formProps = {
        requiredMark: 'optional',
        colon: false,
        className: cx('questionnaire-form', {'as-drawer': applyDrawerStyles})
    };

    async function onSubmitClick() {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && assessment) {
                let values = form.getFieldsValue();
                values = {
                    ...values,
                    vendor: assessment.vendor.id,
                    client: assessment.client.id,
                    assessment: assessment.id,
                }
                let questionnaire = await QuestionnaireAPI.createQuestionnaire(values);
                if (questionnaire) {
                    const {vendor, client, title} = questionnaire;
                    notification.success({
                        message: 'Success!',
                        description:
                            <span>Your questionnaire for {vendor.name} on behalf of {client.name}, <b>{title}</b>, has been successfully created. {vendor.name} may now start their questionnaire.</span>,
                        duration: TOAST_DURATION,

                    })
                    form.resetFields();
                    if (onSubmit) onSubmit(values);
                } else {
                    notification.error({
                        message: 'Error creating questionnaire!',
                        description: <span>Your questionnaire <b>{values.title}</b> could not be created.</span>,
                        duration: TOAST_DURATION,
                    })
                }
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    function onValuesChange(v) {
        setValuesForm(v)
    }

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm} icon={<ArrowRightOutlined/>} iconPosition='right'>
                        Create Questionnaire
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm} icon={<ArrowRightOutlined/>} iconPosition='right'>
                    Create Questionnaire
                </Button>
            )
        }
    }

    return (
        <div className='questionnaire-form-wrapper'>
            <Form {...formProps} layout='vertical' form={form} onValuesChange={onValuesChange}
                  initialValues={initialValues}>
                <Form.Item label='Questionnaire Name' name={QBuilderFormTypes.Title}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Template' name={QBuilderFormTypes.Template}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <TemplateSelect templates={templates}/>
                </Form.Item>
                <Form.Item style={{marginBottom: 18}} label='Product'
                           name={AssessBuilderFormTypes.Product} validateTrigger='onSubmit'
                           help={`Showing products for ${assessment.vendor.name}`}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <ProductSelect vendorId={assessment.vendor.id}/>
                </Form.Item>
                <Form.Item label='Implementation' name={AssessBuilderFormTypes.Implementation} style={{paddingTop: '16px'}}
                           validateTrigger='onSubmit' rules={[{required: true, message: 'This field is required.'}]}>
                    <ImplementationSelect/>
                </Form.Item>
            </Form>

            {renderButton()}
        </div>
    )
}

export default QuestionnaireForm;