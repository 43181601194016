import React, { useState } from 'react';

import './styles.scss';
import {Drawer, notification, Table} from "antd";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes";
import {IUser} from "../../../api/User/UserTypes";
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI";
import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes";
import {QuestionnaireTableConfig} from "./QuestionnaireTableConfig";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import EditQuestionnaireForm from "../EditQuestionnaireForm/EditQuestionnaireForm";
import { TOAST_DURATION } from '../../../constants';

interface Props {
    user?: IUser,
    assessment?: IAssessment,
    disableFilters?: boolean,
    pagination?: boolean,
    questionnaires?: IQuestionnaire[],
    onQuestionnaireChange:()=>void,
}

function QuestionnaireTable(props: Props) {
    const {pagination, questionnaires, onQuestionnaireChange} = props;
    const [curQuestionnaire, setCurQuestionnaire] = useState<IQuestionnaire>();

    // async function onQuestionnaireDeleteClick(questionnaireId: number) {
    //     let deleted = await QuestionnaireAPI.deleteQuestionnaire(questionnaireId);
    //     if (deleted) {
    //         await onQuestionnaireChange();
    //     }
    // }

    async function onSubmitEditQuestionnaire() {
        setCurQuestionnaire(undefined);
        await onQuestionnaireChange();
    }

    async function onEditClick(questionnaireId: number) {
        if (!questionnaires) return;
        let filtered = questionnaires.filter(questionnaire => questionnaire.id === questionnaireId);
        if (filtered.length === 1) setCurQuestionnaire(filtered[0]);
    }

    async function onClickSendQuestionnaireCreateEmail(questionnaireId: number) {
        if (!questionnaires) return;
        let response = await QuestionnaireAPI.resendCreateQuestionnaireEmail(questionnaireId);
        if(response) {
            notification.success({
                message: 'Success!',
                description: <span>Questionnaire created email has been sent to Vendor.</span>,
                duration: TOAST_DURATION,
            });
        } else {
            notification.error({
                message: 'Error sending email',
                description: <span>Could not send email. Please try again later.</span>,
                duration: TOAST_DURATION,
            })
        }
    }

    if (!questionnaires) return <DefaultLoader text='Loading questionnaires...'/>

    return (
        <div className='questionnaire-table'>
            <Drawer title='Edit Questionnaire' width='50%' keyboard={false} open={!!curQuestionnaire}
                    destroyOnClose onClose={() => setCurQuestionnaire(undefined)}
                    footer={null} className='questionnaire-form-drawer'>
                <EditQuestionnaireForm applyDrawerStyles questionnaire={curQuestionnaire}
                                    onSubmit={onSubmitEditQuestionnaire}/>
            </Drawer>
            <div className='questionnaire-table-content'>
                <Table rowKey='id' dataSource={questionnaires}
                       columns={QuestionnaireTableConfig(onQuestionnaireChange, onEditClick, onClickSendQuestionnaireCreateEmail)}
                       pagination={pagination as any}/>
            </div>
        </div>

    )
}

    export default QuestionnaireTable;