import React from 'react';
import {Route, RouteComponentProps, Switch, withRouter} from 'react-router-dom';

import './styles.scss';

import QuestionnaireRequest from "../../components/specific/Questionnaire/QuestionnaireRequest";
import Assessment from "../../components/specific/Assessment/Assessment";
import {BrowserRoutes} from "../../stores/App/BrowserRouter";
import {observer} from "mobx-react";
import AppStore from "../../stores/App/AppStore";


interface Props extends RouteComponentProps<{ assessmentId:string }> {

}

function AssessmentContainer(props:Props) {

    return (
        <div className='assessment-container'>
            <Switch>
                <Route exact path={BrowserRoutes.assessmentsByIdParam} render={({ match })=>
                <Assessment assessmentId={match.params.assessmentId} user={AppStore.user}/>}/>
                <Route path='/assessments/:assessmentId/questionnaires/:questionnaireId' component={QuestionnaireRequest}/>
            </Switch>
        </div>
    )
}

export default withRouter(observer(AssessmentContainer));