import React, {useEffect, useState} from 'react';

import './styles.scss';

import {Table, notification} from "antd";
import {UserRequestsTableConfig} from "./UserRequestsTableConfig";
import UserRequestsApi from '../../../api/UserRequest/UserRequestApi';
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import { IUserRequest } from '../../../api/UserRequest/UserRequestTypes';
import AppStore from '../../../stores/App/AppStore';
import { TOAST_DURATION } from '../../../constants';
import UserAPI from '../../../api/User/UserAPI';
import StrapiUtils from '../../../global/utils/StrapiUtils';

interface Props {
}

function UserRequestsTable(props: Props) {
    const [userRequests, setUserRequests] = useState<IUserRequest[]>([]);
    const [curPage, setCurPage] = useState<number>(1);
    const [numUserRequests, setNumUserRequests] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    const userRequestsLimit = 25;

    async function getUserRequests(params?: any) {
        let curParams = {
            _limit: userRequestsLimit,
            _start: (curPage - 1) * userRequestsLimit,
            _sort: 'created_at:DESC'
        }
        if (params) {
            curParams = {...curParams, ...params};
        }

        let curUserRequests = await UserRequestsApi.get(curParams);
        if (curUserRequests) setUserRequests(curUserRequests);

        let numUserRequests = await UserRequestsApi.getCount(curParams);
        setNumUserRequests(numUserRequests);
    }

    useEffect(() => {
        getUserRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onTableChange(pagination: any, filter: any, sorter: any, extra: any) {
        if (extra.action === 'paginate') {
            setCurPage(pagination.current);
            await getUserRequests({_start: (pagination.current - 1) * userRequestsLimit})
        } else if (extra.action === 'sort') {
            await onSort(sorter, pagination.current);
        }
    }

    let pagination = {
        pageSize: userRequestsLimit,
        total: numUserRequests,
        current: curPage,
        showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
    }

    async function onSort(sorter: any, page: number) {
        let sortString = StrapiUtils.tableSortToSortString(sorter.field, sorter.order);
        let params: any = {_start: (page - 1) * userRequestsLimit};
        if (sortString) {
            params._sort = sortString;
        }
        await getUserRequests(params)
    }


    if (!userRequests || userRequests.length === 0) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading user requests...'/>
            </div>
        )
    }

    async function onApprove(userRequest: IUserRequest) {
        setLoading(true);
        let response = await UserRequestsApi.update(userRequest.id, {approved: AppStore?.user?.email, approved_on: new Date()});
        if (response) {
            let inviteUserRequest = {
                email: userRequest.email,
                first_name: userRequest.first_name,
                last_name: userRequest.last_name,
            }

            if(userRequest.vendor && !userRequest.client) {
                if(userRequest.role.name === 'Vendor') {
                    await UserAPI.inviteVendorEmployee(inviteUserRequest, userRequest.vendor.id);
                } else {
                    await UserAPI.inviteVendorAdmin(inviteUserRequest, userRequest.vendor.id);
                }
            } else if (userRequest.client && !userRequest.vendor) {
                if(userRequest.role.name === 'Client') {
                    await UserAPI.inviteClientEmployee(inviteUserRequest, userRequest.client.id);
                } else {
                    await UserAPI.inviteClientAdmin(inviteUserRequest, userRequest.client.id);
                }
            }

            notification.success({
                message: 'User request approved',
                description: `User request for ${userRequest.email} has been approved. User has been invited to the system.`,
                duration: TOAST_DURATION});
            await getUserRequests();
        } else {
            notification.error({
                message: 'Error approving user request',
                description: `User request for ${userRequest.email} has not been approved. Please contact support.`,
                duration: TOAST_DURATION});
        }
        setLoading(false);
    }

    async function onDeny(userRequest: IUserRequest) {
        setLoading(true);
        let response = await UserRequestsApi.update(userRequest.id, {denied: AppStore?.user?.email, denied_on: new Date()});
        if (response) {
            notification.success({
                message: 'User request denied',
                description: `User request for ${userRequest.email} has been denied. User has not been invited to the system.`,
                duration: TOAST_DURATION});
            getUserRequests();
        } else {
            notification.error({
                message: 'Error denying user request',
                description: `User request for ${userRequest.email} has not been denied. Please contact support.`,
                duration: TOAST_DURATION});
        }
        setLoading(false);
    }

    return (
        <div className='user-requests-table'>
            <div className='user-requests-table-header'>
                <div className='title'>
                    User Requests
                </div>
            </div>
            <div className='user-requests-table-content'>
            <Table rowKey='id' dataSource={userRequests} columns={UserRequestsTableConfig(onApprove, onDeny, !loading)}
                pagination={pagination} onChange={onTableChange}/>
            </div>
        </div>

    )
}

export default UserRequestsTable;