import Progress from "antd/es/progress"
import React from "react"
import { IRiskFinding } from "../../../../api/RiskFinding/RiskFindingTypes"

import "./styles.scss"
import RiskFindingUtils from "../../../../global/utils/riskFindingUtils"

interface Props {
  riskFindings?: IRiskFinding[]
}

function RiskFindingsProgressBar({ riskFindings }: Props) {
  function getPercentageComplete() {
    let answered = getAnsweredFindings()
    let total = getTotalNumFindings()
    let percent = 0
    if (answered && total) percent = Math.round((answered / total) * 100)
    return percent
  }

  function getAnsweredFindings() {
    return riskFindings?.filter(
      (r) => r.client_required && RiskFindingUtils.isRiskFindingAnswered(r)
    ).length
  }

  function getTotalNumFindings() {
    return riskFindings?.filter((r) => r.client_required).length
  }

  return (
    <>
      {riskFindings &&
        riskFindings.filter((r) => r.client_required).length > 0 && (
          <div className="risk-findings-progress-bar">
            <div className="answered-length">
              {getAnsweredFindings()}/{getTotalNumFindings()} Required Risk
              Findings Answered
            </div>
            <Progress
              style={{ minWidth: 150 }}
              percent={getPercentageComplete()}
              size="small"
              trailColor="#c5c5c5"
              strokeColor="#16c784"
            />
          </div>
        )}
    </>
  )
}

export default RiskFindingsProgressBar
