import React from 'react';
import cx from 'classnames';
import {CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import './styles.scss';

import {useImportJobs} from "../../../hooks/ImportJobs.hook";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";
import {StringUtils} from "../../../global/utils/string";

interface Props {
    importJobId: number,
}

function ImportJob(props: Props) {
    const {importJobId} = props;
    const {
        importJobs,
        fetchingImportJobs,
        importJobsRequestError,
    } = useImportJobs(importJobId)

    if (!fetchingImportJobs && !importJobs) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text="Loading import jobs..."/>
            </div>
        )
    }
    if (importJobsRequestError) {
        return <div>There was an error getting your import jobs.</div>
    }

    if (!importJobs) return <div>No import job</div>;

    if (importJobs.length === 1) {
        const {overview, status} = importJobs[0];

        const groupItems = () => {
            let items: any = {};
            if (overview?.errors) {
                Object.keys(overview.errors).forEach(key => {
                    items[key] = {
                        errors: overview.errors[key],
                        validations: overview.validations[key],
                    }
                })
            }
            return items;
        }

        let groupedItems = groupItems();

        return (
            <div className='import-job'>
                <div className='import-job-header'>
                    Import Job Overview ({StringUtils.camelCaseToRegular(status, false, true)})
                </div>
                <div className='overview'>
                <div className='current-import-item-status'>
                        Item Types to Import: {overview?.entitiesToImport?.map(e => StringUtils.capitalize(e)).join(", ")}
                    </div>
                    <div className='current-import-item-status'>
                        Currently Importing: {StringUtils.capitalize(overview?.currentEntityImporting)}
                    </div>

                    {Object.keys(groupedItems).map((key, i) => {
                        let errors = groupedItems[key].errors;
                        let validations = groupedItems[key].validations;
                        let hasErrors = errors?.length > 0;
                        let hasValidations = validations?.length > 0;

                        let icon = <CheckCircleOutlined/>;
                        if (hasErrors) {
                            icon = <CloseCircleOutlined/>;
                        } else if (hasValidations) {
                            icon = <ExclamationCircleOutlined/>;
                        }

                        return (
                            <div key={i} className='single-import-job-item'>
                                <div className={cx('single-import-job-item-header', {
                                    error: hasErrors,
                                    validation: hasValidations
                                })}>
                                    {icon}{StringUtils.capitalize(key)}
                                </div>
                                {hasErrors &&
                                <div className='errors'>
                                    <div className='errors-header'>Errors</div>
                                    <div className='errors-content'>
                                        {errors.map((error: string, i: number) => {
                                            return <div key={i} className='single-error'>{error}</div>
                                        })}
                                    </div>
                                </div>}
                                {hasValidations &&
                                <div className='validations'>
                                    <div className='validations-header'>Needs Validation</div>
                                    <div className='validations-content'>
                                        {validations.map((validation: any, i: number) => {
                                            return <div key={i} className='single-validation'>
                                                <div>Item: {validation.item?.name}</div>
                                                <div>Validation: {validation.toValidate}</div>
                                            </div>
                                        })}
                                    </div>
                                </div>}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ImportJob;
