import React, { useState } from "react"
import { PlusOutlined, CloseOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import "./styles.scss"
import Button from "../../common/Button/Button"
import VendorFilesTable from "../VendorFilesTable/VendorFilesTable"
import { IVendorFile } from "../../../api/VendorFile/VendorFileTypes"

interface Props {
  vendorId?: number
  disabled?: boolean
  onRowClick?: (vendorFile: IVendorFile) => void
}

function VendorFileWidget({ vendorId, disabled, onRowClick }: Props) {
  const [showUpload, setShowUpload] = useState(false)

  const handleCancel = (e: any) => {
    e.stopPropagation()
    setShowUpload(false)
  }

  async function onClickVendorRow(vendorFile: IVendorFile) {
    if (disabled) return
    if (onRowClick) await onRowClick(vendorFile)
    setShowUpload(false)
  }

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        onClick={() => setShowUpload(true)}
        enabled={!disabled}
        htmlType="button"
      >
        Add Existing File
      </Button>
      {showUpload && (
        <Modal
          open={showUpload}
          title="Add Existing File"
          bodyStyle={{ padding: 24 }}
          className="vendor-file-widget-modal"
          centered
          width={800}
          onCancel={handleCancel}
          destroyOnClose
          footer={
            <div className="upload-modal-footer">
              <Button
                key="back"
                color="clear"
                onClick={handleCancel}
                style={{ marginRight: 12 }}
                icon={<CloseOutlined />}
              >
                Cancel
              </Button>
            </div>
          }
        >
          <VendorFilesTable
            vendorId={vendorId}
            disableHeader
            onClickRow={onClickVendorRow}
            disableActions={true}
          />
        </Modal>
      )}
    </div>
  )
}

export default VendorFileWidget
