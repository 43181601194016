import React, { useState } from "react"
import { Alert, Tooltip, Checkbox, notification } from "antd"
import Button from "../../common/Button/Button"
import {
  PaperClipOutlined,
  CopyOutlined,
  ArrowRightOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons"
import cx from "classnames"

import "./styles.scss"

import { IResponse } from "../../../api/Response/ResponseTypes"
import { IQuestion } from "../../../api/Question/QuestionTypes"
import ResponseAPI from "../../../api/Response/ResponseAPI"
import moment from "moment"
import { StringUtils } from "../../../global/utils/string"
import {
  EQuestionnaireStatus,
  IQuestionnaire,
} from "../../../api/Questionnaire/QuestionnaireTypes"
import { RESPONSE_CHOICES, TOAST_DURATION } from "../../../constants"
import { IVendorFile } from "../../../api/VendorFile/VendorFileTypes"
import VendorFileAPI from "../../../api/VendorFile/VendorFileApi"
import QuestionEvidence from "./QuestionEvidence"
import QuestionResponseForm from "./QuestionResponseForm"
import { EFileUploadTypes } from "../../../api/File/FileTypes"
import QuestionnaireUtils from "../../../global/utils/questionnaireUtils"

interface Props {
  question: IQuestion
  num: number
  vendorId?: number
  questionnaire: IQuestionnaire
  response?: IResponse
  disabled?: boolean
  approvalRequired?: boolean
  onChange: (response: IResponse) => void
  onSave?: (time: "start" | "end") => void
}

function Question(props: Props) {
  const {
    question: questionModel,
    num,
    vendorId,
    questionnaire,
    response,
    disabled,
    approvalRequired,
    onChange,
    onSave,
  } = props
  const [approved, setApproved] = useState(response?.approved)
  const [savingQuestion, setSavingQuestion] = useState(false)

  const { id, text } = questionModel

  async function onValuesChange(formFields: any) {
    if (onSave) onSave("start")
    setSavingQuestion(true)
    let data = {
      response_choice: formFields?.response_choice,
      response_text: formFields?.response_text,
      responseId: response?.id,
    }

    if (
      (formFields?.response_choice === RESPONSE_CHOICES.Yes ||
        formFields?.response_choice === RESPONSE_CHOICES.Partial) &&
      response?.skip_evidence
    ) {
      await ResponseAPI.setSkipEvidence(questionnaire.id, {
        skip_evidence: false,
        responseId: response.id,
      })
    }

    let responseResult = await ResponseAPI.saveControlResponse(
      id,
      questionnaire,
      data
    )
    if (onChange) onChange(responseResult)
    if (onSave) onSave("end")

    setSavingQuestion(false)
  }

  let initialValues = {
    response_choice: response?.response_choice,
    response_text: response?.response_text,
    responseId: response?.id,
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`${questionModel.control_number}: ${text}`)
  }

  async function onFilesChanged() {
    setSavingQuestion(true)
    if (response && response.response_choice !== null) {
      let controlResponse = await ResponseAPI.getControlResponse(
        questionnaire.id,
        response.id
      )
      if (controlResponse) {
        if (onChange) onChange(controlResponse)
      }
    }
    setSavingQuestion(false)
  }

  async function deleteResponseFile(responseFileId: number) {
    let res = await ResponseAPI.deleteResponseFile(
      questionnaire.id,
      responseFileId
    )
    if (res) {
      await onFilesChanged()
    }
  }

  function getIdentifier() {
    if (questionModel.control_number) return questionModel.control_number
    else return num + 1
  }

  async function approvalToggle() {
    if (!response) return
    if (response.response_choice === null) return

    setSavingQuestion(true)

    if (onSave) onSave("start")

    let data = {
      approved: !response.approved,
      responseId: response.id,
    }

    let responseResult = await ResponseAPI.approveControlResponse(
      questionnaire.id,
      data
    )
    setApproved(!approved)
    if (onChange) onChange(responseResult)
    if (onSave) onSave("end")

    setSavingQuestion(false)
  }

  async function onClickVendorRow(vendorFile: IVendorFile) {
    if (response) {
      let responseFile = await VendorFileAPI.attachVendorFileToResponseFile(
        vendorFile.id,
        response.id
      )
      if (responseFile) {
        onFilesChanged()
      } else {
        notification.error({
          message: "Error attaching file to response",
          description: (
            <span>
              {vendorFile.file_name} may already be attached to response
            </span>
          ),
          duration: TOAST_DURATION,
        })
      }
    }
  }

  return (
    <div className={cx("question-container", { disabled })}>
      <div className="question">
        <div className="question-header">
          <div className="question-header-left">
            <div className="clipboard-shortcut" onClick={copyToClipboard}>
              <Tooltip title="Copy to Clipboard">
                <CopyOutlined style={{ color: "gray", marginRight: 9 }} />
              </Tooltip>
            </div>
            <div
              className={cx("identifier", {
                complete: QuestionnaireUtils.isQuestionComplete(
                  response,
                  questionModel,
                  approvalRequired,
                  questionModel.isKeyControl
                ),
              })}
            >
              {getIdentifier()}
            </div>
            {questionModel.isKeyControl && (
              <div className="key-control">
                <PaperClipOutlined
                  className="icon"
                  style={{ marginRight: 6 }}
                />
                <div>
                  This is a Key Control. Upload evidence file(s) for this
                  Control Question if possible.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="text">{text}</div>
        {response &&
          response.response_choice !== null &&
          response.previous_response &&
          Object.keys(response.previous_response).length > 0 && (
            <Alert
              type="info"
              message="This question has been pre-answered."
              style={{ width: "100%", margin: "12px 0 0 0" }}
              description={
                <div className="alert-description">
                  <div style={{ marginBottom: 12 }}>
                    You previously answered a question with a matching IRN from
                    a completed questionnaire. Details below:
                  </div>
                  <div style={{ margin: "12px 0" }}>
                    <i>
                      Although this question has been pre-answered, you may
                      override it with more relevant/correct values. This alert
                      will continue to display your previous answer, regardless
                      of the changes made to this question.
                    </i>
                  </div>
                  <div>
                    Questionnaire:{" "}
                    <b>{response.previous_response.questionnaire.title}</b>
                  </div>
                  <div>
                    Last answered on:{" "}
                    <b>
                      {moment(response.previous_response.updated_at).format(
                        "MMM DD, YYYY"
                      )}
                    </b>
                  </div>
                  <div>
                    Answer:{" "}
                    <b>
                      {StringUtils.capitalize(
                        response.previous_response.response_choice
                      )}
                    </b>
                  </div>
                  <div>
                    Comment:{" "}
                    <b>{response.previous_response.response_text || "None"}</b>
                  </div>
                  <div>
                    Files:{" "}
                    <b>
                      {response.previous_response.response_files?.length ||
                        "None"}
                    </b>
                  </div>
                  {response &&
                    response.response_choice !== null &&
                    response.previous_response &&
                    approvalRequired && (
                      <>
                        {!approved && (
                          <div
                            style={{
                              color: "#e7872b",
                              marginTop: 12,
                              fontWeight: 600,
                            }}
                          >
                            WARNING: This Client requires that Vendors must
                            review and approve pre-answered questions. Please
                            review that your answer is correct and press the
                            Approve Answer button below if your pre-populated
                            answer is correct.
                          </div>
                        )}
                        <Button
                          loading={savingQuestion}
                          enabled={!disabled}
                          className="approve-button"
                          color={approved ? "green" : "orange"}
                          icon={
                            approved ? (
                              <CheckCircleOutlined
                                style={{
                                  color: "white !important",
                                  fill: "white",
                                }}
                              />
                            ) : (
                              <ArrowRightOutlined />
                            )
                          }
                          iconPosition={approved ? "left" : "right"}
                          onClick={approvalToggle}
                        >
                          {approved ? "Approved" : "Approve Answer"}
                        </Button>
                      </>
                    )}
                </div>
              }
            />
          )}
      </div>
      <QuestionResponseForm
        entityId={id}
        disabled={disabled}
        initialValues={initialValues}
        onChange={onValuesChange}
      />
      {response &&
        response.response_choice !== null &&
        !response.skip_evidence && (
          <QuestionEvidence
            files={response.response_files}
            entityId={response.id}
            vendorId={vendorId}
            disabled={savingQuestion}
            hideUpload={
              questionnaire.status !== EQuestionnaireStatus.InProgress
            }
            fileUploadType={EFileUploadTypes.Response}
            onFilesChanged={onFilesChanged}
            onFileDelete={deleteResponseFile}
            onRowClick={onClickVendorRow}
          />
        )}
    </div>
  )
}

export default Question
