import { IClient } from "../Client/ClientTypes"
import { IVendor } from "../Vendor/VendorTypes"
import { StrapiBaseFields } from "../Base/BaseTypes"

export enum UserRoles {
  CorlAdmin = "CORL Admin",
  CorlEmployee = "CORL Employee",
  ClientAdmin = "Client Admin",
  VendorAdmin = "Vendor Admin",
  VendorEmployee = "Vendor",
  ClientEmployee = "Client",
}

export interface IInvitation {
  consumed: string
  created_at: string
  email: string
  expiration: string
  first_name: string
  for_role: string
  id: number
  last_name: string
  org_id: number
  org_type: string
  otp: string
  updated_at: string
}

export interface IUser extends StrapiBaseFields {
  activated: number
  blocked: boolean | null
  email: string
  firstName: string
  id: number
  lastName: string
  role: IUserRole
  user_group: number
  username: string
  client?: IClient
  vendor?: IVendor
  user_datum: IUserDatum
  picture?: string
}

export interface IUserDatum extends StrapiBaseFields {
  first_name: string
  last_name: string
  accepted_terms_and_conditions?: boolean
}

export interface ISignupValues {
  username?: string
  first_name: string
  last_name: string
  email: string
  password: string
}

export interface IUserRole {
  description: string
  id: number
  name: string
  type: string
}

export interface ILoginValues {
  username: string
  password: string
}

export interface ILoginResponse {
  jwt: string
  user: IUser
}

export interface ISignupResponse {
  jwt: string
  user: IUser
}
