import axios from "axios";
import {IProduct} from "./ProductTypes";

export default class ProductAPI {

    static async getProducts() {
        try {
            let res = await axios.get('/products');
            return res.data as IProduct[];
        } catch (e) {
            console.error('Could not get products', e.message);
            return undefined;
        }
    }

    static async getProductsByVendorId(vendorId:number){
        try {
            let res = await axios.get(`/products/vendor/${vendorId}`);
            return res.data as IProduct[];
        } catch (e) {
            console.error('Could not get products', e.message);
            return undefined;
        }
    }

    static async getProductById(productId: number) {

    }


    static async createProduct(values: any) {
        try {
            let res = await axios.post('/products', values);
            return res.data as IProduct;
        } catch (e) {
            console.error('Could not create product', e.message);
            return undefined;
        }
    }
}