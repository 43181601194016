import React from 'react';

import './styles.scss';

import {EAssessmentStatus} from "../../../api/Assessment/AssessmentTypes";
import {ClockCircleOutlined, FieldTimeOutlined, CloudUploadOutlined, StopOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import { ERiskFindingStatus } from '../../../api/RiskFinding/RiskFindingTypes';

interface Props {
    status: string,
}

function StatusIcon(props: Props) {
    const {status} = props;
    let Icon;
    let color;

    switch (status) {
        case EAssessmentStatus.InProgress:
            Icon = FieldTimeOutlined;
            color = '#3861fb';
            break;
        case EAssessmentStatus.Submitted:
            Icon = CloudUploadOutlined;
            color = '#16c784';
            break;
        case EAssessmentStatus.Canceled:
            Icon = StopOutlined;
            color ='#ff203c';
            break;
        case EAssessmentStatus.OnHold:
            Icon = ClockCircleOutlined;
            color = '#e7872b';
            break;
        case EAssessmentStatus.GapValidationInProgress:
            Icon = FieldTimeOutlined;
            color = '#3861fb';
            break;
        case EAssessmentStatus.GapValidationSubmitted:
            Icon = CloudUploadOutlined;
            color = '#16c784';
            break;
        case EAssessmentStatus.GapValidationCompleted:
            Icon = CloudUploadOutlined;
            color = '#e7872b';
            break;
        case EAssessmentStatus.RemediationInProgress:
            Icon = FieldTimeOutlined;
            color = '#3861fb';
            break;
        case EAssessmentStatus.RemediationSubmitted:
            Icon = CloudUploadOutlined;
            color = '#16c784';
            break;
        case ERiskFindingStatus.Open:
            Icon = FieldTimeOutlined;
            color = '#3861fb';
            break;
        case ERiskFindingStatus.VendorCommited:
            Icon = ClockCircleOutlined;
            color = '#e7872b';
            break;
        case ERiskFindingStatus.Closed:
            Icon = StopOutlined;
            color = '#ff203c';
            break;
        default:
            return null;
    }

    return (
        <Tooltip className='title'>
            <Icon style={{fontSize:18, color}}/>
        </Tooltip>
    )
}

export default StatusIcon;