import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, notification, Tooltip} from "antd";
import cx from 'classnames';
import {TOAST_DURATION} from '../../../constants';

import './styles.scss';
import moment from 'moment';

import {EditAssessmentBuilderFormTypes} from "./Types";
import {IAssessment} from "../../../api/Assessment/AssessmentTypes"
import VendorSelect from "../VendorSelect/VendorSelect";
import ClientSelect from "../ClientSelect/ClientSelect";
import Button from "../../common/Button/Button";
import ClientAPI from "../../../api/Client/ClientAPI";
import {IClient} from "../../../api/Client/ClientTypes";
import {IVendor} from "../../../api/Vendor/VendorTypes";
import VendorAPI from "../../../api/Vendor/VendorAPI";
import AssessmentAPI from "../../../api/Assessment/AssessmentAPI";
import ProductSelect from "../ProductSelect/ProductSelect";
import {IProduct} from "../../../api/Product/ProductTypes";
import ProductAPI from "../../../api/Product/ProductAPI";
import ImplementationSelect from "../ImplementationSelect/ImplementationSelect";
import {IImplementation} from "../../../api/Implementation/ImplementationTypes";
import ImplementationAPI from "../../../api/Implementation/ImplementationAPI";
import StatusSelect from "../StatusSelect/StatusSelect";
import {ArrowRightOutlined, QuestionCircleOutlined} from "@ant-design/icons";


interface Props {
    onSubmit?: () => void,
    applyDrawerStyles?: boolean,
    assessment?: IAssessment
}

function EditAssessmentForm(props: Props) {
    const {onSubmit, applyDrawerStyles, assessment} = props;
    const [form] = Form.useForm();

    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [vendors, setVendors] = useState<IVendor[]>([]);
    const [clients, setClients] = useState<IClient[]>([]);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [implementations, setImplementations] = useState<IImplementation[]>([]);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [pocNameRequired, setPocNameRequired] = useState(assessment?.point_of_contact);
    
    async function getVendors() {
        let vendors = await VendorAPI.getVendors();
        setVendors(vendors);
    }

    async function getClients() {
        let clients = await ClientAPI.getClients();
        if (clients) {
            setClients(clients);
        }
    }

    async function getProducts(vendorId: number) {
        let products = await ProductAPI.getProductsByVendorId(vendorId);
        if (products) {
            setProducts(products);
        }
    }

    async function getImplementations() {
        let implementations = await ImplementationAPI.getImplementations();
        if (implementations) {
            setImplementations(implementations);
        }
    }


    useEffect(() => {
        getVendors();
        getClients();
        getImplementations();

        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const formProps = {
        requiredMark: 'optional',
        colon: false,
        className: cx('assessment-form', {'as-drawer': applyDrawerStyles})
    };

    async function onSubmitClick() {
        if (submittingForm || !assessment) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields) {
                let values = form.getFieldsValue();
                let response = await AssessmentAPI.editAssessment(assessment.id, values);
                if (response) {
                    const {vendor, client, title} = assessment;
                    notification.success({
                        message: 'Success!',
                        description: <span>Your assessment for {vendor.name} on behalf of {client.name}, <b>{title}</b>, has been successfully edited.</span>,
                        duration: TOAST_DURATION,

                    })
                    form.resetFields();
                    if (onSubmit) onSubmit();
                } else {
                    notification.error({
                        message: 'Error editing assessment!',
                        description: <span>Your assessment <b>{values.title}</b> could not be edited.</span>,
                        duration: TOAST_DURATION,
                    })
                }
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    if (!clients || !vendors){
        return null;
    }

    let initialValues = {
        [EditAssessmentBuilderFormTypes.Status]: assessment?.status,
        [EditAssessmentBuilderFormTypes.Title]: assessment?.title,
        [EditAssessmentBuilderFormTypes.Client]: assessment?.client?.id,
        [EditAssessmentBuilderFormTypes.Vendor]: assessment?.vendor?.id,
        [EditAssessmentBuilderFormTypes.DueDate]: moment(assessment?.due_date),
        [EditAssessmentBuilderFormTypes.CompletionDate]: assessment?.completion_date ? moment(assessment?.completion_date) : undefined,
        [EditAssessmentBuilderFormTypes.Product]: assessment?.product?.id,
        [EditAssessmentBuilderFormTypes.Implementation]: assessment?.implementation?.id,
        [EditAssessmentBuilderFormTypes.PointOfContact]: assessment?.point_of_contact,
        [EditAssessmentBuilderFormTypes.PointOfContactName]: assessment?.point_of_contact_name
    }

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit'
                            icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                        Edit Assessment
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit'
                        icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                    Edit Assessment
                </Button>
            )
        }
    }

    function onValuesChange(v) {
        setValuesForm(v);
        if (v[EditAssessmentBuilderFormTypes.Vendor]) {
            getProducts(v[EditAssessmentBuilderFormTypes.Vendor]);
            form.resetFields([EditAssessmentBuilderFormTypes.Product])
        }
    }

    function onPocValueChange(e) {
        if(form.getFieldValue(EditAssessmentBuilderFormTypes.PointOfContact) ? true : false) setPocNameRequired(true)
        else setPocNameRequired(false)
    }

    return (
        <div>
            <Form {...formProps} layout='vertical' form={form} initialValues={initialValues}
                  onValuesChange={onValuesChange}  className='assessment-form-wrapper'>
                <Form.Item label='Assessment Name' name={EditAssessmentBuilderFormTypes.Title}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Status' name={EditAssessmentBuilderFormTypes.Status}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <StatusSelect/>
                </Form.Item>
                <Form.Item label='Client' name={EditAssessmentBuilderFormTypes.Client} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <ClientSelect clients={clients} disabled={true}/>
                </Form.Item>
                <Form.Item label='Vendor' name={EditAssessmentBuilderFormTypes.Vendor} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <VendorSelect vendors={vendors} disabled={true}/>
                </Form.Item>
                <Form.Item label='Product' name={EditAssessmentBuilderFormTypes.Product} validateTrigger='onSubmit'
                           help={
                               !form.getFieldValue(EditAssessmentBuilderFormTypes.Vendor) ?
                                   <div style={{marginBottom: 12}}>You must choose a Vendor before choosing a
                                       Product.</div>
                                   :
                                   undefined
                           }>
                    <ProductSelect products={products}
                                   disabled={true}
                                   vendorId={form.getFieldValue(EditAssessmentBuilderFormTypes.Vendor)}/>
                </Form.Item>
                <Form.Item label='Implementation' name={EditAssessmentBuilderFormTypes.Implementation}
                           validateTrigger='onSubmit'>
                    <ImplementationSelect implementations={implementations} disabled={true}/>
                </Form.Item>
                <Form.Item label='Due Date' name={EditAssessmentBuilderFormTypes.DueDate} validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <DatePicker style={{width: '100%'}} size='large' format='MMM DD, YYYY'/>
                </Form.Item>
                <Form.Item label='Completion Date' name={EditAssessmentBuilderFormTypes.CompletionDate} validateTrigger='onSubmit'>
                    <DatePicker style={{width: '100%'}} size='large' format='MMM DD, YYYY'/>
                </Form.Item>
                <Form.Item label={<span>Point of Contact Email&nbsp;<Tooltip title="This should be a valid email. Only one email should be listed here."><QuestionCircleOutlined/></Tooltip></span>} 
                            name={EditAssessmentBuilderFormTypes.PointOfContact}
                            onChange={onPocValueChange}
                            rules={[{required: false, type: 'email', message: 'The input is not a valid email'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label={<span>Point of Contact Name&nbsp;
                    <Tooltip title="This should be the first and last name of the Point of Contact. Any email communications to this PoC will use this name."><QuestionCircleOutlined/></Tooltip></span>} 
                            name={EditAssessmentBuilderFormTypes.PointOfContactName}
                            rules={[{required: pocNameRequired, message: 'If specifying Point of Contact Email, you must also provide a name.'}]}>
                    <Input size='large'/>
                </Form.Item>
            </Form>
            {renderButton()}
        </div>
    )
}

export default EditAssessmentForm;