import React, {useEffect, useState} from 'react';
import {Form, Input} from 'antd';
import Button from "../../common/Button/Button";

import './styles.scss';

import {InviteUserFormTypes} from "./InviteUserFormTypes";
import cx from "classnames";
import {ArrowRightOutlined} from '@ant-design/icons';

interface Props {
    onSubmit: (values: any) => void
    applyDrawerStyles?:boolean,
}

function InviteUserForm(props: Props) {
    const {onSubmit, applyDrawerStyles} = props;
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [submittingForm, setSubmittingForm] = useState(false);


    useEffect(() => {

    }, [])


    async function onSubmitClick() {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && onSubmit) {
                let values = form.getFieldsValue();
                await onSubmit(values);
                form.resetFields();
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    function onValuesChange(v) {
        setValuesForm(v)
    }

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm} icon={<ArrowRightOutlined/>} iconPosition='right'>
                        Invite User
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm}>
                    Invite User
                </Button>
            )
        }
    }


    return (
        <Form className={cx('invite-user-form', {'as-drawer': applyDrawerStyles})} name="invite-user-form" layout='vertical' form={form}
              onFinish={onSubmitClick} autoComplete="off" preserve={false} onValuesChange={onValuesChange} requiredMark='optional'>
            <div className='invite-user-form-content'>
                <Form.Item label='Email' name={InviteUserFormTypes.Email} rules={[{required: true, message: 'This field is required and needs to be a valid email.', type: 'email'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='First Name' name={InviteUserFormTypes.FirstName} rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Last Name' name={InviteUserFormTypes.LastName} rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
            </div>
            {renderButton()}
        </Form>
    );
}

export default InviteUserForm;