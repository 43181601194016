import React from "react"

import "./styles.scss"

import { notification } from "antd"
import { TOAST_DURATION } from "../../../constants"
import DataSyncAPI from "../../../api/DataSync/DataSyncAPI"
import SendMessageDrawer from "./SendMessageDrawer"

function DataSyncAuditTable() {
  //const [messages, setMessages] = useState<IDataSyncMessage[]>();
  //const [sendingMessage, setSendingMessage] = useState<boolean>(false)

  // async function getMessages() {
  //     const currentMessages = await DataSyncAPI.getDeadletterMessages();
  //     if (currentMessages) setMessages(currentMessages);
  // }

  // useEffect(() => {
  //     getMessages();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // async function reSendMessage(messageId:string) {
  //     let message = messages?.find(m => m.id === messageId);
  //     if(!message) return;
  //     setSendingMessage(true);
  //     let response = await DataSyncAPI.resendMessage(message);
  //     if(response)
  //     {
  //         notification.success({
  //             message: 'Success',
  //             description:
  //                 <span>Message sent!</span>,
  //             duration: TOAST_DURATION,
  //         });
  //     }
  //     else
  //     {
  //         notification.error({
  //             message: 'Could not send message',
  //             description:
  //                 <span>Please refresh the page and try again. If the message is very old, this may fail often.</span>,
  //             duration: TOAST_DURATION,
  //         });
  //     }
  //     await getMessages();
  //     setSendingMessage(false);
  // }

  async function onSendMessageDrawerSubmit(values: any) {
    //setSendingMessage(true)
    let response = await DataSyncAPI.sendMessage(values)
    if (response) {
      notification.success({
        message: "Success",
        description: <span>Message sent!</span>,
        duration: TOAST_DURATION,
      })
    } else {
      notification.error({
        message: "Could not send message",
        description: (
          <span>
            Please try again or contact the developers with the event name and
            id used to send the message.
          </span>
        ),
        duration: TOAST_DURATION,
      })
    }
    //await getMessages();
    //setSendingMessage(false)
  }

  return (
    <div className="data-sync-audit-table">
      <div className="data-sync-audit-table-header">
        <div className="title">Data Sync Audit</div>
        <SendMessageDrawer
          onSubmit={onSendMessageDrawerSubmit}
          asButton
        ></SendMessageDrawer>
      </div>
      <div className="description">
        Failed sync events will show up here. You can re-send them to try the
        sync again. If you would like to investigate why a message failed,
        please go to&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://portal.azure.com/#@corltech.com/resource/subscriptions/f5e43f68-1b70-41c2-aaf2-5be6ec39ae31/resourceGroups/CORL_Frontend_Portal_Development/providers/microsoft.insights/components/corl-exchange-dynamics-event-handler/logs"
        >
          <u>
            the Azure Applications Insights service for the Live Sync
            application
          </u>
        </a>
        &nbsp;and use the following query:
        <div>
          <code className="azure-query">
            traces<br></br>| where message contains "
            <strong>INSERT_MESSAGE_ID_HERE</strong>"<br></br>| where message
            !contains "Trigger Details:"<br></br>| where message !contains
            "Received new message"<br></br>| project message, timestamp,
            <br></br>
            messageId =
            parse_json(tostring(customDimensions.prop__errorMessage)).EventMessageId,
            <br></br>
            eventMessage =
            parse_json(tostring(customDimensions.prop__errorMessage)).EventMessage,
            <br></br>
            error =
            parse_json(tostring(customDimensions.prop__errorMessage)).ErrorMessage,
            <br></br>
            stackTrace =
            parse_json(tostring(customDimensions.prop__errorMessage)).FullError
            <br></br>
          </code>
        </div>
        You may also edit the above query as you need to look for any other
        message failures or successes by any criteria, such as sent date.
      </div>
      {/* {messages && <div className='data-sync-audit-table-content'>
                <Table rowKey='id' dataSource={messages} columns={DataSyncAuditTableConfig(reSendMessage, sendingMessage)} pagination={false}/>
            </div>}
            {!messages && <div className='default-loader-full'>
                <DefaultLoader text='Loading failed sync events. The events may take a couple of minutes to load, please wait.'/>
            </div>} */}
    </div>
  )
}

export default DataSyncAuditTable
