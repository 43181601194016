import axios from "axios";
import {IQuestion} from "./QuestionTypes";
import {TQuestionType} from "../Base/BaseTypes";

export default class QuestionAPI {

    static async getQuestions(questionType?:TQuestionType, params:any = {}, query?:string){
        try {
            if (questionType) params['question_type'] = questionType;
            let url = '/control-questions';
            if (query) url += `?${query}`
            let res = await axios.get(url, {params});
            return res.data as IQuestion[];
        } catch (e){
            console.log('Could not get assessment templates', e.message);
            return undefined;
        }
    }

    static async createQuestion(values:string){
        try {
            let res = await axios.post('/control-questions', values);
            return res.data as IQuestion;
        } catch (e){
            console.log('Could not create assessment template', e.message);
            return undefined;
        }
    }
}