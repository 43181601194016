import React, {useState} from 'react';
import {Form, Input, Radio} from 'antd';
import Button from "../../common/Button/Button";

import './styles.scss';

import {QuestionFormTypes} from "./QuestionFormTypes";
import IRNSelect from "../IRNSelect/IRNSelect";
import {EQuestionType} from "../../../api/Base/BaseTypes";
import QuestionSelect from "../QuestionSelect/QuestionSelect";
import cx from "classnames";
import {ArrowRightOutlined} from '@ant-design/icons';

interface Props {
    onSubmit: (values: any) => void
    applyDrawerStyles?: boolean,
}

function QuestionForm(props: Props) {
    const {onSubmit, applyDrawerStyles} = props;
    const [form] = Form.useForm();
    // eslint-disable-next-line
    const [valuesForm, setValuesForm] = useState({});
    const [submittingForm, setSubmittingForm] = useState(false);

    async function onSubmitClick() {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && onSubmit) {
                let values = form.getFieldsValue();
                onSubmit(values);
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }

    function onValuesChange(v) {
        setValuesForm(v)
        if (v[QuestionFormTypes.QuestionType] === EQuestionType.Scoping) {
            form.setFieldsValue({
                    [QuestionFormTypes.ControlName]: undefined,
                    [QuestionFormTypes.IRN]: undefined,
                }
            )
        }
        if (v[QuestionFormTypes.IRN]) {
            form.setFieldsValue({
                [QuestionFormTypes.IRN]: v[QuestionFormTypes.IRN]
            })
        }
    }

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm}
                            icon={<ArrowRightOutlined/>} iconPosition='right'>
                        Create Question
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit' loading={submittingForm}>
                    Create Question
                </Button>
            )
        }
    }

    let initialValues = {[QuestionFormTypes.QuestionType]: EQuestionType.Control}

    return (
        <Form className={cx('questions-form', {'as-drawer': applyDrawerStyles})} name="questions-form" layout='vertical'
              form={form} initialValues={initialValues}
              autoComplete="off" preserve={false} onValuesChange={onValuesChange} requiredMark='optional'>
            <div className='questions-form-content'>
                <Form.Item label='Question Text' name={QuestionFormTypes.Text}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input.TextArea autoSize={{minRows: 2}} style={{resize: 'none'}}/>
                </Form.Item>
                <Form.Item label='Type' name={QuestionFormTypes.QuestionType}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Radio.Group defaultValue='control'>
                        <Radio value='control'>
                            Control
                        </Radio>
                        <Radio value='scoping'>
                            Scoping
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Control Number' name={QuestionFormTypes.ControlNumber}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large' placeholder='Input Control Number'/>
                </Form.Item>
                <Form.Item label='Control Group' name={QuestionFormTypes.ControlGroup}
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large' placeholder='Input Control Group'/>
                </Form.Item>
                <Form.Item label='IRN' name={QuestionFormTypes.IRN}
                    rules={[{required: true, message: 'This field is required.'}]}>
                    <IRNSelect loadIRNs/>
                </Form.Item>
                <Form.Item label='Control Name' name={QuestionFormTypes.ControlName}>
                    <Input size='large' placeholder='Input Control Name'/>
                </Form.Item>
                <Form.Item label='Vendor must answer:' name='depends_on'>
                    <QuestionSelect questionType={form.getFieldValue(QuestionFormTypes.QuestionType)}/>
                </Form.Item>
                {!!form.getFieldValue(QuestionFormTypes.DependsOn) &&
                <Form.Item label='With this answer:' name='dependency_text'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Radio.Group>
                        <Radio value='yes'>Yes</Radio>
                        <Radio value='no'>No</Radio>
                        <Radio value='partial'>Partial</Radio>
                        <Radio value='na'>N/A</Radio>
                    </Radio.Group>
                </Form.Item>}
            </div>
            {renderButton()}
        </Form>
    );
}

export default QuestionForm;