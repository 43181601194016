import React, {useState} from 'react';
import {Drawer} from 'antd';
import {PlusOutlined, CloudDownloadOutlined} from '@ant-design/icons';

import './styles.scss';
import Button from "../../common/Button/Button";
import TemplateBuilder from './TemplateBuilder';
import TemplateImporter from './TemplateImporter';

interface Props {
    className?: string,
    onSubmit?: (values: string) => void,
    asButton?: boolean,
}

function TemplateBuilderDrawer(props: Props) {
    const {className, onSubmit, asButton} = props;
    const [showForm, setShowForm] = useState(!asButton);
    const [showImportForm, setShowImportForm] = useState(!asButton);

    const onNewTemplateClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(true);
        setShowImportForm(false);
    }

    const onImportTemplateClick = async (e: any) => {
        e.stopPropagation();
        setShowForm(false);
        setShowImportForm(true);
    }

    const onTemplateImport = async (values: any) => {
        if (onSubmit) onSubmit(values);
        setShowImportForm(false);
    }

    const onFormSubmit = async (values: any) => {
        if (onSubmit) onSubmit(values);
        setShowForm(false);
    }

    return (
        <div className="template-buttons" onClick={e => e.stopPropagation()}>
            {asButton &&
            <Button icon={<CloudDownloadOutlined/>} className={className} onClick={onImportTemplateClick}>
                Import Template
            </Button>}
            {asButton &&
            <Button icon={<PlusOutlined/>} className={className} onClick={onNewTemplateClick}>
                Build New Template
            </Button>}
            <Drawer title='Create Template' width='50%' keyboard={false} open={showForm}
                    destroyOnClose onClose={() => setShowForm(false)}
                    footer={null} className='template-form-drawer'>
                <TemplateBuilder onSubmit={onFormSubmit} applyDrawerStyles/>
            </Drawer>
            <Drawer title='Import Template' width='50%' keyboard={false} open={showImportForm}
                    destroyOnClose onClose={() => setShowImportForm(false)}
                    footer={null} className='template-form-drawer'>
                <TemplateImporter onSubmit={onTemplateImport} applyDrawerStyles/>
            </Drawer>
        </div>
    )
}

export default TemplateBuilderDrawer;
