import axios from "axios";
import {IIRN} from "./IRNTypes";

export default class IRNAPI {
    static async getIRNs() {
        try {
            let res = await axios.get('/irns');
            return res.data as IIRN[];
        } catch (e) {
            console.error('Could not get clients', e.message);
            return undefined;
        }
    }
    static async createIRN(values:any){
        try {
            let res = await axios.post('/irns', values);
            return res.data as IIRN;
        } catch (e) {
            console.log('Could not create IRN at this time', e.message);
            return undefined;
        }
    }
}