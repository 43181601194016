import React from 'react';

import './styles.scss';

import { RouteComponentProps, Route } from 'react-router';

import TemplateTable from '../../components/specific/TemplateTable/TemplateTable'
import {BrowserRoutes} from "../../stores/App/BrowserRouter";
import Template from "../../components/specific/Template/Template";

// This container will render a TemplateTable (component) showing all templates
// Create Template button => onClick => show modal or trigger drawer => form to create new template
// Show categories (vendor/client), Template Name, User Name, Date Created, 

interface Props extends RouteComponentProps<{templateId:string}>{

}

function TemplateBrowserContainer(props:Props){
    return (
        <div className='template-browser'>
            <Route exact path={BrowserRoutes.templates} component={TemplateTable}/>
            <Route path={BrowserRoutes.templatesByIdParam} render={({match}) => {
                return <Template templateId={match.params.templateId}/>
            }}/>
        </div>
    )
}

export default TemplateBrowserContainer;