import axios from "axios"

export default class TaskFileApi {
  static async deleteTaskFile(taskFileId: number) {
    try {
      await axios.delete(`/task-files/${taskFileId}`)
      return true
    } catch (e: any) {
      console.log("Could not delete attachment", e.message)
      return undefined
    }
  }
}
