export type TQuestionType = "scoping"|"control";
export enum EQuestionType {
    Scoping = 'scoping',
    Control = 'control'
}

export const LSAuthTokenKey = 'corl_jwt';

export interface IStrapiFile {
    alternativeText: string,
    caption: string,
    created_at: string,
    ext: string,
    formats: string[],
    hash: string,
    height: number,
    id: number,
    mime: MimeType,
    name: string
    previewUrl: null
    provider: string
    provider_metadata: any
    size: number
    updated_at: string
    url: string
    width: number
}

export interface StrapiBaseFields {
    created_at:string,
    updated_at:string,
    id:number,
}