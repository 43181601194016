import './styles.scss';
import React, {useEffect, useState} from 'react';
import {Form, Input, notification} from "antd";
import Button from "../../common/Button/Button";
import { IVendor } from '../../../api/Vendor/VendorTypes';
import VendorAPI from '../../../api/Vendor/VendorAPI';
import DefaultLoader from '../../common/DefaultLoader/DefaultLoader';
import { ROLES, TOAST_DURATION } from '../../../constants';
import AppStore from '../../../stores/App/AppStore';

interface Props {
    vendorId: number,
    onVendorUpdate: (updatedVendor: IVendor) => void,
}

function VendorSettings({vendorId, onVendorUpdate}: Props) {
    const [submitting, setSubmitting] = useState(false);
    const [vendor, setVendor] = useState<IVendor>();
    const [pocNameRequired, setPocNameRequired] = useState(vendor?.point_of_contact ? true : false);
    const [vendorForm] = Form.useForm();

    const canEditForm = AppStore.user?.role.name === ROLES.CORL_ADMIN;

    useEffect(() => {
        getVendor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!vendor) {
        return (
            <div className='default-loader-full'>
                <DefaultLoader text='Loading vendor settings...'/>
            </div>
        )
    }

    async function getVendor() {
        if(!vendorId) return;
        let vendor = await VendorAPI.getById(vendorId);
        if(vendor) setVendor(vendor);
    }

    let initialValues = {
        name: vendor?.name,
        point_of_contact: vendor?.point_of_contact,
        point_of_contact_name: vendor?.point_of_contact_name
    }

    async function onVendorChange(values: any) {
        if(submitting) return;
        setSubmitting(true);

        let updatedVendor = await VendorAPI.updateVendor(vendorId, {...values});
        if (!updatedVendor) {
            notification.error({
                message: 'Error updating vendor settings',
                description: <span>Please make sure you specified a proper vendor name and a valid email format for the point of contact.</span>,
                duration: TOAST_DURATION,
            })
        }

        if (updatedVendor) {
            notification.success({
                message: 'Successfully updated vendor!',
                duration: TOAST_DURATION,
            });
            setVendor(updatedVendor);
            if(onVendorUpdate) await onVendorUpdate(updatedVendor);
        }
       setSubmitting(false);
    }

    function onPocValueChange(e: any) {
        if(vendorForm.getFieldValue('point_of_contact') ? true : false) setPocNameRequired(true)
        else setPocNameRequired(false)
    }

    return (
        <div className='vendor-settings'>
            <Form form={vendorForm} layout='vertical' initialValues={initialValues} onFinish={values => onVendorChange({...values})} className='form'>
                <Form.Item label='Vendor Name' name='name'
                        rules={[{required: true, message: 'Vendor must have a name.'}]}>
                    <Input disabled={!canEditForm} size='large'/>
                </Form.Item>
                <Form.Item label='Vendor Point of Contact Email' name='point_of_contact' onChange={onPocValueChange} rules={[{type: 'email', message: 'The input is not a valid email'}]}>
                    <Input disabled={!canEditForm} size='large'/>
                </Form.Item>
                <Form.Item label='Vendor Point of Contact Name' name='point_of_contact_name'
                    rules={[{required: pocNameRequired, message: 'If specifying Point of Contact Email, you must also provide a name.'}]}>
                    <Input disabled={!canEditForm} size='large'/>
                </Form.Item>
                {canEditForm &&
                <Form.Item>
                    <Button enabled={!submitting} htmlType='submit' loading={submitting}>Save</Button>
                </Form.Item>}
            </Form>
        </div>
    )
}

export default VendorSettings;