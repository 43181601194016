import React, {useCallback, useEffect, useState} from 'react';
import './styles.scss';
import UserTable from "../UserTable/UserTable";
import {IUser, UserRoles} from "../../../api/User/UserTypes";
import StrapiPermissionsUtil from "../../../api/utils/StrapiPermissionsUtil";
import UserAPI from "../../../api/User/UserAPI";
import AppStore from "../../../stores/App/AppStore";
import InviteUserFormModalAsButton from "../InviteUserForm/InviteUserFormModalAsButton";
import {notification} from "antd";
import _ from "lodash";
import { TOAST_DURATION } from '../../../constants';
import UserRequestApi from '../../../api/UserRequest/UserRequestApi';

interface Props {
    clientId: number,
}

function ClientUserManagement(props: Props) {
    const {clientId} = props;
    const [adminUsers, setAdminUsers] = useState<IUser[]>();
    const [accountUsers, setAccountUsers] = useState<IUser[]>();

    async function getAdminUsers(params?: any) {
        let users = await UserAPI.getAdminUsersForClient(clientId, params);
        if (users) setAdminUsers(users);
    }

    async function getAccountUsers(params?: any) {
        let users = await UserAPI.getAccountUsersForClient(clientId, params);
        if (users) setAccountUsers(users);
    }

    function isAllowedToSeeAdminUsers() {
        return StrapiPermissionsUtil.isRole([UserRoles.CorlAdmin, UserRoles.ClientAdmin, UserRoles.CorlEmployee], AppStore.user)
    }

    useEffect(() => {
        getAdminUsers();
        getAccountUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function renderMessageError(message:any){
        return message?.[0]?.messages?.[0]?.message ? message?.[0]?.messages?.[0]?.message : 'Undefined error.'
    }

    async function onAdminInviteSubmit(values:any) {
        let sendUserRequest = false;

        if(AppStore?.user?.role.name === UserRoles.CorlAdmin || AppStore?.user?.role.name === UserRoles.CorlEmployee) {
            if(adminUsers) {
                let userToInviteDomain = values.email.split('@')[1];
                let hasMatchingDomain = false;
                for(let i = 0; i < adminUsers?.length; i++) {
                    let currentUserEmail = adminUsers[i].email;
                    if(currentUserEmail.includes(userToInviteDomain)) hasMatchingDomain = true;
                }
                sendUserRequest = !hasMatchingDomain;
            }
        }

        //TODO - remove when feature is to be turned back on
        sendUserRequest = false;

        if(sendUserRequest) {
            let response = await UserRequestApi.create({
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                created: AppStore?.user?.email,
                vendor: clientId,
                role: UserRoles.ClientAdmin
            });
            if(response) {
                notification.success({
                    message: 'Success!',
                    description: <span>You have successfully created a user request for <b>{values.email}</b> as a Client Admin. A CORL Admin will need to approve this request for the user to be invited into the system.</span>,
                    duration: TOAST_DURATION,
                })
            } else {
                notification.error({
                    message: 'Error!',
                    description: <span>There was an error creating the user request. Please try again.</span>,
                    duration: TOAST_DURATION,
                })
            }
        } else {
            let inviteSent = await UserAPI.inviteClientAdmin(values, clientId);
            if (!inviteSent.error) {
                notification.success({
                    message: 'Success!',
                    description: <span>You have successfully invited <b>{values.email}</b> as a Client Admin. They should see an invitation in their email within the next few minutes.</span>,
                    duration: TOAST_DURATION,
                })
                getAdminUsers();
                getAccountUsers();
            } else {
                notification.error({
                    message: 'Error sending invitation!',
                    description:
                        <div>
                            <span>Unfortunately, we were not able to send your invitation to <b>{values.email}</b></span>.
                            <div>Error: {renderMessageError(inviteSent.error)}</div>
                        </div>,
                    duration: TOAST_DURATION,
                })
            }
        }        
    }

    async function onAccountInviteSubmit(values:any) {
        let sendUserRequest = false;

        if(AppStore?.user?.role.name === UserRoles.CorlAdmin || AppStore?.user?.role.name === UserRoles.CorlEmployee) {
            if(accountUsers) {
                let userToInviteDomain = values.email.split('@')[1];
                let hasMatchingDomain = false;
                for(let i = 0; i < accountUsers?.length; i++) {
                    let currentUserEmail = accountUsers[i].email;
                    if(currentUserEmail.includes(userToInviteDomain)) hasMatchingDomain = true;
                }
                sendUserRequest = !hasMatchingDomain;
            }
        }

        //TODO - remove when feature is to be turned back on
        sendUserRequest = false;

        if(sendUserRequest) {
            let response = await UserRequestApi.create({
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                created: AppStore?.user?.email,
                vendor: clientId,
                role: UserRoles.ClientEmployee
            });
            if(response) {
                notification.success({
                    message: 'Success!',
                    description: <span>You have successfully created a user request for <b>{values.email}</b> as a Client user. A CORL Admin will need to approve this request for the user to be invited into the system.</span>,
                    duration: TOAST_DURATION,
                })
            } else {
                notification.error({
                    message: 'Error!',
                    description: <span>There was an error creating the user request. Please try again.</span>,
                    duration: TOAST_DURATION,
                })
            }
        } else {
            let inviteSent = await UserAPI.inviteClientEmployee(values, clientId);
            if (!inviteSent.error) {
                notification.success({
                    message: 'Success!',
                    description: <span>You have successfully invited <b>{values.email}</b> as a Client user. They should see an invitation in their email within the next few minutes.</span>,
                    duration: TOAST_DURATION,
                })
                getAdminUsers();
                getAccountUsers();
            } else {
                notification.error({
                    message: 'Error sending invitation!',
                    description:
                        <div>
                            <span>Unfortunately, we were not able to send your invitation to <b>{values.email}</b></span>.
                            <div>Error: {inviteSent.error}</div>
                        </div>,
                    duration: TOAST_DURATION,
                })
            }   
        }
    }

    async function onClientAdminSearch(text:string) {
        await getAdminUsers({email_contains: text})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedClientAdminSearch = useCallback(_.debounce(onClientAdminSearch, 300), []);

    async function onClientEmployeeSearch(text:string) {
        await getAccountUsers({email_contains: text})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedClientEmployeeSearch = useCallback(_.debounce(onClientEmployeeSearch, 300), []);

    async function onDeleteUser(id: number) {
        if (AppStore.user?.id !== id) {
            await UserAPI.deleteClientUser(clientId, id);
            getAdminUsers();
            getAccountUsers();
        }
    }

    return (
        <div className='client-user-management'>
            {isAllowedToSeeAdminUsers() &&
            <div className='users'>
                <div className='client-user-management-left'>
                    <div className='header'>
                        Client Admins
                    </div>
                    <div className='description'>
                        Admins can add and remove Client Employees and other Client Admins.
                    </div>
                    <InviteUserFormModalAsButton key='admin' inviteText='Invite Client Admin'
                                                 onSubmit={onAdminInviteSubmit}/>
                </div>
                <div className='client-user-management-right'>
                    <UserTable users={adminUsers} onSearch={debouncedClientAdminSearch}
                               onDeleteUser={adminUsers && adminUsers.length > 1 ? onDeleteUser : undefined}/>
                </div>
            </div>}
            <div className='users'>
                <div className='client-user-management-left'>
                    <div className='header'>
                        Client Employees
                    </div>
                    <div className='description'>
                        Client Employees can look at questionnaires from Vendors. Only Client Admins can invite
                        Employees.
                    </div>
                    {StrapiPermissionsUtil.isRole([UserRoles.ClientAdmin], AppStore.user) &&
                    <InviteUserFormModalAsButton key='account' inviteText='Invite Client Employee'
                                                 onSubmit={onAccountInviteSubmit}/>}
                </div>
                <div className='client-user-management-right'>
                    <UserTable users={accountUsers} onSearch={debouncedClientEmployeeSearch}
                               onDeleteUser={onDeleteUser}/>
                </div>
            </div>
        </div>
    )
}

export default ClientUserManagement;