import {IQuestion} from "../Question/QuestionTypes";
import {ITemplateItem} from "../TemplateItem/TemplateItemTypes"
import {IBaseFramework} from "../BaseFramework/BaseFrameworkTypes";
import {TQuestionType} from "../Base/BaseTypes";

export interface ITemplate {
    name:string,
    control_questions: IQuestion[],
    id:number,
    template_type:TQuestionType,
    base_frameworks:IBaseFramework[],
    key_controls?:IQuestion[],
    template_items:ITemplateItem[],
    questionnaire_type:EQuestionnaireType,
    grading_logic:EGradingLogic
}

export enum EQuestionnaireType {
    PSQ = 'PSQ',
    VSQ = 'VSQ',
    IVPQ = 'IVPQ',
}

export enum EGradingLogic {
    CorlCleared = 'corl_cleared'
}