import {useState, useEffect} from 'react';
import ImportJobAPI from "../api/ImportJob/ImportJobAPI";
import {IImportJob} from "../api/ImportJob/ImportJobTypes";

export const useImportJobs = (id?: number) => {
    const [importJobs, setImportJobs] = useState<IImportJob[]>();
    const [importJobsRequestError, setImportJobsRequestError] = useState();
    const [fetchingImportJobs, setFetchingImportJobs] = useState(false);

    async function getImportJobs(id?:number) {
        setFetchingImportJobs(true);
        try {
            if (id) {
                let importJob = await ImportJobAPI.getImportJobById(id);
                if (importJob) {
                    setImportJobs([importJob]);
                }
            } else {
                let importJobs = await ImportJobAPI.getImportJobs();
                setImportJobs(importJobs);
            }
        } catch (e) {
            setImportJobs(undefined);
            setImportJobsRequestError(e);
        }
        setFetchingImportJobs(false);
    }

    useEffect(() => {
        if (!importJobs?.length) {
            getImportJobs(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        importJobs,
        importJobsRequestError,
        fetchingImportJobs,
        getImportJobs,
    }
}