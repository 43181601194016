import React, {useEffect, useState} from 'react';
import {Form, Input, notification} from "antd";
import cx from 'classnames';
import {TOAST_DURATION} from '../../../constants';

import './styles.scss';

import {EditQuestionnaireFormTypes} from "./Types";
import {IQuestionnaire} from "../../../api/Questionnaire/QuestionnaireTypes"
import Button from "../../common/Button/Button";
import QuestionnaireAPI from "../../../api/Questionnaire/QuestionnaireAPI";
import ProductSelect from "../ProductSelect/ProductSelect";
import ImplementationSelect from "../ImplementationSelect/ImplementationSelect";
import TemplateAPI from "../../../api/Template/TemplateAPI";
import {ITemplate} from "../../../api/Template/TemplateTypes";
import TemplateSelect from "../TemplateSelect/TemplateSelect";
import {ArrowRightOutlined} from "@ant-design/icons";


interface Props {
    onSubmit?: () => void,
    applyDrawerStyles?: boolean,
    questionnaire?: IQuestionnaire
}

function EditQuestionnaireForm(props: Props) {
    const {onSubmit, applyDrawerStyles, questionnaire} = props;
    const [form] = Form.useForm();

    // eslint-disable-next-line
    const [templates, setTemplates] = useState<ITemplate[]>([]);
    const [submittingForm, setSubmittingForm] = useState(false);

    async function getTemplates() {
        let templates = await TemplateAPI.getTemplates();
        if (templates) setTemplates(templates);
    }

    useEffect(() => {
        getTemplates();

        return function cleanup() {
            form.resetFields();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const formProps = {
        requiredMark: 'optional',
        colon: false,
        className: cx(' questionnaire-form', {'as-drawer': applyDrawerStyles})
    };

    async function onSubmitClick() {
        if (submittingForm) return false;
        setSubmittingForm(true);
        try {
            let valid = await form.validateFields();
            if (!valid.errorFields && questionnaire) {
                let values = form.getFieldsValue();
                values = {
                    ...values,
                    vendor: questionnaire.assessment.vendor.id,
                    client: questionnaire.assessment.client.id,
                    assessment:questionnaire.assessment.id,
                }
                let questionnaireId = questionnaire.id
                let q = await QuestionnaireAPI.updateQuestionnaire(questionnaireId, values);
                if (q) {
                    const {vendor, client, title} = questionnaire;
                    notification.success({
                        message: 'Success!',
                        description:
                            <span>Your questionnaire for {vendor.name} on behalf of {client.name}, <b>{title}</b>, has been successfully updated.</span>,
                        duration: TOAST_DURATION,

                    })
                    form.resetFields();
                    if (onSubmit) onSubmit(values);
                } else {
                    notification.error({
                        message: 'Error creating questionnaire!',
                        description: <span>Your questionnaire <b>{values.title}</b> could not be updated.</span>,
                        duration: TOAST_DURATION,
                    })
                }
            }
        } catch (e) {

        }
        setSubmittingForm(false);
    }


    let initialValues = {
        [EditQuestionnaireFormTypes.Title]: questionnaire?.title,
        [EditQuestionnaireFormTypes.Template]: questionnaire?.template?.id,
        [EditQuestionnaireFormTypes.Implementation]: questionnaire?.implementation?.id,
        [EditQuestionnaireFormTypes.Product]: questionnaire?.product?.id,
        [EditQuestionnaireFormTypes.Vendor]:questionnaire?.vendor.id,
    }

    function renderButton() {
        if (applyDrawerStyles) {
            return (
                <div className='form-stickied-footer'>
                    <Button onClick={onSubmitClick} htmlType='submit'
                            icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                        Edit Questionnaire
                    </Button>
                </div>
            )
        } else {
            return (
                <Button onClick={onSubmitClick} htmlType='submit'
                        icon={<ArrowRightOutlined/>} iconPosition='right' loading={submittingForm}>
                    Edit Questionnaire
                </Button>
            )
        }
    }

    return (
        <div >
            <Form {...formProps} layout='vertical' form={form} initialValues={initialValues}
                  onValuesChange={() => {}} className='assessment-form-wrapper'>
                <Form.Item label='Questionnaire Name' name={EditQuestionnaireFormTypes.Title}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <Input size='large'/>
                </Form.Item>
                <Form.Item label='Template' name={EditQuestionnaireFormTypes.Template}
                           validateTrigger='onSubmit'
                           rules={[{required: true, message: 'This field is required.'}]}>
                    <TemplateSelect templates={templates} disabled={true}/>
                </Form.Item>
                <Form.Item label='Product' name={EditQuestionnaireFormTypes.Product} validateTrigger='onSubmit'
                           >
                    <ProductSelect
                        disabled={true}
                        vendorId={form.getFieldValue(EditQuestionnaireFormTypes.Vendor)}/>
                </Form.Item>
                <Form.Item label='Implementation' name={EditQuestionnaireFormTypes.Implementation} validateTrigger='onSubmit'>
                    <ImplementationSelect disabled={true}/>
                </Form.Item>
            </Form>
            {renderButton()}
        </div>
    )
}

export default EditQuestionnaireForm;