import axios from "axios";
import {IAppSettings, IFeatureFlags} from "./AppSettingsTypes";

export default class AppSettingsApi {
    static async getAppSettings() : Promise<IAppSettings> {
        let appSettings:any = {};

        appSettings['featureFlags'] = await this.getFeatureFlags();

        return appSettings;
    }

    static async getFeatureFlags() : Promise<IFeatureFlags> {
        try {
            let res = await axios.get('/feature-flags');
            return res.data as IFeatureFlags;
        } catch (e: any){
            console.log('Could not get feature flags', e.message);
            return {
                chat: false,
                dynamics_data_sync: false
            };
        }
    }
}