import axios from "axios"
import { ITask } from "./TaskTypes"

export default class TaskApi {
  static async getTasks(params?: any, qs?: string) {
    try {
      let url = "/tasks"
      if (qs) url += `?${qs}`
      let res = await axios.get(url, { params })
      return res.data as ITask[]
    } catch (e: any) {
      console.log("Could not get tasks", e.message)
      return undefined
    }
  }

  static async getTaskCount(params?: any, qs?: string) {
    let url = "/tasks/count"
    if (qs) url += `?${qs}`
    try {
      let res = await axios.get(url, { params })
      return res.data as number
    } catch (e: any) {
      console.log("Could not get tasks", e.message)
      return undefined
    }
  }

  static async updateTask(taskId: number | string, values: any) {
    try {
      let res = await axios.put(`/tasks/${taskId}`, values)
      return res.data as ITask
    } catch (e: any) {
      console.error("Could not update task", e.message)
      return undefined
    }
  }
}
