import axios from "axios";
import {ITemplate} from "./TemplateTypes";

export default class TemplateAPI {

    static async getTemplates(params?: any, qs?: string){
        let url = '/templates';
        if (qs) {
            url += `?${qs}`
        }
        try {
            let res = await axios.get(url, {params});
            return res.data as ITemplate[];
        } catch (e:any){
            console.log('Could not get assessment templates', e.message);
            return undefined;
        }
    }

    static async getTemplateCount(params?: any, qs?: string) {
        let url = '/templates/count';
        if (qs){
            url += `?${qs}`
        }
        try {
            let res = await axios.get(url, {params});
            return res.data as number;
        } catch (e:any){
            console.log('Could not get assessments', e.message);
            return undefined;
        }
    }

    static async getTemplate(templateId:number){
        try {
            let res = await axios.get(`/templates/${templateId}`);
            return res.data as ITemplate;
        } catch (e:any){
            console.log('Could not get assessment templates', e.message);
            return undefined;
        }
    }

    static async getQuestionnaireTemplateQuestions(assessmentId:number, questionnaireId:number){
        try {
            let res = await axios.get(`/assessments/${assessmentId}/questionnaires/${questionnaireId}/template/questions`);
            return res.data as ITemplate;
        } catch (e:any){
            console.log('Could not get assessment templates', e.message);
            return undefined;
        }
    }

    static async createTemplate(values:any){
        try {
            let res = await axios.post('/templates', values);
            return res.data as any;
        } catch (e:any){
            console.log('Could not create assessment template', e.message);
            return undefined;
        }
    }

    static async importTemplate(values:any, results:any){
        const data = {
            baseFrameworks : values["base_frameworks"],
            name : values.name,
            templateType : values["template_type"],
            controlQuestions : results,
            questionnaireType: values["questionnaire_type"]
        }
        try {
            let res = await axios.post('/templates/import', data);
            return res.data as ITemplate;
        } catch (e:any){
            console.log('Could not import assessment template', e.message);
            return undefined;
        }
    }


}