import React from "react";

export const ClientTableConfig = [
    {
        title: 'Client', dataIndex: 'name', key: 'name',
    },
    {
        title: 'Point of Contact', dataIndex: 'point_of_contact', key: 'point_of_contact',
    },
    {
        title: 'Users', dataIndex: 'users', key: 'email',
        render: (users) => {
            return (
                <div>{users.length}</div>
            )
        }
    }
]