import axios from "axios";

const route = '/vendors'

export default class VendorAPI {
    static async getVendors(params?:any){
        let res = await axios.get(route, {params});
        return res.data;
    }

    static async createVendor(data:any){
        try{
            let res = await axios.post(route, data);
            return res.data;
        } catch (e) {
            console.error('Could not create vendor', e.message);
            return undefined;
        }
    }

    static async updateVendor(vendorId: number, data:any){
        try{
            let res = await axios.put(`${route}/${vendorId}`, data);
            return res.data;
        } catch (e) {
            console.error('Could not update vendor', e.message);
            return undefined;
        }
    }

    static async getVendorCount(params?:any){
        let res = await axios.get(`${route}/count`, {params});
        return res.data as number;
    }

    static async getById(vendorId: number){
        let res = await axios.get(`${route}/${vendorId}`);
        return res.data;
    }

    static async getVendorResponseFiles(questionnaireId: number) {
        try {
            let res = await axios.get(`/questionnaires/${questionnaireId}/response-files`)
            return res.data;
        } catch (e) {
            console.log('Could not get vendor response files', e.message);
            return undefined;
        }
    }
}