import Papa from 'papaparse';

export default class PapaParseUtils {
    static async parse(file: File, config) {
        return new Promise((resolve, reject) => {
            let currentConfig = {
                header: true,
                complete(results, file) {
                    resolve(results.data)
                },
                error(err, file) {
                    reject(err)
                },
                ...config
            }

            Papa.parse(file, currentConfig)
        })
    };
};