import React from 'react';
import _ from 'lodash';

import {EditOutlined} from '@ant-design/icons'

import { IQuestion } from '../../../api/Question/QuestionTypes';
import { IBaseFramework } from '../../../api/BaseFramework/BaseFrameworkTypes';
import {StringUtils} from "../../../global/utils/string";
import BrowserRouter, {BrowserRoutes} from "../../../stores/App/BrowserRouter";
import {ITemplate} from "../../../api/Template/TemplateTypes";

export const TemplateTableConfig = (onClickEdit:(templateId:number)=>void) => [
    {
        title: 'Template Name', dataIndex: 'name', key: 'template_name', sorter: true,
        render: (name:string, template:ITemplate) => {
            function onClick(){
                BrowserRouter.push(BrowserRoutes.getTemplateById(template.id))
            }
            return <div className='title' onClick={onClick}>{template.name}</div>
        }
    },
    {
        title: 'Framework', dataIndex: 'base_frameworks', key: 'base_frameworks',
        render: (base_frameworks:IBaseFramework[]) => {
            if (base_frameworks.length > 0) return base_frameworks[0].name;
        }
    },
    {
        title: 'Template Type', dataIndex: 'template_type', key: 'template_type',
        render: (template_type:string) => {
            if (template_type) return StringUtils.camelCaseToRegular(template_type, false, true);
        }
    },
    {
        title: 'Questionnaire Type', dataIndex: 'questionnaire_type', key: 'questionnaire_type',
        render: (questionnaire_type:string) => {
            if (questionnaire_type) return questionnaire_type;
        }
    },
    {
        title: '# Questions', dataIndex: ['control_questions'], key:'control_questions',
        render: (control_questions:IQuestion[], template:ITemplate) => {
            let questions = template.control_questions;
            if (template.key_controls) questions = _.uniqBy([...questions, ...template.key_controls], 'id');
            return questions.length;
        }
    },
    {
        key: 'actions',
        render: (template:ITemplate) => {
            return (
                <EditOutlined onClick={()=>onClickEdit(template.id)}/>
            )
        }
    }
];