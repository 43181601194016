import {IAssessment} from "../Assessment/AssessmentTypes";
import {IVendor} from "../Vendor/VendorTypes";
import {ITemplate} from "../Template/TemplateTypes";
import {IProduct} from "../Product/ProductTypes";
import {IImplementation} from "../Implementation/ImplementationTypes";
import {IResponseFile} from "../ResponseFile/ResponseFileTypes";
import {IClient} from "../Client/ClientTypes";
import {StrapiBaseFields} from "../Base/BaseTypes";
import { IResponse } from "../Response/ResponseTypes";

export interface IQuestionnaire extends StrapiBaseFields {
    status:string,
    assessment:IAssessment,
    vendor:IVendor,
    template:ITemplate,
    product:IProduct,
    client:IClient,
    implementation:IImplementation
    title:string,
    response_files:IResponseFile[],
    responses: IResponse[],
    data_reuse_occurred?: boolean,
}

export enum EQuestionnaireStatus {
    InProgress = 'in_progress',
    Submitted = 'submitted',
    Canceled = 'canceled',
    OnHold = 'on_hold'
}