import React, {useCallback, useEffect, useState} from 'react';
import {Select} from 'antd';

import './styles.scss';
import {IClient} from "../../../api/Client/ClientTypes";
import ClientAPI from "../../../api/Client/ClientAPI";
import _ from "lodash";
import {LoadingOutlined} from "@ant-design/icons";

const {Option} = Select;

interface Props {
    onChange?: (client?: number) => void,
    loading?: boolean,
    className?: string,
    option?: number
    clients?: IClient[],
    curClient?: IClient,
    disabled?: boolean,
    value?:any
}

function ClientSelect(props:Props) {
    const {onChange, loading, option, curClient, clients: propClients, value, disabled} = props;
    const [stateClients, setStateClients] = useState<IClient[]>();

    async function getClients(params?: any) {
        let clients = await ClientAPI.getClients(params)
        if (clients) setStateClients(clients);
    }

    async function onSearch(value: string) {
        if (!value) {
            return null;
        }
        await getClients({name_contains: value});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(_.debounce(onSearch, 300), []);

    useEffect(() => {
        if (!propClients) getClients();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let clients = propClients;
    if (stateClients) {
        clients = stateClients;
    }

    function onClear() {
        if (onChange) {
            onChange(undefined);
            if (!propClients) getClients();
        }
    }

    return (
        <Select className='client-select' size='large' optionLabelProp='children' onSearch={debouncedSearch} 
                placeholder={curClient ? curClient.name : 'Search Clients'}
                defaultValue={curClient ? curClient.name : option} onSelect={onChange as any} loading={loading} 
                showSearch filterOption={false}
                notFoundContent={loading ? <LoadingOutlined spin/> : null} allowClear onClear={onClear} value={value} disabled={disabled}>
            {clients && clients.map(client =>
                <Option value={client.id} key={client.id}>
                    <div className='client-select-name'>
                        {client.name}
                    </div>
                    <div className='client-select-point-of-contact'>
                       {client?.point_of_contact || "No point of contact"}
                    </div>
                </Option>
            )}
        </Select>
    )
}

export default ClientSelect;