import React, {useEffect, useState} from 'react';

import './styles.scss';

import {Table} from "antd";
import {IQuestion} from "../../../api/Question/QuestionTypes";
import QuestionAPI from "../../../api/Question/QuestionAPI";
import {QuestionTableConfig} from "./QuestionTableConfig";
import QuestionFormModalAsButton from "../QuestionForm/QuestionFormModalAsButton";
import TemplateAPI from "../../../api/Template/TemplateAPI";
import DefaultLoader from "../../common/DefaultLoader/DefaultLoader";


interface Props {
    templateId?: number,
}

function QuestionTable(props: Props) {
    const {templateId} = props;
    const [questions, setQuestions] = useState<IQuestion[]>();

    async function getQuestions() {
        if (templateId) {
            let template = await TemplateAPI.getTemplate(templateId);
            if (template) {
                let curQuestions = template.control_questions;
                if (template.key_controls) curQuestions = [...curQuestions, ...template.key_controls];
                setQuestions(curQuestions)
            }
        } else {
            let curQuestions = await QuestionAPI.getQuestions();
            if (curQuestions) setQuestions(curQuestions);
        }

    }

    useEffect(() => {
        getQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onSubmitQuestion(values: any) {
        let question = await QuestionAPI.createQuestion(values);
        if (question) {
            if (!questions) {
                setQuestions([question]);
            }
            else {
                setQuestions([...questions, question]);
            }
        }
    }

    if (!questions){
        return (
            <div className='default-loader-full'>
                <DefaultLoader text="Loading questions..."/>
            </div>
        )
    }

    return (
        <div className='question-table'>
            <div className='question-table-header'>
                <div className='title'>
                    Questions
                </div>
                <QuestionFormModalAsButton onSubmit={onSubmitQuestion}/>
            </div>
            <div className='filters'>

            </div>
            <div className='question-table-content'>
                <Table rowKey='id' dataSource={questions} columns={QuestionTableConfig} colSpan={9}/>
            </div>

        </div>

    )
}

export default QuestionTable;