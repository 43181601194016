import {decorate, observable, computed} from "mobx";
import axios from "axios";
import {IUser} from "../../api/User/UserTypes";
axios.defaults.baseURL = process.env.REACT_APP_ENV === 'production' ? 'https://corl-exchange.azurewebsites.net' : process.env.REACT_APP_ENV === 'development' ? 'https://corl-exchange-dev.azurewebsites.net' : 'http://localhost:1337';

class AppStoreClass {

    initialized:    boolean = false;
    user?:          IUser;
    authToken?:     string;
    authTokenChecked:boolean = false;
    baseAPIUrl = process.env.REACT_APP_ENV === 'production' ? 'https://corl-exchange.azurewebsites.net' : process.env.REACT_APP_ENV === 'development' ? 'https://corl-exchange-dev.azurewebsites.net' : 'http://localhost:1337';
    sidebarCollapsed = localStorage.getItem('corl_sidebar_collapsed') === 'true';
    env = process.env.REACT_APP_ENV;
    authTokenExpiration:any;

    init(){

    }


    get necessaryDataIsLoaded(){
        return true;
    }
}


decorate(AppStoreClass, {
    authToken:      observable,
    authTokenChecked:observable,
    user:           observable,
    necessaryDataIsLoaded: computed,
    sidebarCollapsed:observable,
});


const AppStore = new AppStoreClass();
export default AppStore;