import React from 'react';
import {withRouter, RouteComponentProps, Switch, Route, Redirect} from 'react-router-dom';
import {TeamOutlined, AppstoreAddOutlined, ExportOutlined, CloudSyncOutlined} from '@ant-design/icons';
import cx from 'classnames';
import './styles.scss';
import BrowserRouter, {BrowserRoutes} from "../../stores/App/BrowserRouter";
import VendorUserManagement from "../../components/specific/VendorUserManagement/VendorUserManagement";
import AppStore from "../../stores/App/AppStore";
import StrapiPermissionsUtil from "../../api/utils/StrapiPermissionsUtil";
import CorlUserManagement from "../../components/specific/CorlUserManagement/CorlUserManagement";
import ImportJobTable from "../../components/specific/ImportJobTable/ImportJobTable.component";
import ImportJob from "../../components/specific/ImportJob/ImportJob.component";
import ExportJobTable from "../../components/specific/ExportJobTable/ExportJobTable";
import DataSyncAuditTable from "../../components/specific/DataSyncAuditTable/DataSyncAuditTable";
import ClientManagement from '../../components/specific/ClientManagement/ClientManagement';

interface Props extends RouteComponentProps {

}

function SettingsContainer(props: Props) {
    let tabs = {
        users: {
            id: 'users',
            display: 'Users',
            path: BrowserRoutes.users,
            icon: <TeamOutlined/>,
            hasRequirements: StrapiPermissionsUtil.isClientAdmin(AppStore.user) || StrapiPermissionsUtil.isCorlAdmin(AppStore.user) || StrapiPermissionsUtil.isVendorAdmin(AppStore.user)
        },
        import: {
            id: 'import',
            display: 'Imports',
            path: BrowserRoutes.importJobs,
            icon: <AppstoreAddOutlined/>,
            hasRequirements: StrapiPermissionsUtil.isCorlAdminOrEmployee(AppStore.user)
        },
        export: {
            id: 'export',
            display: 'Jobs',
            path: BrowserRoutes.exportJobs,
            icon: <ExportOutlined/>,
            hasRequirements: StrapiPermissionsUtil.isCorlAdmin(AppStore.user)
        },
        dataSyncAudit: {
            id: 'data-sync-audit',
            display: 'Data Sync Audit',
            path: BrowserRoutes.dataSyncAudit,
            icon: <CloudSyncOutlined/>,
            hasRequirements: StrapiPermissionsUtil.isCorlAdmin(AppStore.user)
        }
    }

    function getSelectedKey() {
        for (let key in tabs) {
            if (props.location.pathname.includes(tabs[key].path)) {
                return key;
            }
        }
        return '';
    }

    function renderTabs() {
        return Object.keys(tabs).map(key => {
            const tab = tabs[key];
            const {display, path, icon, hasRequirements} = tab;
            if (!hasRequirements) return null;
            let selected = key === getSelectedKey()

            function onClick() {
                if (!selected) {
                    BrowserRouter.push(path)
                }
            }

            return (
                <div key={key} className={cx('settings-tab', {selected})} onClick={onClick}>
                    {icon} {display}
                </div>
            )
        })
    }

    return (
        <div className='settings-container'>
            <div className='settings-container-header'>
                Settings
            </div>
            <Route path={BrowserRoutes.settingsById} render={() => {
                return (
                    <div className='settings-tabs'>
                        {renderTabs()}
                    </div>
                )
            }}/>
            <Switch>
                <Route exact path={BrowserRoutes.users} render={() => {
                    if (StrapiPermissionsUtil.isCorlAdmin(AppStore.user)) {
                        return <CorlUserManagement/>;
                    } else if (StrapiPermissionsUtil.isVendorAdmin(AppStore.user)) {
                        return <VendorUserManagement vendorId={AppStore.user?.vendor?.id}/>;
                    } else if (StrapiPermissionsUtil.isClientAdmin(AppStore.user)) {
                        return <ClientManagement clientId={AppStore.user?.client?.id}/>;
                    } else {
                        return <div/>;
                    }
                }}/>
                <Route path={[BrowserRoutes.importJobsByIdParam, BrowserRoutes.importJobs]} render={({match}) => {
                    if (match.params.importJobId) {
                        return (
                            <div style={{padding: 24}}>
                                <ImportJob importJobId={match.params.importJobId}/>
                            </div>
                        )
                    } else {
                        return (
                            <div style={{padding: 24}}>
                                <ImportJobTable/>
                            </div>
                        )
                    }
                }}/>
                <Route path={[BrowserRoutes.exportJobs]} render={({match}) => {
                    return (
                        <div style={{padding: 24}}>
                            <ExportJobTable/>
                        </div>
                    )
                }}/>
                <Route path={[BrowserRoutes.dataSyncAudit]} render={({match}) => {
                    return (
                        <div style={{padding: 24}}>
                            <DataSyncAuditTable/>
                        </div>
                    )
                }}/>
                <Redirect to={BrowserRoutes.users}/>
            </Switch>
        </div>
    )
}

export default withRouter(SettingsContainer);